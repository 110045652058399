<template>
  <a href="https://t.me/info_profitads" target="_blank">
      <img src="../../assets/icons/icon-telegram.svg" alt="telegram icon" />
      <div class="outside-link__content">
          <p>{{ $t('linkElement.text1') }}</p>
          <p>{{ $t('linkElement.text2') }}</p>
      </div>
  </a>
</template>

<script>
export default {
  name: "LinkElement"
}
</script>

<style scoped lang="scss">

@import "@/assets/styles/helpers/variables";

.outside-link {
width: 100%;
padding: 16px;
display: flex;
align-items: center;
justify-content: flex-start;
border: 1px solid $primary;
border-radius: 4px;

&__content {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $primary;
  margin-left: 1.6rem;
}
}
.app-sidebar__link {
  margin-top: 2rem;
}
</style>