<template>
  <div class="history-page page">
    <div class="history-page__title" :class="{'locale-usa': locale==='USA'}">
      <Title :text="$t('history.events')" />
      <div class="history-page__sum">
        {{ $t('history.total_amount') }}:
        <span>{{ sum ? numberWithSpaces(sum).split('.')[0] + " " + $t('currency.currency_symbol') : 0}}</span>
      </div>
    </div>
    <div class="history-page__search">
      <Search @findValue="changeSearchText" :placeholder="$t('search_props.placeholder')" />
      <MultiSelector
        :title="$t('selector_props.event_type')"
        :options="transactionTypesItems"
        class="history-page__select-event"
        :hasSearch="false"
        :hasSelectAll="true"
        :selected="getPreselectedValues"
        :key="getPreselectedValues.length"
        hasDebounce
        @handleChange="getNewData"
      />
      <DatePicker
        :title="$t('data_picker.title')"
        @changeDates="changeDate"
      />
    </div>
    <div class="history-page__table">
      <HistoryTable />

      <div class="history-page__pagination">
        <el-pagination
          v-if="pagination && pagination.total >=10 "
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="10"
          :current-page="pagination.currentPage"
          @current-change="getNewPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/elements/Title";
import Search from "@/components/sort/Search";
// import Selector from "@/components/sort/Selector";
import DatePicker from "@/components/sort/DatePicker";
import HistoryTable from "@/components/blocks/HistoryTable";
import fixDateFormatTransactions from '@/mixin/fixDate'
import numberWithSpaces from '@/mixin/numberWithSpaces'
import MultiSelector from '../components/sort/MultiSelector.vue';
import { clearQuery, changeQuery } from "@/utils/helpers"

export default {
  name: "History",
  components: {
    Title,
    Search,
    // Selector,
    DatePicker,
    HistoryTable,
    MultiSelector,
  },
  mixins: [fixDateFormatTransactions, numberWithSpaces],
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      loading: false,
      params: {
        per_page: 10,
      },
    }
  },
  watch: {
    $route() {
      this.getData()
    },
    activeAccount: {
      deep: true,
      handler(val) {
        if (val) {
          this.getTransactions()
          this.getData()
        }
      }
    },
  },
  computed: {
    getPreselectedValues() {
      if (this.$route.query.type_id && this.transactionTypesItems) {
        return this.$route.query.type_id.split(',').map((typeId) => {
          return this.transactionTypesItems.find((item) => item.id === +typeId);
        }).filter(item => item !== undefined); // Ensure no undefined items are returned
      } 
      return []
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    pagination() {
      return this.$store.state.Ad.paginationEvents
    },
    transactionTypes() {
      return this.$store.state.Ad.transactionTypes
    },
    transactionTypesItems() {
      const arr = []
      if (this.transactionTypes && this.transactionTypes.length) {
        this.transactionTypes.forEach((item) => {
          arr.push({ name: item.name, id: item.type_id })
        })
      }
      return arr
    },
    sum() {
      return this.$store.state.Ad.sum
    },
  },
  methods: {
    getNewPage(page) {
      const query = { ...this.$route.query, page }
      this.$router.push({query})
    },
    getData() {
      const { type_id, ...rest } = this.$route.query
      this.params = {
        ...rest,
        ...(this.$route.query.type_id && { types: this.$route.query.type_id.split(',') })
      }

      const data = {
        id: this.activeAccount.id,
        params: this.params,
      }

      this.$store.dispatch('Ad/getHistory', data)
    },
    changeSearchText(val) {
      if (!val) {
        const query = {...this.$route.query, page: 1, search_text: null }
        // this.$router.push({ query })
        this.$router.push({ path: this.$route.path, query })
      } else {
        const query = {...this.$route.query, page: 1, search_text: val }
        // this.$router.push({ query })
        this.$router.push({ path: this.$route.path, query })
      }
    },
    getNewData(val) {
      let currentTypeIds = this.$route.query.type_id 
        ? this.$route.query.type_id.split(',').map(Number) 
        : [];

      currentTypeIds = val.map((item) => item.id);

      if (currentTypeIds && currentTypeIds.length) {
        this.$router.push({ path: this.$route.path, query: {
          ...this.$route.query,
          page: 1,
          type_id: currentTypeIds.join(',')
        }});
      } else {
        const { type_id, ...rest } = this.$route.query
        this.$router.push({ path: this.$route.path, query: rest });
      }
    },
    changeDate(val) {
      if (val 
          && this.fixDateFormatTransactions(val.startDate)!==this.$route.query.date_from 
          && this.fixDateFormatTransactions(val.endDate, true)!== this.$route.query.date_to) {
        const query = {
          ...this.$route.query, page: 1,
          date_from: this.fixDateFormatTransactions(val.startDate),
          date_to: this.fixDateFormatTransactions(val.endDate, true),
        }
        // this.$router.push({ query })
        this.$router.push({ path: this.$route.path, query })
       
      } else {
        this.getData()
      }
    },
    getTransactions() {
      const data = {
        id: this.activeAccount.id,
        params: this.params,
      }
      this.$store.dispatch('Ad/getTransactioTypes', data)
    },
  },
  mounted() {
    if (this.activeAccount) {
      this.getTransactions()
    }
  },
}
</script>

<style scoped lang="scss">
  @import '@/assets/styles/helpers/variables';
  @import '@/assets/styles/helpers/mixin';

  .history-page {
    &__select-event {
      max-width: 19.3rem;
    }
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4rem;
      &.locale-usa {
        font-family: 'Gilroy';
        font-style: normal;
        .title {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }

    &__sum {
      display: flex;
      align-items: center;
      font-size: 2.3rem;
      font-weight: 700;
      line-height: 1.7rem;
      color: $basicBlack;
      padding-left: 3rem;

      span {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var(--primary);
        padding-left: 1rem;
      }
    }

    &__search {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      .search {
        flex: 1 0 auto;
      }

      .search, .selector {
        margin-right: 3.5rem;
      }
    }

    @include below(993px) {

      &__title {
        margin-bottom: 24px;

        .title {
          font-size: 24px;
        }
      }

      &__sum {
        font-size: 22px;
        line-height: 1.4;

        span {
          font-size: 16px;
        }
      }

      &__search {
        margin-bottom: 16px;

        .search {
          width: 40%;
        }
      }
    }

    @include below(769px) {

      &__title {
        margin-bottom: 16px;

        .title {
          font-size: 18px;
        }
      }

      &__sum {
        font-size: 14px;
        line-height: 1.4;
        padding-left: 16px;

        span {
          font-size: 12px;
          padding-left: 4px;
        }
      }

      &__search {
        flex-direction: column;

        .search, .selector {
          width: 100%;
          margin-right: 0;
          margin-bottom: 8px;
        }

        .date-picker {
          width: 100%;
        }
      }
    }
  }

  .selector {

    &__content {
      display: none;
    }

    &__value {
      white-space: nowrap;
      overflow: hidden;
    }
  }
</style>
