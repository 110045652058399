<template>
  <div class="tech-works">
    <div class="tech-works__block">
      <div class="tech-works__title">
        Сайт закрыт
      </div>
      <div class="tech-works__text">
        В настоящий момент на сайте ведутся технические работы. Скоро все заработает - обязательно возвращайтесь!
      </div>
      <a href="/" class="tech-works__link">
        На главную
      </a>
    </div>
    <div class="tech-works__img">
      <img src="../assets/images/works.svg" alt="technical works">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TechnicalWork',
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";

.tech-works {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 14rem);

  &__block {
    width: 40%;
  }

  &__title {
    font-size: 5rem;
    font-weight: 700;
    line-height: 5.9rem;
    color: $basicBlack;
    margin: 0 0 4rem;
  }

  &__text {
    font-size: 2.3rem;
    font-weight: 400;
    line-height: 2.7rem;
    color: $basicBlack;
    margin: 0 0 4rem;
  }

  &__link {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.9;
    color: $basicWhite;
    background-color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;
    text-decoration: none;
    transition: color .4s linear, background-color .4s linear;
    padding: .7rem 7.5rem;

    &:hover {
      color: $primary;
      background-color: transparent;
    }
  }

  &__img {
    width: 60%;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
</style>
