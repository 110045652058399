let formValidate = {
  data() {
    return {
      isHidePassword: true,
    }
  },
  computed: {
    authErrors() {
      return this.$store.state.Auth.errors
    },
    isError() {
      let error = false
      for (const key in this.errors) {
        if (this.errors[key]) error = true
      }
      // for (const key in this.additional_banks_errors.EUR) {
      //   if (this.additional_banks_errors.EUR[key]) error = true
      // }
      return error
    },
    // euroError() {
    //   if(this.additional_banks.EUR) {
    //     return this.additional_banks.EUR
    //   }
    //   return ''
    // } 
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.$store.commit('Auth/setData', { label: 'errors', data: null })
        for (const key in this.errors) {
          this.errors[key] = null
        }
      }
    },
    // euroError: {
    //   deep: true,
    //   handler () {
    //     this.$store.commit('Auth/setData', { label: 'errors', data: null })
    //     for (const key in this.additional_banks_errors.EUR) {
    //       this.additional_banks_errors.EUR[key] = null
    //     }
    //   }
    // }
  },
  methods: {
    validateEmail() {
      // eslint-disable-next-line no-useless-escape
      const regEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!regEmail.test(String(this.form.email).toLowerCase())) {
        this.errors.email = this.$t('form_validate.error_email')
      }
    },
    validatePassword() {
      const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/
      if (!regPassword.test(String(this.form.password)) || this.form.password.length < 6) {
        this.errors.password = this.$t('form_validate.error_password')
      }
    },
    validateInn() {
      if(isNaN(this.form.inn)) {
        this.errors.inn = this.$t('form_validate.error_inn')
      }
      if(!isNaN(this.form.inn ) && (Number(this.form.inn) < 0 || this.form.inn.length < 12 || this.form.inn.length !== 12)) {
        this.errors.inn = this.$t('form_validate.error_inn')
      }
    }
  }
};

export default formValidate;
