// import VueI18n from 'vue-i18n'

const state = () => ({
    language: null,
})

const getters = {
    language: state => state.language
}

const mutations = {
    changeLang(state, lang) {
       state.language = lang;
    },

    initLocalLang(state, value) { 
        state.language = value
    }
}

export default {
    state,
    getters,
    mutations
};