export const ContractTypeKeys = {
  SERVICE_AGREEMENT: "ServiceAgreement",
  MEDIATION_CONTRACT: "MediationContract",
  ADDITIONAL_AGREEMENT: "AdditionalAgreement"
}

export const ContractType = {
  [ContractTypeKeys.SERVICE_AGREEMENT]: 'Договор оказания услуг',
  [ContractTypeKeys.MEDIATION_CONTRACT]: 'Посреднический договор',
  [ContractTypeKeys.ADDITIONAL_AGREEMENT]: 'Доп. соглашение'
}

export const VatType = {
  WITH_VAT: 'с НДС',
  WITHOUT_VAT: 'без НДС'
}

export const ContractVariant = {
  Distribution: 'Договор на распространение рекламы',
  OrgDistribution: 'Договор на организацию распространения рекламы',
  Representation: 'Представительство',
  Mediation: 'Посредничество',
  Other: 'Иное'
}

export const InteractionType = {
  Contracting: 'Заключение договоров',
  Distribution: 'Действия в целях распространения рекламы',
  CommercialRepresentation: 'Коммерческое представительство',
  Other: 'Иное',
}

export const AgentActingForPublisherTypeKeys = {
  RR: 'RR',
  RD: 'RD'
}

export const AgentActingForPublisherType = {
  [AgentActingForPublisherTypeKeys.RD]: 'В интересах РД',
  [AgentActingForPublisherTypeKeys.RR]: 'В интересах РР',
}