<template>
  <div class="form-element">
    <div class="form-element__title">
      {{ title }}
    </div>
    <div v-if="message" class="form-element__message">
      {{ message }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormElement',
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.form {
  &-element {
    width: 43rem;
    background: #FFFFFF;
    box-shadow: 0 4px 15px rgba(10, 31, 55, 0.1);
    border-radius: 5px;
    padding: 4rem;

    &__title {
      font-weight: bold;
      font-size: 2rem;
      line-height: 2.3rem;
      color: #333;
      margin-bottom: 2rem;
    }

    &__message {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $text;
    }

    .button {
      width: 100%;
      height: 5rem;
      margin: 3rem 0 2rem;
    }
  }

  &-input {
    position: relative;
    width: 100%;
    margin-top: 2rem;
    transition: .3s;

    &:first-of-type {
      margin-top: 0;
    }

    input, textarea {
      width: 100%;
      height: 5rem;
      border: 1px solid #BFC2C6;
      border-radius: 2px;
      font-size: 1.6rem;
      color: #333;
      padding: 1.6rem 1.4rem 0.8rem;
      position: relative;
      z-index: 1;
      background: transparent;

      &:focus {
        outline: none;

        & + .form-input__label {
          font-size: 1.2rem;
          line-height: 1.4rem;
          top: 0.8rem;
        }
      }
    }

    .el-date-editor {
      width: 100%;
    }

    .el-input__inner {
      padding: 1.6rem 1.4rem 0.8rem;
    }

    textarea {
      width: 100%;
      border: 1px solid #BFC2C6;
      border-radius: 2px;
      font-size: 1.6rem;
      color: #333;
      padding: 1.6rem 1.4rem 0.8rem;
      position: relative;
      z-index: 1;
      background: transparent;
      resize: none;

      &:focus {
        outline: none;

        & + .form-input__label {
          font-size: 1.2rem;
          line-height: 1.4rem;
          top: 0.8rem;
        }
      }
    }

    &--disabled {
      opacity: .5;
    }

    &--full {
      .form-input__label {
        font-size: 1.2rem;
        line-height: 1.4rem;
        top: 0.8rem;
      }
    }

    &--error {
      input {
        border: 1px solid $errorValidation;
      }
    }

    &__label {
      position: absolute;
      z-index: 0;
      left: 1.4rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #878B93;
      top: 1.6rem;
      transition: 0.3s ease-out;
    }

    &__error {
      color: $errorValidation;
      font-size: 1.2rem;
    }

    &__icon {
      position: absolute;
      right: 1.7rem;
      top: 1.5rem;
      z-index: 2;
      cursor: pointer;
    }

    &__info {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: 3;
      background: $basicWhite;
      box-shadow: 0 4px 15px rgba(10, 31, 55, 0.1);
      border-radius: 5px;
      padding: 1rem;
      font-size: 1.2rem;
      color: #878B93;

      &-item {
        &::before {
          content: '\2022';
          margin-right: 0.7rem;
        }

        &--success {
          color: $success;

          &::before {
            content: '\2714';
          }
        }
      }
    }
  }

  @include below(577px) {

    &-element {
      width: 288px;
      padding: 16px;

      &__title {
        font-size: 18px;
        text-align: center;
        margin-bottom: 16px;
      }

      &__message {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 16px;
      }

      .button {
        height: auto;
        padding: 8px 16px;
        margin-top: 0;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 1.4;
      }
    }

    &-input {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }
}
</style>
