<template>
  <div class="payment">
    payment
  </div>
</template>

<script>
export default {
  name: "Payment",
  mounted() {
   
  }
}
</script>

<style scoped>

</style>
