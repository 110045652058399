const state = () => ({
    currency: null,
})

const getters = {
    getCurrency: (state, getters) => {
        if (getters.language === 'en') {
            state.currency = 'USD'
        } else if (getters.language === 'ru') {
            state.currency = 'RUB'
        }
        return state.currency
    }
}

const mutations = {
    changeCurrency(state, value) {
        state.currency = value
    },

    initLocalCurrency(state, value) {
        state.currency = value
    }
}

export default {
    state,
    getters,
    mutations
};