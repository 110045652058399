<template>
  <div v-loading="creativesLoading" class="creatives">
    <el-table 
      v-if="(!creativesLoading  || !creativesLoading && hasActiveFilter) && (getInitialContractId || getFinalContractId)"
      :data="getCreatives"
      style="width: 100%"
      class="creatives__table"
      :class="{'creatives__table_no-items': !getCreatives.length && !creativesLoading && hasActiveFilter}"
      stripe
      :key="tableComponentKey"
    >
      <el-table-column min-width="150">
        <template slot="header">
          <TableHeaderFilterSelector class="creatives__table-header-cell-filter" :isOpen="!!filters.status">
            <template #content>
              <div>Статус</div>
            </template>
            <template #options>
              <FloatSelector 
                class="creatives__table-filter"
                required
                :options="Object.keys(status).map((key) => ({id: key, name: status[key]}))"
                :select-fitrst-item="false"
                :default-select-item-id="filters.status"
                :popover-width="220"
                @handleChange="(val) => setFilter('status', val)"
              >
                <template #icons>
                  <img
                    v-if="filters.status"
                    src="@/assets/icons/icon-close.svg"
                    class="creatives__table-filter-icon"
                    @click="$event.stopPropagation(); setFilter('status', null)"
                  >
                </template>
              </FloatSelector>
            </template>
          </TableHeaderFilterSelector>
        </template>
        <template v-if="getCreatives && getCreatives.length"  slot-scope="scope">
          <div class="creatives__table-cell">
            <div 
              class="creatives__table-status"
              :class="{
                'creatives__table-status_active': scope.row.status === StatusType.ACTIVE,
                'creatives__table-status_registration-required': scope.row.status === StatusType.REGISTRATION_REQUIRED,
                'creatives__table-status_registration-error': scope.row.status === StatusType.REGISTRATION_ERROR,
                'creatives__table-status_registering': scope.row.status === StatusType.REGISTERING,
              }"
            >{{ status[scope.row.status] }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
      >
        <template slot="header">
          <div class="creatives__table-header-cell">
            <div>Токен</div>  
            <div>креатива (erid)</div>  
          </div>  
        </template>
        <template v-if="getCreatives && getCreatives.length"  slot-scope="scope">
          <div class="creatives__table-cell creatives__table-cell_center-flex">
            {{ scope.row.erid }}
            <svg class="creatives__table-row-icon" @click="copyErid(scope.row.erid)" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.3333 8.93464V10.9346C11.3333 13.6013 10.2666 14.668 7.59998 14.668H5.06665C2.39998 14.668 1.33331 13.6013 1.33331 10.9346V8.4013C1.33331 5.73464 2.39998 4.66797 5.06665 4.66797H7.06665" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.3333 8.93464H9.19998C7.59998 8.93464 7.06665 8.4013 7.06665 6.8013V4.66797L11.3333 8.93464Z" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.73334 1.33203H10.4" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.66669 3.33203C4.66669 2.22536 5.56002 1.33203 6.66669 1.33203H8.41335" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.6667 5.33203V9.4587C14.6667 10.492 13.8267 11.332 12.7933 11.332" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.6667 5.33203H12.6667C11.1667 5.33203 10.6667 4.83203 10.6667 3.33203V1.33203L14.6667 5.33203Z" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="160"
      >
        <template slot="header">
          <div class="creatives__table-header-cell">
            <div>Наименование</div>  
            <div>брони</div>  
          </div>  
        </template>
        <template v-if="getCreatives && getCreatives.length"  slot-scope="scope">
          <div class="creatives__table-cell" :title="scope.row.creativeGroupName">
            {{ scope.row.creativeGroupName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="160"
      >
        <template slot="header">
          <TableHeaderFilterSelector 
            class="creatives__table-header-cell-filter" 
            :isOpen="!!filters.creativeGroupStartDate"
          >
            <template #content>
              <div>Период брони</div>
            </template>
            <template #options>
              <SingleDatePicker
                class="creatives__table-datepicker creatives__table-filter"
                :title="''"
                appendToBody
                :is-single-date-picker="false"
                :value="{ startDate: filters.creativeGroupStartDate, endDate:  filters.creativeGroupEndDate}"
                @changeDates="changeDates"
              />
            </template>
          </TableHeaderFilterSelector>
        </template>
        <template v-if="getCreatives && getCreatives.length"  slot-scope="scope">
          <div class="creatives__table-cell">
            <div>{{ fixDateFormatWithDots(scope.row.creativeGroupStartDate) }}</div>
            <div>{{ fixDateFormatWithDots(scope.row.creativeGroupEndDate) }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="170"
      >
        <template slot="header">
          <!-- <div class="creatives__table-header-cell">
            <div>Общее описание</div>  
          </div> -->
          <TableHeaderFilterSelector class="creatives__table-header-cell-filter" :isOpen="!!generalInfo.length">
            <template #content>
              <div>Общее описание</div> 
            </template>
            <template #options>
              <div class="form-input creatives__table-header-cell" :class="[{'form-input--full' : generalInfo}]">
                <input 
                  :value="generalInfo"
                  type="text" 
                  required
                  @input="debFilterCreativesByDesc(getCreatives, $event.target.value)"
                >
                <!-- <span class="form-input__label">Общее описание</span> -->
              </div>  
            </template> 
          </TableHeaderFilterSelector>
        </template>
        <template v-if="getCreatives && getCreatives.length"  slot-scope="scope">
          <div class="creatives__table-cell" :title="scope.row.description">
            {{ scope.row.description }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="190"
        align="left"
      >
        <template slot="header">
          <div class="creatives__table-header-cell">
            <div>Форма распространения</div>  
          </div>  
        </template>
        <template slot-scope="scope">
          <div class="creatives__table-cell">
            {{ AdForm[scope.row.form] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          width="70"
          align="left"
        >
          <template slot="header"></template>
          <template v-if="getCreatives && getCreatives.length"  slot-scope="scope">
            <div class="creatives__table-cell">
              <svg class="creatives__table-row-icon" @click="openBindingCreativeDialog(scope.row)" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99992 19.1654H13.9999C17.3333 19.1654 18.6666 17.832 18.6666 14.4987V10.4987C18.6666 7.16536 17.3333 5.83203 13.9999 5.83203H9.99992C6.66659 5.83203 5.33325 7.16536 5.33325 10.4987V14.4987C5.33325 17.832 6.66659 19.1654 9.99992 19.1654Z" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.1599 14.8551L13.5066 12.5018L11.1599 10.1484" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </template>
        </el-table-column>
    </el-table>
    <div v-if="(!getClientId && !getCreatives.length && !creativesLoading && !hasActiveFilter) || !(getInitialContractId || getFinalContractId)" class="creatives__placeholder">
      <div class="creatives__placeholder-image-wrapper">
        <img 
          class="creatives__placeholder-image" 
          :src="getPlaceholderPicture" 
          alt="img" 
          title="img"
        >
      </div>
      <div class="creatives__placeholder-title">
        Для загрузки креативов выберите изначальный, доходный или расходный договор на соответствующих вкладках
      </div>
    </div>
    <div class="creatives__pagination">
      <el-pagination
        v-if="(pagination && pagination.total >= pagination.pageSize && !creativesLoading) && (getInitialContractId || getFinalContractId)"
        layout="prev, pager, next"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :current-page="pagination.currentPage"
        @current-change="setPagination({ ...pagination, currentPage: $event })"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { status, StatusType } from "@/utils/clients"
import { ContractType } from "@/utils/contracts"
import { AdType, AdForm } from "@/utils/creatives"
import fixDateFormat from '@/mixin/fixDate'
import FloatSelector from "@/components/sort/FloatSelector";
import SingleDatePicker from '@/components/sort/SingleDatePicker';
import { copyText } from "@/utils/helpers" 
import TableHeaderFilterSelector from "@/components/sort/TableHeaderFilterSelector";
import { debounce } from "@/utils/helpers";

export default {
  name: 'creatives',
  mixins: [fixDateFormat],
  components: {
    FloatSelector,
    SingleDatePicker,
    TableHeaderFilterSelector
  },
  data() {
    return {
      AdType,
      AdForm,
      status,
      StatusType,
      ContractType,
      bindingCreativeItem: `
        <el-table-column
          width="50"
          align="left"
        >
          <template slot="header"></template>
          <template slot-scope="scope">
            <div class="creatives__table-cell">
              <svg class="creatives__table-row-icon" @click="openBindingCreativeDialog(scope.row)" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99992 19.1654H13.9999C17.3333 19.1654 18.6666 17.832 18.6666 14.4987V10.4987C18.6666 7.16536 17.3333 5.83203 13.9999 5.83203H9.99992C6.66659 5.83203 5.33325 7.16536 5.33325 10.4987V14.4987C5.33325 17.832 6.66659 19.1654 9.99992 19.1654Z" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.1599 14.8551L13.5066 12.5018L11.1599 10.1484" stroke="#7F858D" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </template>
        </el-table-column>
      `,
      generalInfo: ''
    }
  },
  computed: {
    ...mapState('Creatives', [
      'filters', 
      'tableComponentKey', 
      'creativesLoading', 
      'creatives',
      'pagination'
    ]),
    // getCreatives() {
    //   const creatives = [...this.creatives]
    //   return creatives.splice(
    //     (this.pagination.currentPage - 1) * this.pagination.pageSize, 
    //     this.pagination.currentPage * this.pagination.pageSize
    //   )
    // },
    getCreatives: {
      get() {
        const startIndex = (this.pagination.currentPage - 1) * this.pagination.pageSize;
        const endIndex = this.pagination.currentPage * this.pagination.pageSize;
        return this.creatives.slice(startIndex, endIndex);
      },
      set(value) {
        this.$store.commit('Creatives/setCreatives', value);
      },
    },
    getClientId() {
      return this.$route.query.clientId
    },
    getInitialContractId() {
      return this.$route.query.initialContractId
    },
    getFinalContractId() {
      return this.$route.query.finalContractId
    },
    getPlaceholderPicture() {
      return require(`@/assets/images/accounts-default.svg`)
    },
    hasActiveFilter() {
      return Object.values(this.filters).some((filterValue) => filterValue)
    },
  },
  methods: {
    ...mapMutations('Creatives', [
      'increaseTableComponentKey', 
      'clearFilters', 
      'setPagination',
      'clearPagination',
      'setChosenCreative'
    ]),
    openBindingCreativeDialog(creative) {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BindCreative' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      this.setChosenCreative(creative)
    },
    copyErid(text) {
      copyText(text, () => {
        this.$notify({
          message: 'Токен скопирован!',
          type: 'success'
        });
      })
    },
    fetchCreatives() {
      this.$store.dispatch('Creatives/getCreatives', {
        ...this.filters,
        ...(this.filters.creativeGroupStartDate && { creativeGroupStartDate: this.fixDateFormatNumbers(this.filters.creativeGroupStartDate) }),
        ...(this.filters.creativeGroupEndDate && { creativeGroupEndDate: this.fixDateFormatNumbers(this.filters.creativeGroupEndDate) }),
        ...(this.getFinalContractId && { finalContractId: this.getFinalContractId }),
        ...(this.getInitialContractId && { initialContractId: this.getInitialContractId }),
        ...(this.getClientId && { contractorId: this.getClientId }),
      }).then((creatives) => {
        this.increaseTableComponentKey()
        // console.log('creatives', creatives)
        this.setPagination({
          pageSize: 20,
          total: creatives.length,
          currentPage: 1,
        })
      })
    },
    setFilter(filterName, filterValue) {
      this.$store.commit('Creatives/setFilter', {
        filterName,
        filterValue
      })
      this.fetchCreatives()
    },
    changeDates({ startDate, endDate}) {
      this.$store.commit('Creatives/setFilter', { 
        filterName: 'creativeGroupStartDate', 
        filterValue: startDate 
      })
      this.$store.commit('Creatives/setFilter', { 
        filterName: 'creativeGroupEndDate', 
        filterValue: endDate 
      })
      this.fetchCreatives()
    },
    filterCreativesByDescription(creatives, searchString) {
      this.generalInfo = searchString
        if (!searchString) {
          this.fetchCreatives()
          return creatives;
        } 
        const filteredCreatives = creatives.filter(creative => {
            const description = creative.description || '';
            return description.toLowerCase().includes(searchString.toLowerCase());
        });
        this.getCreatives = filteredCreatives
        return filteredCreatives;
    }
  },
  created() {
    if (this.getFinalContractId || this.getInitialContractId) {
      this.fetchCreatives()
    }
    this.debFilterCreativesByDesc = debounce(this.filterCreativesByDescription, 500)
  },
  destroyed() {
    this.clearFilters()  
    this.clearPagination()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.creatives {
  &__table-datepicker {
    width: 140px;
  }
  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }
  
  &__table-row-icon {
    cursor: pointer;
  }

  &__table {
    &-header-cell-filter {
      height: 100%;
      min-height: 5rem;
    }

    &-header-cell {
      background: $gray8;
      color: $gray2;
      padding: .9rem 1.2rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
      border-radius: .4rem;
      min-height: inherit;
      height: 100%;
      min-height: 5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      &.form-input {
        margin-top: 1rem;
      }
      input {
        background-color: #fff;
        padding-top: 0.8rem;
      }
    }
    &-header-cell.form-input {
      padding: 0;
    }

    &-header-cell-name {
      height: 6.9rem;
    }

    &-filter {
      margin-top: 1rem;
    }

    &-filter-icon {
      cursor: pointer;
      z-index: 1;
      width: 1.6rem;
      height: 1.6rem;
    }

    &-cell {
      padding: 0 20px;
      white-space: normal;
      font-size: 1.2rem;
      line-height: normal;
      &_center-flex {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
    }
    
    &-status {
      border-radius: .4rem;
      padding: .2rem .8rem;
      font-size: 1.4rem;
      color: #646D79;
      width: max-content;

      &_active {
        color: $green2;
        background: $green3;
      }
      &_registration-required {
        color: $blue2;
        background: $blue3;
      }
      &_registration-error {
        color: $redClassic;
        background: $red2;
      }
      &_registering {
        background: $primary;
        color: $basicWhite;
      }
    }
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8.2rem;

    &-image {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 6.4rem;
    }

    &-title {
      max-width: 43rem;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.3rem;
      text-align: center;
      color: $basicBlack;
      margin-bottom: 5.4rem;
    }
  }
}
</style>
<style lang="scss">
.creatives__table.el-table td:last-child {
  text-align: left;
}

.creatives__table_no-items .el-table__header-wrapper {
  overflow: auto;
}

.creatives__table .el-table__header-wrapper th.el-table__cell {
  vertical-align: top;
  padding-left: 10px;
  padding-right: 10px;
}

.creatives__table .el-table__body-wrapper tr td {
  text-align: left;
}

.creatives {
  &__table {
    &-datepicker .reportrange-text {
      padding: 1.5rem;
    }
    &-filter .float-selector__value {
      color: var(--black);
    }
    &-filter .float-selector__title {
      font-size: 1.6rem;
    }
    &-filter .selector__content {
      width: 100%;
    }
  }
}
</style>