import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
    representativesList: null,
    pagination: null,
    representativesAllList: null,
    editRepresentative: null,
    errors: null,
    permissions: [],
    representativeBAInfo: null,
})
const getters = {}
const mutations = {
  setData (state, data) {
    state[data.label] = data.data
  },
  setRepresentative(state, data) {
    state.representativesAllList = state.representativesAllList.map(el => {
      if(el.id === data.id) {
        el.role = data.user.role
        el.email = data.user.email
      }
      return el
    })
  },
  addRepresentative(state, data) {
    data.created_at = new Date()
    state.representativesList.push(data)
  },
}
const actions = {
    // geRepresentativesList ({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //       axios({
    //         url: `${apiUrl}/admin/clients`,
    //         method: 'GET',
    //         params: data.params,
    //         headers: { 'Authorization': `Bearer ${data.token}` }
    //       })
    //         .then(resp => {
    //           commit('setData', { label: 'representativesList', data: resp.data.data })
    //           const pagination = {
    //             total: resp.data.total,
    //             currentPage: resp.data.current_page
    //           }
    //           commit('setData', { label: 'pagination', data: pagination })
    //           resolve(resp)
    //         })
    //         .catch(err => {
    //           console.log(err.response)
    //           reject(err)
    //         })
    //     })
    //   },
  getRepresentativesList ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-partners/partners/${data.id}`,
        method: 'GET',
        params: data.params,
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          commit('setData', { label: 'representativesList', data: resp.data.data })
          const pagination = {
            total: resp.data.total,
            currentPage: resp.data.current_page
          }
          commit('setData', { label: 'pagination', data: pagination })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getAllRepresentativesList ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/admin/users`,
        method: 'GET',
        params: data.params,
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          commit('setData', { label: 'representativesAllList', data: resp.data.data })
          const pagination = {
            total: resp.data.meta.total,
            currentPage: resp.data.meta.current_page
          }
          commit('setData', { label: 'pagination', data: pagination })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  editRepresentative({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + '/business-partners/bind-partner',
        data: data.data.representative,
        method: 'POST',
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  createRepresentative({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + '/business-partners/add-partner',
        data: data.data.representative,
        method: 'POST' ,
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          commit('addRepresentative', resp.data.data)
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  getPermissions ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + '/business-partners/permissions',
        method: 'GET',
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
      .then(resp => {
        commit('setData', { label: 'permissions', data: resp.data.data })
        resolve(resp)
      })
      .catch(err => {
        console.log(err.response)
        reject(err)
      })
    })
  },
  unbindRepresentative({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + '/business-partners/unbind-partner',
        data: data.data,
        method: 'POST',
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  getRepresentativeBAInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-partners/accounts/${data.user_id}/${data.ba_id}` ,
        method: 'GET',
        // headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          commit('setData', { label: 'representativeBAInfo', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getAddAccountsForRepresentative({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-partners/accounts/${data.user_id}/${data.ba_id}/${data.service}` ,
        method: 'GET',
        // headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          commit('setData', { label: 'accountsList', data: resp.data.data.data })
          commit('setData', {
            label: 'pagination',
            data: { total : resp.data.data.total, currentPage: resp.data.data.current_page, lastPage: resp.data.data.last_page }})
          resolve(resp.data.data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getRepresentativeAccess({ commit, state}, permissionToCheck) {
    commit('setData', { label: 'getAccepted', data: state.representativeBAInfo.includes(permissionToCheck) })
    return state.representativeBAInfo.includes(permissionToCheck)
  },
 }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
