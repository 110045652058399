<template>
  <div 
    v-if="options && options.length" 
    class="float-selector" 
    :class="{ 'float-selector--disabled': disabled }" 
    v-loading="loading"
  >
    <el-popover
      ref="popoverRef"
      key="trigger"
      trigger="click"
      placement="bottom"
      :width="popoverWidth"
      popper-class="float-selector__popover"
    >
      <template #reference>
        <div 
          class="float-selector__content" 
          @click="showOptions = !showOptions"
        >
          <div 
            v-if="title"
            class="float-selector__title" 
            :class="{ 'float-selector__title--disabled': !selectedValue }"
          >
            {{ title }}
          </div>
          <div class="float-selector__value" key="origin">
            {{ selectedValue }}
          </div>
          <div class="float-selector__icons-group">
            <img 
              class="float-selector__arrow-icon"
              :class="{ 'float-selector__arrow-icon--open': showOptions }" 
              src="@/assets/icons/icon-arrow.svg" 
              alt="arrow"
            >
            <slot name="icons"/>
          </div>
        </div>
      </template>
      <div 
        class="float-selector__options" 
        :class="{'float-selector__options--limit-height': limitOptionsHeight}"
      >
        <div 
          v-for="option in options" 
          :key="option.id" 
          class="float-selector__options-item"
          :class="{ 'float-selector__options-item--disabled': option?.disabledOption }"
          @click="chooseOption(option.name, option.id)"
        >
          {{ option.name }}
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "Floatfloat-selector",
  props: {
    popoverWidth: {
      type: Number,
      default: 200
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    selectFitrstItem: {
      type: Boolean,
      default: true
    },
    sum_available_in:{
      type: Number,
      default: null
    },
    ruValues: {
      type: Array,
      default: null
    },
    enValues: {
      type: Array,
      default: null
    },
    is_for_legals: {
      type: [Number, String],
      default: null
    },
    defaultSelectItemId: null,
    limitOptionsHeight: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      selectedValue: this.selectFitrstItem ? this.options[0].name : null,
      showOptions: false,
      visible: false,
      trigger: 'manual'
    }
  },
  computed: {
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    }
  },
  watch: {
    getLang: {
      deep: true,
      handler() {
        this.translateSelectedValue()
      }
    },
    defaultSelectItemId: {
      handler(value) {
        if (value) {
          const option = this.options.find((option) => {
            return option.id === value
          })
          this.selectedValue = option.name
          this.showOptions = false
        }
      },
      immediate: true,
      deep: true
    },
    fileType(value) {
      if(value) {
        console.log(value)
        this.selectedValue =''
      }
    }
  },
  methods: {
    updateSelectedValue() {
      if(this.sum_available_in) {
        this.selectedValue = this.options.find((item) => item.id === this.sum_available_in).name
      }
      if(this.is_for_legals === 0) {
        this.selectedValue = this.options.find((item) => item.id === this.is_for_legals).name
      }
      if(this.is_for_legals) {
        this.selectedValue = this.options.find((item) => item.id === this.is_for_legals).name
      }
    },
    translateSelectedValue() {
      if (this.enValues && this.ruValues) {
        if (this.$i18n._vm.locale === 'ru' && this.enValues.includes(this.selectedValue)) {
          this.selectedValue = this.ruValues[this.enValues.indexOf(this.selectedValue)]
        } else if (this.$i18n._vm.locale === 'en' && this.ruValues.includes(this.selectedValue)) {
          this.selectedValue = this.enValues[this.ruValues.indexOf(this.selectedValue)]
        }
      }
      return this.selectedValue
    },
    chooseOption(name, id) {
      this.$refs.popoverRef.doToggle()
      this.selectedValue = name
      this.showOptions = false
      this.$emit('handleChange', id)
    },
    serActiveName(name) {
      this.selectedValue = name
    },
  },
  mounted() {
    this.updateSelectedValue()
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.float-selector')) {
        this.showOptions = false
      }
    }, true)
    this.translateSelectedValue()
    this.$nextTick(() => {
      if (this.selectFitrstItem && this.origin ==='documents') {
        this.serActiveName(this.options[0].name)
      this.$emit('handleChange', this.options[0].id)
      }
    })
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixin";

.float-selector {
  position: relative;

  &--disabled {
    pointer-events: none;
    opacity: .5;
  }

  &__content {
    display: flex;
    align-items: center;
    background: $backgroundInput;
    border: 1px solid $border;
    border-radius: 2px;
    height: 5rem;
    position: relative;
    padding: 0.7rem 1.5rem;
  }

  &__title {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $textGrey;
    margin-bottom: 0.2rem;
    transition: .2s;

    &--disabled {
      position: relative;
      top: 11px;
    }
  }

  &__value {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  &__icons-group {
    display: flex;
    align-items: center;
    gap: 1rem;
    right: 1.5rem;
    top: 1.5rem;
    position: absolute;
  }

  &__arrow-icon {
    cursor: pointer;
    transition: 0.3s ease-out;

    &--open {
      transition: 0.3s ease-out;
      transform: rotate(180deg);
    }

    // &__options {
    //   position: absolute;
    //   top: calc(100% + 1rem);
    //   left: 0;
    //   width: 100%;
    //   background: $backgroundInput;
    //   border: 1px solid $border;
    //   border-radius: 2px;
    //   z-index: 1;

    //   &-item {
    //     cursor: pointer;
    //     transition: 0.3s ease-out;
    //     padding: 0.7rem 1.5rem;

    //     &:hover {
    //       background: var(--primary);
    //       color: $basicWhite;
    //     }
    //   }
    // }

    @include below(769px) {

      &__content {
        width: 100%;
        transform: rotate(180deg);
      }
    }
  }

  &__popover {
    padding: 0;
    margin-top: 5px;
  }

  &__popover .popper__arrow {
    display: none;
  }

  &__options {
    width: 100%;
    background: $backgroundInput;
    border: 1px solid $border;
    border-radius: 2px;

    &--limit-height {
      max-height: 20rem;
      overflow: auto;
    }

    &-item {
      cursor: pointer;
      transition: 0.3s ease-out;
      padding: 0.5rem 1.5rem;

      &--disabled {
        pointer-events: none;
        opacity: .5;
      }

      &:hover {
        background: #395FE4;
        color: $basicWhite;
      }
    }

    &-search {
      &.form-input {
        width: calc(100% - 20px);
        margin: 10px;
      }
    }
  }

  @include below(769px) {

    &__content {
      width: 100%;
    }
    &__options {
      width: 100%;
    }
  }
}
</style>
