<template>
  <footer class="footer">
    <div class="container-main">
      <div class="inner">
        <div class="footer__col">
          <a href="/" class="logo"><img src="@/assets/images/logo-white.svg" alt=""></a>
          <div class="logo-text" v-html="$t('footer.text-1')" />
          <br />
          <div class="logo-text" v-html="$t('footer.text-2')" />
        </div>

        <div class="footer__col">
          <div class="footer__col-title">{{ $t('footer.menu-col.title') }}</div>
          <div class="copyright">© ProfitAds, {{getFullYear}}</div>
          <ul class="menu">
            <li class="menu__item" v-if="locale==='USA'">
              <a href="https://lk.profitads.ru/agreement/" target="_blank">{{ $t('footer.menu-col.item-1') }}</a>
            </li>
            <li class="menu__item" v-if="locale==='RU'">
              <a href="https://profitads.ru/agreement/" target="_blank">{{ $t('footer.menu-col.item-1') }}</a>
            </li>
            <li class="menu__item" v-if="locale==='USA'">
              <a href="https://lk.profitads.ru/privacy-policy/" target="_blank">{{ $t('footer.menu-col.item-2') }}</a>
            </li>
            <li class="menu__item" v-if="locale==='RU'">
              <a href="https://profitads.ru/privacy-policy/" target="_blank">{{ $t('footer.menu-col.item-2') }}</a>
            </li>
            <li class="menu__item" v-if="locale==='USA'">
              <a href="https://lk.profitads.ru/dogovor-oferty/" target="_blank">{{ $t('footer.menu-col.item-3') }}</a>
            </li>
            <li class="menu__item" v-if="locale==='RU'">
              <a href="https://profitads.ru/dogovor-oferty/" target="_blank">{{ $t('footer.menu-col.item-3') }}</a>
            </li>
            <li class="menu__item" v-if="locale==='USA'">
              <a href="https://lk.profitads.ru/dogovor-oferty-fl/" target="_blank">{{ $t('footer.menu-col.item-4') }}</a>
            </li>
            <li class="menu__item" v-if="locale==='RU'">
              <a href="https://profitads.ru/dogovor-oferty-fl/" target="_blank">{{ $t('footer.menu-col.item-4') }}</a>
            </li>
          </ul>
        </div>

        <div class="footer__col">
          <div class="footer__col-title">{{ $t('footer.contacts-col.title') }}</div>
          <div class="adress" v-html="$t('footer.contacts-col.adress')" />
          <a href="mailto:info@profitads.ru" class="email">info@profitads.ru</a>
          <div class="footer__social-links small-margin">
            <a href="tg://resolve?domain=info_profitads" class="footer__tg-link">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37259 0 0 5.37259 0 12C0 18.6274 5.37259 24 12 24Z"
                  fill="white"
                />
                <path
                  d="M5.1443 11.7372C5.1443 11.7372 11.0885 9.20835 13.15 8.31787C13.9403 7.96172 16.6203 6.82191 16.6203 6.82191C16.6203 6.82191 17.8573 6.32328 17.7542 7.53427C17.7198 8.03295 17.445 9.7782 17.1701 11.666C16.7578 14.3373 16.3111 17.258 16.3111 17.258C16.3111 17.258 16.2424 18.0772 15.6583 18.2197C15.0742 18.3622 14.1121 17.7211 13.9403 17.5786C13.8028 17.4717 11.3633 15.8689 10.47 15.0853C10.2295 14.8716 9.95462 14.4442 10.5043 13.9455C11.7413 12.7701 13.2187 11.3098 14.1121 10.3837C14.5244 9.95631 14.9367 8.95901 13.2187 10.17C10.7792 11.9153 8.37408 13.5537 8.37408 13.5537C8.37408 13.5537 7.82432 13.9099 6.79355 13.5893C5.76273 13.2688 4.56015 12.8414 4.56015 12.8414C4.56015 12.8414 3.73557 12.3071 5.1443 11.7372Z"
                  fill="#395FE4"
                />
              </svg>
    
              {{ $t('footer.telegram-text') }}
            </a>
          </div>
        </div>

        <div class="footer__col">
          <ul class="menu">
            <li class="menu__item">
              <a href="https://lk.profitads.ru/login" target="_blank">{{ $t('registration.log_in') }}</a>
            </li>
            <li class="menu__item">
              <a href="https://lk.profitads.ru/registration" target="_blank">{{ $t('button_props.create_account')}}</a>
            </li>
          </ul>
          <div class="footer__social-links">
            <a href="https://t.me/+wPSCuNdrHRxlYTUy" class="footer__tg-link" target="_blank">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 24.5771C18.6274 24.5771 24 19.2046 24 12.5771C24 5.94974 18.6274 0.577148 12 0.577148C5.37259 0.577148 0 5.94974 0 12.5771C0 19.2046 5.37259 24.5771 12 24.5771Z" fill="white"/>
                <path d="M5.25439 10.1752C5.25439 9.38612 5.8767 8.73744 6.66511 8.7047L9.2743 8.59636C10.1104 8.56165 10.8071 9.23007 10.8071 10.0669V12.7627C10.8071 13.5995 10.1104 14.2679 9.2743 14.2332L6.6651 14.1249C5.8767 14.0921 5.25439 13.4435 5.25439 12.6544V10.1752Z" fill="#395FE4"/>
                <path d="M8.4724 16.0638C8.23451 15.575 8.59048 15.0059 9.13408 15.0059H10.2828C10.6893 15.0059 11.0187 15.3353 11.0187 15.7417V18.0439C11.0187 18.4503 10.6893 18.7797 10.2828 18.7797H10.2545C9.97293 18.7797 9.71605 18.6191 9.59282 18.3659L8.4724 16.0638Z" fill="#395FE4"/>
                <path d="M11.6372 9.81608C11.6372 8.97473 12.1638 8.22334 12.9546 7.93618L16.2684 6.73292C16.7482 6.55867 17.2554 6.91408 17.2554 7.42462V15.4045C17.2554 15.9151 16.7482 16.2705 16.2684 16.0962L12.9546 14.893C12.1638 14.6058 11.6372 13.8544 11.6372 13.0131V9.81608Z" fill="#395FE4"/>
              </svg>
              {{ $t('footer.telegram-group-text') }}
            </a>
            <a href="https://vk.com/profitads_go" class="footer__tg-link" target="_blank">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 24.5771C18.6274 24.5771 24 19.2046 24 12.5771C24 5.94974 18.6274 0.577148 12 0.577148C5.37259 0.577148 0 5.94974 0 12.5771C0 19.2046 5.37259 24.5771 12 24.5771Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3812 16.7748H12.1887C12.1887 16.7748 12.4325 16.7479 12.5572 16.6137C12.6718 16.4904 12.6681 16.259 12.6681 16.259C12.6681 16.259 12.6523 15.1756 13.1551 15.016C13.6509 14.8588 14.2875 16.0632 14.9621 16.5263C15.4723 16.8767 15.86 16.8 15.86 16.8L17.6641 16.7748C17.6641 16.7748 18.6078 16.7165 18.1603 15.9745C18.1237 15.9139 17.8997 15.4257 16.8189 14.4225C15.6876 13.3725 15.8392 13.5424 17.2019 11.7262C18.0318 10.6201 18.3635 9.9449 18.2598 9.65572C18.1611 9.38019 17.5506 9.45297 17.5506 9.45297L15.5194 9.46553C15.5194 9.46553 15.3687 9.44503 15.2571 9.51181C15.1479 9.57713 15.0778 9.72974 15.0778 9.72974C15.0778 9.72974 14.7562 10.5856 14.3276 11.3136C13.4231 12.8494 13.0613 12.9307 12.9135 12.8352C12.5695 12.6129 12.6555 11.9423 12.6555 11.4658C12.6555 9.97734 12.8812 9.35673 12.2158 9.19609C11.995 9.14281 11.8324 9.10756 11.2677 9.1018C10.5429 9.09443 9.92953 9.10404 9.58217 9.2742C9.35107 9.38737 9.17277 9.63949 9.28142 9.654C9.41572 9.67189 9.71969 9.73606 9.88086 9.95536C10.0891 10.2386 10.0818 10.8746 10.0818 10.8746C10.0818 10.8746 10.2014 12.6267 9.80247 12.8443C9.5287 12.9936 9.15308 12.6889 8.34666 11.2954C7.93356 10.5817 7.62154 9.79262 7.62154 9.79262C7.62154 9.79262 7.56146 9.6452 7.45414 9.56628C7.32399 9.47067 7.14212 9.44036 7.14212 9.44036L5.2118 9.45297C5.2118 9.45297 4.9221 9.46106 4.81563 9.58707C4.72092 9.69924 4.80807 9.93096 4.80807 9.93096C4.80807 9.93096 6.3192 13.4665 8.03041 15.2482C9.59962 16.882 11.3812 16.7748 11.3812 16.7748Z" fill="#395FE4"/>
              </svg>
              {{ $t('footer.vk-group-text') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE
    }
  },
  computed: {
    getFullYear() {
      const year = new Date().getFullYear();
      return year
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/helpers/mixin';
@import '@/assets/styles/helpers/variables';

.footer {
  background-color: #395FE4;
}

.footer__col-title {
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #fff;
  margin-bottom: 3.6rem;
}

.footer .inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 4.8rem 0;
}

.footer .logo {
  color: #fff;
  font-weight: 400;
  font-size: 3.4rem;
  line-height: 4.4rem;
  display: block;
  width: 19rem;

  img {
    max-width: 100%;
  }
}

.footer .logo-text {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #b9d6f1;
}

.footer .copyright {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #b9d6f1;
  margin: 0 0 4rem;
}

.footer .adress {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #b9d6f1;
  margin: 0 0 1.6rem;
}

.footer .email {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #fff;
  margin: 0 0 1.6rem;
}

.footer .menu {
  list-style: none;
  padding: 0;
}

.footer .menu__item {
  margin: 0 3.2rem 0 0;
}

.footer .menu__item a {
  display: inline-block;
  position: relative;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-decoration: none;
  color: #fff;
  margin-bottom: 0.4rem;
}

.footer .menu__item a::before {
  width: 0;
  height: 1px;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0;
  transition: all 0.4s;
}

.footer .menu__item a:hover::before {
  width: 100%;
  left: 0;
  opacity: 1;
}

.footer .menu__item:last-child {
  margin-right: 0;
}

.footer .social {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
}

.footer .social__item {
  margin: 0 3.6rem 0 0;
}

.footer .social__item a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer .social__item a svg {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.footer .social__item a:hover svg {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.footer .social__item:last-child {
  margin-right: 0;
}

.footer__social-links {
    margin-top: 9.2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footer__social-links.small-margin {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footer__tg-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  color: #ffffff;
}

.footer__tg-link svg {
  margin-right: 0.8rem;
}

@media only screen and (max-width: 768px) {
  .footer__col {
      margin-bottom: 3.2rem;
  }

  .footer__col-title {
      margin-bottom: 0.6rem;
  }

  .footer .logo {
      margin-bottom: 0.6rem;
  }

  .footer .inner {
      -ms-flex-direction: column;
      flex-direction: column;
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
  }

  .footer .copyright {
      margin-bottom: 3.2rem;
  }

  .footer .adress {
      margin-bottom: 0.6rem;
  }

  .footer .menu {
      -ms-flex-direction: column;
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
  }

  .footer .menu__item {
      margin-right: 0;
      margin-bottom: 0;
  }

  .footer .menu__item a {
      margin-bottom: 0;
  }

  .footer .menu__item:last-child {
      margin-bottom: 0;
  }

  .footer__social-links  {
      margin-top: 2rem;
  }
}
</style>

