<template>
  <div class="forget-password">
    <FormElement
      v-if="!isNotice"
      :title="$t('password_recovery.title')"
      :message="$t('password_recovery.message')"
    >
      <div
        class="form-input"
        :class="
        [
          {'form-input--full' : form.email},
          {'form-input--error' : errors.email || authErrors}
        ]"
      >
        <input v-model="form.email" type="text" required>
        <span class="form-input__label">
          {{$t('email')}}
        </span>
        <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
        <div v-if="authErrors && authErrors.email" class="form-input__error">
          <p v-for="(err, index) in authErrors.email" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <ButtonElement
          :text="$t('button_props.send')"
          @click-button="sendCode"
      />
      <div class="forget-password__remember">
        {{$t('password_recovery.recall_password')}}
        <router-link :to="{name: 'Login'}">
          {{ $t('password_recovery.log_in') }}
        </router-link>
      </div>
    </FormElement>
    <div v-if="isNotice" class="forget-password__notice">
      {{ $t('password_recovery.message_to_email') }}
    </div>
  </div>
</template>

<script>

import FormElement from "../components/elements/FormElement";
import ButtonElement from "../components/elements/ButtonElement";
import formValidate from "../mixin/formValidate"

export default {
  name: "ForgetPassword",
  components: {
    FormElement,
    ButtonElement,
  },
  mixins: [formValidate],
  data () {
    return {
      form: {
        email: null,
      },
      isNotice: false,
      errors: {
        email: null
      }
    }
  },
  methods: {
    sendCode () {
      this.validateForm()
      if (this.isError) {
        return false
      }
      const data = {
        email: this.form.email
      }
      this.$store.commit('Auth/setData', { label: 'resetPasswordEmail', data: this.form.email })
      this.$store.dispatch('Auth/sendCode', data)
        .then(() => this.$router.push('/reset-password'))
        .catch(err => console.log(err))
    },
    validateForm () {
      if (!this.form.email) {
        this.errors.email = this.$t('password_recovery.input_email')
      } else {
        this.validateEmail()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

  .forget-password {

    &__remember {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $grey1;
      text-align: center;

      a {
        color: var(--primary);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__notice {
      font-size: 2rem;
      font-weight: 600;
      text-align: center;
    }

    @include below(577px) {

      &__remember {
        font-size: 14px;
        line-height: 1.4;
      }
    }
  }
</style>
