<template>
  <div>
    <div v-if="locale==='RU'" class="login-page ru">
      <FormElement :title="$t('login.title')">
        <div v-if="isShowEnterInputs"
            class="form-input"
            :class="
          [
            {'form-input--full' : form.email},
            {'form-input--error' : errors.email || authErrors}
          ]"
        >
          <input v-model="form.email" type="text" required>
          <span class="form-input__label">
          {{$t('email')}}
        </span>
          <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
        </div>
        <div v-if="isShowEnterInputs"
            class="form-input"
            :class="
          [
            {'form-input--full' : form.password},
            {'form-input--error' : errors.password || authErrors}
          ]"
        >
          <input v-model="form.password" :type="isHidePassword ? 'password' : 'text'">
          <span class="form-input__label">
          {{$t('password')}}
        </span>
          <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
            <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
            <img v-else src="@/assets/icons/icon-eye.svg">
          </div>
          <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
        </div>
        <div v-if="authErrors" class="form-input__error">
          {{ authErrors }}
        </div>
        <!-- <div v-if="authErrors" class="form-input__error">
            <p v-for="(err, index) in authErrors" :key="index">
              {{ err }}
            </p>
        </div> -->
        <div v-if="isShowEnterInputs" class="login-page__forget">
          <router-link
              class="login-page__forget-link"
              :to="{name: 'ForgetPassword'}"
          >
            {{$t('forget_password')}}
          </router-link>
        </div>
        
        <div v-if="!isShowEnterInputs"
            class="form-input"
            :class="
          [
            {'form-input--full' : phone},
          ]"
        >
          <input v-model="phone" type="text" disabled>
          <span class="form-input__label">
          {{$t('phone')}}
        </span>
        <div v-if="phoneVerified" class="login-page__phone-verified-icon">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.5402 2.03515C8.87897 2.28222 6.41222 3.53417 4.64172 5.53715C2.87219 7.54007 1.93272 10.1426 2.01472 12.8134C2.09772 15.4854 3.19539 18.0244 5.08497 19.9149C6.97559 21.8045 9.51472 22.9021 12.1865 22.9851C14.8575 23.0672 17.46 22.1277 19.4627 20.3581C21.4656 18.5876 22.7177 16.1209 22.9647 13.4596C23.2421 10.3531 22.1278 7.2829 19.9227 5.0769C17.7167 2.87182 14.6465 1.75765 11.54 2.0349L11.5402 2.03515ZM18.1477 10.1329L11.925 16.2901C11.5373 16.6681 10.9181 16.6681 10.5304 16.2901L6.86244 12.7871C6.46399 12.4043 6.45034 11.7705 6.83412 11.3711C7.21692 10.9727 7.85072 10.959 8.25015 11.3428L11.2101 14.1826L16.7501 8.7098C17.1456 8.3387 17.7648 8.34945 18.1466 8.73617C18.5275 9.12192 18.5314 9.74202 18.1554 10.1326L18.1477 10.1329Z" fill="#219653"/>
            </svg>
          </div>
        </div>
        <div v-if="authErrors && authErrors.phone" class="form-input__error">
            <p v-for="(err, index) in authErrors.phone" :key="index">
              {{ err }}
            </p>
          </div>
        <div v-if="!isShowEnterInputs" class="registration-page__phone-row">
          <div class="registration-page__phone-row-inner">
            <div>
              <div 
                :key="'smsCode'"
                class="form-input" 
                :class="
                [
                  {'form-input--full' : phoneSmsCode}
                ]"
              >
                <input v-model="phoneSmsCode" type="text">
                <span class="form-input__label">{{$t('smsCode')}} </span>
                <div v-if="errors.phoneSmsCode" class="form-input__error" v-text="errors.phoneSmsCode" />
              </div>
            </div>
            <div>
              <ButtonElement
                v-if="phoneSend"
                :text="$t('button_props.confirm')"
                @click-button="checkSmsCode"
              />
            </div>
          </div>
          <div v-if="phoneSend && smsTimer" class="registration-page__phone-row-message">{{ $t('registration.resendCodeTimeout') }} <span>{{ smsTimerCount }}</span> {{ $t('registration.resendCodeTimeout2') }}</div>
          <div v-if="phoneSend && !smsTimer" class="registration-page__phone-row-message is-button" @click="resendSMS">{{ $t('registration.resendCode') }}</div>
        </div>
        <ButtonElement
            :text="$t('button_props.log-in')"
            @click-button="loginWithPhone"
            :disabled="!isShowEnterInputs"
        />
        <router-link
            class="login-page__registration-link"
            :to="{name: 'Registration'}"
        >
          {{$t('login.register')}}
        </router-link>
      </FormElement>
    </div>
    <div v-if="locale==='USA'" class="login-page usa">
      <FormElement :title="$t('login.title')">
        <div
            class="form-input"
            :class="
          [
            {'form-input--full' : form.email},
            {'form-input--error' : errors.email || authErrors}
          ]"
        >
          <input v-model="form.email" type="text" required>
          <span class="form-input__label">
          {{$t('email')}}
        </span>
          <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
        </div>
        <div
            class="form-input"
            :class="
          [
            {'form-input--full' : form.password},
            {'form-input--error' : errors.password || authErrors}
          ]"
        >
          <input v-model="form.password" :type="isHidePassword ? 'password' : 'text'">
          <span class="form-input__label">
          {{$t('password')}}
        </span>
          <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
            <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
            <img v-else src="@/assets/icons/icon-eye.svg">
          </div>
          <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
        </div>
        <div v-if="authErrors" class="form-input__error">
          {{ authErrors }}
        </div>
        <div class="login-page__forget">
          <router-link
              class="login-page__forget-link"
              :to="{name: 'ForgetPassword'}"
          >
            {{$t('forget_password')}}
          </router-link>
        </div>
        <ButtonElement
            :text="$t('button_props.log-in')"
            @click-button="login"
        />
        <router-link
            class="login-page__registration-link"
            :to="{name: 'Registration'}"
        >
          {{$t('login.register')}}
        </router-link>
      </FormElement>
    </div>
  </div>
</template>

<script>

import FormElement from "../components/elements/FormElement";
import ButtonElement from "../components/elements/ButtonElement";
import formValidate from "../mixin/formValidate"

export default {
  name: 'Login',
  components: {
    FormElement,
    ButtonElement,
  },
  mixins: [formValidate],
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      form: {
        email: null,
        password: null,
      },
      errors: {
        email: null,
        password: null,
      },
      phone_verify: null,
      token: '',
      phone: null,
      is_verified: null,
      isShowEnterInputs: true,
      smsTimer: null,
      smsTimerCount: 0,
      phoneSmsCode: '',
      phoneSend: false,
      phoneVerified: false
    }
  },
  watch: {
    phone_verify (val) {
      if (val) {
        this.$store.dispatch('Auth/getUser', {token:this.token, email: this.form.email })
      } else if (!val) {
        this.isShowEnterInputs = false
        this.phoneSend = true
        this.sendSmsTimOut()
      } 
    }
    
  },
  mounted() {
    // this.sendSmsTimOut()
  },
  computed: {

  },
  methods: {
    login () {
      console.log('data', this.form)
      this.validateForm()
      if (this.isError) {
        return false
      }
      const data = this.form
      this.$store.dispatch('Auth/login', data)
    },

    sendSmsTimOut() {
      this.smsTimerCount = 60
      this.smsTimer = setInterval(() => {
          this.smsTimerCount--
          if (!this.smsTimerCount) {
          clearInterval(this.smsTimer)
          this.smsTimer = null
        }
      }, 1000);
    },

    loginWithPhone () {
      this.validateForm()
      if (this.isError) {
        return false
      }
      const data = this.form
      this.$store.dispatch('Auth/loginWithPhoneVerify', data)
      .then((data)=> { 
        this.phone_verify = data.data.data.phone_verify
        this.token = data.data.data.token
        this.phone = data.data.data.phone
        this.is_verified = data.data.data.is_verified
      })
    },
    validateForm () {
      if (!this.form.email) {
        this.errors.email = this.$t('forms_notifies.error_mail')
      } else {
        this.validateEmail()
      }
      if (!this.form.password) {
        this.errors.password =  this.$t('login.input_password')
      }
    },
    resendSMS() {
      if (!this.phone) {
        this.errors.phone = this.$t('registration.input_phone')
        return false
      }
      this.loading = true
      this.$store.dispatch('Auth/resendSmsCode', { phone: this.phone })
        .then((data) => {
          if (data.data && data.data.success) {
            this.phoneSend = true
            this.sendSmsTimOut()
          } else {
            console.log(data.data.errors)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkSmsCode() {
      this.loading = true
      this.$store.dispatch('Auth/checkSmsCode', { phone: this.phone, code: this.phoneSmsCode })
        .then((data) => {
          if (data.data && data.data.success) {
            this.phoneVerified = true
            this.phone_verify = true
            this.sendSmsTimOut()
          } else {
            this.$notify({
              message: this.$t('registration.falseCode'),
              type: 'error'
            });
          }       
        })
        .catch(() => {
          this.$notify({
            message: this.$t('registration.falseCode'),
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
  @import "@/assets/styles/helpers/variables";
  @import "@/assets/styles/helpers/mixin";

  .login-page {
    &__forget {
      display: flex;
      justify-content: flex-end;

      &-link {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: var(--primary);
        text-align: right;
        margin-top: 1.2rem;
        cursor: pointer;
        transition: 0.3s ease-out;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    &__registration-link {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $grey1;
      display: block;
      text-align: center;
      transition: 0.3s ease-out;

      &:hover {
        color: var(--primary);
      }
    }

    &__phone-verified-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0,-35%);
    }

    @include below(577px) {

      &__forget {
        margin-top: 0;
        margin-bottom: 16px;

        a {
          margin-top: 0;
          font-size: 14px;
          line-height: 1.4;
        }
      }

      &__registration-link {
        font-size: 14px;
        line-height: 1.4;
      }
    }
  }
</style>
