import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  chats: null,
  currentChat: null,

  helpdesk: null,
  helpdeskThemes: null,
  helpdeskPagination: null,
  
  applicationChanget: null,
  detailApplication: null,
})
const getters = {
  unreadMessages: (state) => {
    let count = 0
    if (state.chats && state.chats.length) {
      state.chats.forEach((el) => {
        if (el.new_count) count += el.new_count
      })
    }
    return count
  },
  helpDesk: (state) => {
    return state.helpdesk
  }
}
const mutations = {
  setData (state, data) {
    state[data.label] = data.data
  },
}
const actions = {
  getChats ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/tracker/issues', method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'chats', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getChatById ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/tracker/issue/' + id, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'currentChat', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  sendNewMessage (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/tracker/issue`, data, method: 'POST', headers: { 'Content-Type': 'multipart/form-data' } })
        .then(resp => {
  
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  updateChat (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/tracker/issue/${data.id}/comments`, data: data.data, method: 'POST' })
        .then(resp => {
          // setTimeout(() => {
          //   dispatch('getChatById', data.id)
          // }, 2000)
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getAttachment (ctx, id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/tracker/attachment/${id}/download`, method: 'GET', responseType: 'blob' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  getHelpdesk({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/tracker/helpdesk', method: 'GET', params })
        .then(resp => {
          commit('setData', { label: 'helpdesk', data: resp.data.data.data })
          const pagination = {
            total: resp.data.data.total,
            lastPage: resp.data.data.last_page
          }
          commit('setData', { label: 'helpdeskPagination', data: pagination })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  getHelpdeskThemes({ commit }) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/tracker/helpdesk/theme', method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'helpdeskThemes', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  createHelpdesk({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/tracker/helpdesk', method: 'POST', data })
        .then(resp => {
          commit('setData', { label: 'applicationChanget', data: true })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  getDetailApplication({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/tracker/helpdesk/${id}`, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'detailApplication', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  addCommentInApplication(ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/tracker/helpdesk/${data.id}/comments`, method: 'POST', data: data.data})
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  closeApplication(ctx, payload) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/tracker/helpdesk/${payload.id}`, method: 'PUT', data: {status: 4, business_account_id: payload.business_account_id} })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  downloadApplicationFile(ctx, file) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/tracker/helpdesk/${file.id}/download`, method: 'GET', responseType: 'blob', })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], {type: response.request.response.type}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
