<template>
	<div class="cookies-page">
		<div v-if="locale === 'RU'">
			<h1 class="title">{{ $t('agreement.title') }}</h1>
			<p class="text">Настоящее Пользовательское соглашение регулирует отношения между Администрацией Сайта и
				Пользователем по использованию Сайта, расположенного в сети Интернет по адресу:
				<a href="https://profitads.ru" target="_blank">https://profitads.ru</a>
				, и Сервиса ProfitAds, расположенного на Сайте.
			</p>

			<p class="text">Используя Сайт и/или Сервис ProfitAds, пользователь подтверждает, что он достиг
				восемнадцатилетнего возраста, позволяющего ему совершать в рамках Сайта или Сервиса ProfitAds те действия,
				которые он вправе самостоятельно совершать и нести за них ответственность в соответствии с применимым
				законодательством. Если пользователь не достиг такого возраста, пользователь не вправе использовать Сайт или
				Сервис ProfitAds.</p>
			<ul class="list">
				<li>
					<h3 class="subtitle subtitle--blue">1. Основные термины и определения, используемые в настоящем
						Пользовательском соглашении</h3>

					<p class="text">
						<b>Сайт</b>
						– сайт, расположенный в сети Интернет по адресу
						<a href="https://profitads.ru/" target="_blank">https://profitads.ru/</a>
						являющийся совокупностью размещенных в сети Интернет web-страниц, управляемых посредством
						программного обеспечения Администрации Сайта, объединенных единым адресным пространством. В рамках
						Сайта предоставляется доступ к Сервису ProfitAds. <br>Стартовая страница Сайта, посредством которой
						может быть осуществлен доступ ко всем web-страницам Сайта и к Сервису ProfitAds, размещена в сети
						Интернет по адресу
						<a href="https://profitads.ru/" target="_blank">https://profitads.ru/</a>
						<br>
						<b>Сервис ProfitAds</b>
						– программно-аппаратный комплекс, принадлежащий ООО «ПрофитЭдс», расположенный на Сайте, доступ к
						которому осуществляется через Сайт.
						<br>
						<b>Доступ к Сайту/Сервису ProfitAds</b>
						– доступ к функциональным возможностям Сайта и Сервиса ProfitAds, который Пользователь получает
						после прохождения процедуры регистрации на Сайте (последующей авторизации на Сайте). Доступ к
						Сервису ProfitAds осуществляется с использованием логина и пароля.
						<br>
						<b>Администрация Сайта/Сервиса (Администрация)</b>
						– ООО «ПрофитЭдс», зарегистрированное по адресу: 125124, г. Москва, ул. Ямского поля 3-я, д.2,
						корп.26.
						<br>
						<b>Пользователь Сайта (Пользователь)</b>
						– лицо, достигшее возраста восемнадцати лет, имеющее гражданство Российской Федерации, которое, в
						соответствии с законодательством Российской Федерации, может в полном объеме отвечать за собственные
						действия (полностью дееспособное) и получившее доступ к Сайту/Сервису ProfitAds посредством
						регистрации и присоединения к настоящему Пользовательскому соглашению и иным соглашениям, договорам
						и правилам, которые регулируют порядок использования Сервиса ProfitAds.
						<br>
						<b>Личный кабинет</b>
						– учетная запись Пользователя в Сервисе ProfitAds, создаваемая в момент
						регистрации на Сайте, которая предоставляет Пользователю возможность доступа к Сервису
						ProfitAds, и идентифицирующая его как уникального пользователя Сайта. В рамках Сервиса
						ProfitAds Пользователь имеет возможность создавать бизнес-аккаунты в Сервисе ProfitAds,
						использование которых регулируется в соответствии с Договорами-офертами, расположенными в
						сети Интернет по адресам:
						<a href="https://lk.profitads.ru/dogovor-oferty/"
							target="_blank">https://lk.profitads.ru/dogovor-oferty/</a>,
						<a href="https://lk.profitads.ru/dogovor-oferty-fl/"
							target="_blank">https://lk.profitads.ru/dogovor-oferty-fl/</a>
						.
						<br>
						<b>Программный интерфейс приложений (API)</b>
						– интерфейс, позволяющий двум независимым компонентам программного обеспечения обмениваться
						информацией.
						<br>
						<b>Контент</b>
						– элементы дизайна, иллюстрации, графические изображения, скрипты, программы для ЭВМ, видео, музыка,
						звуки и другие объекты, являющиеся результатом интеллектуальной деятельности или не являющиеся
						таковыми, права на которые принадлежат Администрации Сайта или иным лицам.
						<br>
						<b>Пользовательское соглашение</b>
						– настоящее соглашение, определяющее общие условия и порядок использования Сайта/Сервиса ProfitAds,
						регламентирующее правоотношения между Администрацией Сайта и Пользователем.<br>
						Пользовательское соглашение является публичной офертой, адресованной Пользователю Администрацией
						Сайта.
						<br>
						Действующая редакция Пользовательского соглашения расположена в сети Интернет по адресу: <a
							href="https://lk.profitads.ru/agreement/"
							target="_blank">https://lk.profitads.ru/agreement/</a>.
						<br>
						Перед использованием Сайта/Сервиса ProfitAds, Пользователь обязуется и подтверждает, что ознакомился
						с условиями настоящего Пользовательского соглашения.
					</p>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">2. Общие условия функционирования Сайта/Сервиса ProfitAds</h3>
					<ul class="list">
						<li>2.1. Администрация обеспечивает функционирование Сайта/Сервиса ProfitAds в круглосуточном
							режиме, однако не гарантирует отсутствие перерывов, связанных с техническими неисправностями или
							проведением профилактических работ. Администрация не гарантирует, что Сайт/Сервис ProfitAds
							будут функционировать в любое конкретное время в будущем или что они не прекратят работу.</li>
						<li>2.2. Сайт и Сервис ProfitAds, включая их функциональные возможности, а также все скрипты,
							Контент и оформление предоставляются «как есть».</li>
						<li>2.3. Администрация не предоставляет никаких гарантий того, что Сайт или Сервис ProfitAds могут
							подходить или не подходить для конкретных целей использования. Администрация не может
							гарантировать и не обещает никаких специфических результатов от использования Сайта и/или
							Сервиса ProfitAds. </li>
						<li>2.4. При использовании Сайта/Сервиса ProfitAds Пользователь обязан соблюдать меры
							предосторожности при осуществлении определенных действий, в том числе, при переходе по
							размещенным на Сайте гиперссылкам, а также при использовании любых файлов, в том числе
							программного обеспечения, во избежание негативного воздействия вредоносного программного
							обеспечения на компьютер Пользователя, недобросовестного доступа к Личному кабинету, подборки
							пароля и прочих негативных для Пользователя последствий.</li>
						<li>2.5. Сайт/Сервис ProfitAds могут содержать рекламные и информационные материалы, которые
							размещаются Администрацией по своему усмотрению.</li>
						<li>2.6. Регистрируясь на Сайте, Пользователь тем самым подтверждает, что является полностью
							дееспособным гражданином Российской Федерации и достиг возраста восемнадцати лет, необходимого
							для использования Сайта в соответствии с применимым законодательством, в противном случае
							регистрация на Сайте и его использование запрещены.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">3. Условия получения доступа к Сайту/Сервису ProfitAds</h3>

					<p class="text">Для целей Доступа к Сайту и Сервису ProfitAds, Пользователю необходимо создать Личный
						кабинет. Процесс создания Личного кабинета включает в себя обязательное выполнение Пользователем
						нижеперечисленных действий:</p>
					<ul class="list">
						<li>3.1. Регистрация Пользователя на Сайте в соответствии со следующими условиями:

							<p class="text">Для регистрации на Сайте и создании Личного кабинета Пользователь указывает
								адрес электронной почты, являющийся логином для входа в Личный кабинет, номер мобильного
								телефона (с кодом российского оператора) и пароль. Администрация Сайта никаким образом не
								проверяет предоставляемую Пользователем информацию при регистрации, не несет ответственности
								перед любыми третьими лицами за точность и достоверность учетных данных.</p>

							<p class="text">В процессе заполнения регистрационной формы Пользователь самостоятельно выбирает
								логин и пароль, а также иные данные для последующего доступа к Сайту/Сервису ProfitAds.
								Пользователь вправе передать логин и пароль лицу, уполномоченному действовать от имени
								Пользователя, при этом все риски, которые могут возникнуть в связи с такой передачей, несет
								Пользователь.
								<br>
								Пользователь соглашается, что логин и пароль являются конфиденциальной информацией и не
								подлежат разглашению, за исключением случаев, предусмотренных применимым законодательством
								и/или настоящим Пользовательским соглашением. Риск осуществления мошеннических и иных
								неправомерных действий с Личным кабинетом Пользователя в связи с утратой пароля либо
								передачей логина и пароля третьим лицам, несет Пользователь. Администрация Сайта рекомендует
								Пользователю выбирать пароль достаточной сложности, чтобы избежать возможности его подбора
								третьими лицами.
							</p>

							<p class="text">Регистрация Личного кабинета на Сайте считается завершенной после подтверждения
								адреса электронной почты, которое осуществляется путем перехода Пользователя по гиперссылке,
								направленной Администрацией Сайта на адрес электронной почты Пользователя, указанный им при
								регистрации Личного кабинета на Сайте.</p>
							<p class="text">Логин и пароль, указанные Пользователем при регистрации Личного кабинета,
								используются Пользователем для последующей авторизации на Сайте и входа в Личный кабинет.
								Пароль Пользователя для доступа в Личный кабинет Администрации Сайта не доступен.</p>
						</li>

						<li>3.2. Выражение Пользователем согласия с настоящим Пользовательским соглашением, путем активации
							чекбокса «Подтверждаю свое согласие с Пользовательским соглашением и Политикой
							конфиденциальности и обработки персональных данных».

							<p class="text">Каждым последующим Доступом к Сайту/Сервису ProfitAds (использованием
								Сайта/Сервиса ProfitAds), Пользователь выражает согласие с условиями настоящего
								Пользовательского соглашения и Политики конфиденциальности и обработки персональных данных,
								размещенной в сети Интернет по адресу: <a href="https://profitads.ru/privacy-policy/"
									target="_blank">https://profitads.ru/privacy-policy/</a>, в редакциях, которые действуют
								на момент фактического использования Сайта/Сервиса ProfitAds.</p>
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">4. Условия восстановления/прекращения доступа к Сайту/Сервису
						ProfitAds</h3>
					<ul class="list">
						<li>4.1. В случае утраты доступа к Сайту/Сервису ProfitAds Пользователь имеет возможность
							восстановить его самостоятельно путем следования порядку, указанному на Сайте.</li>
						<li>4.2. Пользователь согласен с тем, что Администрация оставляет за собой право прекратить доступ к
							Личному кабинету в любое время без предварительного уведомления Пользователя, а также применить
							любые иные меры по ограничению доступа к Сайту/Сервису ProfitAds.</li>
						<li>4.3. Прекращение доступа к Личному кабинету может быть произведено Администрацией Сайта по
							следующим причинам:
							<br>а) нарушение Пользователем положений настоящего Пользовательского соглашения;
							<br>б) по соответствующему запросу органов власти согласно применимому законодательству;
							<br>в) неиспользование Личного кабинета в течение 180 (Ста восьмидесяти) календарных дней;
							<br>г) в связи с непредвиденными проблемами технического характера или обстоятельствами,
							связанными с безопасностью;
							<br>д) из-за любых нарушений при использовании Сайта/Сервиса ProfitAds;
							<br>е) вследствие возникновения обстоятельств, указанных в п. 6.9. договоров-оферт,
							расположенных в
							сети Интернет по адресам:
							<a href="https://lk.profitads.ru/dogovor-oferty/"
								target="_blank">https://lk.profitads.ru/dogovor-oferty/</a>,
							<a href="https://lk.profitads.ru/dogovor-oferty-fl/"
								target="_blank">https://lk.profitads.ru/dogovor-oferty-fl/</a>
							<br>ж) по иным причинам, которые Администрация Сайта посчитает разумными и необходимыми, в
							том числе для целей обеспечения безопасности функционирования Сайта/Сервиса ProfitAds.
						</li>
						<li>4.4. Пользователь имеет право отказаться от использования Личного кабинета, воспользовавшись
							специальным интерфейсом по его удалению, при условии, что такой функционал реализован
							Администрацией.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">5. Права и обязанности Администрации Сайта</h3>
					<ul class="list">
						<li>5.1. Администрация Сайта обладает необходимыми правами на Контент Администрации (в том числе в
							отношении входящих в состав Сайта программ для ЭВМ, баз данных, информационных материалов,
							графических изображений, являющихся элементами пользовательского интерфейса, и прочее),
							фирменное наименование, товарные знаки (знаки обслуживания), логотипы и иные отличительные знаки
							Администрации Сайта.</li>
						<li>5.2. Администрация Сайта осуществляет текущее управление Сайтом/Сервисом ProfitAds, определяет
							их структуру, внешний вид, разрешает или ограничивает доступ Пользователей к Сайту/Сервису
							ProfitAds, осуществляет иные принадлежащие ей права.</li>
						<li>5.3. Администрация Сайта решает вопросы о порядке размещения на Сайте рекламы, участия в
							партнерских программах и т.д.</li>
						<li>5.4. Администрация Сайта имеет право:
							<ul class="list">
								<li>5.4.1. в любое время изменять оформление Сайта, его содержание, изменять или дополнять
									используемые скрипты, программное обеспечение, Контент и другие объекты, используемые
									или хранящиеся на Сайте, любые серверные приложения, с уведомлением Пользователя или без
									такового;</li>
								<li>5.4.2. удалять без каких-либо причин и без предупреждения любой Контент, в том числе
									Контент, который по усмотрению Администрации нарушает и/или может нарушать применимое
									законодательство, положения Пользовательского соглашения, права третьих лиц, причинять
									им вред или угрожать безопасности;</li>
								<li>5.4.3. приостанавливать, ограничивать или прекращать доступ Пользователя ко всем или к
									любому из разделов Сайта/Сервиса ProfitAds, с предварительным уведомлением или без
									такового;</li>
								<li>5.4.4. удалить Личный кабинет Пользователя по своему усмотрению, в том числе в случае
									совершения Пользователем действий, нарушающих применимое законодательство или положения
									Пользовательского соглашения;</li>
								<li>5.4.5. осуществлять рассылку Пользователям сообщений (в том числе сообщений по
									электронной почте, sms-сообщений и т.п.), являющихся уведомлениями о введении в действие
									новых услуг, либо отмене старых услуг, утверждении и опубликовании новой редакции
									Пользовательского соглашения или иных документов, размещенных на Сайте, а также
									содержащих рекламную информацию о Сервисе ProfitAds.</li>
							</ul>
						</li>
						<li>5.5. Рассылка Пользователям сообщений, содержащих рекламу товаров или услуг третьих лиц, без
							предварительного согласия Пользователей не осуществляется.</li>
						<li>5.6. Администрация Сайта оставляет за собой право уступить свои права и обязанности по
							настоящему Пользовательскому соглашению любому третьему лицу по своему усмотрению, уведомив об
							этом Пользователя по электронной почте, указанной Пользователем при регистрации на Сайте. Права
							и обязанности Пользователя по настоящему Пользовательскому соглашению считаются перешедшими к
							Администрации Сайта в дату, указанную в таком уведомлении.</li>
					</ul>

				</li>

				<li>
					<h3 class="subtitle subtitle--blue">6. Права и обязанности Пользователя</h3>
					<ul class="list">
						<li>6.1. Пользователь вправе:
							<ul class="list">
								<li>6.1.1. менять пароль для доступа к Личному кабинету, а также производить иные действия,
									предоставленные Пользователю функциональными возможностями Сайта;</li>
								<li>6.1.2. осуществлять иные, не запрещенные применимым законодательством действия,
									связанные с использованием Сайта/Сервиса ProfitAds.</li>
							</ul>
						</li>
						<li>6.2. Пользователь обязуется:
							<ul class="list">
								<li>6.2.1. Соблюдать условия Пользовательского соглашения, а также нормы применимого
									законодательства;</li>
								<li>6.2.2. Принимать необходимые меры для обеспечения конфиденциальности логина и пароля,
									используемых для доступа к Личному кабинету, следить за тем, чтобы пароль не сохранялся
									в браузере при возможном использовании компьютера другими лицами;</li>
								<li>6.2.3. Самостоятельно знакомиться с содержанием Пользовательского соглашения и следить
									за изменениями, внесенными в Пользовательское соглашение.</li>
							</ul>
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">7. Пользователю запрещается:</h3>
					<ul class="list">
						<li>7.1. использовать любые автоматические или автоматизированные средства для сбора информации,
							размещенной на Сайте/в рамках Сервиса ProfitAds, за исключением использования специально
							разработанных Администрацией Программных интерфейсов приложения (API);</li>
						<li>7.2. размещать на Сайте/в рамках Сервиса ProfitAds персональные данные, контактные данные
							третьих лиц без их предварительного письменного согласия;</li>
						<li>7.3. указывать при регистрации на Сайте или вводить впоследствии заведомо ложную или вымышленную
							информацию о себе как физическом лице или об индивидуальном предпринимателе/юридическом лице, на
							которого (-ое) регистрируется бизнес-аккаунт в Сервисе ProfitAds;</li>
						<li>7.4. регистрировать более одного Личного кабинета;</li>
						<li>7.5. осуществлять действия, направленные на дестабилизацию функционирования Сайта/Сервиса
							ProfitAds, осуществлять попытки несанкционированного доступа к управлению Сайтом/Сервисом
							ProfitAds или закрытым разделам (разделам, доступ к которым разрешен только Администрации), а
							также осуществлять любые иные аналогичные действия;</li>
						<li>7.6. осуществлять несанкционированный доступ к Личным кабинетам иных Пользователей путем
							подборки или введения пароля, а также предпринимать попытки такого доступа;</li>
						<li>7.7. нарушать требования Пользовательского соглашения и нормы применимого законодательства.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">8. Гарантии и ответственность Сторон</h3>
					<ul class="list">
						<li>8.1. В случае поступления претензий третьих лиц в связи с нарушением Пользователем условий
							Пользовательского соглашения Администрация Сайта вправе осуществить передачу в рамках,
							допустимых применимым законодательством, доступной ей контактной информации Пользователя лицам,
							обратившимся с претензиями, в целях урегулирования возникших разногласий.</li>
						<li>8.2. Пользователь несет персональную ответственность за любую информацию и данные, которые он
							размещает на Сайте/в Сервисе ProfitAds. Пользователь обязуется самостоятельно разрешать
							претензии третьих лиц, связанные с неправомерным размещением информации, данных.</li>
						<li>8.3. Администрация Сайта, предоставляя Пользователю техническую возможность использования
							Сайта/Сервиса ProfitAds, не участвует в формировании содержания Личного кабинета,
							бизнес-аккаунтов, созданных при использовании Сервиса ProfitAds, не контролирует действия
							Пользователя.</li>
						<li>8.4. Администрация Сайта не осуществляет и не имеет технической возможности осуществлять
							предварительную модерацию информации Пользователей и не несет ответственности за информацию,
							размещенную Пользователем.</li>
						<li>8.5. Администрация Сайта не несет ответственности за возможные сбои и перерывы в работе Сайта/
							Сервиса ProfitAds и вызванные ими потерю информации, но приложит все возможные и зависящие от
							Администрации усилия, чтобы не допустить указанное. Администрация не несет ответственности за
							любой ущерб компьютеру Пользователя, мобильным устройствам, любому другому оборудованию или
							программному обеспечению, вызванный или связанный с использованием Сайта/Сервиса ProfitAds.</li>
						<li>8.6. Администрация не несет ответственности за подбор пароля третьими лицами и любые действия,
							совершенные ими с использованием Личного кабинета Пользователя.</li>
						<li>8.7. Администрация Сайта не несет ответственность за любой ущерб, включая упущенную выгоду, или
							вред, возникшие в связи с использованием Пользователем Сайта/Сервиса ProfitAds.</li>
						<li>8.8. Администрация Сайта не несет ответственность за любой ущерб, который может быть причинен
							Пользователю, включая потерю данных, удаление Личного кабинета или прекращение функционирования
							Сайта/Сервиса ProfitAds, принимая при этом все возможные и зависящие от Администрации усилия,
							чтобы исключить или снизить риск неблагоприятных последствий.</li>
						<li>8.9. Пользователь несет ответственность за противоправные действия, осуществляемые с
							использованием Личного кабинета Пользователя.</li>
						<li>8.10. За нарушение положений Пользовательского соглашения, доступ Пользователя к Сайту,
							отдельным разделам Сайта, Сервису ProfitAds может быть ограничен, приостановлен или прекращен на
							неопределенный срок. Доступ может быть восстановлен по усмотрению Администрации по письменному
							заявлению Пользователя.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">9. Заключительные положения</h3>
					<ul class="list">
						<li>9.1. Пользовательское соглашение вступает в силу с момента его акцепта в соответствии с п. 3
							Пользовательского соглашения.</li>
						<li>9.2. В случае внесения Администрацией Сайта изменений в условия Пользовательского соглашения,
							новая редакция Пользовательского соглашения подлежит опубликованию в сети Интернет по адресу:
							<a href="https://lk.profitads.ru/agreement/"
								target="_blank">https://lk.profitads.ru/agreement/</a>
							и вступает в силу с момента такого опубликования. Администрация Сайта уведомляет Пользователя о
							внесении изменений в условия Пользовательского соглашения путем направления соответствующего
							уведомления на электронный адрес Пользователя, указанный при регистрации Личного кабинета, в
							день опубликования новой редакции Пользовательского соглашения. В случае несогласия Пользователя
							с новой редакцией Пользовательского соглашения, Пользователь обязан прекратить использование
							Сайта. Факт использования Пользователем Сайта означает согласие Пользователя с редакцией
							Пользовательского соглашения, действующей на момент такого использования.
						</li>
						<li>9.3. Недействительность одного или нескольких положений Пользовательского соглашения не влечет
							для Сторон недействительности Пользовательского соглашения в целом. В случае признания одного
							или нескольких положений в установленном порядке недействительными, Стороны обязуются исполнять
							взятые на себя по ним обязательства максимально близким к подразумеваемым Сторонами при
							заключении и/или согласованном изменении способом.</li>
						<li>9.4. Настоящее Пользовательское соглашение и взаимоотношения Сторон в связи с действием
							Пользовательского соглашения регулируются законодательством Российской Федерации.</li>
						<li>9.5.Все споры и разногласия по настоящему Соглашению подлежат разрешению путем переговоров и
							обмена претензиями. Досудебный порядок урегулирования споров обязателен для Пользователя и
							Администрации Сайта. Срок рассмотрения претензии – 20 (Двадцать) рабочих дней с даты получения
							претензии. Если в результате переговоров и обмена претензиями Стороны не достигли
							взаимоприемлемого решения, спор подлежит разрешению в суде по месту нахождения Администрации
							Сайта в соответствии с правилами подведомственности, установленными законодательством Российской
							Федерации в соответствии с действующим законодательством Российской Федерации.</li>
						<li>9.6. По вопросам, связанным с исполнением Пользовательского соглашения, просьба обращаться по
							адресу местонахождения Администрации Сайта: 125124, г. Москва, ул. Ямского поля 3-я, д.2,
							корп.26.</li>
						<li>9.7. Согласие с настоящим Пользовательским соглашением может быть отозвано путем направления
							письменного заявления адресу местонахождения Администрации Сайта: 125124, г. Москва, ул. Ямского
							поля 3-я, д.2, корп.26.</li>
					</ul>
				</li>
			</ul>
			<p class="text">
				<b>Реквизиты ООО «ПрофитЭдс»</b>
				<br>Адрес места нахождения: 125124, г. Москва, ул. Ямского поля 3-я, д.2, корп.26
				<br>ОГРН: 1217700649176
				<br>ИНН: 7714480242
				<br>КПП: 771401001
				<br>Р/с: 40702810438000024448
				<br>В банке: ПАО СБЕРБАНК г. Москва
				<br>Корр.счет: 30101810400000000225
				<br>БИК: 044525225
				<br>Адрес электронной почты: info@profitads.ru
				<br><br>
				Редакция от 20.10.2023г.
			</p>
		</div>
		<div v-if="locale === 'USA'">
			<h1 class="title">GENERAL TERMS AND CONDITIONS DIGITAL EAGLE INC</h1>
			<p class="text"><i>Effective Date: January 5, 2024</i></p>
			<p class="text">
				Please read these General Terms and Conditions (the “Agreement”, “General Terms” or “Terms”)
				carefully and completely before using <a href="https://digital-eagle.us">https://digital-eagle.us</a> and <a
					href="https://cp.digital-eagle.us">https://cp.digital-eagle.us</a> (each
				of them and collectively, the "Website"), other our websites and apps, software and other products
				(collectively, the “Platform”) and purchasing our services and products (the “Service") provided
				through or in connection with the Platform and/or by Digital Eagle Inc and/or one of its subsidiaries or
				affiliates ("Digital Eagle", "us" or "we").
			</p>
			<p class="text">
				The Platform is an intermediary technology platform that allows (A) visitors to access the publicly
				available content, services and products, and (B) registered users to purchase our Service and
				services and products of third parties. Visitors and registered users are referred to herein individually
				as “user” and collectively as “users”; “you” and “your” refers to users and Clients.
			</p>
			<p class="text">THESE TERMS INCLUDE AN ARBITRATION CLAUSE AND A WAIVER OF YOUR RIGHT TO PARTICIPATE IN A CLASS
				ACTION OR REPRESENTATIVE LAWSUIT.</p>
			<ol type="I" class="list-position first-main-li">
				<li>
					<b>ACCEPTANCE OF THIS AGREEMENT</b>
					<div class="first-list-wrapper">
						<div class="text">
							I.1. By using the Platform (including any access to the Service) and/or purchasing the
							Service, you
							expressly agree to be bound by this Agreement between you and Digital Eagle, which incorporates
							by this reference any additional terms and conditions posted by Digital Eagle through the
							Platform, or otherwise made available to you by Digital Eagle, including Legal Disclaimers,
							Privacy Policy, Cookie Policy, and to any additional rules, policies and guidelines that we post
							on the Platform (“Agreement”). The information and resources contained on and accessible through
							the Platform are made available by Digital Eagle and its suppliers and vendors, and other third
							parties, in each case subject to your agreement to the terms and conditions of this Agreement.
							By using the Platform and/or Service, you affirm that you are of legal age to enter into this
							Agreement.
						</div>
						<div class="text"> I.2. We may update this Agreement by notifying you of such updates by any
							reasonable
							means, including
							by posting the updated Agreement to the Platform. Any changes in updated Agreement will not
							apply to any dispute between you and us arising prior to the date on which we posted the updated
							Agreement, or otherwise notified you of such updated Agreement. It is your responsibility to
							periodically review the Platform to determine if this Agreement has been updated and remain
							informed about any changes to it. We will also indicate at the top of the regulating document
							when it was last updated. Your continued use of the Platform and/or Service following any
							updates to this Agreement shall constitute notice and acceptance of any such updates. Please, be
							aware that if you do not agree to any provision of the Agreement or do not accept its terms, you
							must not access to and use the Platform and/or use our Service.
						</div>
						<div class="text">
							I.3.
							The current version of the Agreement supersedes all earlier versions and comprises the entire
							agreement between you and us regarding the Platform using and Service, unless different
							provisions are specified in the Special Terms. In case of contradictions between this Agreement
							and the Special Terms, the provisions of the Special Terms shall prevail.
						</div>
					</div>
				</li>
				<li>
					<b>PLATFORM USING</b>
					<div class="first-list-wrapper">
						<div class="text"> II.1. We grant you permission to use the Platform subject to the terms and
							conditions
							of this
							Agreement. Your use of the Platform is at your own risk, including the risk that you might be
							exposed to content that is inaccurate, objectionable, incomplete, fails to provide adequate
							warning about potential risks or hazards, or is otherwise inappropriate.
						</div>
						<div class="text">II.2. By using the Platform, you agree to comply with applicable laws of the
							United
							States and your
							home country, including laws that apply to data transferring. You agree that we cannot and will
							not be liable for any loss or damage arising from your failure to comply with the
							responsibilities specified in this Agreement. Digital Eagle may, at any time in our sole
							discretion for any reason or no reason and without notice, refuse to provide any user with
							access to the Platform; offer opportunities to some or all users.
						</div>
						<div class="text">
							II.3.
							The Platform is not intended for any person who is a resident of a country where it would be
							prohibited or against local laws to provide the information on the Platform. Any
							products/services described on the Platform are only available to persons in jurisdictions in
							which Digital Eagle is permitted to market and sell such products/services.
						</div>
						<div class="text">
							<b>II.4</b> <b>Publicly available content.</b> Any visitor may access the publicly available
							content
							on the
							Platform without any registration. In some cases, you may be directed to subscribe to some
							marketing, educational and other materials without registration.
						</div>
						<div class="text">
							<b>II.5</b> <b>Registered access.</b>
							<div class="text">
								II.5.1.
								When you set up a personal account with Digital Eagle, you are creating a direct customer
								relationship with Digital Eagle that enables you to access and/or utilize the various
								functions
								of
								the Platform as a user, including purchasing the Service. As part of that relationship, you
								provide
								information to Digital Eagle, including but not limited to your full name, email address,
								shipping
								address, phone number and other data (“Registration Data”), that we may collect, use and
								disclose in
								accordance with our Privacy Policy.
							</div>
							<div class="text">
								II.5.2.
								On registering, you will obtain a confidential user ID and password (collectively,
								“Credentials”) for your individual use that must be used to access and use the Services.
								Only
								registered users may access the publicly unavailable content on the Platform.
							</div>
							<div class="text">
								II.5.3.
								You agree by registering: (i) to this Agreement; (ii) to provide true, accurate, current and
								complete registration data, (iii) to maintain and promptly update the registration data to
								keep
								it true, accurate, current and complete, (iv) for security reasons, to maintain the
								confidentiality of any credentials obtained by you, (v) to not allow any other party to use
								your
								credentials; (vi) to ensure that you properly exit from your account at the end of each
								session
								and to immediately notify us of any unauthorized use of your account or any other breach of
								security; and (vii) to take full responsibility for all activities that occur under any
								customer
								account created for your use.
							</div>
							<div class="text">
								II.5.4.
								Digital Eagle is not responsible for any loss or damage arising from your failure to comply
								with
								the foregoing requirements. If you provide any information that is untrue, inaccurate, not
								current or incomplete, or if Digital Eagle has reasonable grounds to suspect that such
								information is untrue, inaccurate, not current or incomplete, Digital Eagle may suspend or
								terminate your personal account and refuse any and all current or future use of the Service
								(or
								any portion thereof). By submitting your information through the Platform for purposes of
								registration, you grant Digital Eagle a perpetual, irrevocable, royalty-free, non-exclusive,
								assignable, sublicensable, transferable, fully-paid, worldwide license to use, copy, modify,
								prepare derivative works, reproduce, reformat, translate, analyze, commercialize, syndicate,
								distribute, transmit, and display this information in connection with Digital Eagle’s
								performance of the Service for you.
							</div>
						</div>
					</div>
				</li>
				<li><b>DEFINITIONS</b>
					<div class="first-list-wrapper">
						<div class="text">
							III.1.
							<b>Advertisement (Ad)</b> shall mean a text advertisement (text ad), a banner containing
							advertising
							information, including graphical or animated images, as well as an inscription or a hyperlink.
						</div>
						<div class="text">
							III.2.
							<b>Advertising campaign</b> shall mean a set of advertisements ordered by Client, for which,
							after
							the execution of Special Terms, services are provided in accordance with the terms of placement
							specified by Client.
						</div>
						<div class="text">
							III.3.
							<b>Advertising Campaign Budget</b> shall mean the amount of money agreed with Client spent in
							the
							reporting period in advertising systems in order to generate user clicks on the Client's
							website.
						</div>
						<div class="text">
							III.4.
							<b>Advertisement placement</b> shall mean the location on the Internet where a display ad is
							shown.
							Ad placements can vary in effectiveness and often require a level of testing to understand where
							the
							optimal placement will be. This will also be dictated by the advertising platform being uses.
						</div>
						<div class="text">
							III.5.
							<b>Advertising platform</b> shall mean a tool for managing and steering online advertising
							campaign,
							which can be used independently or not by Client, including social media such as Meta, TikTok,
							Instagram and others.
						</div>
						<div class="text">
							III.6.
							<b>Click</b> shall mean the user's transition from the link to the Client’s website, virtual
							business card or community on the social network - the page with the Client's contact
							information.
						</div>
						<div class="text">
							III.7.
							<b>Client</b> shall mean a user of the Platform and/or the Service of Digital Eagle.
						</div>
						<div class="text">
							III.8.
							<b>Contextual advertising</b>
							shall mean advertising displayed on the web pages according to the key queries of Client, agreed
							by
							the Parties. The placement cost for the Contextual advertising for the reporting period shall be
							determined based on the cost per click and number of clicks in accordance with the statistical
							data
							of the corresponding system for online advertisement placement.
						</div>
						<div class="text">
							III.9.
							<b>Meta Business Manager</b> shall mean a Meta platform for integrating advertising on Meta
							which is
							used to display and track advertisements, centrally manage advertising campaigns, pages and
							advertising accounts.
						</div>
						<div class="text">
							III.10.
							<b>Force Majeure Event</b> shall mean a fire, flood, explosion, strike, war, insurrection,
							embargo,
							government requirement, act of civil or military authority, act of God, or any similar event,
							occurrence or condition which is not caused, in whole or in part, by that party, and which is
							beyond
							the reasonable control of that party.
						</div>
						<div class="text">
							III.11.
							<b>Google Adwords (Ads) System</b>
							shall mean Contextual Advertising System, ads of which are placed on Google search resources and
							Google media network.
						</div>
						<div class="text">
							III.12.
							<b>Materials</b>
							shall mean ads placed by Client or on behalf of Client in the contextual advertising systems;
							Client’s IDs, email addresses, passwords, comments, photographs, images, graphics, text and data
							and
							any other forms of materials or information submitted by you through or otherwise in connection
							with
							the Platform or/and Service, regardless of whether such information is posted publicly or with
							password protection.
						</div>
						<div class="text">
							III.13.
							<b>Media advertising</b> shall mean graphic and/or animated ads placed on the advertising
							platforms.
							The placement cost of the media advertising for the reporting period shall be determined based
							on
							the impression rates and the number of impressions according to the statistical data of the
							corresponding system for online advertisement placement.
						</div>
						<div class="text">
							III.14.
							<b>Personal account</b> shall mean a part of a software using by Digital Eagle for purpose of
							rendering the Service to Client under this Agreement in the form of the Client's online account
							on
							the Platform through which Client may access and utilize the Service, audit the balance of
							advertising campaign budget in the system and receive a technical support of Digital Eagle.
						</div>
						<div class="text">
							III.15.
							<b>Reporting period</b> shall mean a period of the Service rendered by Digital Eagle to Client
							and
							equal to one (1) calendar month. The first reporting period shall start on the day following the
							day
							of receipt by Digital Eagle of the first advance payment made by Client according to the
							provisions
							hereof and end on the last day of the month in which this advance payment was received.
						</div>
						<div class="text">
							III.16.
							<b>Search engine</b> shall mean a software solution intended for online searching and responding
							to
							the user's query (defined by a key words and phrases) by a list of links to the relevant
							websites.
						</div>
						<div class="text">
							III.17.
							<b>Special Terms</b> shall mean the Special Terms and Conditions executed between Digital Eagle
							and
							Client in addition to this Agreement in purpose of purchasing by Client the particular services
							and
							products offered by Digital Eagle and/or third parties. The Special Terms are the integral part
							of
							this Agreement. The initial duration of the Special Terms is twelve (12) months and shall come
							into
							effect from the date of their signing by the authorized representatives of both parties. In case
							neither party declares the termination not later than 30 days before the date of expiry hereof,
							the
							Special Terms shall be prolonged for the next 12 months. The number of prolongations is not
							limited.
						</div>
						<div class="text">
							III.18.
							<b>Targeted Ads System (System)</b> shall mean a service owned by a third party (Meta, TikTok,
							etc.)
							which places the Client's advertisements and allows Client or Digital Eagle to manage the
							Advertisements and Advertising campaigns formed by Client or Digital Eagle.
						</div>
						<div class="text">
							III.19.
							<b>Targeting</b> shall mean a criterion in the system of ultra-precise targeting of
							advertisements,
							which allows to select from all users only the part that satisfies the parameters set by Client
							from
							the list offered by the corresponding system, and to show advertisements to it.
						</div>
						<div class="text">
							III.20.
							<b>Teaser advertising</b> shall mean an advertisement that contains a text and a small graphic
							image. The cost of placement of a teaser advertisement for the reporting period shall be
							determined
							based on the cost per click and number of clicks in accordance with the statistical data of the
							corresponding search engine for online advertisement placement.
						</div>
						<div class="text">
							III.21.
							<b>TikTok Ads system</b> shall mean a TikTok platform for integrating advertising on TikTok
							which is
							used to display and track advertisements, centrally manage advertising campaigns, pages and
							advertising accounts.
						</div>
						<div class="text">
							III.22.
							<b>User interface of the targeted ads system (User Interface)</b> shall mean an interface for
							accessing statistical data for placing advertisements and other information, managing the
							advertisement placement, which, among other things, provides the functionality of remote
							interaction
							of the Parties within the framework of the Special Terms. The Client’s entry into the User
							Interface
							is possible only after the conclusion of the Special Terms and is carried out using the Client’s
							login and password through the user's personal page (account) of the Client in the corresponding
							social media.
						</div>
						<div class="text">
							III.23.
							<b>VK Ads system</b> shall mean a VK Ads platform for integrating advertising on VK Ads which is
							used to display and track advertisements, centrally manage advertising campaigns, pages and
							advertising accounts.
						</div>
						<div class="text">
							III.24.
							<b>Website of the Client</b> shall mean the website specified in the Special Terms.
						</div>
						<div class="text">
							III.25.
							<b>Yandex Direct</b> shall mean Contextual Advertising System, ads of which are placed on Yandex
							search resources and Yandex media network.
						</div>
					</div>
				</li>
				<li>
					<b>SERVICE</b>
					<div class="first-list-wrapper">
						<div class="text">
							IV.1. Digital Eagle provides the services of online placement of advertisements in the
							third-party
							systems
							for advertisement placement, including creating on behalf of the Client online accounts on the
							third-party platforms/ systems and replenishment of the advertising budget accounts in the
							advertising systems for the Client (the “Service”). Rendering the Service is subject to
							availability
							of third-party products and services and execution of the Special Terms (in addition to this
							Agreement) between Digital Eagle and particular Client where the parties establish the
							specification
							of the Service, period of rendering the Service, the price of the Service and other specific
							terms
							and conditions of Service. Digital Eagle may provide Client with other services not
							mentioned in
							his Agreement or the Platform but specified in the Special Terms.

						</div>
						<div class="text">
							IV.2. Please note that references to or description of the Service (or related coupons or
							discounts)
							on the Platform should not be interpreted as endorsement of such Service and that such Service
							may
							be made available by Digital Eagle or by third parties. We reserve the right to refuse to render
							the
							Service to you for any reason and no reason.
						</div>
					</div>
				</li>
				<li>
					<b>PRICE OF SERVICE, COSTS AND PAYMENT</b>
					<div class="first-list-wrapper">
						<div class="text">
							5.1. Price of the Service and costs may consist of:
							<ul class="list dash">
								<li>budget of the advertising campaign;</li>
								<li>commission fee for the service to replenish the advertising budget;</li>
								<li>commission fee for payment processing and documentation management;</li>
								<li>other fees specified in the Special Terms.</li>
							</ul>
						</div>
						<div class="text">
							5.2. The specific fees of Digital Eagle, costs and payment terms shall be agreed by the parties
							in
							the Special Terms.
						</div>
						<div class="text">
							5.3. Client shall make all payments under this Agreement in U.S. dollars. Unless otherwise
							provided
							in the Special Terms or in the particular invoice, all invoices shall be due and payable within
							15
							days of receipt by Client in the manner specified in the Special Terms or the relevant invoice
							issued by Digital Eagle. The payment shall be deemed made at the moment of receipt of the funds
							on
							the bank account of Digital Eagle.
						</div>
						<div class="text">
							5.4. Client shall pay Digital Eagle in full all possible costs and other charges incurred by
							Digital
							Eagle related to the Service, including bank fees for transfer to and receipt of payments by
							Digital
							Eagle under this Agreement and other charges for goods and services procured from outside
							sources
							and billed to Digital Eagle. In addition, Client is responsible for any taxes applicable to
							Client’s
							transactions.
							Client shall use only OUR type of bank transfer paying to Digital Eagle.
						</div>
						<div class="text">
							5.5. If the invoice, or any amount due under this Agreement remains unpaid 10 days after its due
							date, Client shall pay Digital Eagle a default interest (penalty) at the rate of 0.02% per day
							on
							all amounts past due from the date that such amount became due and payable. If any payment due
							is
							not made by you, Digital Eagle may, in addition to its other remedies, at its sole discretion
							and
							without notice to you, (a) suspend its performance under this Agreement and your access to and
							use
							of the Platform or/and the Service, or (b) terminate the Special Terms and this Agreement and
							your
							access to and the use of the Service. If legal action is necessary to collect fees or charges
							due
							from you, then Client shall reimburse Digital Eagle for all expenses incurred in collecting the
							fees
							and charges, including all attorney fees and other expenses.
						</div>
						<div class="text">
							5.6. If Client does not agree with the amount of invoice, Client shall submit detailed
							objections to
							Digital Eagle via email within five (5) business days after receiving the relevant invoice.
						</div>
						<div class="text">
							5.7. Digital Eagle has the right to unilaterally change its fees for the Service under this
							Agreement. Digital Eagle shall notify Client of such change not later than 1 (one) month prior
							to
							the date when such change takes effect. In case Client does not agree with such change, Client
							shall
							notify Digital Eagle of disagreement with new fees no later than seven (7) business days prior
							to
							the date when such change takes effect and in this event the Special Terms are subject to
							termination at the date when such change takes effect.
						</div>
						<div class="text">
							5.8. Third-party rates for advertisements and other products and services, quantity,
							availability of
							the Service are subject to change without notice. Specials, sales, and other promotions offered
							by
							third parties may not be available at the time of the Service rendering.
						</div>
						<div class="text">
							5.9 If the advertising platform blocks the Client’s account in this platform due to the
							violation of
							the advertising rules by Client without the possibility of unblocking the account, Client shall
							pay Digital Eagle compensation in the amount of up to 100% of the advertising budget paid by
							Client, but no less than $500. This compensation does not apply in case of temporary
							blocking of the Client’s account.
							The current advertising policies and other rules of advertising platform can be found here:
							<div class="agreements__social-links">
								<span>VK -</span><a
									href="https://vk.com/ads?act=office_help&terms=1">https://vk.com/ads?act=office_help&terms=1</a>
								<span>Meta -</span><a
									href="https://www.facebook.com/policies/ads">https://www.facebook.com/policies/ads</a>
								<span>Google -</span><a
									href="https://www.google.com/ads/policies">https://www.google.com/ads/policies</a>
								<span>TikTok -</span><a
									href="https://ads.tiktok.com/help/article?aid=6685586866860720134">https://ads.tiktok.com/help/article?aid=6685586866860720134</a>
								<span>Yandex -</span><a
									href="https://yandex.com/support/direct/moderation/adv-rules.html">https://yandex.com/support/direct/moderation/adv-rules.html</a>
							</div>
						</div>
					</div>
				</li>
				<li>
					<b>MATERIALS</b>
					<div class="first-list-wrapper">
						<div class="text">6.1 The Platform is not designed or intended to be used as a disaster recovery or
							emergency data storage facility and you are responsible for creating and maintaining copies of
							your
							materials (including any photos, as applicable) prior to posting, uploading or otherwise
							submitting
							such materials through the Platform.
						</div>
						<div class="text">6.2. Digital Eagle and its suppliers and vendors are not required to assess or
							otherwise determine the validity or legitimacy of any complaints or demands that they may
							receive
							regarding any materials that you may use or allow others to use in connection with the Platform
							and
							Service (including materials posted or submitted to the Platform) before Digital Eagle and its
							suppliers and vendors take any remedial action that they consider, in their sole discretion, to
							be
							appropriate.
						</div>
						<div class="text">
							6.3. NEITHER DIGITAL EAGLE NOR ITS SUPPLIERS OR VENDORS CONTROL THE MATERIALS POSTED OR
							SUBMITTED TO
							THE PLATFORM, NOR DO WE OR THEY HAVE ANY OBLIGATION TO MONITOR, SCREEN, POLICE OR EDIT THOSE
							MATERIALS FOR COMPLIANCE WITH APPLICABLE LAWS OR THIS AGREEMENT. YOU MAY FIND SOME OF THE
							MATERIALS
							POSTED BY OTHER USERS TO BE OFFENSIVE, HARMFUL, INACCURATE OR DECEPTIVE. YOU SHOULD USE CAUTION
							AND
							COMMON SENSE WHEN USING THE PLATFORM AND SERVICE.
						</div>
						<div class="text">
							6.4. Client shall pay Digital Eagle, in addition to covering damage, a penalty in the amount of
							100% of the advertising budget, but no less than $500, for every case of fraud representation,
							cloaking, gambling and other materials violating the advertisement placement rules of the
							relevant advertising platform. Digital Eagle is eligible to set off its claim of penalty against
							the payment made by Client towards advertising budget.
						</div>
					</div>
				</li>
				<li><b>INTELLECTUAL AND OTHER PROPRIETARY RIGHTS</b>
					<div class="first-list-wrapper">
						<div class="text">VII.1. Subject to your compliance with this Agreement, Digital Eagle grants to
							you,
							for so long as you are permitted by Digital Eagle to use the Platform, a limited, revocable,
							non-exclusive, non-transferable license to access, use and display the Platform (including any
							content or other materials generally made available through the Platform to users of the
							Platform)
							solely for your non-commercial use. No title, rights, or interests in any downloaded materials
							from
							the Platform are afforded you as a result of such downloading.
						</div>
						<div class="text">
							VII.2. As between you and Digital Eagle, Digital Eagle owns rights to the Platform and all the
							content on the Platform, including text, graphics, legends, customized graphics, original
							photographs, data, images, music, audio and video clips, typefaces, titles, button icons, logos,
							designs, words or phrases, page headers, and software as well as the design, coordination,
							arrangement, enhancement, and presentation of this material. The Platform and all the content on
							the
							Platform is subject to trademark, service mark, copyright, and/or other intellectual property
							rights
							held by Digital Eagle and its suppliers and vendors. Any trademark or copyright notices may not
							be
							deleted or altered in any way. Digital Eagle's trademarks and copyrights may not be used in
							connection with any products or services that are not offered by or on behalf of Digital Eagle.
							You
							acknowledge that these rights are valid and protected in all forms, media, and technologies
							existing
							now or hereinafter developed.
						</div>
						<div class="text">
							VII.3. Copying, publishing, broadcasting, re-broadcasting, webcasting, transmitting, modifying,
							deleting, augmenting, distributing, downloading, storing, reproducing, sublicensing, adapting,
							creating derivative works of any content available through the Platform, or posting this content
							or
							selected portions of this content in any manner on any network computer, broadcast media, or
							other
							technologies existing now or hereinafter developed for unauthorized publication or commercial
							use
							without the prior written consent of Digital Eagle is strictly prohibited. You hereby agree not
							to
							reproduce, duplicate, copy, sell, resell, decompile, disassemble, or exploit for any commercial
							purposes any portion of the Platform, use of the Platform, or access to the Platform, or to
							collect
							any information about the Platform users, or otherwise systematically download and store the
							Platform content. You agree that you will not send, upload, post, reproduce, transmit or
							distribute
							any communication, content or material of any type through the Platform or otherwise to Digital
							Eagle that infringes or violates any rights of any party or violates this Agreement.
						</div>
						<div class="text">
							VII.4. If you submit or otherwise provide to Digital Eagle any communications, content or
							material
							for publicly available placement on the Platform, including, without limitation, any idea,
							concept
							or invention, you hereby irrevocably grant to Digital Eagle an unrestricted, worldwide,
							perpetual,
							sublicensable (through multiple tiers), royalty-free license to use, reproduce, display
							publicly,
							perform, publish, transmit, distribute and otherwise exploit such materials in any medium and
							for
							any purpose, and you further agree that Digital Eagle is free to use any ideas, concepts or
							know-how
							that you or individuals acting on your behalf provide to Digital Eagle in accordance with the
							foregoing license grant.
						</div>
						<div class="text">
							VII.5. Client represents and warrants that Digital Eagle’s use of any and all materials
							furnished by
							Client hereunder will not infringe any Intellectual Property Right of any third party.
						</div>
						<div class="text">
							VII.6.
							The Digital Millennium Copyright Act of 1998 (the "DMCA") provides recourse for copyright owners
							who
							believe that material appearing on the Internet infringes their rights under U.S. copyright law.
							If
							you believe in good faith that materials available on the Platform infringe your rights under
							U.S.
							copyright law, you may send to Digital Eagle a written notice by mail, e-mail or fax, requesting
							that Digital Eagle remove such material or block access to it. If you believe in good faith that
							someone has wrongly filed a notice of copyright infringement against you, the DMCA permits you
							to
							send to Digital Eagle a counter-notice. Notices and counter-notices must be sent in writing and
							meet
							the then-current statutory requirements imposed by the DMCA (see http://www.copyright.gov for
							details), which, with respect to notices of infringement, currently include, among other
							requirements, the following:
							<ul class="list disc">
								<li>Sufficient information identifying the copyrighted work(s) believed to be infringed;
								</li>
								<li>Sufficient information identifying the allegedly infringing material(s) and the location
									of
									such material(s) in order to permit Digital Eagle to locate such material(s);</li>
								<li>A statement from the owner (or the owner's authorized representative) of the copyrighted
									work(s) believed to be infringed that such owner or authorized representative has a good
									faith belief that the allegedly infringing materials are used in a manner not authorized
									by
									the copyright owner, its agent, or the law;</li>
								<li>Contact information for the complaining party, including a mailing address, a telephone
									number and an email address;</li>
								<li>A statement that the information in the notification is accurate and, under penalty of
									perjury, that the complaining party is authorized to act on the copyright owner's
									behalf;
								</li>
								<li>A signature or the electronic equivalent from the owner (or the owner's authorized
									representative) of the copyrighted work(s) believed to be infringed.</li>
							</ul>
							Notices and counter-notices must be sent in writing to Digital Eagle to the mail or email
							address
							specified in this Agreement.
						</div>
						<div class="text">
							VII.7.
							Client agrees that the Service to be performed pursuant to this Agreement, including all tasks,
							duties, results, inventions and intellectual property developed or performed pursuant to this
							Agreement, are not considered "work made for hire" as defined in 17 U.S.C. Section 101, and that
							any
							such work is by virtue of this Agreement are not assigned to Client and shall be the sole
							property
							of Digital Eagle for all purposes, including, but not limited to, copyright, trademark, service
							mark, patent, and trade secret laws, unless otherwise specified in the Special Terms. Client
							agrees
							to execute any and all documents prepared by Digital Eagle and to do all other lawful acts as
							may be
							required by Digital Eagle to establish, document, and protect such rights.
						</div>
					</div>
				</li>
				<li>
					<b>REPRESENTATIONS, DISCLAIMER OF WARRANTY</b>
					<div class="first-list-wrapper">
						<div class="text">VIII.1. Each party warrants that it has full capacity and authority, and all
							necessary
							licenses, permits and consents to enter into and perform this Agreement and that those signing
							this
							Agreement are duly authorized to bind the party for whom they sign.</div>
						<div class="text">VIII.2. Digital Eagle represents and warrants that it will perform the Service:
							(a) in
							a good, timely, efficient, professional and workmanlike manner; and (b) using personnel who are
							qualified and fully familiar with the technology, process and procedures to be used to deliver
							the
							Service.</div>
						<div class="text">VIII.3. Although Digital Eagle reserves the right to correct any errors,
							omissions, or
							inaccuracies, we do not accept any responsibility for the accuracy, reliability, currency, or
							completeness of any information, content, materials, services, products, functionality or other
							resources (collectively, "Resources") available on or accessible through the Platform
							(eventypographical or imaging errors), including the substance, accuracy, or sufficiency of any
							service or product information listed on the Platdorm. Further, Digital Eagle does not represent
							that the Platform will operate without interruption or error, nor do we provide any assurances
							of
							the availability or usability of the online services.</div>
						<div class="text">VIII.4. Digital Eagle does not accept any liability for the consequences arising
							from
							the application, use, or misuse of any resources contained on or made available through the
							Platform, including any injury and/or damage to any person or property as a matter of product
							liability, negligence, or otherwise.</div>
						<div class="text">VIII.5. Digital Eagle will attempt to keep the information, services and resources
							accessible through the Platform timely and accurate, but make no guarantees, and disclaim any
							implied warranty or representation about the Platform's accuracy, relevance, timeliness,
							completeness, reliability, security or appropriateness for a particular purpose.</div>
						<div class="text">VIII.6. Without limiting the generality of the foregoing, Digital Eagle makes no
							representation or warranty that the quality of any goods, services, information or other
							materials
							purchased or obtained through the Platform will meet your expectations, or that any password
							protection or other security measures for materials that you may use or allow others to use in
							connection with the Platform and Service will prevent unauthorized access to such materials, or
							that
							any such materials will not be accessed or misused by any other party.</div>
						<div class="text">VIII.7. Digital Eagle does not endorse any claims about the efficacy of
							advertisements. Digital Eagle specifically disclaims any guarantee or warranty, express or
							implied,
							with respect to any advertisements. No information conveyed by Digital Eagle either orally or in
							writing shall create such a warranty.</div>
						<div class="text">VIII.8. Your access and use of the Service, Platform and any other websites,
							including
							any resources contained on, or otherwise made available by, them, is solely at your own risk.
						</div>
						<div class="text">VIII.9. You represent and warrant that you have the right to use any credit card
							or
							other method of payment that you submit in connection with the Service. Further terms and
							conditions
							related to payment transactions may apply.</div>
						<div class="text">VIII.10. DIGITAL EAGLE DISCLAIMS ALL EXPRESS OR IMPLIED REPRESENTATIONS OR
							WARRANTIES
							REGARDING THE INFORMATION, CONTENT, SERVICES, PRODUCTS, MERCHANDISE, MATERIALS, FUNCTIONALITY,
							AND
							ANY OTHER RESOURCES AVAILABLE ON OR ACCESSIBLE THROUGH THE PLATFORM, INCLUDING (WITHOUT
							LIMITATION)
							ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
							NON-INFRINGEMENT.
							ALL SUCH INFORMATION, CONTENT, SERVICES, PRODUCTS, MERCHANDISE, MATERIALS, FUNCTIONALITY AND
							OTHER
							RESOURCES ARE MADE AVAILABLE "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND.
						</div>
						<div class="text">VIII.11. The Platform may contain links to, or otherwise make available,
							third-party
							sites, services, products, information, content, materials, merchandise, functionality and/or
							other
							resources ("Third – Party Goods and Resources"). These links and access are provided for your
							convenience and reference only. Digital Eagle does not control such Third – Party Goods and
							Resources and, therefore, Digital Eagle is not responsible for such Third – Party Goods and
							Resources, or any content posted on or made available by such Third – Party Goods and Resources.
							Be
							aware that Digital Eagle does not control, makes no guarantees about, and disclaims any express
							or
							implied representations or warranties about such Third – Party Goods and Resources, including
							without limitation the security of any materials, or the accuracy, relevance, timeliness,
							completeness, or appropriateness for a particular purpose of the information or the resources
							contained on or made available by such Third – Party Goods and Resources or any other websites.
							Digital Eagle reserves the right to terminate such links or such access at any time. The fact
							that
							Digital Eagle offers such links or access should not be construed in any way as an endorsement,
							authorization, or sponsorship of such Third – Party Goods and Resources, or any content made
							available thereby. Because some Third – Party Goods and Resources employ automated search
							results or
							otherwise link you to Third – Party Goods and Resources containing information that may be
							deemed
							inappropriate or offensive, Digital Eagle cannot be held responsible for the accuracy, copyright
							compliance, legality, or decency of material contained in or made available by Third – Party
							Goods
							and Resources, including circular ads available online, and you hereby irrevocably waive any
							claim
							against Digital Eagle with respect to such Third – Party Goods and Resources. Your use of any
							Third
							– Party Goods and Resources is subject to the Third Party's terms, conditions and policies
							applicable to such products, services or materials (such as Terms of Service or Privacy Policies
							of
							the providers of such products, services or materials). Digital Eagle is not responsible for the
							privacy and security of any information you share with that Third Party, including your credit
							card
							or payment information. When you elect to receive these services from a Third Party, you agree
							to
							hold that Third Party responsible for any unauthorized use or disclosure of your personal
							information.</div>
					</div>
				</li>
				<li><b>LIABILITY AND LIMITATION OF LIABILITY</b>
					<div class="first-list-wrapper">
						<div class="text">IX.1. If Client has any complaint regarding the Service, Digital Eagle shall
							respond
							to this complaint upon the written request of Client sent via e-mail and stating the detailed
							description of Client’s demands and, if this complaint is reasonable, shall remedy the lacks of
							the
							Service within a reasonable period of time.</div>
						<div class="text">IX.2. Digital Eagle shall not be obliged to rectify any particular defect of the
							Service result if attempts to rectify such defect other than normal recovery or diagnostic
							procedures have been made by the Client's personnel or third parties without the permission of
							Digital Eagle.</div>
						<div class="text">IX.3. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL DIGITAL
							EAGLE OR OUR EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, AFFILIATES, SUPPLIERS, VENDORS, LICENSORS,
							CO-BRANDERS OR PARTNERS (COLLECTIVELY, "DIGITAL EAGLE PARTIES") BE LIABLE FOR ANY DIRECT,
							INDIRECT,
							SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER
							RESULTING FROM ANY LOSS OF USE, LOSS OF OR DAMAGE TO DATA, LOSS OF PROFITS, BUSINESS, BUSINESS
							INTERRUPTION, FAILURE TO ACHIEVE COST SAVINGS, LOSS OF ANTICIPATED SAVINGS OR PROFIT, LOSS OF
							GOODS,
							DEPLETION OF GOODWILL, LITIGATION, OR ANY OTHER PECUNIARY LOSS, WHETHER BASED ON BREACH OF
							CONTRACT,
							TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR OTHERWISE ARISING OUT OF OR IN ANY WAY
							CONNECTED
							WITH THE USE, OPERATION OR PERFORMANCE OF THE SERVICE, WITH THE DELAY OR INABILITY TO USE THE
							SERVICE, ANY DEFECTS IN THE SERVICE, OR WITH THE PROVISION OF, OR FAILURE TO MAKE AVAILABLE, ANY
							INFORMATION, SERVICES, PRODUCTS, MATERIALS, OR OTHER RESOURCES AVAILABLE ON OR ACCESSIBLE
							THROUGH
							THE SERVICE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</div>
						<div class="text">IX.4. You understand and agree that neither Digital Eagle nor its suppliers or
							vendors
							are responsible for any claim, loss, or damage directly or indirectly resulting from your use of
							the
							Platform or the information resources contained on or accessible through the Platform. Digital
							Eagle
							does not guarantee the sequence, accuracy, or completeness of any information or content on the
							Platform and third-party service/product providers’ resources and shall not be liable in any way
							to
							you or anyone else who may use the information or content or to whom the information or content
							may
							be furnished, for any delays, inaccuracies, unavailability, errors, or omissions therefrom or in
							the
							transmission or delivery of all or any part thereof or for any damage arising therefrom or
							occasioned thereby.</div>
						<div class="text">IX.5. THE OPERATION OF THE PLATFORM MAY BE AFFECTED BY NUMEROUS FACTORS BEYOND
							DIGITAL
							EAGLE'S CONTROL. THE OPERATION OF THE PLATFORM, WHETHER BY DIGITAL EAGLE, ITS SUPPLIERS OR ITS
							VENDORS, MAY NOT BE SECURE. SECURITY AND PRIVACY RISKS CANNOT BE ELIMINATED. PASSWORD PROTECTION
							AND
							ANY OTHER SECURITY MEASURES MAY NOT PREVENT UNAUTHORIZED ACCESS TO MATERIALS YOU MAY USE OR
							ALLOW
							OTHER PERSONS TO USE IN CONNECTION WITH THE PLATFORM OR THE SERVICE, INCLUDING MATERIALS POSTED
							OR
							SUBMITTED TO THE PLATFORM.</div>
						<div class="text">IX.6. ANY LIABILITY ON THE PART OF THE DIGITAL EAGLE PARTIES, IN THE AGGREGATE,
							SHALL
							NOT EXCEED THE FEES PAID BY CLIENT FOR THE PARTICULAR SERVICE PROVIDED.</div>
						<div class="text">IX.7. You acknowledge and agree that the limitations set forth above are
							fundamental
							elements of this Agreement and the Service would not be provided to you absent such limitations.
						</div>
						<div class="text">IX.8. No action, suit or proceeding arising out of or in connection with this
							Agreement may be brought more than one year after the asserted cause of action arises, except
							that
							Digital Eagle may bring an action for payment within three years after the date of the most
							recent
							payment of Client or after the asserted cause of action arises whichever occurs later. The
							provisions of this Section shall not limit (a) liability for breach of any confidentiality
							obligation, (b) liability for infringement of the other party’s Intellectual Property Rights,
							(c)
							the indemnity obligations set forth in this Agreement, (d) liability for payment of interest
							added
							by a court or an arbitration panel to a judgment entered in any action or proceeding under this
							Agreement.
						</div>
					</div>
				</li>
				<li><b>INDEMNIFICATION</b>
					<div class="first-list-wrapper">
						<div class="text">
							X.1. You agree to indemnify, defend, and hold harmless Digital Eagle, its parents, subsidiaries,
							affiliates, any related companies, suppliers, licensors and partners, and the officers,
							managers,
							employees, representatives and contractors from and against any and all third party claims,
							actions,
							demands, losses, damages, costs, liabilities and expenses (including but not limited to
							attorneys’
							fees and court costs) arising out of, or in any way relating to your access to or use of the
							Platform and/or Service, your violation of this Agreement, any products or services purchased or
							obtained by you in connection with the Platform, your breach of any applicable law. We reserve
							the
							right, at your expense, to assume the exclusive defense and control of any matter for which you
							are
							required to indemnify us and you agree to cooperate with our defense of these claims. You agree
							not
							to settle any such matter without the prior written consent of Digital Eagle. We will use
							reasonable
							efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
						</div>
					</div>
				</li>
				<li><b>CONFIDENTIALITY</b>
					<div class="first-list-wrapper">
						<div class="text">XI.1. In case of execution of the Special Terms, it is anticipated that each of
							the
							parties will disclose to the other party proprietary or confidential information that is
							identified
							as confidential at the time of disclosure or which can reasonably be regarded as confidential
							(“Information”). Information may include software programs, technical data, methodologies,
							customer
							information and business information of the parties.
						</div>
						<div class="text">XI.2. Each party shall be a “Disclosing Party” with respect to Information which
							that
							party discloses to the other and shall be a “Receiving Party” with respect to Information which
							that
							party receives from the other. The Receiving Party shall employ diligent efforts to maintain the
							secrecy and confidentiality of all Information. Such diligent efforts shall be at least
							equivalent
							to that degree of care which Receiving Party normally exercises with regard to its own property
							that
							it maintains secret and confidential, but in any event no less than a reasonable degree of care.
						</div>
						<div class="text">XI.3. Information may be disclosed only for purposes of the Service with
							Disclosing
							Party and only to the Receiving Party’s employees, contractors and agents with a need to know,
							provided that each such employee has previously been advised of the terms of this Agreement and
							each
							such contractor and agent has agreed in writing to be bound by an obligation of confidentiality
							no
							less restrictive than the terms herein. The disclosure of Information shall not be construed to
							grant to the Receiving Party any ownership or other proprietary interest in such Information.
							The
							Receiving Party agrees that it does not acquire any title, ownership, or other intellectual
							property
							right or license by virtue of such disclosure.</div>
						<div class="text">XI.4. A Receiving Party has no obligation with respect to any Information
							disclosed
							hereunder which: (a) was rightfully in Receiving Party’s possession before receipt from
							Disclosing
							Party other than through prior disclosure by Disclosing Party; or (b) is or becomes a matter of
							general public knowledge through no breach of this Agreement; or (c) is rightfully received by
							Receiving Party from a third party without an obligation of confidentiality; or (d) is
							independently
							developed by Receiving Party. A Receiving Party may also disclose Information, without being in
							violation hereof, if and to the extent that such Information is disclosed under operation of
							law,
							governmental regulation, or court order, provided Receiving Party, if permitted by applicable
							law,
							first gives Disclosing Party notice and a reasonable opportunity to secure confidential
							protection
							of such Information.</div>
						<div class="text">XI.5. Upon termination of the Special Terms, the Receiving Party shall (a)
							immediately
							cease using the Information, (b) promptly return or destroy all tangible embodiments of the
							Information, and (c) promptly certify in writing Receiving Party’s compliance with this
							paragraph.
							The confidentiality obligations of a Receiving Party under this Agreement shall terminate five
							years
							after the Disclosing Party’s receipt of Receiving Party’s written certification required by this
							paragraph. Each party acknowledges that all records, documents, and other tangible evidence
							containing or of Information prepared by it or coming into its possession by virtue of its
							engagement hereunder are and will remain the property of the Disclosing Party and each party
							acknowledges that the other party may retain an archival copy of the information provided that
							the
							confidentiality obligations continue to apply.</div>
						<div class="text">XI.6. If a Receiving Party breaches the provisions of this Section, the damage to
							the
							Disclosing Party may be irreparable. Therefore, in the event of a breach or threat of breach,
							Disclosing Party shall be entitled to seek equitable relief, in addition to any other relief
							available at law.
						</div>
					</div>
				</li>
				<li><b>OTHER RIGHTS AND OBLIGATIONS</b>
					<div class="first-list-wrapper">
						<div class="text">XII.1. You agree to use the Platform only for lawful purposes. You agree not to
							interrupt or attempt to interrupt the operation of the Platform in any way. Any conduct by you
							that,
							in our sole discretion, restricts, inhibits, or interferes with the ability of any other user to
							enjoy the Platform will not be tolerated, including by means of hacking or defacing any portion
							of
							the Platform, or by engaging in spamming, flooding, or other disruptive activities. You are
							strictly
							prohibited from communicating on or through the Platform (including in connection with any
							materials) any unlawful, harmful, offensive, threatening, abusive, libelous, harassing,
							defamatory,
							vulgar, obscene, profane, hateful, fraudulent, sexually explicit, racially, ethnically, or
							otherwise
							objectionable material of any sort, including, but not limited to, any material that encourages
							conduct that would constitute a criminal offense, give rise to civil liability, or otherwise
							violate
							any applicable local, state, national, or international law. We reserve the right to terminate
							or
							suspend your access to and use of the Platform, or parts of the Platform, without notice, if we
							believe, in our sole discretion, that it is in violation of any applicable law or it is harmful
							to
							our interests or the interests, including intellectual property or other rights, of another user
							or
							other third – party partners, affiliates, sponsors, providers, licensors, or merchants.</div>
						<div class="text">XII.2. It is your responsibility to ascertain and obey all applicable local,
							state,
							and federal laws (including minimum age requirements) regarding the possession, use and sale of
							any
							products or services on the Platform. By submitting any information through the Platform in
							connection with purchasing any products or services, you grant to us the right to provide such
							information to third parties for purposes of facilitating such purchase. Verification of
							information
							may be required prior to the acknowledgment or completion of any transaction.</div>
						<div class="text">XII.3. You must not post, transmit or otherwise make available through or in
							connection with the Platform any virus or other computer code, file or program that is
							potentially
							harmful or invasive or intended to damage or hijack the operation of, or to monitor the use of,
							any
							hardware, software or equipment.</div>
						<div class="text">XII.4. You agree to provide true, accurate, current, and complete information in
							connection with the Platform and the Service. It is your responsibility to maintain and promptly
							update your personal account information to keep it true, accurate, current, and complete. If
							you
							provide any information that is fraudulent, untrue, inaccurate, incomplete, or not current, or
							we
							have reasonable grounds to suspect that such information is fraudulent, untrue, inaccurate,
							incomplete, or not current, we reserve the right to suspend or terminate your personal account
							without notice and refuse any and all current and future use of the Platform and the Service.
							Because any termination of your access to the Platform may be effected without prior notice, you
							acknowledge and agree that we may immediately deactivate or delete your personal account and all
							related information and files in your personal account and bar any further access to such files
							or
							the Platform and the Service. Furthermore, you agree that we shall not be liable to you or any
							third
							party for any termination of your access to your personal account and the Service.</div>
						<div class="text">XII.5. You may be asked to supply a user ID and password and other information to
							register for purpose of using the Service. We may refuse to grant you a user ID that
							impersonates
							someone else, is or may be illegal, is or may be protected by trademark or other proprietary
							rights
							law, is vulgar or otherwise offensive, or may cause confusion, as determined by us in our sole
							discretion. For security reasons, user IDs and passwords must be non-obvious, hard-to-guess,
							confidential and changed on a regular basis, and you must log out at the end of each session.
							You
							are responsible for maintaining the confidentiality of your user ID and password and are fully
							responsible for all activities (including purchases, as applicable) that occur in connection
							with
							your user ID or password such that, for all purposes under this Agreement, any activities in
							connection with your user ID or password will be deemed to be your activities. You agree to
							immediately notify us of any unauthorized use of either your user ID or password or any other
							breach
							of security. You further agree that you will not permit others, including those whose personal
							accounts have been terminated, to access the Platform using your user ID or password. All user
							IDs
							and passwords remain the property of Digital Eagle, and may be cancelled or suspended at any
							time by
							Digital Eagle without any prior notice or any liability to you or any other person. Digital
							Eagle is
							not under any obligation to verify the actual identity or authority of the user of any user ID
							or
							password. If Digital Eagle, in its sole discretion, considers a password to be insecure, then
							Digital Eagle may cancel the password.</div>
						<div class="text">XII.6. You must respond promptly to all email and other correspondence from
							Digital
							Eagle, including without limitation email and correspondence concerning complaints or concerns
							regarding your use of the Platform, the Service and the use of your personal account.</div>
						<div class="text">XII.7. Your submission of information through the Platform is governed by our
							Privacy
							Policy, which, among other things, explains how we seek to protect the privacy of the personal
							information that you provided to us through the Platform. When participating in sweepstakes or
							contests offered through the Platform, you are urged to review the official rules and
							regulations
							governing those promotions. All materials submitted as part of a contest will be governed by
							contest
							rules published with the contest.</div>
						<div class="text">XII.8. You are responsible for obtaining, maintaining and paying for all hardware,
							software and all telecommunications and other services needed for you to use the Platform and
							the
							Service.</div>
						<div class="text">XII.9. Each party shall appoint an authorized representative responsible for
							interaction with another party and provide their contact details to each other within two (2)
							business days from the date of the Special Terms execution. Correspondence of those persons via
							electronic communication shall be valid upon the negotiation and approval of all stages of
							rendering
							the Service.</div>
						<div class="text">XII.10. Client guarantees that the advertisements placed by Client or on its
							behalf in
							the advertising system(s) do not violate any rights and interests of any third parties protected
							by
							law, as well as applicable laws. In case of any claims of any third parties concerning violation
							of
							their rights and interests and/or legislation by the Client’s ads (including those that may be
							made
							against Digital Eagle), Client shall resolve these issues on its own behalf, using its own
							resources
							and at its own expense and Digital Eagle shall have the right to reimbursement by Client of
							expenses
							related to such claim disputes.</div>
						<div class="text">XII.11. Client shall have the right to check the progress and quality of the
							Service
							rendered by Digital Eagle under the Special Terms, as well as provide Digital Eagle with
							additional
							clarifying guidance on the provision of the Service.
						</div>
						<div class="text">XII.12. Digital Eagle shall:
							<div class="text">(1) provide the Service to Client on the terms and in the manner, specified by
								the
								parties in this Agreement and the Special Terms;
							</div>
							<div class="text">(2) start rendering the Service within three (3) business days upon an
								execution
								of the Special Terms, receipt from Client of the relevant payment of the Service and costs,
								providing us by Client with the relevant materials and Client’s performance of other
								obligations
								specified in this Agreement and the Special Terms;</div>
							<div class="text">(3) be given an extension of time for completion of any Service if Client
								requests
								changes of the scope of Service, or/and a delay is caused in whole or in part by an action
								or
								omission of Client or its employees, agents or third – party contractors, or/and a force
								majeure
								event occurs. The parties shall use best endeavors to agree in writing, signed by both
								parties,
								what extension of time is reasonable in the circumstances. The parties shall take all
								reasonable
								action to minimize the effects of the force majeure event and promptly notify the other
								party in
								writing of the nature and extent of the force majeure event causing its failure or delay in
								performance;
							</div>
							<div class="text">(4) have the right to stop or do not start rendering the Service, as well as
								to
								withdraw from rendering the Service and demand compensation for costs and damages in case of
								Client’s violation of this Agreement or/and the Special Terms. Digital Eagle shall notify
								Client
								about such circumstances by sending an e-mail;</div>
							<div class="text">(5) provide Client with the opportunity to examine the electronic statistical
								data
								of the advertising campaign, using the guest login and password provided by Digital Eagle
								via
								e-mail. The statistical data shall be provided in the amount and form, stipulated by the
								characteristics of the relevant search engine for online advertisement placement.</div>
						</div>
						<div class="text">XII.13. Client shall be liable for management of the advertising campaign of
							Client
							and its result, as well as management of the personal account. Client shall not be entitled to
							make
							any claims against Digital Eagle in case of failure to achieve the planned indicators and/or
							non-
							placement of the ads by the contextual ad system (systems).
						</div>
						<div class="text">XII.14. Client agrees that Digital Eagle shall have the right to use the Service
							information rendered to Client and its results for the purpose of promotion/advertising Digital
							Eagle’s activity without additional improvement of Client. Client also agrees that both during
							the
							term hereof, and for an unlimited period after termination of this Agreement or the Special
							Terms
							(regardless of the reason of termination) Digital Eagle shall have the right to use the
							trademarks,
							logos, trade names of Client, reviews specifically provided by Client, in the following ways:
							<ul class="list disc">
								<li>place in the portfolio in the list of the clients/partners;</li>
								<li>provide at various public events in the list of the clients/partners;
								</li>
								<li>place online on the Website.
								</li>
							</ul>
						</div>
					</div>
				</li>
				<li><b>TERMINATION. REFUND POLICY</b>
					<div class="first-list-wrapper">
						<div class="text">XIII.1. This Agreement is effective until terminated by either party. If you no
							longer
							agree to be bound by this Agreement, you must cease your use of the Platform. Your use of the
							Platform is at your sole risk. If you are dissatisfied with the Platform, its content, or any of
							the
							terms, conditions, and policies of this Agreement, your sole and exclusive legal remedy is to
							discontinue using the Platform, unless otherwise is specified herein or in the Special Terms.
						</div>
						<div class="text">XIII.2. f you did not enter into the Special Terms, you may terminate this
							Agreement
							at any time by closing your personal account, discontinuing any access to or use of the
							Platform. If
							the Special Terms are in place and you want to terminate this Agreement and the Special Terms,
							you
							have to perform all your payment obligations before the date of proposed termination and provide
							us
							with a thirty days’ written notice of termination by email.</div>
						<div class="text">XIII.3. Digital Eagle may at any time and for any reason, with or without cause,
							and
							in their sole discretion, immediately: (i) suspend or terminate (in whole or in part) your
							authorization to use the Platform and personal account you may have; (ii) suspend or terminate
							and
							permanently delete and destroy any user ID, password, URL, IP address or domain name; (iii)
							remove
							from the Platform and permanently delete and destroy any materials (or any components thereof)
							that
							you or others may have posted or submitted to the Platform (and for purposes of this Agreement,
							"posted" and "submitted" includes transmission on or through the Internet and in hardcopy format
							through facsimile or post) for any reason or no reason; (iv) restrict access to the materials
							posted
							or submitted to the Platform and to any personal account you may have; and (v) prohibit you from
							any
							future use of the Platform; all without any prior notice or liability to you or any other
							person.
						</div>
						<div class="text">XIII.4. If this Agreement is terminated for any reason, then: (a) this Agreement
							will
							continue to apply and be binding upon you in respect of the accrued rights, remedies,
							obligations or
							liabilities existing at termination and your prior use of the Platform (and any unauthorized
							further
							use of the Platform), including payment of any charges accrued in connection with use of the
							Platform and Service and your indemnification obligations; (b) Digital Eagle may immediately
							remove
							from the Platform and permanently delete and destroy any materials that you or others may have
							posted or submitted to the Platform without any prior notice or liability to you or any other
							person; (c) any fees and charges previously paid by you for unused Service will not be refunded
							(if
							this Agreement is terminated as a result of its violation by you); and (d) any rights or
							licenses
							granted to us under this Agreement will survive such termination.</div>
						<div class="text">XIII.5. In the event of Client’s cancellation of the Service after entering into
							the
							Special Terms and making an advance payment for the Service, before rendering the Service by
							Digital
							Eagle, Digital Eagle is entitled to unilaterally withhold as a liquidation damage 10% of an
							advance
							payment and all costs/expenses related to the Service.</div>
						<div class="text">XIII.6. In the event of Client’s cancellation of the Service after entering into
							the
							Special Terms and making an advance payment for the Service, upon Digital Eagle started
							rendering
							the Service, Digital Eagle is entitled to unilaterally withhold as a liquidation damage 100% of
							an
							advance payment and all costs/expenses related to the Service.</div>
					</div>
				</li>
				<li><b>APPLICABLE LAW. DISPUTE RESOLUTION</b>
					<div class="first-list-wrapper">
						<div class="text">14.1. This Agreement, your use of the Platform and the Service, all transactions
							through the Platform, and all related matters, regardless of your location, are governed solely
							by,
							and construed solely in accordance with, the laws of the State of New York, excluding any rules
							of
							private international law or the conflict of laws which would lead to the application of any
							other
							laws. </div>
						<div class="text">14.2. In the event that any claim or dispute arises out of or in connection with
							this
							Agreement, the parties shall, following service of written notice by one party on the other,
							attempt
							to resolve amicably by way of good faith negotiations and discussions any such dispute or claim
							as
							soon as reasonably practicable (and in any event within 14 calendar days after such notice or by
							such later date as the parties may otherwise agree in writing). If the parties are unable to
							resolve
							the dispute or claim in accordance with this section, either party may commence proceedings in
							accordance with the arbitration clause below.</div>
						<div class="text">14.3. Any dispute, controversy, difference or claim arising out of or relating to
							this
							Agreement and/or in connection with it shall be finally decided with the Arbitration Court
							attached
							to the Czech Chamber of Commerce and the Agricultural Chamber of the Czech Republic by one
							arbitrator appointed by the President of the Arbitration Court in accordance with the On-line
							Rules
							of the Arbitration Court. The parties select the e-mail addresses for the conduct of the on-line
							arbitral proceedings:
							Digital Eagle - registration@digital-eagle.us
							Client – email address used for registration of a personal account.
							The law of this arbitration clause shall be Czech Law. The arbitration proceedings shall be
							conducted in English.
						</div>
						<div class="text">14.4. If for any reason the arbitration clause above is not applicable to the
							dispute
							between the parties of this Agreement, you agree that any claim or dispute arises out of or in
							connection with this Agreement shall be commenced exclusively in the state and federal courts of
							the
							State of New York, New York County. Each party (you and Digital Eagle) hereby irrevocably
							submits to
							the exclusive jurisdiction of the state and federal courts of the State of New York, New York
							County
							for the adjudication of any dispute hereunder or in connection herewith or with any transaction
							contemplated hereby or discussed herein, and hereby irrevocably waives, and agrees not to assert
							in
							any suit, action or proceeding, any claim that it is not personally subject to the jurisdiction
							of
							any such court, that such suit, action or proceeding is improper or inconvenient venue for such
							proceeding. Each party (you and Digital Eagle) hereby irrevocably waives personal service of
							process
							and consents to process being served in any such suit, action or proceeding by mailing a copy
							thereof via registered or certified mail or overnight delivery (with evidence of delivery) to
							such
							party at the address in effect for notices to it under this Agreement and agrees that such
							service
							shall constitute good and sufficient service of process and notice thereof. Nothing contained
							herein
							shall be deemed to limit in any way any right to serve process in any manner permitted by law.
							The
							parties (you and Digital Eagle) hereby waive all rights to a trial by jury.</div>
						<div class="text">14.5. If either party shall commence an action or proceeding to enforce any
							provisions
							of the Agreement, then the prevailing party in such action or proceeding shall be reimbursed by
							the
							other party for its reasonable attorneys’ fees and other costs and expenses reasonably incurred
							in
							connection with the investigation, preparation and prosecution of such action or proceeding.
						</div>
					</div>
				</li>
				<li><b>ADA COMPLIANCE, DISABILITIES, ACCESSIBILITY</b>
					<div class="first-list-wrapper">
						<div class="text">
							15.1. We attempt to make the Platform accessible to all individuals. If you use special adaptive
							equipment and encounter problems when using the Platform, please report them via email. We will
							let
							you know if the information is available in an alternate format. We strive to meet World Wide
							Web
							Consortium (W3C) Recommendations and other web industry standards, specifically conforming to
							HTML
							5, CSS Level 3, WAI-ARIA, and the U.S. Access Board's Section 508 guidelines. We are committed
							to
							making the Platform available to as many people as possible and makes every effort to ensure its
							communications are accessible to those with special needs, including those with visual, hearing,
							cognitive and motor impairments.
						</div>
					</div>
				</li>
				<li><b>MISCELLANEOUS</b>
					<div class="first-list-wrapper">
						<div class="text">16.1. Electronic communications. When you send emails or other electronic messages
							to
							Digital Eagle or in connection with the Service, you are communicating with Digital Eagle
							electronically and consent to Digital Eagle’s review and analysis of such messages and to
							receive
							return communications, if any, from Digital Eagle electronically. You agree that all agreements,
							notices, disclosures and other communications that Digital Eagle provides to you electronically
							satisfy any legal requirement that such communications be in writing. It is user's
							responsibility to
							turn on notifications, including but not limited to notifications from a third-party payment
							processing company. By accessing or using the Platform, you consent to receive communications
							through emails, push notifications, text messages (including SMS and MMS), and phone calls.
							These
							communications may promote Digital Eagle or projects listed on the Platform. Please note that
							any
							communications, including phone calls, may be monitored and recorded for quality control
							purposes.
							You can opt-out of certain communications by submitting a relevant request.
						</div>
						<div class="text">16.2. In addition to the rights and privileges described in the terms, conditions,
							and
							policies outlined above, we further reserve the right to elect to electronically monitor areas
							of
							the Platform and the Service and may disclose any content, records, or electronic communication
							of
							any kind if required to do so by any law, regulation, or government request, if such disclosure
							is
							necessary or appropriate to operate the Platform, or to protect our rights or property, or the
							rights of the users, partners, affiliates, sponsors, providers, licensors, or merchants. If
							alerted
							to allegedly infringing, defamatory, damaging, illegal, or offensive content, we may investigate
							the
							allegation and determine in our sole discretion whether to remove or request the removal of such
							content from the Platform.</div>
						<div class="text">16.3. We control the Platform from our corporate offices within the United States
							of
							America, and the Platform and the Service is not intended to subject Digital Eagle to any
							non-U.S.
							jurisdiction or law. By accessing and using the Platform and Service you agree that such access
							and
							use is subject to the terms, conditions, and policies of this Agreement as well as applicable
							laws.
							Our failure to insist upon strict performance of any provision of this Agreement shall not be
							construed as an implicit waiver of any provision or right.
						</div>
						<div class="text">16.4. If any part of this Agreement is ruled to be unenforceable, then such part
							shall
							be severed, with the remainder of the Agreement remaining in full force and effect. This
							Agreement
							constitutes the entire agreement between you and Digital Eagle governing your use of the
							Platform
							and Service.</div>
						<div class="text">16.5. Neither this Agreement, nor any content, materials or features of the
							Platform
							and Service create any partnership, joint venture, employment, or other agency relationship
							between
							you and Digital Eagle. You may not enter into any contract on behalf of Digital Eagle or bind
							Digital Eagle in any way.
						</div>
						<div class="text">16.6. You may not assign any of your rights under this Agreement, and any such
							attempt
							will be null and void. Digital Eagle may, in its sole discretion, assign or transfer, without
							further consent or notification, this Agreement or any or all of the contractual rights and
							obligations pursuant to this Agreement, in whole or in part, to any affiliate of Digital Eagle
							or to
							a third party in the event that some or all of the business of Digital Eagle is transferred to
							such
							other third party by way of merger, sale of its assets or otherwise.</div>
						<div class="text">16.7. No failure or delay by Digital Eagle to exercise any right or remedy set out
							in
							this Agreement or by law shall constitute a waiver of that or any other right or remedy, nor
							shall
							it preclude or restrict the further exercise of that or any other right or remedy. No single or
							partial exercise of such right or remedy shall preclude or restrict the further Digital Eagle’s
							exercise of that or any other right or remedy.
						</div>
						<div class="text">16.8. We hereby notify you that parental control protections (such as computer
							hardware, software or filtering services) are commercially available that may assist you in
							limiting
							access to material that is harmful to minors.</div>
						<div class="text">16.9. If you have a question or complaint regarding the Platform and Service,
							please
							contact us as follows (e-mail communications are not necessarily secure, so please do not
							include
							sensitive information in any e-mail to us):
							<div><b>Mail</b>: Digital Eagle Inc, 1992 Morris Ave #326, Union, NJ, 07083, U.S.</div>
							<div><b>Email</b>: <a
									href="mailto:registration@digital-eagle.us">registration@digital-eagle.us</a></div>
							<p class="text"><i>All materials © 2024 Digital Eagle Inc., unless otherwise noted. All rights
									reserved.
								</i></p>
						</div>
					</div>
				</li>
			</ol>
		</div>
		<!-- <div v-if="locale === 'USA'">
			<h1 class="title">{{ $t('agreement.title') }}</h1>
			<h3 class="title subtitle--blue">TERMS OF USE</h3>
			<p class="text"><b>PLEASE NOTE: THESE TERMS OF USE CONTAIN AN ARBITRATION CLAUSE AND CLASS ACTION WAIVER. THE
					WAIVER AFFECTS HOW DISPUTES WITH MARKETCALL ARE RESOLVED. BY ACCEPTING THESE TERMS OF USE, YOU AGREE TO
					BE BOUND BY THIS ARBITRATION PROVISION. PLEASE READ IT CAREFULLY.</b>
			</p>
			<h3 class="suptitle">1. ACCEPTANCE</h3>
			<p class="text">These Terms of Use governs your use of the Digital Eagle, Inc. (“MarketCall”) websites,
				including
				https://digital-eagle.us/ (each a “Site”). By using the Site, you agree, without limitation or
				qualification, to be
				bound by these Terms of Use and the Digital Eagle Privacy Policy. If you do not agree, please do not use the
				Site.</p>
			<p class="text">Digital Eagle may revise these Terms of Use at any time. Your continued usage of the Site will
				mean
				you accept those changes, and you agree comply with all applicable laws and regulations. The materials
				provided on the Site are protected by law, including, but not limited to, United States copyright laws and
				international treaties.</p>
			<p class="text">The terms “You,” “Your,” “User,” and “Users,” as used herein, refer to all individuals and/or
				entities accessing the Site for any reason.
			</p>

			<h3 class="suptitle">2. ELIGIBILITY</h3>
			<p class="text">
				You must be 18 years of age or older to visit or use the Site in any manner. By visiting the Site or
				accepting these Terms of Use, You represent and warrant to Digital Eagle that You are 18 years of age or
				older,
				and that You have the right, authority, and capacity to agree to and abide by these Terms of Use. You also
				represent and warrant to Digital Eagle that You will use the Site in a manner consistent with any and all
				applicable laws and regulations
			</p>

			<h3 class="suptitle">3. USE RESTRICTIONS</h3>
			<p class="text">The content on the Site, such as text, graphics, images, photographs, illustrations, trademarks,
				trade names, service marks, logos, information obtained from Digital Eagle's licensors, and other materials
				(“Contents”) is protected by copyright under both United States and foreign laws. Title to the Contents
				remains with Digital Eagle. Any use of the Contents not expressly permitted by these Terms of Use is a
				breach
				of these Terms of Use and may violate copyright, trademark, and other laws. Except as stated herein, none of
				the Contents may be copied, reproduced, distributed, republished, downloaded, displayed, posted or
				transmitted in any form or by any means without the prior written permission of Digital Eagle or its
				licensors.
			</p>
			<p class="text">Except as permitted by United States copyright law, you may not reproduce or communicate any of
				the content on the Site, including files or images that may be downloadable from the Site, without the
				permission of Digital Eagle. All rights not expressly granted herein are reserved to Digital Eagle and its
				licensors. If you violate any of these Terms of Use, your permission to use the Contents automatically
				terminates and you must immediately destroy any copies you have made of any portion of the Contents.</p>
			<p class="text">So long as you are in compliance with these Terms of Use, Digital Eagle grants a non-exclusive
				limited, non- transferable license to use the Site. You may not distribute or make the Site available over a
				network where it could be used by multiple devices at the same time. You may not rent, lease, lend, sell,
				redistribute or sublicense the Site. You may not copy, decompile, reverse engineer, disassemble, attempt to
				derive the source code of, modify, or create derivative works of the Site, any updates, or any part thereof.
				Any attempt to do so is a violation of these Terms of Use. If you breach this restriction, you may be
				subject to prosecution and damages. These Terms of Use will govern any upgrades provided by Digital Eagle
				that
				replace and/or supplement the original Site, unless such upgrade is accompanied by separate or updated Terms
				of Use. If you violate any of these Terms of Use, your permission to use the Contents automatically
				terminates and you must immediately destroy any copies you have made of any portion of the Contents.</p>
			<p class="text">You may not, without Digital Eagle's written permission, “mirror” any Contents contained in the
				Site or any other server. You may not use the Site for any purpose that is unlawful or prohibited by these
				Terms of Use. You may not use the Site in any manner that could damage, disable, overburden, or impair the
				Site, or interfere with any other party’s use and enjoyment of the Site. You may not attempt to gain
				unauthorized access to the Site through hacking, password mining or any other means. Digital Eagle reserves
				the
				right, in its sole discretion, to terminate your access to the Site, or any portion thereof, at any time,
				for any reason or for no reason at all, without prior notice or any notice.
			</p>
			<h3 class="suptitle">4. USER SUBMISSIONS</h3>
			<p class="text">The personal information you submit to Digital Eagle is governed by the Digital Eagle Privacy
				Policy.
				To the extent there is an inconsistency between the Terms of Use and the Privacy Policy, the Privacy Policy
				shall govern.
			</p>
			<p class="text">Except as provided under Submission of Ideas (below), Digital Eagle does not claim ownership of
				any
				information or material a user provides to Digital Eagle or posts, uploads, input, submits, or transmits
				through a Site (“Submission”). You agree that you will not make any Submission that is, in whole or in part,
				libelous; scandalous; inflammatory; discriminatory; defamatory; false; threatening; vulgar; obscene;
				pornographic; profane; abusive; harassing; invasive of another’s privacy; hateful or bashing; aimed at
				gender, race, color, sexual orientation, national origin, religious views, or disability; in violation of
				local, state, national, or international law; or that infringes on, or violates, any right of any party.
				Additionally, you agree that you will not: (a) make any Submission that is an advertisement or solicitation
				of business; (b) disrupt the normal flow of dialogue or make a Submission unrelated to the topic being
				discussed (unless it is clear the discussion is free-form); (c) post a chain letter or pyramid scheme;
				impersonate another person; (d) distribute viruses or other harmful computer code; (e) harvest or otherwise
				collects information about others, including email addresses, without their consent; (f) post the same note
				more than once or “spamming”; or (g) engage in any other conduct that restricts or inhibits any other person
				from using or enjoying the Site, or which, in the judgment of Digital Eagle, exposes Digital Eagle or any of
				its
				licensors, partners, or customers to any liability or detriment of any type.

			</p>
			<p class="text">By making a Submission, you agree that such Submission is non-confidential, non-proprietary, and
				may be disseminated or used by Digital Eagle. If you make a Submission, you automatically grant — or warrant
				that the owner of such content has expressly granted — Digital Eagle a royalty-free, perpetual, irrevocable,
				worldwide nonexclusive license to use, reproduce, create derivative works from, modify, publish, edit,
				translate, distribute, perform, and display the Submission in any media or medium, or any form, format, or
				forum now known or hereafter developed. Notwithstanding the foregoing, Digital Eagle is not required to use
				any
				Submission.
			</p>
			<p class="text">You are solely responsible for your Submission, the consequences of making a Submission, and
				your reliance on any Submissions. Digital Eagle is not responsible for the consequences of any Submission.
				Digital Eagle is not responsible for screening or monitoring Submissions made through a Site by users. If
				notified by a user of a Submission allegedly in violation of these Terms of Use, Digital Eagle may
				investigate
				the allegation and determine in good faith and its sole discretion whether to remove such Submission.
				Digital Eagle will have no liability or responsibility to users for performance or nonperformance of such
				activities.

			</p>
			<p class="text">Digital Eagle reserves the right (but is not obligated) to: (a) record the dialogue on the Site;
				(b) investigate an allegation that a Submission does not comply with these Terms of Use and determine in its
				sole discretion to remove or request the removal of the Submission; (c) remove Submissions which are
				abusive, illegal, disruptive, or outdated, or that otherwise fail to comply with these Terms of Use; (d)
				terminate a user’s access to any or all parts of the Site upon any breach of these Terms of Use or the law;
				(e) monitor, edit, or disclose any Submission; (f) edit or delete any Submission posted on the Site,
				regardless of whether such Submission violates these Terms of Use.
			</p>
			<h3 class="suptitle">5. SUBMISSION OF IDEAS</h3>
			<p class="text">
				Digital Eagle policy is to not accept or review unsolicited ideas or suggestions from persons outside the
				company. Notwithstanding such policy, any ideas, suggestions, know-how, or concepts that are offered or
				communicated to Digital Eagle through a Site or otherwise shall be the property of Digital Eagle, and may be
				treated by Digital Eagle as non- confidential information. Digital Eagle shall have the unrestricted right
				to use
				and disclose such ideas, suggestions, know-how or concepts for any purpose without compensation or
				obligation to any party.
			</p>
			<h3 class="suptitle">6. LIABILITY OF MARKETCALL AND ITS LICENSORS AND PARTNERS</h3>
			<p class="text">The use of the Site or the Contents is at your own risk. The Contents in the Site could include
				technical inaccuracies or typographical errors. Digital Eagle may make changes or improvements at any time.
			</p>
			<p class="text">THE CONTENTS IN THE SITE ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS
				OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, MARKETCALL DISCLAIMS ALL
				WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. MARKETCALL DOES NOT WARRANT THAT THE
				FUNCTIONS CONTAINED IN THE MATERIAL WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR
				THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
				MARKETCALL DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OF OR THE RESULT OF THE USE OF THE
				CONTENTS IN THE SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NOT
				MARKETCALL) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION.</p>
			<p class="text">TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, MARKETCALL ASSUMES NO LIABILITY,
				WHETHER IN CONTRACT OR TORT, FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
				INCLUDING (WITHOUT LIMITATION) DAMAGES FOR LOSS OF ANTICIPATED PROFITS OR REVENUE OR OTHER ECONOMIC LOSS IN
				CONNECTION WITH OR ARISING FROM ANY ACT OR OMISSION BY MARKETCALL, ITS AGENTS, AFFILIATES, JOINT VENTURE
				PARTNERS, INDEPENDENT CONTRACTORS, OR UNAFFILIATED THIRD PARTIES AS A RESULT OF ANY ACT OR OMISSION IN
				FULFILLMENT OF OR IN BREACH OF THESE TERMS OF USE OR THE PRIVACY POLICY.</p>
			<h3 class="suptitle">7. YOUR ACCOUNT</h3>
			<p class="text">If you use the Site, you are responsible for maintaining the confidentiality of your account and
				password and for restricting access to your computer. You agree to accept responsibility for all activities
				that
				occur under your account or password. Digital Eagle reserves the right to refuse service, terminate
				accounts,
				remove or edit content, or cancel orders in its sole discretion.
			</p>

			<h3 class="suptitle">8. CONTACT WITH THIRD PARTIES AND THIRD PARTY WEBSITES</h3>
			<p class="text">The Site may contain hyperlinks to other websites and webpages (“Third-Party Pages”), as well as
				to
				text, graphics, videos, images, music, sounds, and information belonging to or originating from other
				third-parties (collectively, “Third-Party Applications”). Digital Eagle does not investigate, monitor, or
				review
				any Third-Party Pages or Third-Party Applications to ensure their accuracy, completeness, or
				appropriateness.
				Digital Eagle is not responsible for the Third- Party Pages or any Third-Party Applications accessed through
				the
				Site. You agree that Digital Eagle shall have no liability with respect to any loss or damage of any kind
				incurred
				as a result of any dealings between you and any third party, or as a result of the presence of such third
				party
				on the Site.</p>
			<p class="text">The inclusion of any hyperlinks to any Third-Party Pages or Third-Party Applications on the Site
				does not indicate Digital Eagle’s approval or endorsement thereof. These links are provided solely as a
				convenience
				or benefit to users. Your interactions with a third party on the Site, or based on such third party’s
				participation or presence on the Site, are solely between you and the third party. Digital Eagle makes no
				representations or warranties with respect to the content, ownership, or legality of any such linked third
				party
				website. If you choose to leave the Site to access any Third-Party Pages or Third-Party Applications, you do
				so
				at your own risk.</p>

			<h3 class="suptitle">9. INDEMNITY</h3>
			<p class="text">You agree to defend, indemnify, and hold Digital Eagle, its officers, directors, employees,
				agents,
				licensors, and partners, harmless from and against any claims, actions, demands, liabilities, and
				settlements
				including, without limitation, reasonable legal and accounting fees, resulting from, or alleged to result
				from,
				your use of the Site or Contents, or your violation of these Terms of Use.</p>
			<h3 class="suptitle">10. MODIFICATION OR SUSPENSION OF THE SITE</h3>
			<p class="text">You agree that Digital Eagle may, in its sole discretion and at any time, modify, discontinue,
				or
				suspend its operation of the Site, any part thereof or any Contents, temporarily or permanently, without
				notice
				or liability to you.</p>
			<h3 class="suptitle">11. GENERAL</h3>
			<p class="text">Digital Eagle makes no claims that the Contents are appropriate or may be downloaded outside of
				the
				United States of America. Access to the Contents may not be legal by certain persons or in certain
				countries. If
				you access the Site from outside the United States, you do so at your own risk and are responsible for
				compliance with the laws of your jurisdiction. The following provisions survive the expiration or
				termination of
				these Terms of Use for any reason whatsoever: Liability of Digital Eagle and its Licensors and Partners, Use
				Restrictions, User Submissions, Submission of Ideas, Indemnity, Applicable Laws, and Complete Agreement.</p>
			<h3 class="suptitle">12. APPLICABLE LAWS</h3>
			<p class="text">These Terms of Use are governed in accordance with the laws of California, United States of
				America,
				without regard to its conflict of law provisions. You and Digital Eagle hereby expressly consent and submit
				to
				the
				exclusive jurisdiction of the state and federal courts of Los Angeles, California for the adjudication or
				disposition of any claim, action or dispute arising out of these Terms of Use. If any provision of these
				Terms
				of Use is found to be invalid by any court having competent jurisdiction, the invalidity of such provision
				shall
				not affect the validity of the remaining provisions of these Terms of Use, which shall remain in full force
				and
				effect. No waiver of any of these Terms of Use shall be deemed a further or continuing waiver of such term
				or
				condition or any other term or condition.</p>
			<h3 class="suptitle">13. MANDATORY AGREEMENT TO ARBITRATE ON AN INDIVIDUAL BASIS</h3>
			<p class="text">Any and all controversies, disputes, demands, counts, claims, or causes of action (including the
				interpretation and scope of this clause, and the arbitrability of the controversy, dispute, demand, counts,
				claim, or cause of action) between you and Digital Eagle or Digital Eagle’s employees, agents, successors,
				or
				assigns,
				shall exclusively be settled through binding and confidential arbitration, except that you or Digital Eagle
				may
				take claims to small claims court if the dispute qualifies for hearing by such a court. In addition, each
				party
				retains the right to seek injunctive or other equitable relief in a court of competent jurisdiction to
				prevent
				the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks,
				trade
				secrets, patents, or other intellectual property rights.</p>
			<p class="text">There is no judge or jury in arbitration and arbitration procedures are simpler and more limited
				than rules applicable in court. YOU ACKNOWLEDGE AND AGREE THAT, APART FROM THE NARROW EXCEPTIONS ABOVE, YOU
				AND
				MARKETCALL ARE EACH WAIVING YOUR RIGHTS TO SUE IN COURT, INCLUDING RIGHTS TO RECEIVE A TRIAL BY JURY OR TO
				PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION LAWSUIT, CLASS-WIDE ARBITRATION,
				PRIVATE ATTORNEY-GENERAL ACTION, OR ANY OTHER REPRESENTATIVE PROCEEDING.</p>
			<p class="text">Arbitration shall be subject to the U.S. Federal Arbitration Act and federal arbitration law,
				and
				shall be conducted by Judicial Arbitration Mediation Services, Inc. (“JAMS”) pursuant to the JAMS
				Streamlined
				Arbitration Rules & Procedures effective July 1, 2014 (the “JAMS Rules”) and as modified by this agreement
				to
				arbitrate. The JAMS Rules, including instructions for bringing arbitration, are available on its website at
				http://www.jamsadr.com/rules- streamlined-arbitration. The Minimum Standards are available at
				https://www.jamsadr.com/consumer-minimum- standards/.</p>
			<p class="text">You and Digital Eagle must abide by these rules: (a) the arbitration shall be conducted on an
				individual basis and not in a class, consolidated or representative action and the arbitrator shall not
				award
				class-wide relief; (b) Digital Eagle will pay its arbitration costs as required by JAMS rules, and in the
				event
				that you are able to demonstrate that the costs of arbitration will be prohibitive as compared to costs of
				litigation, Digital Eagle will pay as much of your arbitration filing and hearing fees as the arbitrator
				deems
				is
				necessary to prevent the arbitration from being cost-prohibitive as compared to the cost of litigation; (c)
				the
				arbitrator may award any individual relief or individual remedies that are permitted by applicable law; and
				(d)
				each side pays his, her or its own attorneys' fees and costs unless the claim(s) at issue permit the
				prevailing
				party to be paid its fees and litigation costs, and in such instance, the fees and costs awarded shall be
				determined by the applicable law.</p>
			<p class="text">With the exception of subpart (a) in the paragraph above, if any part of this arbitration
				provision
				is deemed invalid, unenforceable or illegal, then the balance of this arbitration provision shall remain in
				effect and be construed in accordance with its terms as if the invalid, unenforceable, or illegal provision
				were
				not contained. If, however, subpart (a) in the paragraph above is found invalid, unenforceable or illegal,
				then
				the entirety of this arbitration provision shall be null and void. If for any reason a claim proceeds in
				court
				rather than in arbitration, the dispute shall be exclusively brought in state or U.S. federal court in
				California.</p>
			<h3 class="suptitle">14. NOTICE AND TAKE DOWN PROCEDURES</h3>
			<h3>If you believe any Submission accessible on or from the Site infringes your copyright, you may request
				removal
				of those materials (or access thereto) from the Site by contacting Digital Eagle (address identified below)
				and
				providing the following information:
			</h3>
			<ul class="list">
				<li>Identification of the copyrighted work that you believe to be infringed. Please describe the work, and
					where
					possible include a copy or the location (e.g., URL) of an authorized version of the work;
				</li>
				<li>Identification of the Submission that you believe to be infringing and its location. Please describe the
					Submission, and provide us with its URL or any other pertinent information that will allow us to locate
					the
					Submission;
				</li>
				<li>Your name, address, telephone number, and (if available) email address;</li>
				<li>A statement that you have a good faith belief that the complained of use of the work is not authorized
					by
					the copyright owner, its agent, or the law;</li>
				<li>A statement that the information you have supplied is accurate, and indicating that “under penalty of
					perjury,” you are the copyright owner or authorized representative;</li>
			</ul>
			<h3 class="suptitle">15. COMPLETE AGREEMENT</h3>
			<p class="text">Except as expressly provided in a particular notice or disclaimer posted by or on behalf of
				Digital Eagle on the Site, these Terms of Use, including Digital Eagle's Privacy Policy, constitute the
				entire
				agreement between you and Digital Eagle with respect to the use of the Site and Contents.</p>
		</div> -->
	</div>
</template>

<script>
export default {
	name: 'Agreement',
	metaInfo: {
		title: `${process.env.VUE_APP_LOCALE === 'USA' ? 'Digital eagle' : 'ProfitAds'}`
	},
	data() {
		return {
			locale: process.env.VUE_APP_LOCALE,
		}
	}
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.cookies-page {
	width: 100%;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.9;
	color: $basicBlack;
	background: #fff;
	box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
	padding: 12rem;

	.list-position {
		>li {
			list-style-position: inside;
		}
	}

	.first-list-wrapper {
		padding-left: 20px;
		margin-top: 10px;
	}

	.first-main-li {
		>li::marker {
			font-weight: bold;
		}
	}

	.title {
		font-size: 3rem;
		line-height: 4rem;
		font-weight: 700;
		margin: 0 0 3rem;
	}

	.subtitle {
		font-weight: 700;
		margin: 0 0 1.5rem;

		&--blue {
			color: var(--primary);
		}
	}

	.text {
		margin: 0 0 3rem;

		&--gray {
			color: $gray;
		}
	}

	.list {
		padding: 0 0 0 2rem;
		margin: 0 0 3rem;

		&__item {
			margin: 0 0 1.5rem;

			a {
				color: var(--primary);
			}

			ul {
				padding-left: 2rem;
			}
		}
	}

	.dash {
		list-style-type: none;

		li:before {
			content: "-";
			margin-right: 5px;
		}
	}

	.disc {
		list-style-type: disc;
	}

	a {
		color: var(--primary);
		text-decoration: underline;

		&:hover {
			opacity: 0.7;
		}
	}

	.link {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 1.6rem;
		line-height: 1.9;
		color: var(--primary);
		background-color: transparent;
		border: 1px solid var(--primary);
		border-radius: 4px;
		text-decoration: none;
		transition: color .4s linear, background-color .4s linear;
		padding: 7px 50px;

		&:hover {
			color: $basicWhite;
			background-color: var(--primary);
		}
	}

	@include below(993px) {
		padding: 60px 32px;
	}

	@include below(769px) {
		padding: 32px 24px;
	}

	@include below(577px) {
		padding: 24px 16px;

		.title {
			font-size: 16px;
			line-height: 1.4;
			margin-bottom: 16px;
			text-align: center;
		}

		.text {
			font-size: 14px;
			line-height: 1.4;
			margin-bottom: 16px;
		}

		.list {
			padding-left: 16px;
			margin-bottom: 16px;

			li {
				margin-bottom: 8px;

				.subtitle {
					font-size: 14px;
					line-height: 1.4;
					margin-bottom: 8px;
				}

				p {
					font-size: 14px;
				}

				.list {

					li {
						font-size: 14px;
						line-height: 1.4;
					}
				}
			}
		}
	}
}

.agreements__social-links {
	display: grid;
	grid-template-columns: minmax(0, 150px) minmax(0, 1fr);
}</style>
