<template>
    <div v-if="total" class="statistics__data-ad-item">
        <div @click="openClose" class="statistics__header">
            <div class="statistics__data-ad-title">
                <img class="statistics__data-ad-icon" :src="imgUrl" alt="">
                <div class="statistics__data-ad-name">{{ title }}</div>
            </div>
            <div class="statistics__data-value-wrapper">
                <div class="statistics__data-ad-value" :class="{ empty: !total }">
                    {{ total ? locale ==='USA' ? numberWithSpacesFix2(total) : numberWithSpacesCeil(total) + " " + $t('currency.currency_symbol') :
                        $t('no_data') }}
                </div>
                <img v-if="Object.keys(filteredObject).length" :class="{ rotate: isOpen }" src="../../assets/icons/icon-arrow-gray-down.svg" alt="arrow">
            </div>
        </div>
        <div v-if="isOpen">
            <ul>
                <!-- temporaryFilteredObject should be changed to filteredObject -->
                <li v-for="(value, key, index) in filteredObject" :key="index">
                    <span>{{ value.name }}</span>
                    <div>
                        <span>{{locale ==='USA' ? numberWithSpacesFix2(value.amount) : numberWithSpacesCeil(value.amount) }}</span> {{ $t('currency.currency_symbol') }}
                    </div>
                </li>
            </ul>
        </div>

    </div>
</template>
<script>
import checkCashback from '@/mixin/checkCashback'
import numberWithSpaces from '@/mixin/numberWithSpaces'

export default ({
    name: 'DataAddItem',
    mixins: [checkCashback, numberWithSpaces],
    props: {
        img: {
            type: String,
            required: true,
            default: ''
        },
        title: {
            type: String,
            required: true,
            default: ''
        },
        total: {
            type: Number,
            required: true,
            default: 0
        },
        statObject: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            isOpen: false,
            locale: process.env.VUE_APP_LOCALE
        }
    },
    computed: {
        platformList() {
            const platforms = this.$store.state.Ad.platformsList || []
            const platFormList = platforms.reduce((initial, item) => {
                const obj = { name: item.name, slug: item.slug, statistics: item.statistics }
                initial.push(obj)
                return initial
            }, [])
            return platFormList
        },
        imgUrl() {
            let url = require(`@/assets/icons/${this.img}`)
            return url
        },

        temporaryFilteredObject() {
            let entries = Object.entries(this.filteredObject);
            let filtered = entries.filter(([key]) => key !== "telegram");
            let result = Object.fromEntries(filtered);
            return result;
        },

        filteredObject() {
            let platformList = this.platformList.reduce((result, platform) => {
                const key = platform.slug;
                if (this.statObject[key]) {
                    result[key] = {
                        ...this.statObject[key],
                        name: platform.name
                    };
                }
                return result;
            }, {});

            const filteredStatObject = Object.entries(platformList).reduce((acc, [key, value]) => {
                if (value.isVisible && value.statistics) {
                    acc[key] = value;
                }

                return acc;
            }, {})
            return filteredStatObject
        }
    },
    methods: {
        openClose() {
            if(Object.keys(this.filteredObject).length) {
                this.isOpen = !this.isOpen
            }
            return false
        }
    },
    mounted() {
    }
})
</script>
<style scoped lang="scss">
.rotate {
    transform: rotate(180deg);
}

ul {
    margin-top: 1.4rem;

    li {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #7F858D;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.4rem;
        padding: 0.8rem 0 0.8rem 0;
        border-bottom: 1px solid #EDEFF3;
    }
}

.statistics__data-ad-item {
    display: flex;
    flex-direction: column;

    .statistics__data-value-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            margin-left: 2rem;
        }

    }

    .statistics__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        .statistics__data-ad-title {
            display: flex;
            align-items: center;
        }

        .statistics__data-ad-icon {
            display: block;
            width: 3.3rem;
            height: 3.3rem;
            margin-right: 1.2rem;
        }

        .statistics__data-ad-name {
            font-size: 1.6rem;
            line-height: 1.9;
            color: #333333;
        }

        .statistics__data-ad-value {
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 1.9;
            color: #333333;
        }

        .statistics__data-ad-value.empty {
            font-weight: 400;
            color: #7F858D;
        }
    }

}
</style>