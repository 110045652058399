import axios from 'axios'
import store from '..'

const apiUrl = process.env.VUE_APP_API_URL

const filters = () => ({
  status: null,
  contractorInn: null,
})

const pagination = () => ({
  currentPage: 1,
  pageSize: 20, 
  total: 0,
})


const form = () => ({
  number: "", 
  date: null,
  Amount: "",
  VatIncluded: false,
  type: "",
  subjectType: "",
  actionType: null,
  isAgentActingForPublisher: null,
  ParentMainContractId: null,
  contractorId: null,
  clientId: null,
})

const state = () => ({
  tableComponentKey: 0,
  initialContracts: [],
  initialContractsIsLoading: true,
  pagination: pagination(),
  filters: filters(),
  form: form(),
  allInitialContracts: [],
})

const getters = {
  getInitialContractById: (state) => (id) => {
    return state.allInitialContracts.find((initialContract) => initialContract.id === id)
  },
  getInitialContracts(state) {
    return state.initialContracts
  },
  getInitialContractsIsLoading(state) {
    return state.initialContractsIsLoading
  },
}

const mutations = {
  setAllInitialContracts(state, value) {
    state.allInitialContracts = value
  },
  setForm(state, { formName, formValue }) {
    state.form[formName] = formValue
  },
  cleanForm(state) {
    state.form = form()
  },
  increaseTableComponentKey(state) {
    state.tableComponentKey += 1
  },
  setPagination(state, value) {
    state.pagination = value
  },
  clearPagination(state) {
    state.pagination = pagination()
  },
  clearFilters(state) {
    state.filters = filters()
  },
  setFilter(state, { filterName, filterValue }) {
    state.filters[filterName] = filterValue
  },
  addInitialContract(state, value) {
    state.initialContracts.push(value)
  },
  setinitialContracts(state, value) {
    state.initialContracts = value
  },
  setinitialContractsIsLoading(state, value) {
    state.initialContractsIsLoading = value
  },
}

const actions = {
  fetchAllInitialContracts(ctx) {
    const payload = { url: "/webapi/v1/contracts/getinitialcontracts", data: {} }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')

    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          resolve(resp.data.data)
          ctx.commit('setAllInitialContracts', resp.data.data)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  getInitialContracts(ctx, data) {
    const payload = { url: "/webapi/v1/contracts/getinitialcontracts", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')

    ctx.commit('setinitialContractsIsLoading', true)

    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          resolve(resp.data.data)
          ctx.commit('setinitialContracts', resp.data.data)
          ctx.commit('setinitialContractsIsLoading', false)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  createInitialContract(ctx, data) {
    const payload = { url: "/webapi/v1/contracts/createinitialcontract", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          resolve(resp)
          ctx.commit('addInitialContract', resp.data.data)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};