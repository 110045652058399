export const AdType = {
  CPM: "Оплата за 1000 показов",
  CPC: "Оплата за клик на баннер",
  CPA: "Оплата за целевое действие",
  Other: "Иное"
}

export const AdFormKeys = {
  BANNER: "Banner",
  TEXT: "Text",
  TEXT_GRAPHIC: "TextGraphic",
  VIDEO: "Video",
  AUDIO: "Audio",
  AUDIO_BROADCAST: "AudioBroadcast",
  VIDEO_BROADCAST: "VideoBroadcast",
  OTHER: "Other"
}

export const AdForm = {
  [AdFormKeys.BANNER]: "Баннер",
  [AdFormKeys.TEXT]: "Текстовый блок",
  [AdFormKeys.TEXT_GRAPHIC]: "Текстово-графический блок",
  [AdFormKeys.VIDEO]: "Видеоролик",
  [AdFormKeys.AUDIO]: "Аудиозапись",
  [AdFormKeys.AUDIO_BROADCAST]: "Аудиотрансляция в прямом эфире",
  [AdFormKeys.VIDEO_BROADCAST]: "Видеотрансляция в прямом эфире",
  [AdFormKeys.OTHER]: "Иное"
}