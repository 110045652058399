<template>
    <div class="blanket-layout">
        <slot />
    </div>
</template>
<script>
export default{
    name: "BlanketLayout",
    data() {
        return {}
    }
}
</script>
<style scoped>
.blanket-layout {
    width: 100%;
}

</style>