<template>
  <div class="error-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ErrorLayout"
}
</script>

<style scoped>

</style>
