<template>
  <main class="main">
    <section class="top">
      <div class="container-main">
        <div class="inner">
          <div class="block">

            <div class="anim-items">
              <h1 class="title">Единый сервис пополнения рекламных кабинетов</h1>
            </div>
            
            <div class="anim-items description">
              <p>Оптимизируйте расходы, получайте кэшбэк со всех платежей и управляйте рекламой выгодно</p>
            </div>

            <div class="anim-items btn-container">
              <router-link
                  :to="{name: 'Registration'}"
                  class="top__btn btn btn--blue"
              >
                Попробовать  бесплатно
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="playgrounds" id="playgrounds">
      <div class="container-main">
        <div class="inner">
          <h2 class="title">Вся реклама - в одном окне</h2>

          <div class="block">
            <p class="text">
              Меньше рутины в работе с рекламными кабинетами: 
            </p>
            <ul class="list">
              <li>– один договор на все площадки,</li>
              <li>– единый баланс,</li>
              <li>– перераспределение бюджетов между рекламными площадками,</li>
              <li>– расчеты в рублях – никаких курсовых разниц.</li>
            </ul>


            <div class="playgrounds__btn-container">
              <router-link
                :to="{name: 'Registration'}"
                class="playgrounds__btn btn"
              >
                Зарегистрироваться
              </router-link>
              <a
                href="https://t.me/info_profitads"
                class="playgrounds__tg-link"
                target="_blank"
              >
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                    fill="white"
                  />
                  <path
                    d="M10.2891 23.4737C10.2891 23.4737 22.1775 18.416 26.3005 16.635C27.8811 15.9227 33.2412 13.6431 33.2412 13.6431C33.2412 13.6431 35.7151 12.6458 35.5089 15.0678C35.4401 16.0652 34.8904 19.5557 34.3407 23.3312C33.516 28.6739 32.6227 34.5153 32.6227 34.5153C32.6227 34.5153 32.4853 36.1537 31.3171 36.4387C30.1489 36.7236 28.2247 35.4414 27.8811 35.1564C27.6062 34.9427 22.7272 31.7371 20.9405 30.1699C20.4594 29.7425 19.9097 28.8877 21.0092 27.8903C23.4831 25.5395 26.438 22.6189 28.2247 20.7668C29.0493 19.9119 29.8739 17.9173 26.438 20.3393C21.559 23.8299 16.7487 27.1067 16.7487 27.1067C16.7487 27.1067 15.6491 27.8191 13.5876 27.1779C11.5259 26.5369 9.12079 25.682 9.12079 25.682C9.12079 25.682 7.47163 24.6135 10.2891 23.4737Z"
                    fill="#395FE4"
                  />
                </svg>

                Есть вопросы? <br />
                Напишите нам в телеграм.
              </a>
            </div>
            
          </div>
        </div>
      </div>
    </section>

    <section class="profitable" id="profitable">
      <div class="container-main">
        <div class="inner">
          <h2 class="title">ProfitAds <br>
            <span>- удобно и выгодно</span>
          </h2>

          <div v-if="width > 991" class="row">
            <div class="col anim-items">
              <kinesis-container class="item">
                <kinesis-element class="item__inner" type="depth_inv" :strength="20">
                  <kinesis-element class="item__img">
                    <img src="@/assets/images/profitable-img-5.png" alt="img" title="img" class="img-responsive">
                  </kinesis-element>

                  <h3 class="item__title">Выгодно</h3>
                  <ul class="item__text">
                    <li>пополнение день в день</li>
                    <li>бонусы и скидки за любые траты в кабинетах</li>
                    <li>индивидуальные условия по отсрочкам платежа</li>
                  </ul>
                </kinesis-element>
              </kinesis-container>
            </div>

            <div class="col anim-items">
              <kinesis-container class="item">
                <kinesis-element class="item__inner" type="depth_inv" :strength="20">
                  <kinesis-element class="item__img">
                    <img src="@/assets/images/profitable-img-6.png" alt="img" title="img" class="img-responsive">
                  </kinesis-element>

                  <h3 class="item__title">Удобно</h3>
                  <ul class="item__text">
                    <li>один договор на все площадки</li>
                    <li>простое перераспределение бюджетов</li>
                    <li>интерактивный отчет</li>
                  </ul>
                </kinesis-element>
              </kinesis-container>
            </div>

            <div class="col anim-items">
              <kinesis-container class="item">
                <kinesis-element class="item__inner" type="depth_inv" :strength="20">
                  <kinesis-element class="item__img">
                    <img src="@/assets/images/profitable-img-7.png" alt="img" title="img" class="img-responsive">
                  </kinesis-element>

                  <h3 class="item__title">Надежно</h3>
                  <ul class="item__text">
                    <li>соответствие требованиям <br>маркировки рекламы</li>
                    <li>работа в крупных агентских кабинетах сертифицированного партнера</li>
                    <li>полный пакет закрывающих документов по всем площадкам</li>
                  </ul>
                </kinesis-element>
              </kinesis-container>
            </div>

            <div class="col anim-items">
              <kinesis-container class="item">
                <kinesis-element class="item__inner" type="depth_inv" :strength="20">
                  <kinesis-element class="item__img">
                    <img src="@/assets/images/profitable-img-8.png" alt="img" title="img" class="img-responsive">
                  </kinesis-element>

                  <h3 type="rotate" class="item__title">Экспертиза</h3>
                  <ul class="item__text">
                    <li>техническая поддержка в чате и по телефону</li>
                    <li>ответим на вопросы по рекламным площадкам, помогаем пройти модерацию</li>
                  </ul>
                </kinesis-element>
              </kinesis-container>
            </div>
          </div>

          <div v-else class="row">
            <div class="col anim-items">
              <div class="item">
                <div class="item__inner">
                  <div class="item__img">
                    <img src="@/assets/images/profitable-img-mobile-1.png" alt="img" title="img" class="img-responsive">
                  </div>

                  <h3 class="item__title">Выгодно</h3>
                  <ul class="item__text">
                    <li>пополнение день в день</li>
                    <li>бонусы и скидки за любые траты в кабинетах</li>
                    <li>индивидуальные условия по отсрочкам платежа</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col anim-items">
              <div class="item">
                <div class="item__inner">
                  <div class="item__img">
                    <img src="@/assets/images/profitable-img-mobile-2.png" alt="img" title="img" class="img-responsive">
                  </div>

                  <h3 class="item__title">Удобно</h3>
                  <ul class="item__text">
                    <li>один договор на все площадки</li>
                    <li>простое перераспределение бюджетов</li>
                    <li>интерактивный отчет</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col anim-items">
              <div class="item">
                <div class="item__inner">
                  <div class="item__img">
                    <img src="@/assets/images/profitable-img-mobile-3.png" alt="img" title="img" class="img-responsive">
                  </div>

                  <h3 class="item__title">Надежно</h3>
                  <ul class="item__text">
                    <li>соответствие требованиям маркировки рекламы</li>
                    <li>работа в крупных агентских кабинетах сертифицированного партнера</li>
                    <li>полный пакет закрывающих документов по всем площадкам</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col anim-items">
              <div class="item">
                <div class="item__inner">
                  <div class="item__img">
                    <img src="@/assets/images/profitable-img-mobile-4.png" alt="img" title="img" class="img-responsive">
                  </div>

                  <h3 type="rotate" class="item__title">Экспертиза</h3>
                  <ul class="item__text">
                    <li>техническая поддержка в чате и по телефону</li>
                    <li>ответим на вопросы по рекламным площадкам, помогаем пройти модерацию</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="capability" id="capability">
      <div class="capability__img parallax-img"></div>

      <div class="container-main">
        <div class="inner">
          <h2 class="title">Возможности</h2>
          <el-carousel class="slider" :autoplay="false" :interval="5000"  trigger="click" arrow="always">
            <el-carousel-item class="slide">
              <div class="item">
                <div class="item__img">
                  <img src="../assets/images/capability-slider-img-1.png" alt="img" title="img" width="760" height="493" class="img-responsive">
                </div>

                <div class="item__block">
                  <h3 class="item__title">Перевод денег</h3>
                  <p class="item__text">с баланса аккаунта <br> на аккаунты рекламных <br> сервисов</p>
                </div>
              </div>
            </el-carousel-item>

            <el-carousel-item class="slide">
              <div class="item">
                <div class="item__img">
                  <img src="../assets/images/capability-slider-img-2.png" alt="img" title="img" width="760" height="493" class="img-responsive">
                </div>

                <div class="item__block">
                  <h3 class="item__title">Просмотр истории <br> операций</h3>
                  <p class="item__text">аккаунта и статистики <br> расходов на рекламу</p>
                </div>
              </div>
            </el-carousel-item>

            <el-carousel-item class="slide">
              <div class="item">
                <div class="item__img">
                  <img src="../assets/images/capability-slider-img-3.png" alt="img" title="img" width="760" height="493" class="img-responsive">
                </div>

                <div class="item__block">
                  <h3 class="item__title">Помощь онлайн</h3>
                  <p class="item__text">создание обращений <br> в тех.поддержку сервиса</p>
                </div>
              </div>
            </el-carousel-item>

            <el-carousel-item class="slide">
              <div class="item">
                <div class="item__img">
                  <img src="../assets/images/capability-slider-img-6.png" alt="img" title="img" width="760" height="493" class="img-responsive">
                </div>

                <div class="item__block">
                  <h3 class="item__title">Перераспределение <br> бюджетов</h3>
                  <p class="item__text">между площадками</p>
                </div>
              </div>
            </el-carousel-item>

            <el-carousel-item class="slide">
              <div class="item">
                <div class="item__img">
                  <img src="../assets/images/capability-slider-img-4.png" alt="img" title="img" width="760" height="493" class="img-responsive">
                </div>

                <div class="item__block">
                  <h3 class="item__title">Неограниченное <br> количество рекламных <br> аккаунтов</h3>
                  <p class="item__text">разных площадок в одном интерфейсе</p>
                </div>
              </div>
            </el-carousel-item>

<!--            <el-carousel-item class="slide">-->
<!--              <div class="item">-->
<!--                <div class="item__img">-->
<!--                  <img src="../assets/images/capability-slider-img-6.png" alt="img" title="img" width="760" height="493" class="img-responsive">-->
<!--                </div>-->

<!--                <div class="item__block">-->
<!--                  <h3 class="item__title">Уведомления</h3>-->
<!--                  <p class="item__text">Об окончании бюджета</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </el-carousel-item>-->
          </el-carousel>

          <router-link
              :to="{name: 'Registration'}"
              class="capability__btn btn btn--blue"
          >
            Попробовать  бесплатно
          </router-link>
        </div>
      </div>
    </section>

<!--    <section class="blog">-->
<!--      <div class="blog__img parallax-img">-->
<!--        <img class="img-responsive" src="@/assets/images/blog-img-bg.png" alt="img">-->
<!--      </div>-->

<!--      <div class="container-main">-->
<!--        <div class="inner">-->
<!--          <h2 class="title">Полезное в блоге</h2>-->

<!--          <div  v-if="width > 768" class="row">-->
<!--            <div class="col anim-items">-->
<!--              <a href="#" title="link" class="item">-->
<!--                <div class="item__img">-->
<!--                  <img src="@/assets/images/blog-img-1.jpg" alt="img" class="img-responsive img-responsive&#45;&#45;cover">-->
<!--                </div>-->

<!--                <div class="item__title">Настройка рекламы <br> в Facebook</div>-->
<!--                <div class="item__date">12 ноября, 2020</div>-->
<!--              </a>-->
<!--            </div>-->

<!--            <div class="col anim-items">-->
<!--              <a href="#" title="link" class="item">-->
<!--                <div class="item__img">-->
<!--                  <img src="@/assets/images/blog-img-2.jpg" alt="img" class="img-responsive img-responsive&#45;&#45;cover">-->
<!--                </div>-->

<!--                <div class="item__title">Анализ аудитории <br> в инстагарм</div>-->
<!--                <div class="item__date">12 ноября, 2020</div>-->
<!--              </a>-->
<!--            </div>-->

<!--            <div class="col anim-items">-->
<!--              <a href="#" title="link" class="item">-->
<!--                <div class="item__img">-->
<!--                  <img src="@/assets/images/blog-img-3.jpg" alt="img" class="img-responsive img-responsive&#45;&#45;cover">-->
<!--                </div>-->

<!--                <div class="item__title">Настройка <br> янедкс метрики</div>-->
<!--                <div class="item__date">12 ноября, 2020</div>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->

<!--          <el-carousel v-else class="slider" indicator-position="outside" :autoplay="true" :interval="5000" trigger="click" arrow="never">-->
<!--            <el-carousel-item class="slide">-->
<!--              <a href="#" title="link" class="item">-->
<!--                <div class="item__img">-->
<!--                  <img src="@/assets/images/blog-img-1.jpg" alt="img" class="img-responsive img-responsive&#45;&#45;cover">-->
<!--                </div>-->

<!--                <div class="item__title">Настройка рекламы <br> в Facebook</div>-->
<!--                <div class="item__date">12 ноября, 2020</div>-->
<!--              </a>-->
<!--            </el-carousel-item>-->

<!--            <el-carousel-item class="slide">-->
<!--              <a href="#" title="link" class="item">-->
<!--                <div class="item__img">-->
<!--                  <img src="@/assets/images/blog-img-2.jpg" alt="img" class="img-responsive img-responsive&#45;&#45;cover">-->
<!--                </div>-->

<!--                <div class="item__title">Анализ аудитории <br> в инстагарм</div>-->
<!--                <div class="item__date">12 ноября, 2020</div>-->
<!--              </a>-->
<!--            </el-carousel-item>-->

<!--            <el-carousel-item class="slide">-->
<!--              <a href="#" title="link" class="item">-->
<!--                <div class="item__img">-->
<!--                  <img src="@/assets/images/blog-img-3.jpg" alt="img" class="img-responsive img-responsive&#45;&#45;cover">-->
<!--                </div>-->

<!--                <div class="item__title">Настройка <br> янедкс метрики</div>-->
<!--                <div class="item__date">12 ноября, 2020</div>-->
<!--              </a>-->
<!--            </el-carousel-item>-->
<!--          </el-carousel>-->

<!--          <router-link-->
<!--            class="blog__btn btn btn&#45;&#45;blue"-->
<!--            :to="{name: 'MainPage'}"-->
<!--          >-->
<!--            Показать еще-->
<!--          </router-link>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <!-- <section class="faq">
      <div class="container-main">
        <div class="inner">
          <h2 class="title">FAQ</h2>

          <el-collapse class="questions" v-model="activeQuestions" @change="handleChange">
            <el-collapse-item class="questions__item" title="График работы Службы технической поддержки" name="1">
              <div>Служба поддержки работает с 9:00 до 18:00 в будние дни (суббота, воскресенье - выходной).  Заявки, отправленные до 17:30, будут выполнены в день обращения.</div>
            </el-collapse-item>

            <el-collapse-item class="questions__item" title="Подключение партнерской программы" name="2">
              <div>Для получения условий сотрудничества и инструкции по API напишите на почту <a href="mailto:sales@profitads.ru" title="sales@profitads.ru">sales@profitads.ru</a></div>
            </el-collapse-item>

            <el-collapse-item class="questions__item" title="Получение закрывающих документов" name="3">
              <div>
                <p>Каждый месяц закрывающие документы можно получить:</p>
                <ul>
                  <li>через ЭДО;</li>
                  <li>в офисе компании;</li>
                  <li>почтой, на адрес, указанный при регистрации.</li>
                </ul>
              </div>
            </el-collapse-item>

            <el-collapse-item class="questions__item" title="Подключение аккаунта MyTarget" name="4">
              <div>Для подключения аккаунта MyTarget пользователь должен зарегистрировать аккаунт рекламодателя в MyTarget, как физическое лицо. С подробной инструкцией Вы можете ознакомиться в кабинете.</div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </section> -->

    <section class="start" id="start">
      <div class="container-main">
        <div class="inner">
          <h2 class="title">
            <span>Как начать <br>работу с </span>
            ProfitAds 
          </h2>

          <div class="steps">
            <div class="steps__item">
              <div class="steps__step-number">1</div>
              <div class="steps__step-content">
                <div class="steps__step-title">Зарегистрируйтесь в системе</div>
                <div class="steps__step-text">Введите адрес электронной почты и <br>придумайте пароль для своей учетной записи.</div>
              </div>
            </div>
            <div class="steps__item">
              <div class="steps__step-number">2</div>
              <div class="steps__step-content">
                <div class="steps__step-title">Создайте бизнес-аккаунт</div>
                <div class="steps__step-text">
                  Укажите:<br>
                  <ul>
                    <li>– название организации,</li>
                    <li>– ИНН компании – необходимо для закрывающих документов,</li>
                    <li>– ФИО, телефон и почту контактного лица, </li>
                    <li>– банковские реквизиты</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="steps__item">
              <div class="steps__step-number">3</div>
              <div class="steps__step-content">
                <div class="steps__step-title">Пополните баланс</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <kinesis-container tag="section" class="subscribe">
      <div class="container-main">
        <div class="inner">
          <div class="subscribe__bg">
            <kinesis-element type="depth_inv" :strength="20" class="subscribe__bg-img woman">
              <img src="@/assets/images/woman.png" alt="img">
            </kinesis-element>

            <kinesis-element type="depth_inv" :strength="20" class="subscribe__bg-img money">
              <img src="@/assets/images/money.png" alt="img">
            </kinesis-element>

            <kinesis-element type="depth_inv" :strength="20" class="subscribe__bg-img money-big">
              <img src="@/assets/images/money-big.png" alt="img">
            </kinesis-element>

            <kinesis-element type="depth_inv" :strength="20" class="subscribe__bg-img ball">
              <img src="@/assets/images/ball.svg" alt="img">
            </kinesis-element>

            <kinesis-element type="depth_inv" :strength="20" class="subscribe__bg-img ball-pink">
              <img src="@/assets/images/ball-pink.svg" alt="img">
            </kinesis-element>
          </div>

          <div class="block">
            <h2 class="title">Присоединяйся!</h2>
            <p class="text">С нами работают уже более 150 клиентов</p>

            <form class="subscribe__form">
              <input class="subscribe__form-input" type="email" v-model="subscribeMail" placeholder="E-mail">
              <div class="subscribe__form-btn btn btn--blue" @click="saveEmail">Попробовать бесплатно</div>
            </form>
          </div>
        </div>
      </div>
    </kinesis-container>
  </main>
</template>

<script>

export default {
  name: "Main",
  data() {
    return {
      width: 0,
      activeQuestions: ['1'],
      subscribeMail: '',
    };
  },
  methods: {
    handleChange(val) {
      return val;
    },
    saveEmail() {
      this.$store.commit('Auth/setData', { label: 'registerEmail', data: this.subscribeMail })
      this.$router.push('/registration')
    },
    animOnscroll() {
      const animItems = document.querySelectorAll('.anim-items');

      for (let index = 0; index < animItems.length; index++) {
        let animItem = animItems[index];
        let animItemHeight = animItem.offsetHeight;
        let animItemOffset = this.offset(animItem).top;
        let animStart = 4;

        let animItemPoint = window.innerHeight - animItemHeight / animStart;
        if (animItemHeight > window.innerHeight) {
          animItemPoint = window.innerHeight - window.innerHeight / animStart;
        }

        if ((window.pageYOffset > animItemOffset - animItemPoint) && window.pageYOffset < (animItemOffset + animItemHeight)) {
          animItem.classList.add('active');
        } else {
          if (animItem.classList.contains('.anim-no-hide')) {
            animItem.classList.remove('active');
          }
        }
      }
    },
    offset(el) {
      const rect = el.getBoundingClientRect(),
          scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft}
    },
    parallaxImg() {
      let scrolled = window.pageYOffset
      let scrolledStep = scrolled * 0.2

      let updatesImgs = document.querySelectorAll('.parallax-img')

      updatesImgs.forEach((item) => {
        item.style.transform = 'translateY(-'+ scrolledStep +'px)'
      })
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
  // beforeRouteEnter() {
  //   if(process.env.VUE_APP_LOCALE==='USA') window.location='https://digital-eagle.us/'
  //   if(process.env.VUE_APP_LOCALE==='RU')  window.location='https://profitads.ru/'
  // },
  created() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
  mounted() {
    this.parallaxImg();
    this.animOnscroll();
    window.addEventListener('scroll', this.animOnscroll);
    window.addEventListener('scroll', this.parallaxImg);
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/public/style";

.main-cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

.main {
  min-height: 80vh;
  padding-top: 8rem;
}

.top {
  overflow: hidden;
  background: url("../assets/images/top-services.png") center bottom/contain no-repeat, linear-gradient(90deg, #F1F8FF 0%, #FFFFFF 100%) ;
  height: 86rem;

  .inner {
    position: relative;
    padding: 14.3rem 0 15.5rem;
    display: flex;
    justify-content: center;
  }

  .block {
    max-width: 76rem;
  }

  .title {
    font-size: 5.6rem;
    font-weight: 900;
    line-height: 6.6rem;
    color: $primary;
    margin: 0 0 3.2rem;
    text-align: center;
  }

  .description {
    font-size: 2.4rem;
    line-height: 3.4rem;
    color: #6B6E72;
    margin: 0 0 6.4rem;
    text-align: center;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }

  .anim-items {
    overflow: hidden;

    h1, p, .top__btn, img {
      transform: translate3d(0, 200%, 0);
      opacity: 0;
      transition: transform .8s ease-out, opacity .8s ease-out;
    }

    .top__btn {
      transition-delay: .9s;
    }

    &.active {

      h1, p, .top__btn, img {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    height: 60rem;

    .inner {
      background-size: 40% auto;
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }

    .title {
      font-size: 3.4rem;
      line-height: 4rem;
    }
  }

  @media only screen and (max-width: 768px) {

    .inner {
      background-image: none;
    }
  }

  @media only screen and (max-width: 576px) {
    height: auto;
    background: url("../assets/images/top-services-mobile.png") center bottom no-repeat, linear-gradient(90deg, #F1F8FF 0%, #FFFFFF 100%);
    padding-bottom: 20.5rem;

    .inner {
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
    }

    .block {
      text-align: center;
    }

    .title {
      font-size: 3.2rem;
      font-weight: 900;
      line-height: 4.2rem;
      margin-bottom: 2.4rem;
    }

    .description {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 8rem;
    }
  }
}

.playgrounds {
  background-color: $primary;

  .inner {
    padding: 6.4rem 0;
  }

  .title {
    font-size: 5.6rem;
    font-weight: 900;
    line-height: 6.6rem;
    color: $basicWhite;
    margin: 0 0 6.4rem 0;
  }

  .text {
    font-size: 2.4rem;
    line-height: 3.4rem;
    color: $basicWhite;
    margin: 0 0 2rem;
  }

  .list {
    color: $basicWhite;
    font-size: 2rem;
    line-height: 3rem;
    list-style: none;
    margin: 0 0 8rem;
  }

  &__btn-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__tg-link {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 2.4rem;
      font-weight: 500;
      padding: 1.2rem 2.4rem;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      width: max-content;

      svg {
        margin-right: 2.4rem;
      }
    }

  @media only screen and (max-width: 1200px) {
    .title {
      font-size: 3.4rem;
      line-height: 4rem;
      margin-right: 0;
      margin-bottom: 3.2rem;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .title {
      font-size: 3.4rem;
      line-height: 4rem;
      margin-right: 0;
      margin-bottom: 3.2rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .inner {
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
    }

    &__tg-link {
      font-size: 1.8rem;

      svg {
        width: 4rem;
        margin-right: 1rem;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .title {
      font-size: 3.2rem;
      line-height: 4.2rem;
      margin-bottom: 2.4rem;
    }

    .text {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 2.4rem;
    }

    .list {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 3.2rem;
    }

    &__btn {
      max-width: 22.8rem;
      display: flex;
    }

    &__btn-container {
    flex-direction: column;
    align-items: center;
  }

    &__tg-link {
      margin-top: 2rem;
      font-size: 1.4rem;
      padding: 1.1rem 1.6rem;

      svg {
        width: 3rem;
        margin-right: 1rem;
      }
    }
  }
}

.profitable {
  background-color: #EBF3FA;
  padding: 6.4rem 0;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    align-self: flex-start;
    font-size: 6.6rem;
    font-weight: 900;
    line-height: 7.2rem;
    color: $primary;
    margin: 0 0 2.5rem;

    span {
      font-size: 4.8rem;
      font-weight: 400;
      line-height: 5.8rem;
    }
  }

  .row {
    max-width: 116rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4rem;
  }

  .col {
    width: 50%;
    padding: 0 4rem;
    margin: 0 0 3rem;

    &:nth-child(n+3) {
      margin-bottom: 0;
    }
    &:nth-child(1) {

      .item {

        &__img {
          top: -11.6rem;
          right: -4.2rem;
        }
      }
    }

    &:nth-child(2) {

      .item {

        &__text {
          max-width: 30rem;
        }

        &__img {
          top: -5.6rem;
          right: -6.2rem;
        }
      }
    }

    &:nth-child(3) {

      .item {

        &__text {
          max-width: 36.5rem;
        }

        &__img {
          top: -6.6rem;
          right: -5.2rem;
        }
      }
    }

    &:nth-child(4) {

      .item {

        &__text {
          max-width: 30rem;
        }

        &__img {
          top: -7.6rem;
          right: -4.2rem;
        }
      }
    }
  }

  .anim-items {

    .item {
      transform: translate3d(0, 200%, 0);
      opacity: 0;
      transition: transform .8s ease-out, opacity .8s ease-out;
    }

    @for $i from 1 to 100 {

      &:nth-child(#{$i}) {

        .item {
          transition-delay: #{.2 + $i/10}s;
        }
      }
    }

    &.active {

      .item {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }

  .item {
    position: relative;
    height: 100%;
    padding: 5.6rem 3.2rem 0 0;

    &__inner {
      z-index: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(10px);
      border-radius: 16px;
      padding: 2.4rem;
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }

    &__title {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 3.4rem;
      color: $basicBlack;
      margin: 0 0 1.6rem;
    }

    &__text {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: #6B6E72;
      padding-left: 2rem;
    
      li::marker {
        font-size: 12px;
      }
    }

    &::before {
      width: 18rem;
      height: 18rem;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      background-color: #5D8AFE;
      border-radius: 50%;
    }
  }

  @media only screen and (max-width: 991px) {

    .title {
      font-size: 3.4rem;
      line-height: 4rem;
      margin-right: 0;
      margin-bottom: 3.2rem;

      span {
        font-size: 3.4rem;
      }
    }

    .row {
      margin-right: -1.5rem;
      margin-left: -1.5rem;
    }

    .col {
      width: 100%;
      padding: 0 1.5rem;

      &:nth-child(n+3) {
        margin-bottom: 1.5rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(1) {

        .item {

          &__img {
            top: -37px;
            right: -10px;
          }
        }
      }

      &:nth-child(2) {

        .item {
          padding-top: 1.6rem;

          &__img {
            top: -27px;
            right: -8px;
          }

          &::before {
            top: 0;
          }
        }
      }

      &:nth-child(3) {

        .item {
          padding-top: 1.6rem;

          &__img {
            top: -20px;
            right: -8px;
          }

          &::before {
            top: 0;
          }
        }
      }

      &:nth-child(4) {

        .item {
          padding-top: 1.6rem;

          &__img {
            top: -15px;
            right: -15px;
          }

          &::before {
            top: 0;
          }
        }
      }
    }

    .item {
      padding-top: 4.4rem;
      padding-right: 1.6rem;

      &__img {
        width: 9.6rem;
        height: 9.6rem;
      }

      &__inner {
        height: auto;
        padding: .8rem 7.6rem .8rem .8rem;
      }

      &__title {
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: .8rem;
      }

      &__text {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }

      &::before {
        width: 11rem;
        height: 11rem;
        top: 2.8rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    .title {
      font-size: 3.2rem;
      line-height: 4.2rem;

      span {
        font-size: 2.4rem;
        font-weight: 400;
        line-height: 3.2rem;
      }
    }

    .col {
      margin-bottom: 2.8rem;
    }
  }
}

.capability {
  position: relative;
  background-color: #B9D6F1;
  padding: 6.4rem 0;

  .title {
    font-size: 5.6rem;
    font-weight: 900;
    line-height: 6.6rem;
    color: $basicWhite;
    margin: 0 0 6.4rem;
  }

  .slider {

    .el-carousel__container {
      height: 55rem;
    }

    .el-carousel__indicators.el-carousel__indicators--horizontal {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-carousel__indicator.el-carousel__indicator--horizontal {
        min-width: 16px;
        height: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0 20px 0 0;

        &.is-active {

          .el-carousel__button {
            width: 16px;
            height: 16px;
            background-color: $primary;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .el-carousel__button {
        width: 8px;
        height: 8px;
        background-color: #fff;
        border-radius: 50%;
        opacity: 1;
        transition: all .4s;
      }
    }

    .el-carousel__arrow {
      top: unset;
      bottom: 0;
      transform: none;
      background-color: transparent;

      &--left, &--right {
        width: 24px;
        height: 24px;
        left: 35%;

        i {
          display: flex;
          align-items: center;
          justify-content: center;

          &::before {
            width: 24px;
            height: 24px;
            content: '';
            background-image: url("../assets/icons/icon-slide-prev.svg");
          }
        }
      }

      &--right {
        left: unset;
        right: 35%;

        i {

          &::before {
            background-image: url("../assets/icons/icon-slide-next.svg");
          }
        }
      }
    }
  }

  .slide {
    height: 49.3rem;

    &.is-animating {
      transition: transform .6s ease-in-out;
    }

    &.is-active {

      .item {

        &__block {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  .item {
    width: 76rem;
    height: 100%;
    position: relative;
    margin: 0 auto;

    &__img {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0px 24px 40px rgba(72, 74, 83, 0.08));
      border-radius: 16px;

      img {
        border-radius: 16px;
      }
    }

    &__block {
      z-index: 2;
      position: absolute;
      top: 11.2rem;
      left: -18.4rem;
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
      border: 1px solid $basicWhite;
      border-radius: 16px;
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transition: opacity .6s, transform .6s;
      transition-delay: .4s;
      padding: 1.6rem 2.4rem;
    }

    &__title {
      font-size: 3.4rem;
      font-weight: 700;
      line-height: 4.4rem;
      color: $primary;
      margin: 0 0 1.6rem;
    }

    &__text {
      font-size: 2rem;
      font-weight: 500;
      line-height: 3rem;
      color: #6B6E72;
      margin: 0;
    }
  }

  &__btn {
    max-width: 23.8rem;
    display: flex;
    margin: 6rem auto 0;
  }

  &__img {
    width: 23.3rem;
    height: 28.9rem;
    position: absolute;
    top: 80%;
    left: 0;
    background-image: url(../assets/images/capability-img.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
  }

  @media only screen and (max-width: 991px) {

    .title {
      font-size: 3.4rem;
      line-height: 4rem;
      margin-bottom: 3.2rem;
    }

    .slider {

      .el-carousel__container {
        height: 40rem;
      }

      .el-carousel__arrow {

        &--left, &--right {
          left: 25%;
        }

        &--right {
          left: unset;
          right: 25%;
        }
      }
    }

    .slide {
      height: 32.4rem;
    }

    .item {
      width: 50rem;

      &__block {
        top: 5rem;
        left: -10rem;
      }

      &__title {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }

      &__text {
        font-size: 1.8rem;
        line-height: 2.6rem;
      }
    }

    &__img {
      width: 15rem;
      height: 15rem;
    }

    &__btn {
      margin-top: 3.2rem;
    }
  }

  @media only screen and (max-width: 768px) {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    .item {
      width: 100%;
      display: flex;

      &__img {
        width: 50rem;
        height: auto;
        margin-left: auto;
        margin-top: auto;
      }

      &__block {
        left: 2rem;
        top: 2rem;
        padding: 1.6rem;
      }

      &__title {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: .8rem;
      }

      &__text {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }

  @media only screen and (max-width: 576px) {

    &__img {
      display: none;
    }

    .title {
      font-size: 3.2rem;
      line-height: 4.2rem;
      margin-bottom: 3.2rem;
    }

    .slider {

      .el-carousel__container {
        height: 39rem;
      }

      .el-carousel__arrow {
        display: none;
      }
    }

    .slide {
      height: 32.5rem;
    }

    .item {
      width: 100%;
      display: flex;

      &__img {
        width: 28.2rem;
        height: 18.2rem;
        margin-left: auto;
        margin-top: auto;
      }

      &__block {
        left: 0;
        top: 0;
        padding: 1.6rem;
        width: 28.2rem;
        min-height: 15.5rem;
      }

      &__title {
        font-size: 2.2rem;
        line-height: 2.6rem;
        margin-bottom: .8rem;
      }

      &__text {
        font-size: 2rem;
        line-height: 2.6rem;
      }
    }
  }
}

.blog {
  position: relative;
  background-color: #F7FBFF;
  padding: 6.4rem 0;

  &__img {
    width: 25.5rem;
    height: 29.8rem;
    position: absolute;
    top: 60%;
    right: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-size: 5.6rem;
    font-weight: 900;
    line-height: 6.6rem;
    color: $primary;
    margin: 0 0 6.4rem;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.6rem;
  }

  .col {
    width: calc(100%/3);
    padding: 0 1.6rem;
  }

  .anim-items {
    overflow: hidden;

    .item {
      transform: translate3d(0, 200%, 0);
      opacity: 0;
      transition: transform .8s ease-out, opacity .8s ease-out;
    }

    @for $i from 1 to 100 {

      &:nth-child(#{$i}) {

        .item {
          transition-delay: #{.2 + $i/10}s;
        }
      }
    }

    &.active {

      .item {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }

  .item {
    display: block;
    height: 100%;

    &__img {
      width: 100%;
      height: 31.2rem;
      overflow: hidden;
      border-radius: 16px;
      margin: 0 0 2.4rem;

      img {
        border-radius: 16px;
        transform: translate3d(0, 0, 0);
        transition: transform .8s;
      }
    }

    &__title {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 3.4rem;
      color: #1B1D24;
      transition: color .4s;
      margin: 0 0 1.6rem;
    }

    &__date {
      font-size: 14px;
      line-height: 2.4rem;
      color: #9BA2AB;
    }

    &:hover {

      .item__img {

        img {
          transform: scale(1.2);
        }
      }

      .item__title {
        color: $primary;
      }
    }
  }

  &__btn {
    max-width: 23.8rem;
    display: flex;
    margin: 6rem auto 0;
  }

  .slider {

    .el-carousel__container {
      height: 40rem;
      margin: 0 0 3.2rem;
    }

    .el-carousel__indicators.el-carousel__indicators--horizontal {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-carousel__indicator.el-carousel__indicator--horizontal {
        min-width: 16px;
        height: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0 20px 0 0;

        &.is-active {

          .el-carousel__button {
            width: 16px;
            height: 16px;
            background-color: $primary;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .el-carousel__button {
        width: 8px;
        height: 8px;
        background: #9CAFF1;
        border-radius: 50%;
        opacity: 1;
        transition: all .4s;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 991px) {

    .title {
      font-size: 3.4rem;
      line-height: 4rem;
      margin-bottom: 3.2rem;
    }

    &__img {
      width: 15rem;
      height: 20.4rem;
      top: -8rem;

      img {
        height: auto;
      }
    }

    .row {
      margin-right: -1.5rem;
      margin-left: -1.5rem;
    }

    .col {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    .item {

      &__img {
        height: auto;
        margin-bottom: 1.6rem;
      }

      &__title {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }

    &__btn {
      margin-top: 3rem;
    }
  }

  @media only screen and (max-width: 768px) {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    &__img {
      display: none;
    }

    .title {
      font-size: 2rem;
      line-height: 3.2rem;
      margin-bottom: 3.2rem;
    }

    .col {
      width: 33%;
      margin-bottom: 1.6rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .item {

      &__img {
        margin-bottom: .8rem;
      }

      &__title {
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: .8rem;
      }

      &__date {
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }

    &__btn {
      margin-top: 3.2rem;
    }
  }

  @media only screen and (max-width: 576px) {

    .col {
      width: 100%;
    }

    .slider {

      .el-carousel__container {
        height: 30rem;
      }
    }
  }
}

.start {
  padding: 12.4rem 0 2.4rem;
  background: $primary;
  
  .inner {
    display: flex;
  }

  .title {
    font-size: 5.6rem;
    font-weight: 900;
    line-height: 6.6rem;
    color: $basicWhite;
    margin-right: 22.2rem;

    span {
      font-size: 4.8rem;
      font-weight: 400;
      line-height: 5.8rem;
      display: block;
      margin-bottom: 2rem;
      color: #B9D6F1;
    }
  }

  .steps {
    &__item {
      display: flex;
      margin-bottom: 9rem;
    }

    &__step-number {
      border: 2px solid rgba(37, 150, 190, .4);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.4rem;
      font-weight: 500;
      width: 8.4rem;
      height: 8.4rem;
      flex-shrink: 0;
      color: $basicWhite;
      transform: translateY(-30%);
    }

    &__step-content {
      color: $basicWhite;
      padding-left: 3.2rem;
    }

    &__step-title {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 3.4rem;
      margin-bottom: 2rem;
    }

    &__step-text {
      font-size: 2rem;
      font-weight: 400;
      line-height: 3rem;

      li {
        list-style-type: none;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 6.4rem 0 2.4rem;

    .inner {
      flex-wrap: wrap;
    }

    .title {
      margin-right: 0;
      margin-bottom: 6.2rem;

      br {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 3.2rem 0 3.2rem;

    .title {
      font-size: 4.8rem;
      line-height: 5.8rem;
      margin-bottom: 3.2rem;

      span {
        font-size: 2.4rem;
        line-height: 3.4rem;
        margin-bottom: 1.6rem;
      }
    }

    .steps {
      width: 100%;

      &__item {
        flex-direction: column;
        margin-bottom: 3.2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__step-number {
        width: 5.6rem;
        height: 5.6rem;
        font-size: 2rem;
        transform: translateY(0);
        margin-bottom: 3.2rem;
      }

      &__step-content {
        padding-left: 0;
      }

      &__step-title {
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 3.2rem;
      }

      &__step-text {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
}

.faq {
  padding: 6.4rem 0;

  .title {
    font-size: 5.6rem;
    font-weight: 900;
    line-height: 6.6rem;
    color: $primary;
    margin: 0 0 6.4rem;
  }

  .questions {
    max-width: 112.8rem;
    border: none;
    margin: 0 auto;

    &__item {
      background-color: #E4F1FD;
      border-radius: 8px;
      padding: 1.6rem 6.4rem;
      margin: 0 0 1.6rem;

      .el-collapse-item__header {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 3.4rem;
        color: $basicBlack;
        border-bottom: none;
        background-color: transparent;

        .el-collapse-item__arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 3rem;
          margin: 0 0 0 auto;

          &::before {
            width: 22px;
            height: 22px;
            content: '';
            background-image: url(../assets/icons/icon-faq-plus.svg);
          }

          &.is-active {
            transform: none;

            &::before {
              width: 22px;
              height: 2px;
              content: '';
              background-image: url(../assets/icons/icon-faq-minus.svg);
            }
          }
        }
      }

      .el-collapse-item__wrap {
        background-color: transparent;
        border-bottom: none;
      }

      .el-collapse-item__content {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: $basicBlack;
        padding-top: 1rem;
        padding-bottom: 0;

        a {
          font-size: 1.6rem;
          font-weight: 600;
          transition: color .4s;

          &:hover {
            color: $primary-hover;
          }
        }

        p {
          margin: 0 0 1.2rem;
        }

        ul {

          li {
            position: relative;
            padding: 0 0 0 2rem;
            margin: 0 0 .4rem;

            &::before {
              content: '\2014';
              position: absolute;
              top: 0;
              left: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {

    .title {
      font-size: 3.4rem;
      line-height: 4rem;
      margin-bottom: 3.2rem;
    }

    .questions {

      &__item {
        padding-right: 3.2rem;
        padding-left: 3.2rem;

        .el-collapse-item__header {
          font-size: 2rem;
          line-height: 2.6rem;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    .title {
      font-size: 3.2rem;
      line-height: 4.2rem;
      margin-bottom: 3.2rem;
    }

    .questions {
      max-width: 100%;

      &__item {
        padding: 1.6rem 1.4rem 1.6rem .8rem;

        .el-collapse-item__header {
          font-size: 1.8rem;
          line-height: 2.4rem;

          .el-collapse-item__arrow {
            padding-left: 1rem;
          }
        }
      }
    }
  }
}

.subscribe {
  overflow: hidden;
  background: #F7FBFF;

  .inner {
    min-height: 78.9rem;
    position: relative;
    padding: 7.5rem 0 3.8rem;

    &::before {
      width: 3.5rem;
      height: 3.5rem;
      content: '';
      position: absolute;
      top: 8.8rem;
      left: -7.5rem;
      background-color: #F6C928;
      border-radius: 50%;
    }
  }

  &__bg {
    width: 66.4rem;
    height: 66.4rem;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);
    background: #E4F1FD;
    border-radius: 50%;

    &-img {
      position: absolute;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &.woman {
        width: 58rem;
        height: 70rem;
        left: 5rem;
        bottom: 2rem;
      }

      &.money {
        width: 7.6rem;
        height: 7.6rem;
        top: -4rem;
        left: 30rem;
      }

      &.money-big {
        width: 9.1rem;
        height: 9.1rem;
        left: 20rem;
        top: 4rem;
      }

      &.ball {
        width: 9.4rem;
        height: 9.4rem;
        right: -10rem;
      }

      &.ball-pink {
        width: 7.9rem;
        height: 7.9rem;
        left: -10rem;
        bottom: 10rem;
      }
    }
  }

  .block {
    width: 50%;
    padding: 4rem 0 0;
  }

  .title {
    font-size: 5.6rem;
    font-weight: 900;
    line-height: 6.6rem;
    color: $primary;
    margin: 0 0 2.7rem;
  }

  .text {
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    color: #6B6E72;
    margin: 0 0 6.4rem;
  }

  &__form {
    display: flex;
    align-items: center;

    &-input {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.4rem;
      background: $basicWhite;
      box-shadow: 0px 24px 40px rgba(72, 74, 83, 0.08);
      outline: none;
      border-radius: 4px;
      border: 1px solid transparent;
      transition: border-color .4s;
      padding: 1.5rem;
      margin: 0 .8rem 0 0;

      &::placeholder {
        color: $gray4;
        transition: opacity .4s;
      }

      &:focus {
        border-color: $primary;

        &::placeholder {
          opacity: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {

    .inner {
      min-height: unset;
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }

    &__bg {
      display: none;
    }

    .block {
      width: 60%;
      padding-top: 2.4rem;
    }

    .title {
      font-size: 3.4rem;
      line-height: 4rem;
      margin-bottom: 3.2rem;
    }

    .text {
      margin-bottom: 3.2rem;
    }

    &__form {

      &-input {
        font-size: 1.4rem;
        padding: 1.1rem 1.2rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {

    .inner {
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
    }

    .block {
      width: 100%;
      padding-top: 0;
    }

    .title {
      font-size: 3.2rem;
      line-height: 4.2rem;
      margin-bottom: .8rem;
    }

    .text {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 3.2rem;
    }

    &__form {
      flex-direction: column;
      align-items: flex-start;

      &-input {
        width: 100%;
        max-width: 288px;
        margin-right: 0;
        margin-bottom: 1.6rem;
      }
    }
  }
}

</style>
