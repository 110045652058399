import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  graphicStatistic: null,
  tableStatistic: null,
  referenceValues: null
})

const getters = {}
const mutations = {
  setData (state, data) {
    state[data.label] = data.data
  },
}
const actions = {
  getReferenceValues ({ commit }, token) {
    return new Promise((resolve, reject) => {
      axios({ 
        url: `${apiUrl}/admin/benchmark-cashback`, 
        method: 'GET', 
        headers: { 'Authorization': `Bearer ${token}` },
      })
        .then(resp => {
          commit('setData', { label: 'referenceValues', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addReferenceValues (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ 
        url: `${apiUrl}/admin/benchmark-cashback`, 
        method: 'POST', 
        headers: { 'Authorization': `Bearer ${data.token}` },
        data: data.data
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getGraphicStatistic ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ 
        url: `${apiUrl}/admin/statistics/graphic`, 
        method: 'POST', 
        headers: { 'Authorization': `Bearer ${data.token}` },
        data: data.params 
      })
        .then(resp => {
          let data = resp.data.data
          commit('setData', { label: 'graphicStatistic', data: data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getTableStatistic ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ 
        url: `${apiUrl}/admin/statistics/table`, 
        method: 'POST', 
        headers: { 'Authorization': `Bearer ${data.token}` },
        data: data.params
      })
        .then(resp => {
          commit('setData', { label: 'tableStatistic', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getExelStatistic (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ 
        url: `${apiUrl}/admin/statistics/excel`, 
        method: 'POST', 
        headers: { 'Authorization': `Bearer ${data.token}` },
        data: data.params
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
