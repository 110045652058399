import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_URL

const pagination = () => ({
  currentPage: 0,
  total: 0,
  perPage: 20
})

const errors = () => ({
  title: null,
  text: null,
  pos: null,
})

const form = () => ({
  title: "",
  text: "",
  pos: null,
  active: false,
})

const state = () => ({
  questions: [],
  form: form(),
  pagination: pagination(),
  errors: errors(),
  isLoading: true
})

const getters = {}

const mutations = {
  clearFormErrors(state) {
    state.errors = errors()
  },
  setFormError(state, { key, value }) {
    state.errors[key] = value
  },
  replaceFaq(state, updatedFaq) {
    const faqIndex = state.questions.findIndex((faq) => faq.id === updatedFaq.id)
    state.questions.splice(faqIndex, 1, updatedFaq)
  },
  setFaq(state, questions) {
    state.questions = questions
  },
  clearForm(state) {
    state.form = form()
  },
  setForm(state, form) {
    const isFullForm = !form.key
    if (isFullForm) {
      state.form = form
    } else {
      state.form[form.key] = form.value
    }

    this.commit('faq/clearFormErrors')
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  clearPagination(state) {
    state.pagination = pagination()
  },
  setTableLoading(state, isLoading) {
    state.isLoading = isLoading
  }
}

const actions = {
  updateFaq(ctx, payload) {
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/faq/${payload.id}`,
        method: 'PUT',
        headers: { 'Authorization': `Bearer ${token}` },
        data: payload
      }).then(response => {
          resolve(response.data.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addFaq(ctx, payload) {
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/faq`,
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
        data: payload
      }).then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteFaq(ctx, faqId) {
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/faq/${faqId}`,
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` },
      }).then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  fetchFaq(ctx, data) {
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    ctx.commit('setTableLoading', true)

    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/faq`,
        method: 'GET',
        ...(data && data.params && { params: data.params }),
        headers: { 'Authorization': `Bearer ${token}` }
      }).then(({ data }) => {
          ctx.commit('setPagination', {
            ...ctx.state.pagination,
            currentPage: data.current_page,
            total: data.total
          })
          ctx.commit('setFaq', data.data)
          ctx.commit('setTableLoading', false)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};