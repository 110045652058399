const state = () => ({
  dialogVisible: false,
  activeDialog: '',
  dialogTitle: '',
  userDialog: false,
  isEditable: false,
  userIdForAccount: null,
  managerFor: '',
  isOpenUL: false,
  baActivePlatform: null,
});

const getters = {};

const mutations = {
  setData (state, data) {
    state[data.label] = data.data;
  },
};

const actions = {
  closeDialog({ commit }) {
    commit('setData', { label: 'dialogVisible', data: false })
    commit('setData', { label: 'activeDialog', data: '' })
  },
  setData({commit}, data) {
    commit('setData', { label: data.label, data: data.data })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
