<template>
  <div class="search">
    <img src="@/assets/icons/icon-search.svg" alt="search" class="search__icon">
    <form action="#" @submit.prevent="submitForm">
      <input type="text" :placeholder=placeholder v-model="searchText" @input="$emit('search-input', searchText)">
    </form>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    placeholder: {
      type: String,
      default: 'Поиск'
    }
  },
  data () {
    return {
      searchText: ''
    }
  },
  methods: {
    submitForm() {
      this.$emit('findValue', this.searchText)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/styles/helpers/variables";
  @import "../../assets/styles/helpers/mixin";

  .search {
    position: relative;

    input {
      background: $backgroundInput;
      border: 1px solid $border;
      box-sizing: border-box;
      border-radius: 2px;
      height: 5rem;
      padding: 0 4rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      width: 100%;
    }

    &__icon {
      position: absolute;
      left: 1.5rem;
      top: 1.7rem;
      width: 1.6rem;
    }
  }
</style>
