
<template>
    <div class="tooltip-wrapper" tabindex="-1">
        <el-tooltip popper-class="tooltip-width" placement="top">
            <div slot="content">
                <slot>
                    {{ data }}
                </slot>
            </div>
            <el-button>?</el-button>
        </el-tooltip>
    </div>
</template>
<script>
export default {
    name: "Tooltip",
    props: {
        data: {
            type: String,
            default: ''
        }
    },
}
</script>
<style lang="scss">
    .tooltip-width {
        max-width: 200px;
    }
    .tooltip-wrapper {
        padding-left: 1rem;
    }
</style>