let checkCashback = {
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    role() {
      return this.$store.state.Auth.role
    },
    showCashback() {
      let isShow = true
      if (this.role === '' || this.role === 'user') {
        if (this.activeAccount && this.activeAccount.cashback_conditions) {
          for (let key in this.activeAccount.cashback_conditions.advertising) {
            this.activeAccount.cashback_conditions.advertising[key].forEach((el) => {
              if (el.percent) {
                isShow = true
              } else if (!el.percent && !this.activeAccount.cashback_balance) {
                isShow = false
              }
            })
          }
          this.activeAccount.cashback_conditions.deposit.forEach((el) => {
            if (el.percent) {
              isShow = true
            } else if (!el.percent && !this.activeAccount.cashback_balance) {
              isShow = false
            }
          })
          for (let key in this.activeAccount.commissions_conditions) {
            if (Array.isArray(this.activeAccount.commissions_conditions[key])) {
              this.activeAccount.commissions_conditions[key].forEach((el) => {
                if (el.percent) {
                  isShow = true
                }
              })
            }
          }
        }
      }
      return isShow
    },
    showCashbackSidebar() {
      let isShow = true
      if (this.role === '' || this.role === 'user') {
        if (this.activeAccount && this.activeAccount.cashback_conditions) {
          for (let key in this.activeAccount.cashback_conditions.advertising) {
            this.activeAccount.cashback_conditions.advertising[key].forEach((el) => {
              if (el.percent) {
                isShow = true
              } else if (!el.percent && !this.activeAccount.cashback_balance) {
                isShow = false
              }
            })
          }
          this.activeAccount.cashback_conditions.deposit.forEach((el) => {
            if (el.percent) {
              isShow = true
            } else if (!el.percent && !this.activeAccount.cashback_balance) {
              isShow = false
            }
          })
        }
      }
      return isShow
    },
  }
};

export default checkCashback;
