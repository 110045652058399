<template>
    <div v-if="promoList && promoList.length" class="promo-table" v-loading="loading">

        <el-table :data="promoList" :row-class-name="tableRowClassName" style="width: 100%" :highlight-current-row=true
            :highlight-selection-row=true :indent=20>

            <el-table-column width="150" :label="$t('promo_table.promocode')" prop="code" />

            <el-table-column  :label="$t('promo_table.status')" width="250" >
                <template slot-scope="scope">
                    <div>{{scope.row.status}}</div>
                </template>
            </el-table-column>

            <el-table-column width="200" class-name="promocodes__table-email"  :label="$t('promo_table.active_period')">
                <template slot-scope="scope">
                    <div>{{scope.row.available_at}}/</div><div>{{scope.row.expired_at}}</div>
                </template>
            </el-table-column>

            <el-table-column width="200" prop="sum_available_in" :label="$t('promo_table.invalidation_period')" >
                <template slot-scope="scope">
                    <div>{{scope.row.sum_available_in}} {{ locale==='RU'?getMonthWord(scope.row.sum_available_in):getMonthWordUSA(scope.row.sum_available_in)}}</div>
                </template>
            </el-table-column>

            <el-table-column width="400" prop='description' class-name="promocodes__table-holding"
                :label="$t('promo_table.job_description')" />
            <el-table-column width="400" class-name="promocodes__table-holding" :label="$t('promo_table.clients')" >
                <template slot-scope="scope">
                    <ul>
                        <li v-for="(client, key) in scope.row.business_accounts" :key="key">{{client.email}}</li>
                    </ul>
                </template>
            </el-table-column>

            <!-- <el-table-column class-name="promocodes__table-edit">
              <template slot-scope="scope">
                <span v-if="scope.row.is_active === 0" class="promocodes__table-edit__text ">Деактивирован</span>
  
                <span class="promocodes__table-edit__btn"  @click="editPromo(scope.row)">
                  <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 8.5C3 9.32843 2.32843 10 1.5 10C0.671573 10 0 9.32843 0 8.5C0 7.67157 0.671573 7 1.5 7C2.32843 7 3 7.67157 3 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17Z"
                      fill="#9BA2AB"/>
                  </svg>
                </span>
              </template>
            </el-table-column> -->
            <el-table-column>
                <template slot-scope="scope">
                    <el-dropdown
                        class="promocodes__table-edit"
                        trigger="click"
                        :key="scope.id"
                        @command="handleCommand"
                        >
                        <span class="el-dropdown-link promocodes__table-dropdown-item">
                            <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 8.5C3 9.32843 2.32843 10 1.5 10C0.671573 10 0 9.32843 0 8.5C0 7.67157 0.671573 7 1.5 7C2.32843 7 3 7.67157 3 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17Z"
                                fill="#9BA2AB"/>
                            </svg>
                        </span>
                        <el-dropdown-menu class="promocodes__table-change-list" :class="{'locale-usa': locale === 'USA'}" slot="dropdown">
                            <el-dropdown-item 
                            class="promocodes__table-change-list__item"
                            :command="{name: 'edit', data: scope.row}"
                            >
                                {{$t('edit')}}
                            </el-dropdown-item>
                            <el-dropdown-item 
                            class=""
                            :command="{name: 'delete', id: scope.row.id}"
                            >
                                {{$t('delete')}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template> 
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    name: 'PromoTable',
    props: {
            loading: Boolean,
            promoList: {
                type: Array,
                default: () => []
            }
        
    },
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
        }
    },
    methods: {
        handleCommand(command) {
            if (command.name === 'edit') {
                this.editPromo(command.data);
            }
            if (command.name === 'delete') {
               this.deletePromocode(command.id)
            }
        },
        tableRowClassName() {
          return 'el-table__row--promo-row';
        },
        getMonthWordUSA(number) {
            if(Number.isInteger(number) && number > 0) {
                let word = ""
                if(number===1) word = 'month'
                else if(number>1) word = 'months'
                return word
            } else {
                return 'Incorrect data'
            }
        },
        getMonthWord(number) {
            // Проверяем, что число является целым и положительным
            if (Number.isInteger(number) && number > 0) {
                // Определяем последнюю цифру числа
                let lastDigit = number % 10
                // Определяем предпоследнюю цифру числа
                let secondLastDigit = Math.floor(number / 10) % 10
                // Инициализируем переменную для слова
                let word = ""
                // Если предпоследняя цифра равна 1, то слово будет "месяцев"
                if (secondLastDigit === 1) {
                    word = "месяцев"
                }
                // Иначе, если последняя цифра равна 1, то слово будет "месяц"
                else if (lastDigit === 1) {
                    word = "месяц"
                }
                // Иначе, если последняя цифра от 2 до 4, то слово будет "месяца"
                else if (lastDigit >= 2 && lastDigit <= 4) {
                    word = "месяца"
                }
                // Иначе, слово будет "месяцев"
                else {
                    word = "месяцев"
                }
                // Возвращаем слово
                return word
            }
            // Если число не является целым или положительным, то возвращаем ошибку
            else {
                return "Неверный ввод"
            }
        },

        editPromo(data) {
            this.$store.commit('Dialog/setData', { label: 'promoTrigger', data: 'PromoTable' })
            this.$store.commit('promo/setData', { label: 'editPromo', data: data })
            this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'PromoData' })
            this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
        },
        deletePromocode(id) {
            const token = localStorage.getItem('token')
            const data = {token, id}
            this.$store.dispatch('promo/deletePromo', data)
            .then(()=> {
                this.$notify({
                  message: this.$t('promo_table.delete_promo'),
                  type: 'success'
                })
            })
            .catch(err => {
              console.error(err.message)
              this.$notify({
                  message: err.message,
                  type: 'error'
                })
              this.errors.api_error = err.message  
            })
        }
    }

}
</script>
<style  lang="scss" >
    .promo-table {
        .el-table th > .cell, .el-table td > .cell {
            white-space: normal;
            word-break: break-word;
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 12px;
            text-align: left;
        }
    }
    .promocodes__table-edit__btn {
        padding:  0 1rem;
        &:hover {
            cursor: pointer;
        }
    }
    .promocodes {
        &__table{
            &-dropdown-item {
                &:hover {
                    cursor: pointer;
                    svg {
                        path {
                            fill: var(--primary);
                        }
                    }
                }
            }
            &-change-list {
                &__item {
                    border-bottom: 1px solid #DADEE6;
                }
            }
        }
    }
</style>