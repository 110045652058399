import en from './languages/en.json'
import ru from './languages/ru.json'
import numberFormats from './numberFormats'
import dateTimeFormats from './dateTimeFormats'



export const Languages = {
    en,
    ru
}

export const numberFormat = {
    numberFormats
}

export const dateTimeFormat = {
    dateTimeFormats
}