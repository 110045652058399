<template>
	<div class="cookies-page">
		<div v-if="locale === 'USA'">
			<h1 class="title">SPECIAL TERMS AND CONDITIONS DIGITAL EAGLE INC</h1>
			<p class="text"><i>Effective Date: January 5, 2024</i></p>
			<p class="text">
				These Special Terms and Conditions (these “Special Terms”) are made and entered into as of January 5, 2024
				(the "Effective Date"), by and between Digital Eagle Inc, a New Jersey corporation
				(“Digital Eagle” or “Provider”), and (“Client”), where Provider and Client are sometimes referred to
				herein each individually as a “Party” and collectively as the “Parties”.
			</p>
			<p class="text">
				In consideration of the promises, covenants and agreements herein contained, the Parties agree as
				follows:
			</p>
			<ol>
				<li>Digital Eagle provides the services of online placement of advertisements in the third-party<br />
					systems for advertisement placement (the “Service”), including:
					<ul class="terms-text">
						<li>creating on behalf of the Client online accounts on the third-party platforms/ systems;</li>
						<li>replenishment of the advertising budget accounts
							in the advertising systems for the Client.
						</li>
					</ul>
				</li>
				<li>Budget of the advertising campaign:
					<table class="iksweb">
						<tbody>
							<tr>
								<td>Name of Service</td>
								<td>Advertising System
									(Platform)</td>
								<td>Minimum budget
									Replenishment in
									the first month</td>
								<td>Minimum budget
									Replenishment in
									the second month</td>
							</tr>
							<tr>
								<td>Replenishment of the advertising
									budget of the Client’s account
									(accounts)</td>
								<td>Meta </td>
								<td>$1000</td>
								<td>$1000</td>
							</tr>
							<tr>
								<td>Replenishment of the advertising
									budget of the Client’s account
									(accounts)
								</td>
								<td>Google Ads</td>
								<td>$1000</td>
								<td>$1000</td>
							</tr>
							<tr>
								<td>Replenishment of the advertising
									budget of the Client’s account
									(accounts)</td>
								<td>TikTok</td>
								<td>$1000</td>
								<td>$1000</td>
							</tr>
							<tr>
								<td>Replenishment of the advertising
									budget of the Client’s account
									(accounts)</td>
								<td>VKAds</td>
								<td>$1000</td>
								<td>$1000</td>
							</tr>
							<tr>
								<td>Replenishment of the advertising
									budget of the Client’s account
									(accounts)</td>
								<td>Yandex</td>
								<td>$1000</td>
								<td>$1000</td>
							</tr>
						</tbody>
					</table>
					<ul class="terms-text">
						<li>Digital Eagle provides Client with an account in the advertising
							system in USD, PLN
							currency.</li>
						<li>The parties agreed that the replenishment of funds in the advertising platform is carried
							out at the rate, established by TD Bank, at the date of receipt of payment by Digital
							Eagle from Client</li>
						<li>The parties agreed that the replenishment of funds in the advertising platform is carried
							out at the rate, established by TD Bank, at the date of receipt of payment by Digital
							Eagle from Client</li>
						<li>If Client does not fulfill the condition of replenishing the minimum advertising budget,
							Provider has the right to unilaterally charge Client a penalty fee of $ 100.</li>
					</ul>
				</li>
				<li>Client shall pay Provider the following fees for the Service:
					<table class="iksweb">
						<tbody>
							<tr>
								<td>Name of fee </td>
								<td>Advertising System
									(Platform)</td>
								<td>Rate</td>
							</tr>
							<tr>
								<td rowspan="5">commission fee for the service to replenish the
									advertising budget (calculated as a percentage of the
									Client’s advertising budget replenished to the account)</td>
								<td>Meta </td>
								<td>4 %</td>
							</tr>
							<tr>
								<td>Google Ads</td>
								<td>3 %</td>
							</tr>
							<tr>
								<td>TikTok</td>
								<td>2%</td>
							</tr>
							<tr>
								<td>VKAds</td>
								<td>2%
								</td>
							</tr>
							<tr>
								<td>Yandex</td>
								<td>2%</td>
							</tr>
						</tbody>
					</table>
					<br/>
					<table class="iksweb">
						<tbody>
							<tr>
								<td>Name of fee </td>
								<td>Rate</td>
							</tr>
							<tr>
								<td>commission fee for payment processing and documentation
									management (Client shall pay for processing and payment by Provider
									of each invoice of a third-party service provider)</td>
								<td>$ 85 /each payment</td>
							</tr>
						</tbody>
					</table>
				</li>
			</ol>
			<br>
			<div><b> Digital Eagle Inc</b> <br><i>Mail</i> : 1992 Morris Ave #326, Union, NJ, 07083, U.S.</div>
			<div><i>Email</i> : <a href="mailto:registration@digital-eagle.us">registration@digital-eagle.us</a></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SpecialTerms',
	metaInfo: {
		title: `${process.env.VUE_APP_LOCALE === 'USA' ? 'Digital eagle' : 'ProfitAds'}`
	},
	data() {
		return {
			locale: process.env.VUE_APP_LOCALE,
		}
	}
}
</script>

<style lang="scss" >
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.cookies-page {
	width: 100%;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.9;
	color: $basicBlack;
	background: #fff;
	box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
	padding: 12rem;

	.list-position {
		>li {
			list-style-position: inside;
		}
	}

	.first-list-wrapper {
		padding-left: 20px;
		margin-top: 10px;
	}

	.first-main-li {
		>li::marker {
			font-weight: bold;
		}
	}

	.title {
		font-size: 3rem;
		line-height: 4rem;
		font-weight: 700;
		margin: 0 0 3rem;
	}

	.subtitle {
		font-weight: 700;
		margin: 0 0 1.5rem;

		&--blue {
			color: var(--primary);
		}
	}

	.text {
		margin: 0 0 3rem;

		&--gray {
			color: $gray;
		}
	}

	.list {
		padding: 0 0 0 2rem;
		margin: 0 0 3rem;

		&__item {
			margin: 0 0 1.5rem;

			a {
				color: var(--primary);
			}

			ul {
				padding-left: 2rem;
			}
		}
	}

	.dash {
		list-style-type: none;

		li:before {
			content: "-";
			margin-right: 5px;
		}
	}

	.disc {
		list-style-type: disc;
	}

	a {
		color: var(--primary);
		text-decoration: underline;

		&:hover {
			opacity: 0.7;
		}
	}

	.link {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 1.6rem;
		line-height: 1.9;
		color: var(--primary);
		background-color: transparent;
		border: 1px solid var(--primary);
		border-radius: 4px;
		text-decoration: none;
		transition: color .4s linear, background-color .4s linear;
		padding: 7px 50px;

		&:hover {
			color: $basicWhite;
			background-color: var(--primary);
		}
	}

	@include below(993px) {
		padding: 60px 32px;
	}

	@include below(769px) {
		padding: 32px 24px;
	}

	@include below(577px) {
		padding: 24px 16px;

		.title {
			font-size: 16px;
			line-height: 1.4;
			margin-bottom: 16px;
			text-align: center;
		}

		.text {
			font-size: 14px;
			line-height: 1.4;
			margin-bottom: 16px;
		}

		.list {
			padding-left: 16px;
			margin-bottom: 16px;

			li {
				margin-bottom: 8px;

				.subtitle {
					font-size: 14px;
					line-height: 1.4;
					margin-bottom: 8px;
				}

				p {
					font-size: 14px;
				}

				.list {

					li {
						font-size: 14px;
						line-height: 1.4;
					}
				}
			}
		}
	}
}

.terms-text {
	margin-left: 15px;
}

table.iksweb {
	text-decoration: none;
	border-collapse: collapse;
	width: 100%;
	text-align: center;
}

table.iksweb th {
	font-weight: normal;
	font-size: 14px;
	color: #ffffff;
	background-color: #354251;
}

table.iksweb td {
	font-size: 13px;
	color: #354251;
}

table.iksweb td,
table.iksweb th {
	white-space: pre-wrap;
	padding: 10px 5px;
	line-height: 13px;
	vertical-align: middle;
	border: 1px solid #354251;
}

table.iksweb tr:hover {
	background-color: #f9fafb
}

table.iksweb tr:hover td {
	color: #354251;
	cursor: default;
}

.mobile-table {
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
}
</style>
