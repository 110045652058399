<template>
  <div class="auth-layout">
    <div class="auth-layout__wrap">
      <!-- <router-link :to="{name: 'MainPage'}" class="auth-layout__title" :class="{'locale-usa': locale==='USA'}">
        <img class="img-responsive img-responsive--svg"  :src="getPic(locale)" alt="logo" title="logo">
      </router-link> -->
      <a :href="locale==='USA'?'https://digital-eagle.us/':'https://profitads.ru/'" class="auth-layout__title" :class="{'locale-usa': locale==='USA'}">
        <img class="img-responsive img-responsive--svg"  :src="getPic(locale)" alt="logo" title="logo">
      </a>

      <slot />
    </div>
    <div class="notifications-container">
      <MailNotification />
      <Cookies />
    </div>
  </div>
</template>

<script>
import Cookies from "@/components/blocks/Cookies";
import MailNotification from "@/components/blocks/MailNotification";

export default {
  name: 'AuthLayout',
  components: {
    Cookies,
    MailNotification,
  },
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE
    }
  },
  methods: {
    getPic(locale) {
      if(locale==='RU') {
        return  require("@/assets/images/logo.svg")
      } else {
        return require("@/assets/images/logo_new.svg")
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/mixin";

  .auth-layout {
    background: #F3F6FB;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 13rem 0 7.8rem;

    &__title {
      color: var(--primary);
      font-size: 3.5rem;
      margin-bottom: 3.5rem;
      text-align: center;
      font-weight: 500;
      display: block;
      &.locale-usa {
        img {
          width: 60%;
          height: auto;
          display: block;
        }
      }

      img{
        width: 19rem;
        height: 4rem;
        margin: 0 auto;
      }
    }

    @include below(577px) {
      padding: 60px 16px;

      &__title {
        font-size: 24px;
        margin-bottom: 24px;
      }
    }
  }
</style>
