<template>
  <div v-if="activeClient" class="info">
    <div class="container">
      <router-link :to="{ name: 'Clients' }" class="info__back" :class="{'locale-usa':locale==='USA'}">
        <img :src="getPic(locale)" alt="icon">
        {{ $t('info_for_manager.clients_list') }}
      </router-link>
      <div class="info__data">
        <div class="info__data-client-info">
          <div class="info__data-client-info-title">
            {{ $t('info_for_manager.client_info_title') }} {{ activeClient.id }}
          </div>
          <div class="info__data-client-info-date">
            {{ $t('info_for_manager.registered') }} {{ fixDateFormat(activeClient.created_at) }}
          </div>
          <div v-if="userEmail" class="info__data-client-info-date">
            {{ $t('info_for_manager.login') }} {{ userEmail }}
          </div>
        </div>
        <div class="info__data-item">
          <div class="info__data-item-title">
            {{ $t('info_for_manager.type') }}
          </div>
          <div class="info__data-item-value">
            {{ activeClient.is_legal ? $t('info_for_manager.legal') : $t('info_for_manager.individual') }}
          </div>
        </div>
        <div v-if="activeClient.is_legal" class="info__data-item">
          <div class="info__data-item-title">
            {{ $t('info_for_manager.company_name') }}
          </div>
          <div class="info__data-item-value">
            {{ activeClient.legal_name ? activeClient.legal_name : '' }}
          </div>
        </div>
        <div class="info__data-item">
          <div class="info__data-item-title">
            {{ $t('info_for_manager.contact_person') }}
          </div>
          <div v-if="activeClient.is_legal" class="info__data-item-value">
            {{ activeClient.legal_contact_name }}
          </div>
          <div v-else class="info__data-item-value">
            {{ activeClient.first_name ? activeClient.first_name : '' }}
            <br>
            {{ activeClient.last_name ? activeClient.last_name : '' }}
          </div>
        </div>
        <div class="info__data-item">
          <div class="info__data-item-title">
            {{ $t('info_for_manager.contacts') }}
          </div>
          <div class="info__data-item-value">
            {{ activeClient.email ? activeClient.email : '' }}
            <br>
            {{ activeClient.phone ? activeClient.phone : '' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fixDateFormat from '@/mixin/fixDate'

export default {
  name: "InfoForManager",
  mixins: [fixDateFormat],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE
    }
  },
  computed: {
    activeClient() {
      return this.$store.state.Auth.activeAccount
    },
    userEmail() {
      return localStorage.getItem('active_client_email')
    }
  },
  methods: {
    getPic(locale) {
      if(locale==='RU') {
        return  require("@/assets/icons/icon-arrow-blue.svg")
      } else {
        return require("@/assets/icons/icon-arrow-red.svg")
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.info {
  margin-top: 4.7rem;

  &__back {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.3rem;
    color: var(--primary);
    &.locale-usa {
      font-family: 'Gilroy';
      font-style: normal;
    }

    img {
      margin-right: 1.2rem;
      transform: rotate(90deg);
      display: block;
    }
  }

  &__data {
    background: #FFF0C2;
    border-radius: .6rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem 2.4rem 3rem;
    margin-top: 1.5rem;

    &-client-info {
      margin-right: 6rem;

      &-title {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.3rem;
        margin-bottom: 3px;
      }

      &-date {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #7F858D;
      }
    }

    &-item {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-right: 3rem;

      &:last-of-type {
        margin-right: 0;
      }

      &-title {
        color: #7F858D;
        margin-bottom: 5px;
        white-space: nowrap;
      }

      &-vvalue {
        color: #333333;
      }
    }
  }
}

@include below(993px) {
  .info__data{
    flex-direction: column;
    padding: 15px;
    > div{
      margin-right: 0;
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }
}
</style>
