<template>
	<div class="cookies-page" :class="{ 'locale-usa': locale === 'USA' }">
		<h1 class="title">{{ $t('offer_contract.title') }}</h1>
		<div v-if="locale === 'RU'">
			<p class="text">Настоящий Договор (далее – «Договор») – настоящий документ, действующая редакция которого
				размещена в сети Интернет по адресу:
				<a href="https://lk.profitads.ru/dogovor-oferty-fl/"
					target="_blank">https://lk.profitads.ru/dogovor-oferty-fl/</a>, составленный в виде оферты, т.е.
				предложения заключить договор на указанных в настоящем Договоре условиях путем его акцепта. Настоящий
				Договор содержит все существенные условия договора об установлении, изменении или прекращении гражданских
				прав и обязанностей Сторон, а также все приложения, изменения и дополнения к нему, которые опубликованы
				и/или будут опубликованы ООО «ПрофитЭдс» по адресу:
				<a href="https://lk.profitads.ru/dogovor-oferty/"
					target="_blank">https://lk.profitads.ru/dogovor-oferty/</a>.
			</p>
			<p class="text">Акцепт настоящего Договора допускается исключительно физическим лицом, достигшим возраста
				восемнадцати лет, имеющим гражданство Российской Федерации, полностью дееспособным в соответствии с
				законодательством Российской Федерации.</p>
			<p class="text">Настоящий Договор (далее – «Договор») является договором-офертой между Обществом с ограниченной
				ответственностью «ПрофитЭдс» (далее по тексту – «Лицензиар») и физическим лицом, достигшим возраста
				восемнадцати лет, имеющим гражданство Российской Федерации, полностью дееспособным в соответствии с
				законодательством Российской Федерации (далее по тексту – «Лицензиат»), которое принимает настоящее
				предложение заключить настоящий Договор на изложенных в нем условиях. </p>

			<h2 class="subtitle">ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>

			<p class="text"><b>Стороны</b> – стороны настоящего Договора: Лицензиат и Лицензиар.
				<br>
				<b>Контекстная реклама</b>
				– реклама, показ которой осуществляется на веб-страницах по ключевым запросам Лицензиата.
				<br>
				<b>Таргетированная реклама</b>
				– реклама, показ которой осуществляется на веб-страницах и в мобильных приложениях для аудитории, выбранной
				по таргетингам Лицензиата.
				<br>
				<b>Материалы</b>
				– текстовые объявления, баннеры и т.д., которые размещает Лицензиат в Системах контекстной/таргетированной
				рекламы. В настоящем Договоре понятие «Материалы» означает Материалы, размещаемые Лицензиатом посредством
				аккаунтов Лицензиата в Системах контекстной/таргетированной рекламы, привязанных Лицензиатом к Рекламным
				аккаунтам в Бизнес-аккаунте в Сервисе ProfitAds.
				<br>
				<b>Отчетный период</b>
				– 1 (Один) календарный месяц.
				<br>
				<b>Системы контекстной/таргетированной рекламы</b>
				(по отдельности – «Система
				контекстной/таргетированной рекламы) – Система MyTarget, Система ВКонтакте, Система VK
				Реклама, Система Яндекс.Директ, Система Google Ads, Рекламная платформа Telegram.
				<br>
				<b>Система MyTarget</b>
				– система таргетированной рекламы, рекламные объявления которой размещаются в социальных сетях Одноклассники
				и ВКонтакте, а также в мобильных и десктопных сервисах и контентных проектах экосистемы VK, в рекламной сети
				MyTarget и в сети Nativeroll.
				<br>
				<b>Система ВКонтакте</b>
				– система таргетированной рекламы, рекламные объявления которой размещаются на сайтах vk.com, а также в
				мобильных приложениях ВКонтакте.
				<br>
				<b>Система VK Реклама</b>
				– система таргетированной рекламы, рекламные объявления которой размещаются в социальной сети ВКонтакте, а
				также в мобильных и десктопных сервисах и контентных проектах экосистемы VK.
				<br>
				<b>Система Яндекс.Директ</b>
				- система контекстной рекламы, рекламные объявления которой размещаются на поисковых ресурсах Яндекс и в
				рекламной сети Яндекс.
				<br>
				<b>Система Google Ads</b>
				- система контекстной рекламы, рекламные объявления которой размещаются на поисковых ресурсах Google и в
				контекстно-медийной сети Google.
				<br>
				<b>Рекламная платформа Telegram</b>– система таргетированной рекламы, рекламные объявления
				которой размещаются в мессенджере Telegram.
				<b>Правила Систем контекстной/таргетированной рекламы</b>
				(по отдельности – «Система
				контекстной/таргетированной рекламы) – правила, расположенные на сайтах:
				<a href="https://vk.com/ads?act=office_help&terms"
					target="_blank">https://vk.com/ads?act=office_help&terms</a>
				(Система ВКонтакте),
				<a href="https://ads.vk.com/help/articles/moderation"
					target="_blank">https://ads.vk.com/help/articles/moderation</a>
				(Система VK Реклама),
				<a href="https://ads.vk.com/documents/direct_adv_rules"
					target="_blank">https://ads.vk.com/documents/direct_adv_rules</a>
				(Система VK Реклама),
				<a href="https://target.my.com/adv/help/rules/" target="_blank">https://target.my.com/adv/help/rules/</a>
				(Система MyTarget),
				<a href="https://help.mail.ru/feed/advert" target="_blank">https://help.mail.ru/feed/advert</a>
				(Правила сервиса Пульс, размещение Материалов в котором осуществляется Лицензиатом посредством Системы
				MyTarget),
				<a href="https://yandex.ru/legal/adv_rules/index.html"
					target="_blank">https://yandex.ru/legal/adv_rules/index.html</a>
				(Система Яндекс.Директ),
				<a href="https://support.google.com/adspolicy/ " target="_blank">https://support.google.com/adspolicy/</a>
				(Система Google Ads); а также Условия использования
				Рекламной платформы Telegram.
				<br>
				<b>Условия использования Рекламной платформы Telegram</b> – правила использования Рекламной
				платформы Telegram, расположенные на сайтах: <a href="https://promote.telegram.org/guidelines"
					target="_blank">https://promote.telegram.org/guidelines</a>,
				<a href="https://telegram.org/privacy" target="_blank">https://telegram.org/privacy</a>
				,
				<a href="https://telegram.org/tos" target="_blank">https://telegram.org/tos</a>
				, <a href="https://promote.telegram.org/tos." target="_blank">https://promote.telegram.org/tos.</a>
				<b>Сайт</b>
				– сайт, расположенный в сети Интернет по адресу
				<a href="https://profitads.ru/" target="_blank">https://profitads.ru/</a>являющийся совокупностью
				размещенных в сети Интернет web-страниц, управляемых посредством программного обеспечения администрации
				Сайта, объединенных единым адресным пространством. На Сайте предоставляется доступ к Сервису ProfitAds
				<br>
				Стартовая страница Сайта, посредством которой может быть осуществлен доступ ко всем web-страницам Сайта и к
				Сервису ProfitAds, расположена в сети Интернет по адресу <a href="https://profitads.ru/"
					target="_blank">https://profitads.ru/</a>
				<br>
				<b>Сервис ProfitAds</b>
				– программно-аппаратный комплекс, принадлежащий Лицензиару, расположенный в сети Интернет по адресу:
				<a href="https://profitads.ru/" target="_blank">https://profitads.ru/</a>
				, предоставляющий Лицензиату возможности, указанные в Разделе 3 настоящего Договора. Представляет собой
				совокупность информации, текстов, графических элементов, дизайна, изображений, фото и видеоматериалов
				(аудиовизуальных произведений) и иных объектов интеллектуальной собственности, а также программ для ЭВМ,
				содержащихся в информационной системе, обеспечивающей доступность такой информации в сети Интернет. Все
				действия по настоящему Договору совершаются с использованием функционала Сервиса ProfitAds - проекта,
				осуществляющего деятельность в области информационных технологий в части разработки программного
				обеспечения, получившего статус IT-компании в соответствии с требованиями законодательства Российской
				Федерации.
				<br>
				<b>Срок</b>
				– разрешенный период использования Сервиса ProfitAds в соответствии с условиями Договора. Если иное не
				предусмотрено приложениями или дополнительными соглашениями к Договору, право использования Сервиса
				ProfitAds предоставляется на весь срок действия настоящего Договора.
				<br>
				<b>Территория</b>
				– разрешенная территория использования Сервиса ProfitAds, включающая территорию следующих стран: весь мир.
				<br>
				<b>Пользовательский интерфейс Сервиса ProfitAds</b>
				– интерфейс доступа к возможностям Сервиса ProfitAds, доступный после регистрации Бизнес-аккаунта в
				соответствии с Разделом 3 Договора.
				<br>
				<b>Личный кабинет</b>
				– учетная запись в Сервисе ProfitAds. Порядок использования Личного кабинета регулируется настоящим
				Договором и Пользовательским соглашением, расположенным в сети Интернет по адресу:
				<a href="https://lk.profitads.ru/agreement/" target="_blank">https://lk.profitads.ru/agreement/</a>.
				<br>
				<b>Бизнес-аккаунт</b>
				– аккаунт, зарегистрированный Лицензиатом в Личном кабинете.
				<br>
				<b>Баланс Бизнес-аккаунта</b>
				– счет Лицензиата, пополняемый Лицензиатом в Бизнес-аккаунте.
				<br>
				<b>Рекламный аккаунт</b>
				– аккаунт, зарегистрированный Лицензиатом в Бизнес-аккаунте, соответствующий аккаунту Лицензиата в Системе
				контекстной/таргетированной рекламы. В настоящем Договоре понятие «Рекламный аккаунт» соответствует аккаунту
				Лицензиата в Системе контекстной/таргетированной рекламы, привязанному Лицензиатом к соответствующему
				Рекламному аккаунту.
				<br>
				<b>Баланс Рекламного аккаунта</b>
				– баланс денежных средств Лицензиата на Рекламном аккаунте, равный сумме денежных средств на аккаунте в
				Системе контекстной/таргетированной рекламы, привязанном Лицензиатом к соответствующему Рекламному аккаунту.
				<br>
				<b>Неактивный клиент</b>
				– Лицензиат, который в течение 180 (Ста восьмидесяти) календарных дней не расходует денежные средства на
				Балансе Бизнес-аккаунта.
			</p>
			<ul class="list">
				<li>
					<h3 class="subtitle subtitle--blue">1. АКЦЕПТ ДОГОВОРА</h3>
					<ul class="list">
						<li>1.1. Условия настоящего Договора считаются акцептованными, при условии выполнения Лицензиатом
							совокупности следующих действий:
							<ul class="list">
								<li>1.1.1. регистрация Лицензиатом Бизнес-аккаунта в Сервисе ProfitAds путем заполнения
									анкеты на Сайте; </li>
								<li>1.1.2. пополнение Лицензиатом Баланса Бизнес-аккаунта на условиях и в порядке,
									предусмотренных Разделом 5 настоящего Договора.</li>
							</ul>
						</li>
						<li>1.2. Акцепт Договора будет означать, что Лицензиатом безоговорочно приняты все условия Договора,
							а также все дополнительные условия сотрудничества, размещенные в сети Интернет по адресу:
							<a href="https://lk.profitads.ru/dogovor-oferty/"
								target="_blank">https://lk.profitads.ru/dogovor-oferty/</a>
							.
						</li>
						<li>1.3. В случае внесения Лицензиаром изменений в условия Договора, новая редакция Договора
							подлежит опубликованию в сети Интернет по адресу:
							<a href="https://lk.profitads.ru/dogovor-oferty/"
								target="_blank">https://lk.profitads.ru/dogovor-oferty/</a>
							и вступает в силу с момента такого опубликования. Лицензиар уведомляет Лицензиата о внесении
							изменений в условия Договора путем направления соответствующей информации на электронный адрес
							Лицензиата, указанный Лицензиатом в Бизнес-аккаунте, в день опубликования новой редакции
							Договора. В случае несогласия Лицензиата с изменением условий Договора, Лицензиат вправе
							расторгнуть Договор с предварительным письменным уведомлением Лицензиара не менее чем за 30
							(Тридцать) календарных дней до предполагаемой даты расторжения путем направления
							соответствующего уведомления на адрес места нахождения Лицензиара, указанный в Разделе 12
							настоящего Договора, с одновременным направлением скан-копии такого уведомления на электронный
							адрес Лицензиара, указанный в разделе 12 настоящего Договора. Договор считается расторгнутым по
							истечении 30 (Тридцати) календарных дней с даты получения Лицензиаром скан-копии уведомления о
							расторжении Договора по электронной почте. В случае, если Лицензиат продолжает использовать
							возможности Сервиса ProfitAds после опубликования новой редакции Договора, Лицензиат
							соглашается, что использование Сервиса ProfitAds осуществляется на условиях новой редакции
							Договора и всех последующих редакций, опубликованных в соответствующий период.
						</li>
						<li>1.4. Принимая предложение о заключении настоящего Договора, Лицензиат на весь период действия
							Договора дает согласие на направление информационных писем/уведомлений/предложений на адрес
							электронной почты, указанный Лицензиатом в Бизнес-аккаунте, а также дает согласие на
							использование иных контактных данных, предоставленных Лицензиатом при регистрации в Сервисе
							ProfitAds и при использовании Бизнес-аккаунта, на условиях, указанных в Политике
							конфиденциальности и обработки персональных данных, размещенной в сети Интернет по адресу:
							<a href="https://profitads.ru/privacy-policy/"
								target="_blank">https://profitads.ru/privacy-policy/</a>
							.
						</li>
						<li>1.5. Акцепт Договора на иных, нежели предусмотрено настоящим Договором условиях, не является
							акцептом Договора и не влечет признание настоящего Договора заключенным. </li>
						<li>1.6. Лицензиар предоставляет доступ к Сервису ProfitAds по настоящему Договору исключительно
							после акцепта условий настоящего Договора.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">2. ПРЕДМЕТ ДОГОВОРА</h3>
					<ul class="list">
						<li>
							2.1. Лицензиар обязуется предоставлять Лицензиату за вознаграждение на условиях простой
							(неисключительной) лицензии права использования Сервиса ProfitAds, а именно: предоставить доступ
							и право пользования Бизнес-аккаунтом для целей пополнения аккаунтов в Системах
							контекстной/таргетированной рекламы, привязанных к Рекламным аккаунтам в Бизнес-аккаунте, а
							Лицензиат обязуется уплачивать лицензионное вознаграждение.
						</li>
						<li>
							2.2. В рамках Договора Лицензиар предоставляет Лицензиату за вознаграждение на условиях простой
							(неисключительной) лицензии права использования Сервиса ProfitAds, а Лицензиат обязуется
							уплачивать лицензионное вознаграждение.
						</li>
						<li>
							2.3. Указанные в настоящем разделе права предоставляются исключительно для использования в
							пределах Территории и Срока в объеме и на условиях, предусмотренных настоящим Договором.
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">3. РЕГИСТРАЦИЯ БИЗНЕС-АККАУНТА. ВОЗМОЖНОСТИ СЕРВИСА PROFITADS</h3>
					<ul class="list">
						<li>3.1. Для использования возможностей Сервиса ProfitAds Лицензиат регистрирует Бизнес-аккаунт на
							Сайте. Регистрация Бизнес-аккаунта возможна после авторизации в Личном кабинете. Бизнес-аккаунт
							регистрируется на одно физическое лицо, достигшее возраста восемнадцати лет, имеющее гражданство
							Российской Федерации, полностью дееспособное в соответствии с законодательством Российской
							Федерации
							Нажимая кнопку «Сохранить» для создания Бизнес-аккаунта Лицензиат подтверждает достоверность
							сведений, указанных при создании Бизнес-аккаунта, и соглашается с условиями настоящего Договора
							и Политикой конфиденциальности и обработки персональных данных, размещенной в сети Интернет по
							адресу:
							<a href="https://profitads.ru/privacy-policy/"
								target="_blank">https://profitads.ru/privacy-policy/</a>
							.
						</li>
						<li>3.2. По завершении регистрации Бизнес-аккаунта в Сервисе ProfitAds в соответствии с п. 3.1.
							Договора Лицензиат вправе использовать следующие возможности Сервиса ProfitAds:
							<ul class="list">
								<li>3.2.1. пополнение Баланса Бизнес-аккаунта;</li>
								<li>3.2.2. создание Рекламных аккаунтов внутри Бизнес-аккаунта путем привязки аккаунтов
									Лицензиата в Системах контекстной/таргетированной рекламы к Рекламным аккаунтам. Один
									Рекламный аккаунт соответствует одному аккаунту в Системе контекстной/таргетированной
									рекламы. В Рекламном аккаунте отображается следующая информация:
									<br>- информация о расходах денежных средств на Рекламном аккаунте за предыдущий
									календарный день;
									<br>- информация о размере денежных средств на Рекламном аккаунте;
									<br>- информация о статистике расходования денежных средств во всех Рекламных аккаунтах
									Лицензиата в каждой Системе контекстной/таргетированной рекламы за конкретный период
									(день/неделя/месяц);
									<br>- информация о пополнении Рекламного аккаунта;
								</li>
								<li>3.2.3. пополнение Баланса Рекламного аккаунта путем перевода денежных средств с Баланса
									Бизнес-аккаунта на Баланс Рекламного аккаунта;</li>
								<li>3.2.4. изменение лимитов рекламного бюджета рекламных кампаний в Рекламном аккаунте;
								</li>
								<li>3.2.5. создание обращений в техническую поддержку Сервиса ProfitAds;</li>
								<li>3.2.6. иные возможности, доступные в Пользовательском интерфейсе Сервиса ProfitAds.</li>
							</ul>
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>
					<ul class="list">
						<li><b>Лицензиат обязан:</b>
							<ul class="list">
								<li>4.1.1. Оплатить лицензионное вознаграждение в порядке, указанном в настоящем Договоре.
								</li>
								<li>4.1.2. в течение 5 (Пяти) рабочих дней с момента направления Лицензиаром Универсального
									передаточного документа на адрес электронной почты, указанный Лицензиатом в
									Бизнес-аккаунте, или с момента размещения Лицензиаром Универсального передаточного
									документа в Личном кабинете Лицензиата, направить Лицензиару на адрес электронной почты
									Лицензиара finance@profitads.ru возражения по предоставленному Лицензиаром
									Универсальному передаточному документу. В случае ненаправления возражений в течение
									указанного срока, доступ к сервису ProfitAds по настоящему Договору считается
									предоставленным в полном объеме.</li>
								<li>4.1.3. Нести полную ответственность за содержание Материалов и обязуется при размещении
									Материалов с использованием Систем контекстной/таргетированной рекламы соблюдать
									требования, установленные законодательством Российской Федерации и/или иной страны, на
									территории которой распространяются (таргетируются) соответствующие Материалы, и
									Правилами Систем контекстной/таргетированной рекламы.</li>
								<li>4.1.4. В случае возникновения любых претензий, исков, требований третьих лиц по поводу
									нарушения их прав и интересов, законодательства Российской Федерации или иной страны, на
									территории которой распространяются (таргетируются) соответствующие Материалы, при
									размещении Материалов в Системах контекстной/таргетированной рекламы (в том числе таких,
									которые будут предъявлены Лицензиару), урегулировать указанные вопросы от собственного
									имени, собственными силами и за собственный счет, а также возместить Лицензиару убытки,
									понесенные им в связи с возникновением таких претензий, исков, требований.</li>
								<li>4.1.5. Не предоставлять третьим лицам доступ в Бизнес-аккаунт. Лицензиат несет
									ответственность за сохранность данных для доступа в Бизнес-аккаунт и самостоятельно
									несет ответственность за любые убытки, связанные с несанкционированным доступом в
									Бизнес-аккаунт третьими лицами. Лицензиат гарантирует, что все действия, совершенные в
									Бизнес-аккаунте, считаются совершенными Лицензиатом, а также гарантирует, что сообщения,
									направленные с адреса электронной почты, указанного Лицензиатом в Бизнес-аккаунте,
									считаются отправленными Лицензиатом и Лицензиат несет ответственность за действия
									третьих лиц, совершенные с использованием указанного адреса электронной почты.</li>
								<li>4.1.6. Самостоятельно ознакомиться с Правилами Систем контекстной/таргетированной
									рекламы и самостоятельно отслеживать изменения в них.</li>
								<li>4.1.7. Указать достоверные, точные и полные данные Лицензиата при регистрации
									Бизнес-аккаунта. Лицензиат обязуется поддерживать актуальность и достоверность данных,
									представленных в Бизнес-аккаунте, и, в случае необходимости, незамедлительно вносить
									изменения в них. </li>
							</ul>
						</li>
						<li><b>4.2. Лицензиат вправе:</b>
							<ul class="list">
								<li>4.2.1. Использовать возможности Сервиса ProfitAds, указанные в Разделе 3 настоящего
									Договора.</li>
								<li>4.2.2. При наличии замечаний направить Лицензиару письменную мотивированную претензию в
									течение 5 (пяти) рабочих дней с даты выявления замечаний или обратиться за защитой своих
									прав в суд. Бремя доказывания распределяется между Сторонами в соответствии с Законом РФ
									от 07.02.1992 N 2300-1 «О защите прав потребителей» и действующим законодательством
									Российской Федерации.</li>
								<li>4.2.3. Получать информацию о параметрах Сервиса ProfitAds по контактным данным
									Лицензиара, указанным в Разделе 12 настоящего Договора.</li>
							</ul>
						</li>
						<li><b>4.3. Лицензиар обязан:</b>
							<ul class="list">
								<li>4.3.1. Предоставить Лицензиату доступ к Сервису ProfitAds на условиях, определенных
									Сторонами в Договоре. </li>
								<li>4.3.2. По окончании каждого Отчетного периода, в котором Лицензиату предоставлялся
									доступ к
									Сервису ProfitAds по настоящему Договору, направлять Лицензиату на адрес электронной
									почты,
									указанный Лицензиатом в Бизнес-аккаунте, или размещать в Личном кабинете Лицензиата
									Универсальный передаточный документ с указанием денежных средств, израсходованных в
									Рекламных аккаунтах за Отчетный период, а в случае, если в соответствующем Отчетном
									периоде
									Лицензиат осуществлял пополнение Рекламного аккаунта Системы Google Ads и/или Рекламной
									платформы Telegram, – денежных средств, израсходованных Лицензиатом в Рекламных
									аккаунтах
									Системы Google Ads и/или Рекламной платформы Telegram за Отчетный период, и денежных
									средств в размере комиссии за зачисление денежных средств на Рекламный аккаунт Системы
									Google Ads и/или Рекламной платформы Telegram, взимаемой Лицензиаром в соответствии с
									пп.
									5.4.2., 5.5.2. настоящего Договора.
									<br>Стороны особо оговорили, что денежные средства, израсходованные в Рекламных
									аккаунтах
									системы VK Реклама за Отчетный период, указываются в Универсальных передаточных
									документах как денежные средства, израсходованные в Рекламных аккаунтах Системы
									MyTarget.
									4.3.3. Оказывать техническую поддержку Лицензиату по вопросам, связанным с
									использованием
									возможностей Сервиса ProfitAds, по обращениям Лицензиата, формируемым Лицензиатом в
									Пользовательском интерфейсе Сервиса ProfitAds или направленным посредством электронной
								</li>
								<li>4.3.3. Оказывать техническую поддержку Лицензиату по вопросам, связанным с
									использованием возможностей Сервиса ProfitAds, по обращениям Лицензиата, формируемым
									Лицензиатом в Пользовательском интерфейсе Сервиса ProfitAds или направленным посредством
									электронной почты Лицензиара, указанный в Разделе 12 настоящего Договора. Часы работы
									технической поддержки указываются на Сайте. Срок ответа на обращение Лицензиата – 24
									(Двадцать четыре) часа с момента формирования обращения.</li>
							</ul>
						</li>
						<li><b>4.4. Лицензиар вправе:</b>
							<ul class="list">
								<li>4.4.1. Прекратить предоставление доступа к Сервису ProfitAds, а также отказаться от
									исполнения
									Договора и потребовать возмещения затрат и убытков в случае невыполнения Лицензиатом
									своих
									обязательств по настоящему Договору, в том числе при просрочке оплаты Лицензиатом
									лицензионного вознаграждения по настоящему Договору, в случае несоблюдения Лицензиатом
									Правил Систем контекстной/таргетированной рекламы при размещении Материалов с
									использованием Сервиса ProfitAds. Лицензиар уведомляет Лицензиата об указанных
									обстоятельствах путем направления сообщения по электронной почте, указанной Лицензиатом
									в
									Бизнес-аккаунте.</li>
								<li>4.4.2. Прекратить доступ Лицензиата к Бизнес-аккаунту в следующих случаях:
									<br>• В случае нарушения Лицензиатом Правил Систем контекстной/таргетированной рекламы
									при размещении Лицензиатом Материалов;
									<br>• В случае противоречия Материалов законодательству Российской Федерации и/или иной
									страны, на территории которой распространяются (таргетируются) Материалы;
									<br>• В случае предъявления претензий/требований/исков к Лицензиару в отношении
									содержания Материалов со стороны третьих лиц или государственных органов;
									<br>• В случае неисполнения Лицензиатом своих обязательств, предусмотренных настоящим
									Договором;
									<br>• В случае нарушения Лицензиатом гарантий и заверении, предусмотренных настоящим
									Договором;
									<br>• В случае предоставления ложной, неполной информации в Бизнес-аккаунте;
									<br>• В случае, если Лицензиат признан Лицензиаром Неактивным клиентом;
									<br>• В случае несоблюдения Лицензиатом Правил Систем контекстной и/или таргетированной
									рекламы при размещении Материалов с использованием Сервиса ProfitAds.
								</li>
								<li>4.4.3. Не предоставлять Лицензиату Отчет об использовании Сервиса ProfitAds. Данные о
									расходовании денежных средств в Бизнес-аккаунте доступны Лицензиату в Рекламных
									аккаунтах.</li>
								<li>4.4.4. Производить профилактические и технические работы на сервере. При проведении
									подобных работ Сервис ProfitAds может быть недоступен.</li>
								<li>4.4.5. Без согласования с Лицензиатом производить обновления Сервиса ProfitAds, а также
									выполнять работы по его оптимизации.</li>
								<li>4.4.6. Вносить изменения в условия настоящего Договора в соответствии с п. 1.3.
									настоящего Договора.</li>
							</ul>
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">5. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</h3>
					<ul class="list">
						<li>5.1. За предоставляемые по настоящему Договору права использования Сервиса ProfitAds
							Лицензиат обязан уплачивать Лицензиару лицензионное вознаграждение в размере, определяемом
							согласно положениям настоящего Раздела Договора в зависимости от состава и объема
							фактического использования функционала Сервиса ProfitAds, которое представляет собой суммы
							денежных средств, израсходованных Лицензиатом в Рекламных аккаунтах за Отчетный период, а в
							случае, если в соответствующем Отчетном периоде Лицензиат осуществлял пополнение
							Рекламного аккаунта Системы Google Ads и/или Рекламной платформы Telegram, – суммы
							денежных средств, израсходованных Лицензиатом в Рекламных аккаунтах Системы Google Ads
							и/или Рекламной платформы Telegram за Отчетный период, и сумму денежных средств в размере
							комиссии за зачисление денежных средств на Рекламный аккаунт Системы Google Ads и/или
							Рекламной платформы Telegram, взимаемой Лицензиаром в соответствии с пп. 5.4.2., 5.5.2.
							настоящего Договора. Размер денежных средств, израсходованных в Рекламных аккаунтах,
							соответствует рекламному бюджету, израсходованному Лицензиатом в аккаунтах в Системах
							контекстной/таргетированной рекламы, привязанных Лицензиатом к Рекламным аккаунтам.
							Лицензионное вознаграждение облагается налогом на добавленную стоимость (НДС) по ставке,
							установленной действующим законодательством Российской Федерации.
						</li>
						<li>5.2. Оплата лицензионного вознаграждения осуществляется в безналичной форме. Стороны установили,
							что Лицензиар не имеет расчетно-кассового оборудования, позволяющего осуществлять прием наличных
							денежных средств и ввиду этого осуществляет прием платежей в безналичной форме, что не является
							ограничением прав Лицензиата в соответствии с законом РФ от 07.02.1992 N 2300-1 «О защите прав
							потребителей».</li>
						<li>5.3. Оплата лицензионного вознаграждения Лицензиатом осуществляется в рублях РФ путем внесения
							Лицензиатом авансового платежа (предварительной оплаты) в размере не менее 10 000 (Десяти тысяч)
							рублей с учетом НДС по ставке, установленной действующим законодательством Российской Федерации,
							путем перечисления денежных средств посредством оплаты банковской картой через платежную
							платформу, куда Лицензиат направляется Сервисом ProfitAds после предварительного указания своих
							фамилии, имени, отчества, электронной почты и телефона (с кодом российского оператора) и
							выражения согласия с настоящим Договором. В течение 1 (Одного) рабочего дня после поступления
							оплаченных Лицензиатом денежных средств на расчетный счет Лицензиара Лицензиар зачисляет на
							Баланс Бизнес-аккаунта Лицензиата сумму денежных средств, оплаченную Лицензиатом, за вычетом
							технического сбора, размер которого составляет 2,85% (Две целых восемьдесят пять процентов) от
							суммы денежных средств, оплаченных Лицензиатом.
							<br> Лицензиар обязуется направлять Лицензиату чек с указанием денежных средств, оплаченных
							Лицензиатом.
							<br>Стороны соглашаются, что в случае поступления нескольких авансовых платежей (предварительных
							оплат) в счет оплаты лицензионного вознаграждения по настоящему Договору, зачет таких
							предварительных оплат осуществляется в хронологическом порядке, а их сумма за определенный
							Отчетный период представляет собой лицензионное вознаграждение.
						</li>
						<li>5.4. Стороны особо оговорили следующее в случае использования Лицензиатом оплаченного
							лицензионного вознаграждения для пополнения Рекламного аккаунта Системы Google Ads:
							<ul class="list">
								<li>5.4.1 Соответствующая сумма денежных средств пересчитывается из рублей РФ в доллары США
									по курсу, определяемому Лицензиаром, поскольку зачисление денежных средств в Рекламный
									аккаунт Системы Google Ads осуществляется в долларах США. Информация о сумме денежных
									средств, подлежащей зачислению Лицензиаром на соответствующий Рекламный аккаунт Системы
									Google Ads, доступна Лицензиату до осуществления им пополнения соответствующего
									Рекламного аккаунта Системы Google Ads;</li>
								<li>5.4.2 Лицензиар вправе взимать комиссию за зачисление денежных средств на Рекламный
									аккаунт Системы Google Ads (далее – «комиссия за зачисление»), рассчитываемую в виде
									процента от рекламного бюджета, на сумму которого пополняется Рекламный аккаунт Системы
									Google Ads. Соответствующий процент определяется Лицензиаром. Информация о
									соответствующем проценте доступна Лицензиату до осуществления им пополнения
									соответствующего Рекламного аккаунта Системы Google Ads;</li>
								<li>5.4.3 В Универсальном передаточном документе за Отчетный период сумма денежных средств,
									израсходованных в Рекламном аккаунте Системы Google Ads, указывается в рублях РФ по
									курсу, указанному в п. 5.4.1. настоящего Договора. Комиссия за зачисление за Отчетный
									период рассчитывается в соответствии с п. 5.4.2. настоящего Договора.</li>
							</ul>
						</li>
						<li>5.5. Стороны особо оговорили следующее в случае использования Лицензиатом оплаченного
							лицензионного вознаграждения для пополнения Рекламного аккаунта Рекламной платформы
							Telegram:
							<ul class="list">
								<li>5.5.1. Соответствующая сумма денежных средств пересчитывается из рублей РФ в евро по
									курсу,
									определяемому Лицензиаром, поскольку зачисление денежных средств в Рекламный аккаунт
									Рекламной платформы Telegram осуществляется в евро. Информация о сумме денежных средств,
									подлежащей зачислению Лицензиаром на соответствующий Рекламный аккаунт Рекламной
									платформы Telegram, доступна Лицензиату до осуществления им пополнения соответствующего
									Рекламного аккаунта Рекламной платформы Telegram;</li>
								<li>Лицензиар вправе взимать комиссию за зачисление денежных средств на Рекламный аккаунт
									Рекламной платформы Telegram (далее – «комиссия за зачисление»), рассчитываемую в виде
									процента от рекламного бюджета, на сумму которого пополняется Рекламный аккаунт
									Рекламной
									платформы Telegram. Соответствующий процент определяется Лицензиаром. Информация о
									соответствующем проценте доступна Лицензиату до осуществления им пополнения
									соответствующего Рекламного аккаунта Рекламной платформы Telegram;</li>
								<li>В Универсальном передаточном документе за Отчетный период сумма денежных средств,
									израсходованных в Рекламном аккаунте Рекламной платформы Telegram, указывается в рублях
									РФ
									по курсу, указанному в п. 5.5.1. настоящего Договора. Комиссия за зачисление за Отчетный
									период
									рассчитывается в соответствии с п. 5.5.2. настоящего Договора.</li>
							</ul>
						</li>
						<li>5.6. Обязательства Лицензиата по оплате лицензионного вознаграждения считаются исполненными
							с момента зачисления денежных средств на расчетный счет Лицензиара.</li>
						<li>5.7. При недостаточности суммы авансового платежа (недостаточности денежных средств на
							Балансе Рекламного аккаунта) Лицензиату недоступны возможности, указанные в пп. 3.2.3., 3.2.4.
							настоящего Договора.</li>
						<li>5.8. Возврат денежных средств возможен по письменному заявлению Лицензиата об отказе от
							использования Сервиса ProfitAds.
							При отказе Лицензиата от использования Сервиса ProfitAds, Лицензиар возвращает Лицензиату
							уплаченные денежные средства по письменному заявлению Лицензиата, направленному в виде
							скана на электронную почту Лицензиара finance@profitads.ru. Возврат денежных средств
							осуществляется на банковскую карту Лицензиата, с которой была произведена оплата. Возврату
							подлежит исключительно вся сумма денежных средств, имеющаяся на Балансе Бизнес-аккаунта,
							частичный возврат денежных средств Лицензиаром не осуществляется. Срок возврата денежных
							средств зависит от банка, обслуживающего Лицензиата. При этом в случае, если в Отчетном
							периоде, в котором Лицензиат направил указанное в настоящем пункте письменное заявление,
							Лицензиат размещал/размещает Материалы в Системах контекстной/таргетированной рекламы с
							использованием сервиса ProfitAds, возврат денежных средств осуществляется Лицензиаром по
							истечении соответствующего Отчетного периода, поскольку Лицензиар получает данные о
							рекламном бюджете, фактически израсходованном Системах контекстной/таргетированной
							рекламы за Отчетный период, по окончании соответствующего Отчетного периода. Лицензиат
							уведомлен о том, что банк и платежная платформа могут взымать свою комиссию с суммы возврата
							денежных средств за выполнение операции, все суммы комиссий банка и платежной платформы
							оплачиваются Лицензиатом самостоятельно и не компенсируются Лицензиаром.
						</li>
						<li>5.9. Стороны безоговорочно принимают и соглашаются на использование данных Сервиса ProfitAds
							для установления состава и объема фактического использования функционала Сервиса ProfitAds
							Лицензиатом в целях расчета лицензионного вознаграждения. Таким образом, в качестве
							единственного достоверного источника для установления состава и объема фактического
							использования функционала Сервиса ProfitAds Лицензиатом, в целях расчета лицензионного
							вознаграждения, Стороны признают данные (сведения) самого Сервиса ProfitAds.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">6. ОТВЕТСТВЕННОСТЬ СТОРОН. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</h3>
					<ul class="list">
						<li>6.1. За нарушение условий настоящего Договора Стороны несут ответственность, предусмотренную
							действующим законодательством Российской Федерации и настоящим Договором.</li>
						<li>6.2. В случае несвоевременного исполнения Лицензиатом обязательств по оплате лицензионного
							вознаграждения, Лицензиар вправе взыскать с Лицензиата неустойку в размере 0,2% от неоплаченной
							в срок суммы за каждый день просрочки. На авансовые платежи неустойка за просрочку оплаты не
							начисляется.</li>
						<li>6.3. В случае, если в результате нарушения Лицензиатом Правил Систем контекстной/таргетированной
							рекламы аккаунт Лицензиата в Системе контекстной/таргетированной рекламы, привязанный к
							Рекламному аккаунту, будет заблокирован, Лицензиат обязан уплатить Лицензиару неустойку в
							размере 10% (десять процентов) от общей фактической суммы выплаченных по Договору лицензионных
							вознаграждений на дату блокировки аккаунта.</li>
						<li>6.4. В случае нарушения Лицензиатом Правил Систем контекстной/таргетированной рекламы, Лицензиат
							обязуется возместить Лицензиару убытки, понесенные Лицензиаром в результате таких нарушений. В
							случае совершения Лицензиатом указанных нарушений, Лицензиар вправе расторгнуть настоящий
							Договор в одностороннем порядке без применения каких-либо санкций со стороны Лицензиата. При
							этом Договор считается расторгнутым с даты, указанной Лицензиаром в уведомлении о расторжении.
						</li>
						<li>6.5. Лицензиат несет полную ответственность за сохранность данных для доступа к Бизнес-аккаунту,
							и за убытки, которые могут возникнуть по причине несанкционированного использования
							соответствующих данных. Лицензиар не несет ответственности и не возмещает убытки, возникшие по
							причине несанкционированного доступа третьих лиц к Бизнес-аккаунту.</li>
						<li>6.6. Лицензиар не несет ответственности:
							<br>• за какие-либо косвенные/непрямые убытки и/или упущенную выгоду Лицензиата, иных третьих
							лиц вне зависимости от того, мог ли Лицензиар предвидеть возможность причинения таких убытков в
							конкретной ситуации или нет;
							<br>• за содержание Материалов;
							<br>• в случае неправомерного доступа к Бизнес-аккаунту лицом или лицами, не являющимися
							Сторонами по настоящему Договору, если такое деяние повлекло уничтожение, блокирование,
							модификацию, копирование информации;
							<br>• за недостижение прогнозируемых Лицензиатом показателей и результатов размещения Материалов
							с использованием аккаунтов Лицензиата в Системах контекстной/таргетированной рекламы,
							привязанных к Рекламному аккаунту;
							<br>• за неразмещение Материалов Системами контекстной/таргетированной рекламы.
						</li>
						<li>6.7. Совокупная ответственность Лицензиара по Договору ограничивается возмещением Лицензиату
							прямого документально подтвержденного реального ущерба в размере, не превышающем суммы
							фактически уплаченных Лицензиатом лицензионных вознаграждений по Договору на момент причинения
							ущерба. Упущенная выгода не подлежит возмещению Лицензиаром.</li>
						<li>6.8. Лицензиат самостоятельно несет полную ответственность:
							<br>• за возможные нарушения интеллектуальных прав третьих лиц при размещении Материалов;
							<br>• за соблюдение норм законодательства Российской Федерации и/или иной страны, на территории
							которой распространяются (таргетируются) соответствующие Материалы, связанных с фактом
							размещения Материалов;
							<br>• за наличие всех необходимых в соответствии с действующим законодательством Российской
							Федерации лицензий, сертификатов, разрешений и согласований государственных органов для занятия
							видом деятельности, рекламируемым в Материалах, реализации товаров, рекламируемых в Материалах,
							а также на размещение в Материалах рекламы товаров, работ, услуг.
						</li>
						<li>6.9. В случае блокировки аккаунта Лицензиата в Системе контекстной/таргетированной рекламы,
							привязанного Лицензиатом к Рекламному аккаунту соответствующей Системы
							контекстной/таргетированной рекламы, по вине Лицензиата, в том числе по причине нарушения
							Лицензиатом Правил соответствующей Системы контекстной/таргетированной рекламы,
							Лицензиар вправе:
							<ul class="list">
								<li>Не возвращать Лицензиату остаток денежных средств на Балансе соответствующего
									Рекламного аккаунта, а также комиссию за зачисление денежных средств, предусмотренную
									пп. 5.4., 5.5. настоящего Договора;</li>
								<li>Лишить Лицензиата возможности создания нового Рекламного аккаунта в соответствующей
									Системе контекстной/таргетированной рекламы в Сервисе ProfitAds;</li>
								<li>Заблокировать Личный кабинет, в Бизнес-аккаунте которого зарегистрирован
									соответствующий заблокированный Рекламный аккаунт, навсегда.</li>
							</ul>
							Условия настоящего пункта Договора имеют приоритет над иными условиями Договора,
							регулирующими возврат денежных средств Лицензиату.
						</li>
						<li>6.10. Лицензиар не осуществляет проверку соблюдения Лицензиатом установленных каким-либо
							применимым законодательством, Правилами Систем контекстной/таргетированной рекламы
							требований, предъявляемых к Материалам, а равно не осуществляет проверку Материалов.</li>
						<li>6.11. В случае предъявления к Лицензиару претензий/требований со стороны государственных
							органов либо третьих лиц, связанных с размещением Материалов посредством аккаунтов в
							Системах контекстной/таргетированной рекламы, привязанных к Рекламным аккаунтам, Лицензиат
							обязан незамедлительно представить документы, а также иные доказательства, подтверждающие
							право Лицензиата на размещение Материалов, а также иные документы, подтверждающие
							соблюдение законодательства и прав третьих лиц. Настоящим Лицензиат подтверждает свое
							согласие на передачу Лицензиаром третьим лицам информации о Лицензиате в случае
							предъявления Лицензиару претензий, исков и/или предписаний со стороны третьих лиц,
							основанием для которых послужило размещение Материалов.</li>
						<li>6.12. В случае умышленного размещения Материалов, содержащих вредоносное программное
							обеспечение, а также любые иные программы-шпионы или вредоносные коды, а также товары и
							услуги, запрещенные к реализации или рекламированию законодательством Российской
							Федерации, Лицензиат по требованию Лицензиара обязуется возместить причиненные убытки.
						</li>
						<li>6.13. Лицензиату запрещается:
							<ul class="list">
								<li>копировать, либо иным способом использовать программную часть Сервиса ProfitAds, а
									также его дизайн;</li>
								<li>изменять каким бы то ни было способом программную часть Сервиса ProfitAds, совершать
									действия, направленные на изменение функционирования и работоспособности Сервиса
									ProfitAds;</li>
								<li>осуществлять модификацию Сервиса ProfitAds, в том числе изменять, декомпилировать,
									дизассемблировать, дешифровать и производить иные действия с кодом программного
									обеспечения;</li>
								<li>распространять, копировать или иным образом осуществлять обнародование кода
									программного обеспечения, являющегося частью Сервиса ProfitAds.</li>
							</ul>
						</li>
						<li>6.14. Лицензиат обязуется компенсировать Лицензиару все убытки, связанные с ненадлежащим
							исполнением настоящего Договора со стороны Лицензиата.</li>
						<li>6.15. Любые требования Лицензиара о возмещении Лицензиатом убытков, неустоек, иных
							требований, указанных в настоящем Договоре, подлежат исполнению Лицензиатом в течение 10
							(Десяти) рабочих дней с момента получения соответствующего письменного требования
							Лицензиара.</li>
						<li>
							6.16. Лицензиат уведомлен о том, что Сервис ProfitAds предоставляется Лицензиату на условиях
							«как есть», в связи с чем Лицензиату не представляются какие-либо гарантии, что Сервис ProfitAds
							будет соответствовать каким-либо ожиданиям и/или требованиям Лицензиата.
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">7. ОСОБЫЕ УСЛОВИЯ И ЗАВЕРЕНИЯ ОБ ОБСТОЯТЕЛЬСТВАХ</h3>
					<ul class="list">
						<li>7.1. Лицензиат в порядке ст. 431.2 ГК РФ дает Лицензиару следующие заверения об обстоятельствах:
							<ul class="list">
								<li>7.1.1. Лицензиат является физическим лицом, достигшим возраста восемнадцати лет,
									полностью дееспособным в соответствии с законодательством Российской Федерации, имеющим
									гражданство Российской Федерации;</li>
								<li>7.1.2. Лицензиат гарантирует, что он не имеет каких-либо ограничений (в том числе полных
									запретов) в соответствии с законодательством Российской Федерации на заключение и
									исполнение настоящего Договора;</li>
								<li>7.1.3. Вся информация, предоставленная Лицензиатом в связи с настоящим Договором,
									соответствует действительности, является полной и точной во всех отношениях;</li>
								<li>7.1.4. В отношении Лицензиата не начата процедура несостоятельности (банкротства);</li>
								<li>7.1.5. Не существует какого-либо положения любого существующего контракта, договора или
									иного документа, по которому Лицензиат вступает в противоречие с настоящим Договором или
									выполнением любого из его положений;</li>
							</ul>
						</li>
						<li>7.2. Настоящим Лицензиат гарантирует, что:
							<ul class="list">
								<li>7.2.1. является законным владельцем сайта, продвигаемого посредством размещения
									Материалов, и всех его составляющих, или продвижение сайта и его составляющих одобрено
									его владельцем;</li>
								<li>7.2.2. все товары (работы/услуги), продвигаемые посредством размещения Материалов,
									подлежащие обязательной сертификации и/или декларированию соответствия,
									сертифицированы/задекларированы;</li>
								<li>7.2.3. имеются все необходимые разрешения (лицензии, свидетельства), выданные
									уполномоченными государственными органами на объект рекламирования, продвигаемый
									посредством размещения Материалов;</li>
								<li>7.2.4. совершены все иные действия, требуемые законодательством Российской
									Федерации/законодательством места осуществления деятельности для занятия видом
									деятельности, рекламируемом в Материалах;</li>
								<li>7.2.5. Материалы соответствуют требованиям, предъявляемым Системами
									контекстной/таргетированной рекламы, действующим законодательством РФ и/или иной страны,
									на территории которой распространяются (таргетируются) соответствующие Материалы, в том
									числе о рекламе, конкуренции, правах на результаты интеллектуальной деятельности и
									средства индивидуализации, но не ограничиваясь перечисленными, и иным условиям
									настоящего Договора;</li>
								<li>7.2.6. Объекты рекламирования в Материалах не запрещены и не ограничены в обороте, не
									нарушают каких-либо прав третьих лиц и действующего законодательства Российской
									Федерации;</li>
								<li>7.2.7. Материалы не являются оскорбительными, неприличными, угрожающими или
									ругательными;</li>
								<li>7.2.8. Лицензиат принимает настоящий Договор на добровольной основе, ознакомился со
									всеми условиями настоящего Договора, понимает предмет Договора, понимает значение и
									последствия своих действий в отношении принятия и исполнения настоящего Договора;</li>
								<li>7.2.9. Лицензиатом указаны достоверные, полные и точные данные Лицензиата в
									Бизнес-аккаунте.</li>
							</ul>
						</li>
						<li>7.3. Лицензиат признает и понимает, что Лицензиар заключает настоящий Договор, полагаясь на
							вышеперечисленные заверения и гарантии.</li>
						<li>7.4. Заверения и гарантии представляют собой «существенные условия» настоящего Договора для
							целей Гражданского Кодекса Российской Федерации (включая, но, не ограничиваясь, для целей ст.
							431.2, 432 ГК РФ). Любое нарушение или недействительность какого-либо заверения или гарантии
							будет считаться существенным нарушением настоящего Договора для целей статьи 450 ГК РФ. В
							случае, если заверения об обстоятельствах и гарантии, указанные в настоящем Договоре, будут
							недостоверными и/или нарушенными, Лицензиат обязан возместить Лицензиару по его требованию
							убытки, причиненные недостоверностью и/или нарушением таких заверений и гарантий, а Лицензиар
							вправе отказаться от исполнения настоящего Договора.</li>
						<li><b>7.5. Особые условия в части соблюдения требований ст. 18.1 Федерального закона от
								13.03.2006г. N 38-ФЗ «О рекламе» </b>
							<ul class="list">
								<li>7.5.1. Для пункта 7.5. настоящего Договора Стороны согласовали следующие термины и их
									определения:
									<br><b>• Реклама</b> – информация, распространенная в Системах
									контекстной/таргетированной
									рекламы в сети Интернет любым способом, в любой форме и с использованием любых
									средств, адресованная неопределенному кругу лиц и направленная на привлечение
									внимания к объекту рекламирования, формирование или поддержание интереса к нему
									и его продвижение на рынке.
									<br><b>• Рекламодатель</b> – изготовитель или продавец товара либо иное определившее
									объект рекламирования и (или) содержание рекламы лицо.
									<br><b>• Рекламораспространитель</b> – лицо, осуществляющее распространение Рекламы в
									сети Интернет любым способом, в любой форме и с использованием любых средств.
									<br><b>• Рекламная система</b> – информационная система и (или) программа для
									электронных вычислительных машин, которые предназначены и используются для организации
									распространения в сети Интернет Рекламы посредством принадлежащих третьим лицам
									информационных ресурсов (сайтов в сети Интернет, страниц сайта в сети Интернет,
									информационных систем и (или) программ для электронных вычислительных машин).
									<br><b>• Оператор рекламной системы</b> – лицо, осуществляющее деятельность по
									обеспечению функционирования рекламной системы. Стороны особо оговорили, что в п. 7.5.
									настоящего Договора под Оператором рекламных данных понимаются в том числе кандидаты в
									Операторы рекламных данных, то есть лица, претендующие на получение статуса Оператора
									рекламных данных.
									<br><b>• Уполномоченный орган</b> – федеральный орган исполнительной власти,
									осуществляющий функции по контролю и надзору в сфере средств массовой информации,
									массовых коммуникаций, информационных технологий и связи.
									<br><b>• ЕРИР</b> – Единый реестр интернет рекламы, предназначенный для ее учета,
									хранения и обработки в целях обеспечения прослеживаемости распространения рекламы в сети
									Интернет.
									<br><b>• Оператор рекламных данных (ОРД)</b> – владелец программ для электронных
									вычислительных машин, предназначенных для установления факта распространения рекламы в
									сети Интернет, уполномоченных Уполномоченным органом осуществлять учет рекламы в сети
									Интернет и предоставлять информацию в Уполномоченный орган.
									<br><b>• Информация</b> – информация о распространенной в сети Интернет рекламе, учет,
									хранение и обработку которой осуществляет Уполномоченный орган, которая одновременно
									соответствует следующим требованиям:
									<ul class="list" style="list-style: none;">
										<li>- в соответствии с законодательством Российской Федерации обязанность по
											предоставлению такой информации Оператору рекламных данных возложена на
											Рекламодателя;</li>
										<li>- в соответствии с законодательством Российской Федерации обязанность по
											предоставлению такой информации Оператору рекламных данных может быть возложена
											Рекламодателем на Рекламораспространителя, Оператора рекламных систем в рамках
											исполнения договорных отношений с Рекламодателем и/или лицами, уполномоченными
											Рекламодателем.</li>
									</ul>
								</li>
								<li>7.5.2. В целях соблюдения требований ст. 18.1 Федерального закона от 13.03.2006г. N
									38-ФЗ «О рекламе», принимая во внимание, что Лицензиат размещает Рекламу в результате
									предоставления Лицензиаром доступа к Сервису ProfitAds по настоящему Договору, Стороны
									пришли к соглашению о том, что Лицензиар берет на себя обязательства по предоставлению
									Информации в ЕРИР через Оператора рекламных данных при предоставлении Лицензиаром
									доступа к Сервису ProfitAds по настоящему Договору.
									<br>Лицензиар передает Информацию в ЕРИР через Оператора рекламных данных самостоятельно
									или обеспечивает наличие идентичной обязанности о передаче Информации в ЕРИР за
									Лицензиата в договоре с лицом, обязанным передавать Информацию.
								</li>
								<li>7.5.3. Лицензиар вправе по своему усмотрению определять Оператора рекламных
									данных/Операторов рекламных данных, которому/которым Лицензиар предоставляет Информацию
									в соответствии с п. 7.5.2. настоящего Договора, без согласования с Лицензиатом.
								</li>
								<li>7.5.4. В случае необходимости получения Лицензиаром от Лицензиата Информации или любой
									иной дополнительной информации, необходимой для выполнения Лицензиаром своих
									обязательств, указанных в п. 7.5.2. настоящего Договора, от Лицензиата, Лицензиат
									обязуется предоставить такую Информацию и/или дополнительную информацию в течение 1
									(Одного) рабочего дня с даты получения от Лицензиара соответствующего запроса по
									электронной почте. В случае изменения Информации, передаваемой в ЕРИР через Оператора
									рекламных данных, Лицензиат обязуется уведомить в течение 1 (Одного) рабочего дня
									Лицензиара о таких изменениях.
									<br>Лицензиар вправе вносить исправления в Информацию, подлежащей передаче в ЕРИР через
									Оператора рекламных данных, в случае получения от Лицензиата измененной Информации и/или
									получения запроса от Оператора рекламных данных по предварительному согласованию с
									Лицензиатом посредством электронной почты.
									<br>В случае несвоевременного и неполного предоставления Лицензиатом Лицензиару
									Информации, предоставление которой в ЕРИР через Оператора рекламных данных требуется до
									начала размещения Рекламы, дата размещения такой Рекламы переносится на время просрочки
									исполнения Лицензиатом обязанности, указанной в абз. 1 настоящего пункта Договора.
								</li>
								<li>7.5.5. Настоящим Лицензиат в порядке ст. 431.2 Гражданского кодекса Российской Федерации
									заверяет и гарантирует, что он является конечным Рекламодателем в отношении объекта
									(объектов) рекламирования, Реклама которых размещается Лицензиатом в результате
									использования Сервиса ProfitAds.
									<br>В случае, если в отношении какого-либо объекта рекламирования, Реклама которого
									будет размещаться Лицензиатом в результате использования Сервиса ProfitAds, Лицензиат не
									является конечным Рекламодателем, Лицензиат обязуется сообщить об этом Лицензиару
									посредством электронной почты. В таком случае Лицензиат в порядке ст. 431.2.
									Гражданского кодекса Российской Федерации заверяет и гарантирует, что предоставленная
									Лицензиатом информация о конечном Рекламодателе в отношении объекта рекламирования
									является полной и достоверной.
								</li>
								<li>7.5.6. В случае, если Лицензиат не сообщит Лицензиару достоверную и полную информацию о
									конечном Рекламодателе в отношении какого-либо объекта рекламирования, Реклама которого
									размещается Лицензиатом в результате предоставления Лицензиаром доступа к Сервису
									ProfitAds по настоящему Договору, а также в случае невыполнения Лицензиатом
									обязательств, указанных в абз. 1 п. 7.5.4. настоящего Договора, Лицензиат обязуется
									компенсировать Лицензиару документально подтвержденный реальный ущерб, понесенный
									Лицензиаром вследствие невыполнения Лицензиатом своих обязательств надлежащим образом, в
									том числе суммы штрафов, пеней, неустоек, выставленных Лицензиару Уполномоченным
									органом, иными государственными органами и третьими лицами, привлеченными Лицензиаром
									для исполнения обязательств по настоящему Договору.
								</li>
								<li>7.5.7. В случае невыполнения/ненадлежащего выполнения Лицензиаром по своей вине
									обязательств, указанных в п. 7.5.2. настоящего Договора, Лицензиар обязуется возместить
									Лицензиату документально подтвержденный реальный ущерб, причиненный Лицензиату таким
									невыполнением, в том числе суммы штрафов, пеней, неустоек, выставленных Лицензиату
									Уполномоченным органом, иными государственными органами и третьими лицами, привлеченными
									Лицензиаром к оказанию услуг по настоящему Договору. Указанная ответственность не
									применяется к Лицензиару в случае, когда такое невыполнение/ненадлежащее выполнение
									Лицензиаром своих обязательств, указанных в п. 7.5.2. настоящего Договора, произошло не
									по вине Лицензиара, в том числе вследствие технических неполадок в работе Оператора
									рекламных данных и/или ЕРИР, а также в случае невыполнения Лицензиатом обязательств,
									указанных в абз. 1 п. 7.5.4. настоящего Договора и/или вследствие несоответствия
									действительности заверений, указанных в п. 7.5.5. настоящего Договора.
									<br>Лицензиар не несет перед Лицензиатом ответственность за последствия предоставления в
									ЕРИР через Оператора рекламных данных Информации, переданной Лицензиатом, если Лицензиар
									не менял содержание такой Информации. Изменение формы передачи Информации не является
									содержательным изменением информации.
								</li>
								<li>Стороны пришли к соглашению о том, что разглашение любой Информации в рамках выполнения
									Лицензиаром своих обязательств, указанных в п. 7.5.2. настоящего Договора, Оператору
									рекламных данных, Уполномоченному органу, иным третьим лицам и государственным органам,
									не является нарушением условий о конфиденциальности, установленных Сторонами в настоящем
									Договоре и любых соглашениях/договорах о конфиденциальности, подписанных Сторонами на
									дату подписания настоящего Договора, и в любых соглашениях/договорах о
									конфиденциальности, которые будут подписаны Сторонами после подписания настоящего
									Договора. Лицензиар освобождается от обязанности уведомлять Лицензиата о разглашении
									любой Информации в рамках выполнения Лицензиаром своих обязательств, указанных в п.
									7.5.2. настоящего Договора, Оператору рекламных данных, Уполномоченному органу, иным
									третьим лицам и государственным органам, несмотря на иные положения настоящего Договора
									и любых соглашений/договоров о конфиденциальности, подписанных Сторонами на дату
									подписания настоящего Договора, и любых соглашений/договоров о конфиденциальности,
									которые будут подписаны Сторонами после подписания настоящего Договора.
									<br>Положения настоящего пункта Договора имеют преимущество перед иными положениями
									настоящего Договора, соглашений/договоров о конфиденциальности, подписанных Сторонами на
									дату подписания настоящего Договора, и любых соглашений/договоров о конфиденциальности,
									которые будут подписаны Сторонами после даты подписания настоящего Договора, за
									исключением случаев, когда такими соглашениями/договорами о конфиденциальности прямо не
									будет предусмотрено их преимущество над положениями настоящего пункта Договора.
								</li>
								<li>7.5.9. Стороны пришли к соглашению о том, что выполнение Лицензиаром своих обязательств,
									указанных в п. 7.5.2. настоящего Договора, входит в общую стоимость выплачиваемых
									Лицензиатом лицензионных вознаграждений по настоящему Договору и отдельно не
									тарифицируется до момента установления Оператором рекламных данных/Операторами рекламных
									данных, которому/которым Лицензиар предоставляет Информацию в соответствии с п. 7.5.2
									настоящего Договора, стоимости своих услуг. После установления Оператором рекламных
									данных/Операторами рекламных данных, которому/которым Лицензиар предоставляет Информацию
									в соответствии с п. 7.5.2. настоящего Договора стоимости своих услуг Лицензиар вправе в
									одностороннем порядке пересмотреть указанные в настоящем пункте условия, в том числе
									Лицензиар может установить стоимость выполнения Лицензиаром своих обязательств,
									указанных в п. 7.5.2. настоящего Договора. В этом случае применяется порядок,
									предусмотренный п. 1.3. настоящего Договора.
								</li>
							</ul>
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">8. ОБСТОЯТЕЛЬСТВА НЕПРЕДОЛИМОЙ СИЛЫ</h3>
					<ul class="list">
						<li>8.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств
							по настоящему Договору, если это неисполнение явилось следствием обстоятельств непреодолимой
							силы, которые возникли после заключения Договора, либо если неисполнение обязательств Сторонами
							по Договору явилось следствием событий чрезвычайного характера, которые Стороны не могли ни
							предвидеть, ни предотвратить разумными мерами.</li>
						<li>8.2. К обстоятельствам непреодолимой силы относятся события, на которые Сторона не может
							оказывать влияния и за возникновение которых она не несет ответственности, как то: война,
							восстание, забастовка, землетрясение, наводнение, пожар, суровые погодные условия или подобные
							явления, правительственные постановления, распоряжения (указы) государственных или муниципальных
							органов Российской Федерации, законы и иные нормативные акты компетентных органов, принятые
							после подписания настоящего Договора и делающие невозможным исполнение обязательств,
							установленных настоящим Договором, а также действия государственных или муниципальных органов и
							их представителей, препятствующих выполнению условий Договора, и другие непредвиденные
							обстоятельства.</li>
						<li>8.3. Сторона, ссылающаяся на обстоятельства непреодолимой силы, обязана информировать другую
							Сторону о наступлении и характере подобных обстоятельств в письменной форме с приложением копий
							документов, выданных уполномоченным государственным органом или организацией, подтверждающих
							наступление обстоятельств непреодолимой силы. При этом Лицензиар вправе направить указанное
							уведомление на адрес электронной почты, указанный Лицензиатом в Бизнес-аккаунте, что будет
							являться достаточным уведомлением.</li>
						<li>8.4. В случае возникновения обстоятельств непреодолимой силы срок выполнения обязательств по
							настоящему Договору отодвигается соразмерно времени, в течение которого действуют такие
							обстоятельства и их последствия.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">9. СРОК ДЕЙСТВИЯ И РАСТОРЖЕНИЕ ДОГОВОРА</h3>
					<ul class="list">
						<li>9.1. ННастоящий Договор вступает в силу с даты его акцепта в соответствии с Разделом 1
							настоящего Договора и действует бессрочно.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">10. КОНФИДЕНЦИАЛЬНОСТЬ</h3>
					<ul class="list">
						<li>10.1. Стороны обязуются сохранять в тайне и считать конфиденциальной полученную в процессе
							исполнения Договора информацию, переданную одной Стороной другой Стороне и обозначенную
							передающей Стороной как конфиденциальная информация передающей Стороны (далее -
							«Конфиденциальная Информация»), и не раскрывать, не разглашать, не опубликовывать или иным
							способом не предоставлять такую информацию какой-либо третьей стороне без предварительного
							письменного разрешения передающей Стороны. </li>
						<li>10.2. Каждая из Сторон предпримет все необходимые меры для защиты Конфиденциальной Информации с
							такой же степенью тщательности, с какой она защищает собственную конфиденциальную информацию.
							Доступ к Конфиденциальной Информации Лицензиата будет предоставлен только тем сотрудникам
							Лицензиара, которым он обоснованно необходим для выполнения служебных обязанностей, связанных с
							исполнением Договора.</li>
						<li>10.3. Конфиденциальная Информация всегда остается собственностью передающей Стороны и не должна
							копироваться или иным способом воспроизводиться без предварительного письменного согласия
							передающей Стороны.</li>
						<li>10.4. Обязательство сохранять в тайне Конфиденциальную Информацию передающей Стороны не
							распространяется на информацию, которая:
							<br>- на момент раскрытия являлась или стала всеобщим достоянием не вследствие нарушения,
							допущенного принимающей Стороной; и/или
							<br>- становится известной принимающей Стороне из источника, иного, чем передающая Сторона, без
							нарушения принимающей Стороной условий настоящего Договора, что может быть удостоверено
							документами, достаточными для подтверждения того, что источником получения Конфиденциальной
							Информации является третья сторона; и/или
							<br>- была известна принимающей Стороне до ее раскрытия по Договору, что подтверждается
							документами, достаточными для установления факта обладания Конфиденциальной Информацией; и/или
							<br>- была раскрыта с письменного разрешения передающей Стороны.
						</li>
						<li>10.5. Обязательство сохранять в тайне Конфиденциальную Информацию в соответствии с условиями
							настоящей статьи вступает в силу с момента подписания Договора обеими Сторонами и остается в
							силе в течение 3 (Трех) лет с момента окончания срока действия Договора или его расторжения по
							какой-либо причине.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">11. ПРОЧИЕ УСЛОВИЯ</h3>
					<ul class="list">
						<li>11.1. Лицензиат соглашается с тем, что Лицензиар может без дополнительного согласования с
							Лицензиатом использовать в качестве рекламы деятельности Лицензиара факт сотрудничества с
							Лицензиатом и результаты использования Лицензиатом Сервиса ProfitAds (публиковать кейсы в сети
							Интернет, показывать другим Лицензиатам, рассказывать на тренингах и т.д.).</li>
						<li>11.2. Лицензиат соглашается с тем, что как в течение срока действия настоящего Договора, так и в
							течение неограниченного периода после его прекращения (независимо от причин прекращения)
							Лицензиар будет иметь право использовать товарные знаки, логотипы, фирменные наименования
							Лицензиата, отзывы, специально предоставленные Лицензиатом, следующими способами:
							<br>- размещать в портфолио в списке своих клиентов/партнеров;
							<br>- представлять на разных публичных мероприятиях в списке своих клиентов/партнеров;
							<br>- размещать на сайте Лицензиара в сети Интернет.
						</li>
						<li>11.3. Настоящий Договор не предоставляет Лицензиату каких-либо прав, кроме прямо указанных в
							настоящем Договоре, на любую интеллектуальную собственность, которой владеет Лицензиар или любые
							третьи лица, включая, но не ограничиваясь, любые исходные коды, программы для ЭВМ, базы данных,
							аппаратные средства, программное обеспечение, изобретения, проекты, конфигурации, процессы,
							ноу-хау, информацию и формулы, а также все компоненты вышеуказанного.</li>
						<li>11.4. Правом, применимым к настоящему Договору, является право Российской Федерации.</li>
						<li>11.5. Все споры, возникающие при исполнении и расторжении настоящего Договора, разрешаются путем
							переговоров, либо в суде по месту, определенному процессуальным законодательством Российской
							Федерации. При нахождении Лицензиата за пределами Российской Федерации, споры рассматриваются на
							территории Российской Федерации, по месту нахождения Лицензиара.</li>
						<li>11.6. Права и обязательства Лицензиата по Договору или какая-либо их часть могут быть переданы
							Лицензиатом третьей стороне исключительно при наличии письменного согласия Лицензиара. Лицензиар
							вправе передавать свои права и обязанности третьим лицам в порядке, предусмотренном действующем
							законодательством Российской Федерации.</li>
						<li>11.7. В соответствии с частью 2 статьи 9 Федерального закона от 06.04.2011 No 63-ФЗ «Об
							электронной подписи» Стороны пришли к соглашению о равнозначности информации в электронной
							форме, подписанной простой электронной цифровой подписью, документу на бумажном носителе,
							подписанному собственноручной подписью Лицензиата и руководителя или уполномоченного им лица
							Лицензиара.
							<br>Под простой электронной подписью, понимается электронная подпись, которая посредством
							использования ключа простой электронной подписи (далее – ключ) подтверждает факт формирования
							электронной подписи уполномоченным представителем Стороны
							<br>Ключом является сочетание 2 элементов – идентификатора и пароля ключа. Идентификатором
							является логин Стороны или руководителя или уполномоченного им лица Стороны в информационной
							системе, а паролем ключа - уникальная последовательность, неизвестная для третьих лиц, пароль
							для доступа в информационную систему.
							<br>Под информационной системой понимается программное обеспечение, позволяющее вести переписку
							между Сторонами по телекоммуникационным сетям с использованием электронной почты.
							<br>В соответствии с Договором простой электронной подписью могут быть подписаны следующие
							документы:
							<br>- Приложения;
							<br>- Дополнительные соглашения;
							<br>- Универсальные передаточные документы;
							<br>- Акты сверок;
							<br>- Счета;
							<br>- Иные документы, оформляемые Сторонами по настоящему Договору.
						</li>
						<li>11.8. Порядок проверки электронной подписи: документ считается подписанным простой электронной
							подписью Лицензиара при соблюдении следующих условий: документ оформлен в виде фотокопии
							документа на бумажном носителе, подписанного собственноручной подписью руководителя или
							уполномоченного им лица Лицензиара и приложен в качестве вложения в сообщение электронной почты,
							отправленного с адреса, указанного в разделе 12. При этом на фотокопии должен быть явно различим
							текст документа, подписи уполномоченных лиц и оттиск печати (при наличии печати). Фотокопия
							должна содержать все обязательные реквизиты документа, предусмотренные статьей 9 Федерального
							закона от 06.12.2011 No 402-ФЗ «О бухгалтерском учете», если соответствующее законодательство
							применимо к соответствующему документу.</li>
						<li>11.9. Официальными каналами связи для передачи документов и (или) информации по настоящему
							Договору являются:
							<br>- электронная почта;
							<br>- почта России;
							<br>- курьерская служба;
							<br>- Личный кабинет;
							<br>- Бизнес-аккаунт.
						</li>
						<li>11.10. Заявки, письма, файлы, направленные посредством сети Интернет с использованием следующих
							адресов электронной почты: со стороны Лицензиата – адреса электронной почты, указанного в
							Бизнес-аккаунте, со стороны Лицензиара: – адреса электронной почты, указанного в Разделе 12
							настоящего Договора, признаются Сторонами в суде в качестве письменных доказательств. При
							предъявлении их в качестве доказательств, достаточно представить распечатанное электронное
							сообщение, файл, заверенные подписью Лицензиата или подписью уполномоченного лица и печатью
							Лицензиара.
							<br>Переписка между Сторонами, а также обмен информацией и уведомлениями, также осуществляется
							посредством Сервиса ProfitAds.
							<br>Стороны признают юридическую силу за сообщениями, уведомлениями, документами, направленными
							посредством электронной почты с адресов, указанных в настоящем пункте Договора, и через Личный
							кабинет, Бизнес-аккаунт и признают их равнозначными документам на бумажных носителях,
							подписанным собственноручной подписью, так как только сами Стороны и уполномоченные ими лица
							имеют доступ к соответствующим адресам электронной почты, Личному кабинету и Бизнес-аккаунту.
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue"><b>12. РЕКВИЗИТЫ ЛИЦЕНЗИАРА</b></h3>
					<p class="text">Адрес места нахождения: 125124, г. Москва, ул. Ямского поля 3-я, д.2, корп.26
						<br>ОГРН: 1217700649176
						<br>ИНН: 7714480242
						<br>КПП: 771401001
						<br>Р/с: 40702810801990005005
						<br>В банке: АО «АЛЬФА-БАНК»
						<br>Корр.счет: 30101810200000000593
						<br>БИК: 044525593
						<br>Адрес электронной почты: info@profitads.ru, иные адреса электронной почты с доменом
						@profitads.ru
						<br><br>
						Редакция от 20.10.2023 г.
					</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DogovorOfertyFl',
	data() {
		return {
			locale: process.env.VUE_APP_LOCALE,
		}
	}
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.cookies-page {
	width: 100%;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.9;
	color: $basicBlack;
	background: #fff;
	box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
	padding: 12rem;

	.title {
		font-size: 3rem;
		line-height: 4rem;
		font-weight: 700;
		margin: 0 0 3rem;
	}

	.subtitle {
		font-weight: 700;
		margin: 0 0 1.5rem;

		&--blue {
			color: $primary;
		}
	}

	.text {
		margin: 0 0 3rem;

		&--gray {
			color: $gray;
		}
	}

	.list {
		padding: 0 0 0 2rem;
		margin: 0 0 3rem;

		&__item {
			margin: 0 0 1.5rem;

			a {
				color: $primary;
			}

			ul {
				padding-left: 2rem;
			}
		}
	}

	a {
		color: $primary;
		text-decoration: underline;

		&:hover {
			opacity: 0.7;
		}
	}

	.link {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 1.6rem;
		line-height: 1.9;
		color: $primary;
		background-color: transparent;
		border: 1px solid $primary;
		border-radius: 4px;
		text-decoration: none;
		transition: color .4s linear, background-color .4s linear;
		padding: 7px 50px;

		&:hover {
			color: $basicWhite;
			background-color: $primary;
		}
	}

	@include below(993px) {
		padding: 60px 32px;
	}

	@include below(769px) {
		padding: 32px 24px;
	}

	@include below(577px) {
		padding: 24px 16px;

		.title {
			font-size: 16px;
			line-height: 1.4;
			margin-bottom: 16px;
			text-align: center;
		}

		.text {
			font-size: 14px;
			line-height: 1.4;
			margin-bottom: 16px;
		}

		.list {
			padding-left: 16px;
			margin-bottom: 16px;

			li {
				margin-bottom: 8px;

				.subtitle {
					font-size: 14px;
					line-height: 1.4;
					margin-bottom: 8px;
				}

				p {
					font-size: 14px;
				}

				.list {

					li {
						font-size: 14px;
						line-height: 1.4;
					}
				}
			}
		}
	}
}
</style>
