<template>
  <div class="faq">
    <div v-loading="isLoading" class="container">
      <div class="faq__header">
        <div class="faq__title">
          {{$t('faq.page.table_title')}}
        </div>
        <div class="faq__add" @click="openDialogAddingQuestion()">
          + {{$t('faq.page.add')}}
        </div>
      </div>
      <FAQTable :items="questions"></FAQTable>
      <div v-if="!isLoading && !questions.length">
        {{$t('nothing_found')}}
      </div>
      <div class="faq__pagination">
        <el-pagination
          v-if="pagination && pagination.total >= pagination.perPage"
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :current-page="pagination.currentPage"
          @current-change="fetchNewFaq($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FAQTable from "@/components/blocks/FAQTable";
import { mapActions, mapState, mapMutations } from "vuex"

export default {
  name: 'FAQ',
  components: {
    FAQTable
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('faq', ['pagination', 'questions', 'isLoading']),
  },
  methods: {
    ...mapActions('faq', ['fetchFaq']),
    ...mapMutations('faq', ['setPagination']),
    openDialogAddingQuestion() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditFAQ' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    fetchNewFaq(page = 1) {
      this.fetchFaq({ params: { per_page: this.pagination.perPage, page: page } })
    }
  },
  created() {
    this.fetchNewFaq()
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";

.faq {
  padding: 5rem 0;
  min-height: 51.1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
  }

  &__add {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--primary);
    cursor: pointer;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    gap: 2.4rem;

    .search {
      width: 70%;
    }

    .selector {
      width: 28%;

      &__content {
        width: 100%;
      }
    }
  }

 
  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }
}

.faq_filters-button {
  height: 5rem;
  .button {
    height: 100%;
    min-width: 16rem;
    padding: 1.3rem 2.5rem;
  }
}

@media screen and (max-width: 992px) {
  .faq { 
    &__filters {
    flex-direction: column;
    }
    .search , .selector, .date-picker {
      width: 100%;
    }
  }
}
</style>
