<template>
  <div class="unsubscribe-page page" v-loading="loading">
    <div>
      <div class="unsubscribe-page__title">
        <Title :text="$t('unsubscribe.question')" />
      </div>
      <div class="unsubscribe-page__buttons">
          <ButtonElement
            :text="$t('button_props.unsubscribe')"
            @click-button="unsubscribe"
          />
          <ButtonElement
          :text="$t('button_props.cancel')"
            view="empty"
            @click-button="cancel"
          />
        </div>
      
    </div>
  </div>
</template>

<script>
import Title from "@/components/elements/Title";
import ButtonElement from '@/components/elements/ButtonElement'

export default {
  name: "UnsubscribeEmail",
  components: {
    Title,
    ButtonElement
  },
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      lang: this.$i18n._vm.locale,
      loading: false
    };
  },
  methods: {
    unsubscribe() {
      const url = this.$route.query.queryURL
      if (url) {
        this.loading = true
        this.$store.dispatch('Auth/unsubscribeEmail', url)
        .then(() => {
          this.$notify({
            message: this.$t('unsubscribe.unsubscribeSuccess'),
            type: 'success'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            message: this.$t('business_account_client.error'),
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
    cancel() {
      window.location.href = 'https://profitads.ru/'
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.unsubscribe-page {
  &__title {
    text-align: center;
    margin-bottom: 4rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    
    .button {
      width: 20rem;
      height: 5rem;
    }
  }
}

</style>
