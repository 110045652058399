<template>
  <div class="users" :class="{'locale-usa': locale === 'USA'}">
    <div class="container">
      <div class="users__header">
        <div class="users__title">
         {{ $t('users.title') }}
        </div>
        <div v-if="currentRole === 'admin' || currentRole ==='finance'" class="users__add" @click="addNewUser">
          +  {{ $t('users.add_user') }}
        </div>
      </div>
      <div class="users__filters">
        <Search placeholder="E-mail" @findValue="changeSearchText" />
        <Selector
          :title="$t('users.role')"
          :options="locale==='USA'? clientTypesUSA:clientTypes"
          @handleChange="getNewData"
        />
      </div>
      <div
          v-if="usersList && usersList.length"
          class="users__table"
          v-loading="loading"
      >
        <el-table
          :data="usersList"
          :row-class-name="tableRowClassName"
          style="width: 100%"
        >

          <el-table-column
            width="350"
            label="ID"
            prop="id"
          />

          <el-table-column
            prop="role_ru"
            :label="$t('users.role')"
            width="170"
          />

          <el-table-column
            class-name="users__table-email"
            prop="email"
            :label="$t('users.email')"
          >
            <template slot-scope="scope">
              <a :href="'mailto:' + scope.row.email">
                {{ scope.row.email }}
              </a>
            </template>
          </el-table-column>

          <el-table-column :label="$t('users.created_date')">
            <template slot-scope="scope">
              <span>
                {{ fixDateFormat(scope.row.created_at) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
              class-name="users__table-holding"
              :label="$t('users.holding')"
          >
            <template slot-scope="scope">
              <span class="icon-holding" v-if="scope.row.is_holding === 1">
                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.72727 10.2836L1.43182 6.20896L0 7.56716L5.72727 13L18 1.35821L16.5682 0L5.72727 10.2836Z" fill="#395FE4"/>
                </svg>
              </span>
            </template>
          </el-table-column>

          <el-table-column class-name="users__table-edit">
            <template slot-scope="scope">
              <span v-if="scope.row.is_active === 0" class="users__table-edit__text">{{ $t('users.deactivated') }}</span>

              <span class="users__table-edit__btn"  @click="editUser(scope.row.id)">
                <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 8.5C3 9.32843 2.32843 10 1.5 10C0.671573 10 0 9.32843 0 8.5C0 7.67157 0.671573 7 1.5 7C2.32843 7 3 7.67157 3 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17Z"
                    fill="#9BA2AB"/>
                </svg>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="usersList && !usersList.length">
        {{ $t('nothing_found') }}
      </div>
      <div class="users__pagination">
        <el-pagination
          v-if="pagination && pagination.total >=10"
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="10"
          :current-page="pagination.currentPage"
          @current-change="getNewPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/sort/Search";
import Selector from "@/components/sort/Selector";
import fixDateFormat from '@/mixin/fixDate'

export default {
  name: 'Users',
  components: {
    Search,
    Selector,
  },
  mixins: [fixDateFormat],
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      clientTypes: [
        {
          id: 0,
          name: 'Все'
        },
        {
          id: 'finance',
          name: 'Финансист'
        },
        {
          id: 'manager',
          name: 'Менеджер'
        },
        {
          id: 'admin',
          name: 'Админ'
        },
        {
          id: 'user',
          name: 'Клиент'
        },
      ],
      clientTypesUSA: [
        {
          id: 0,
          name: 'All'
        },
        {
          id: 'finance',
          name: 'Financier'
        },
        {
          id: 'manager',
          name: 'Manager'
        },
        {
          id: 'admin',
          name: 'Admin'
        },
        {
          id: 'user',
          name: 'Client'
        },
      ],
      role: {
        finance: 'Финансист',
        admin: 'Админ',
        manager: 'Менеджер',
        user: 'Клиент',
      },
      roleUSA: {
        finance: 'Financier',
        admin: 'Admin',
        manager: 'Manager',
        user: 'Client',
      },
      loading: false,
      params: {
        per_page: 10,
      },
    }
  },
  computed: {
    currentRole() {
      return this.$store.state.Auth.role
    },
    usersList() {
      let users = []
      if(this.$store.state.Users.usersAllList) {
        this.$store.state.Users.usersAllList.map(el => {
          if(this.locale ==='USA') el.role_ru = this.roleUSA[el.role] || this.roleUSA.user
          if(this.locale ==='RU') el.role_ru = this.role[el.role] || this.role.user
          return users.push(el)
        })
      }
      return users
    },
    pagination() {
      return this.$store.state.Users.pagination
    },
  },
  watch: {
    $route() {
      for (let key in this.$route.query) {
        this.params[key] = this.$route.query[key]
      }
      this.getData()
    }
  },
  created() {
    for (let key in this.$route.query) {
      this.params[key] = this.$route.query[key]
    }
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const token = localStorage.getItem('token')
      await this.$store.dispatch('Users/getAllUsersList', { params: this.params, token })
      this.loading = false
    },
    changeSearchText(val) {
      if (!val) {
        const query = { ...this.$route.query, page: 1, email: null }
        this.$router.push({query})
      } else {
        const query = {...this.$route.query, page: 1, email: val }
        this.$router.push({query})
      }
    },
    getNewPage(page) {
      const query = { ...this.$route.query, page }
      this.$router.push({query})
    },
    getNewData(val) {
      if (val === 0) {
        const query = { ...this.$route.query, page: 1, role: null }
        this.$router.push({query})
      } else {
        const role = val
        const query = { ...this.$route.query, page: 1, role }
        this.$router.push({query})
      }
    },
    addNewUser() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditUser' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    editUser(id) {
      let user = this.$store.state.Users.usersAllList.filter(el => el.id === id)[0]
      this.$store.commit('Users/setData', { label: 'editUser', data: user })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditUser' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    tableRowClassName({row}) {
      if (row.is_active === 0) {
        return 'el-table__row--deactivated';
      }
      return '';
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";

.users {
  &.locale-usa {
    .users__title {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 4rem;
    }
    .el-table__header, .el-table__body {
      font-family: 'Gilroy'; 
    }
    .el-table__row {
      .el-table__cell:nth-child(-n+2) {

          font-weight: 700;
        
      }
    }
  }
  padding: 5rem 0;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
  }

  &__add {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--primary);
    cursor: pointer;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    .search {
      width: 70%;
    }

    .selector {
      width: 28%;

      &__content {
        width: 100%;
      }
    }
  }

  &__table {

    .el-table__row {
      cursor: pointer;

      &--deactivated {
        td {
          background-color: rgba($redLight , .1);
        }


        &:hover {

          td {
            background-color: rgba($redLight , .2)!important;
          }
        }
      }
    }

    a {
      color: var(--primary);
    }

    &-button {
      background: var(--primary);
      border: 1px solid var(--primary);
      padding: 1rem 2rem;
      border-radius: 4px;
      color: #fff;
    }

    &-date {
      color: #9BA2AB;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    &-edit {

      .cell {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &__text {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.9rem;
        color: $redLight;
        margin: 0 1.8rem 0 0;
      }

      &__btn {
        display: inline-flex;
        align-items: center;

        svg {
          display: block;
          height: auto;

          path {
            transition: fill .4s;
          }
        }

        &:hover {

          svg {

            path {
              fill: $primaryHover;
            }
          }
        }
      }
    }

    &-email {

      .cell {
        color: $primary;
      }

      &.is-leaf {

        .cell {
          color: $gray2;
        }
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }
}
</style>
