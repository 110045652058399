export const numberFormats = {
    'en-US': {
        currency: {
            style: 'currency',
            currency: 'USD'
        }
    },
    'ru': {
        currency: {
            style: 'currency',
            currency: 'RUB'
        }
    }
}