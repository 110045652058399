import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  documentsList: null,
  documentsPagination: null,
  files:[
    {id:"agreement", name:"Agreement"}, 
    {id:"terms", name:"Special Terms"}, 
    {id:"invoice", name:"Invoice"}, 
    {id:"act", name:"Monthly report"}
  ],
  statuses:[
    {id:"accepted", name:"accepted"}, 
    {id:"signed", name:"signed"}, 
    {id:"finalized", name:"finalized"}
  ],
  currency: [
    {id:"USD", name:"USD"},
    {id:"EUR", name:"EUR"}
  ],
  invoices: [
    {id:"created", name:"created"},
    {id:"paid", name:"paid"}
  ],
  updateTable:false
})
const getters = {}
const mutations = {
  setData (state, data) {
    state[data.label] = data.data
  },
}
const actions = {
  getDocuments ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/finance/documents`, method: 'GET', params })
        .then(resp => {
          let data = resp.data.data
          data.forEach(el => {
            const m = el.file_name.match(/\.([^.]+)$/)
            el.doc =  m && m[1]
          });
          commit('setData', { label: 'documentsList', data: data })
          commit('setData', { label: 'documentsPagination', data: resp.data.meta })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getDocumentFile (ctx, id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/finance/get-temp-link/${id}`, method: 'GET' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  dellDocumentFile (ctx, data) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/finance/documents/${data.id}`,headers: { 'Authorization': `Bearer ${token} ` }, method: 'DELETE', params: data.params })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addDocument({ commit }, data) {
    const token = localStorage.getItem('token')
    console.log('token', token)
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/finance/documents', headers: { 'Authorization': `Bearer ${token} ` }, method: 'POST', data })
        .then(resp => {
          commit('setData', { label: 'updateTable', data: true });
          resolve(resp);
        })
        .catch(err => {
          console.log(err.response);
          console.log(err.response.status)
          reject(err);
        });
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
