import { render, staticRenderFns } from "./WidgetLinkElement.vue?vue&type=template&id=dafa7718&scoped=true"
import script from "./WidgetLinkElement.vue?vue&type=script&lang=js"
export * from "./WidgetLinkElement.vue?vue&type=script&lang=js"
import style0 from "./WidgetLinkElement.vue?vue&type=style&index=0&id=dafa7718&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dafa7718",
  null
  
)

export default component.exports