<template>
  <div class="cookies-page">
    <div v-if="locale === 'RU'">
      <h1 class="title">{{ $t('cookies.page_title') }}</h1>
      <p v-if="locale === 'RU'" class="text text--gray">Файл cookie представляет собой небольшой файл, обычно состоящий из
        букв и цифр. Этот файл
        сохраняется на вашем компьютере, планшетном ПК, телефоне или другом устройстве, которое Вы используете для
        посещения сайта. Файлы cookie широко используются владельцами сайтов для обеспечения работы сайтов или повышения
        эффективности работы, а также для получения аналитической информации.</p>
      <h3 v-if="locale === 'RU'" class="subtitle subtitle--blue">Мы можем использовать различные типы файлов cookie:</h3>

      <ul v-if="locale === 'RU'" class="list">
        <li class="list__item">Строго необходимые файлы cookie. Эти файлы cookie необходимы, чтобы сайт работал корректно,
          они позволят Вам передвигаться по нашему сайту и использовать его возможности. Эти файлы не идентифицируют вас
          как личность. Если вы не согласны использовать данный тип файлов, это может оказать влияние на
          производительность веб-сайта, или его компонентов.
        </li>
        <li class="list__item">Файлы cookie, относящиеся к производительности, эффективности и аналитике. Эти файлы
          помогают нам понять, как посетители взаимодействуют с нашим сайтом, предоставляя информацию о тех областях,
          которые они посетили и количестве времени, которое они провели на сайте, так же они показывают проблемы в работе
          интернет-ресурса, например, сообщения об ошибках. Это поможет нам улучшить работу сайта. Файлы cookie,
          относящиеся к аналитике, также помогают нам измерять эффективность рекламных кампаний и оптимизировать
          содержание сайтов для тех, кого заинтересовала наша реклама. Данный тип файлов cookies не может быть использован
          для вашей идентификации. Вся информация, которая собирается и анализируется, анонимна.
        </li>
        <li class="list__item">Функциональные файлы cookie. Эти файлы cookie служат для того, чтобы опознавать
          пользователей, возвращающихся на наш сайт. Они позволяют нам индивидуально подбирать содержание сайта для Вас.
          Если вы блокируете этот тип файлов, то это может повлиять на производительность и функциональность веб-сайта и
          может ограничить доступ к контенту на сайте.
        </li>
      </ul>

      <h3 v-if="locale === 'RU'" class="subtitle">Каким образом собирается и используется другая информация?</h3>
      <h3 v-if="locale === 'RU'" class="subtitle subtitle--blue">Мы можем использовать файлы cookie в различных целях, в
        том числе,
        чтобы:</h3>

      <ul v-if="locale === 'RU'" class="list">
        <li class="list__item">Облегчить себе и третьим лицам получение информации о Ваших посещениях сайта.</li>
        <li class="list__item">Обрабатывать Ваши заявки.</li>
        <li class="list__item">Анализировать информацию о Вашем посещении страниц для совершенствования нашего
          сайта.
        </li>
        <li class="list__item">Помогать Вам в получении необходимой информации.</li>
        <li class="list__item">Предоставлять рекламу, сообщения и контент, созданные нами и третьими лицами, на данном
          сайте и сайтах других лиц, учитывая Ваши интересы.
        </li>
        <li class="list__item">Определять количество посетителей и то, как они используют наш сайт, — для повышения
          эффективности сайта и для наилучшего понимания интересов их аудитории.
        </li>
      </ul>

      <h3 v-if="locale === 'RU'" class="subtitle subtitle--blue">Как долго файлы cookie хранятся на моем устройстве?</h3>
      <p v-if="locale === 'RU'" class="text">Некоторые файлы cookie действуют с момента вашего входа на сайт до конца
        данной конкретной сессии
        работы в браузере. При закрытии браузера эти файлы становятся ненужными и автоматически удаляются. Такие файлы
        cookie называются «сеансовыми».</p>
      <p v-if="locale === 'RU'" class="text">Некоторые файлы cookie сохраняются на устройстве и в промежутке между
        сессиями
        работы в браузере —
        они не удаляются после закрытия браузера. Такие файлы cookie называются «постоянными». Срок хранения постоянных
        файлов cookie на устройстве различается для разных файлов cookie. Мы и другие компании используем постоянные файлы
        cookie в различных целях: например, чтобы точнее определить, как часто Вы посещаете наши сайты или как часто Вы на
        них возвращаетесь, как с течением времени меняется характер использования наших сайтов, а также для оценки
        эффективности рекламы.</p>
      <h3 v-if="locale === 'RU'" class="subtitle subtitle--blue">Кто размещает файлы cookie на моем устройстве?</h3>
      <p v-if="locale === 'RU'" class="text">Файлы cookie могут размещаться на Вашем устройстве администрацией сайта. Эти
        файлы cookie называются
        «собственными». Некоторые файлы cookie могут размещаться на Вашем устройстве другими операторами. Такие файлы
        cookie называются файлами «третьих лиц».</p>
      <p v-if="locale === 'RU'" class="text">Мы и третьи лица можем использовать файлы cookie, чтобы узнать, когда Вы
        посещаете наши сайты, как
        взаимодействуете с электронной почтой, рекламой и другим контентом. На основе файлов cookie может собираться и
        использоваться обобщенная и другая информация, не связанная с идентификацией отдельных пользователей (например, об
        операционной системе, версии браузера и URL-адресе, с которого выполнен переход на данную страницу, в том числе из
        электронного письма или рекламного объявления) — благодаря этому мы можем предоставить Вам более широкие
        возможности и проанализировать маршруты посещения сайтов. Такая технология позволяет подсчитать количество
        пользователей, которые посетили конкретный сервис, перейдя по ссылке с определенного баннера за пределами данного
        сайта, по текстовой ссылке или изображениям, включенным в рассылку. Кроме того, она служит инструментом для сбора
        обобщенной статистики об использовании сайта в целях аналитического исследования и помогает нам оптимизировать
        наши сайты, предлагать рекламу в соответствии с Вашими интересами, как подробно описано ниже.</p>
      <h3 v-if="locale === 'RU'" class="subtitle subtitle--blue">Как управлять файлами cookie?</h3>
      <p v-if="locale === 'RU'" class="text">Большинство интернет-браузеров изначально настроены автоматически принимать
        cookie. Вы можете
        изменить настройки таким образом, чтобы блокировать cookie или предупреждать пользователя, когда файлы данного
        типа будут отправлены на устройство. Есть несколько способов управления cookie. Пожалуйста, обратитесь к
        инструкции браузера для того, чтобы узнать больше о том, как скорректировать или изменить настройки браузера. Если
        отключить cookie, которые мы используем, то это может повлиять на вашу работу в Интернете, в то время как на нашем
        сайте вы, возможно, не сможете получать персональную информацию при посещении. Если вы используете различные
        устройства для просмотра и доступа к нашему сайту (например, компьютер, смартфон, планшет и т.д.), вы должны
        убедиться, что каждый браузер на каждом устройстве настроен в соответствии с вашей точкой зрения на работу с
        файлами cookie.</p>
      <p v-if="locale === 'RU'" class="text text--gray">Мы используем файлы cookie, чтобы улучшить ваш опыт и поддержать
        нашу миссию.</p>
    </div>
    <div v-if="locale === 'USA'">
      <h1 class="title">{{ $t('cookies.page_title') }}</h1>
      <p class="text text--gray">Cookies are text files containing small amounts of information which are downloaded to
        your computer or mobile device when you visit a site and allow a site to recognize your device. Cookies managed by
        Digital Eagle only are called “first party cookies” whereas cookies from third parties are called “third party
        cookies” as explained below.
      </p>

      <h3 class="subtitle subtitle--blue">Why do we use cookies and similar technologies?</h3>
      <p class="text">Cookies do a lot of different jobs, such as letting you navigate between pages efficiently,
        remembering your preferences and generally improving the user experience. They can also help to ensure that the
        advertisements you see online are more relevant to you and your interests. In addition, cookies can help us to
        analyze the use of our websites and online content (analytics cookies) and they can also facilitate/track the
        interaction on our websites and online content with social media (e.g. links to social media sites, like buttons,
        etc.).
      </p>

      <h3 class="subtitle subtitle--blue">Does Digital Eagle use cookies for marketing and analytics?</h3>

      <p class="text">
        Yes, we may use information collected from our cookies to identify user behavior and to serve content and offers
        based on your profile, and for the other purposes described below, to the extent legally permissible in certain
        jurisdictions.
      </p>

      <p class="text">
        In other cases, we can associate cookie information (including information from cookies placed via our
        advertisements on third party sites) with an identifiable individual. For example:
      </p>

      <ul class="list">
        <li class="list__item">If we send you a targeted email which includes web beacons, cookies or similar technologies
          we will know whether you open, read, or delete the message.
        </li>
        <li class="list__item">When you click a link in a marketing e-mail you receive from Digital Eagle, we will also
          use a
          cookie to log what pages you view and what content you download from our websites, even if you are not
          registered at or signed into our site.
        </li>
        <li class="list__item">Combining and analyzing personal data – As described above, we may combine data from
          publicly available sources, and from our different e-mail, website, and personal interactions with you (this
          includes information collected across our different websites such as our careers and corporate sites and
          information collected when you sign-up or log on to our sites or connect to our sites using your social media
          credentials (such as LinkedIn). We combine this data to better assess your experience with Digital Eagle and to
          perform the other activities described throughout our privacy policy.
        </li>
      </ul>
      <h3 class="subtitle subtitle--blue">Do you use any cookies from third party companies?</h3>
      <p class="text">
        Some cookies, web beacons and other tracking and storage technologies that we use are from third party companies
        (third party cookies), such as Meta, Microsoft, Marketo Munchkin Tracking, Twitter, Knotch, YouTube,
        Instagram, Yoptima and Linkedin Analytics to provide us with web analytics and intelligence about our sites which
        may also be used to provide measurement services and target ads. These companies use programming code to collect
        information about your interaction with our sites, such as the pages you visit, the links you click on and how
        long you are on our sites. This code is only active while you are on an Digital Eagle website. For more
        information on
        how these companies collect and use information on our behalf, please refer to their privacy policies: Meta at
        Meta Data Policy Google (including YouTube) at Google Privacy & Terms, Microsoft at Microsoft Privacy
        Statement, Marketo at Marketo Privacy Policy, Linkedin at Linkedin Privacy Policy, Twitter at Twitter Privacy
        Policy, Knotch at Knotch Privacy Policy, Instagram at Instagram Data Policy and Yoptima at Yoptima Privacy Policy.
      </p>

      <h3 class="subtitle subtitle--blue">What types of cookies does the site use?</h3>
      <p class="text">The cookies used on Digital Eagle sites have been categorized as per the table below. However, it is
        important to note that not all cookies may be used in all jurisdictions or websites. A list of the categories of
        cookies used on this website is set out below.</p>

      <h3 class="subtitle">
        Strictly Necessary cookies
      </h3>
      <p class="text">
        These cookies are essential in order to enable you to move around the site and use its features, such as accessing
        secure areas of the site. Without these cookies, services you have asked for cannot be provided.
      </p>

      <h3 class="subtitle">
        First party analytics cookies
      </h3>
      <p class="text">
        These cookies allow us to employ data analytics so we can measure and improve the performance of our site and
        provide more relevant content to you. These cookies don't collect information that identifies a visitor down to an
        individual level that is available to us. These cookies are not passing personally identifiable information to any
        external third party other than in limited cases when we engage a service provider to act on our behalf but who is
        then unable to use the data for their own purposes. These include, Adobe’s Analytics, Target and Audience Manager;
        Contentsquare and Demandbase.
      </p>

      <h3 class="subtitle">
        Performance cookies
      </h3>
      <p class="text">
        Performance cookies are generally third-party cookies from vendors we work with or who work on our behalf that
        collect information about your visit and use of the Digital Eagle website, for instance which pages you visit the
        most
        often, and if you get error messages from web pages. These cookies don't collect information that identifies a
        visitor. All information these cookies collect is anonymous and is only used to improve how the website works.
        Third party vendors may have access to this data and may use it to improve their overall services and offerings.
      </p>

      <h3 class="subtitle">
        Functionality cookies
      </h3>
      <p class="text">
        These cookies allow a site to remember choices you make (such as your username, language or the region you are in)
        and provide more enhanced, personal features.
      </p>
      <p class="text">
        These cookies cannot track your browsing activity on other websites. They don’t gather any information about you
        that could be used for advertising or remembering where you’ve been on the Internet outside our site.
      </p>

      <h3 class="subtitle">
        Advertising and social media cookies
      </h3>
      <p class="text">

        Advertising and social media cookies (including web beacons and other tracking and storage technologies) are used
        to (1) deliver advertisements more relevant to you and your interests; (2) limit the number of times you see an
        advertisement; (3) help measure the effectiveness of the advertising campaign; (4) retargeting to Digital Eagle
        websites/information and (5) understand people’s behavior after they view an advertisement. They are usually
        placed on behalf of advertising networks with the site operator’s permission. They remember that you have visited
        a site and quite often they will be linked to site functionality provided by the other organization. This may
        impact the content and messages you see on other websites you visit.
      </p>

      <p class="text">
        If you have any questions now or during your visit, please submit your request <a href="mailto:info@digital-eagle.us">contact us</a> .
      </p>



    </div>
  </div>
</template>

<script>
export default {
  name: "CookiesPage",
  metaInfo: {
		title:`${process.env.VUE_APP_LOCALE==='USA'?'Digital eagle':'ProfitAds'}`
	},
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.cookies-page {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.9;
  color: $basicBlack;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
  padding: 12rem;

  .title {
    font-size: 3rem;
    font-weight: 700;
    margin: 0 0 3rem;
  }

  .subtitle {
    font-weight: 700;
    margin: 0 0 1.5rem;

    &--blue {
      color: var(--primary);
    }
  }

  .text {
    margin: 0 0 3rem;

    &--gray {
      color: $gray;
    }
  }

  .list {
    padding: 0 0 0 2rem;
    margin: 0 0 3rem;

    &__item {
      margin: 0 0 1.5rem;
    }
  }

  .link {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    line-height: 1.9;
    color: var(--primary);
    background-color: transparent;
    border: 1px solid var(--primary);
    border-radius: 4px;
    text-decoration: none;
    transition: color .4s linear, background-color .4s linear;
    padding: 7px 50px;

    &:hover {
      color: $basicWhite;
      background-color: $primary;
    }
  }
}
</style>
