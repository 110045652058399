<template>
  <div class="switch-wrapper" @click="changeActive">
    <div class="switch" :class="[{'switch-active' : active}, {'locale-usa': locale === 'USA'}]"/>
    <div class="switch-label">{{ label }}</div>
  </div>
  
</template>

<script>
export default {
  name: 'SwitchElement',
  props: {
    label :{
      type: String,
      default: ''
    }
  },
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      active: false
    }
  },
  methods: {
    changeActive() {
      this.active = !this.active
      this.$emit('change', this.active)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/styles/helpers/variables";
  .switch-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .switch-label {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $basicBlack;
    margin-left: 1.3rem;
  }

  .switch {
    width: 3.4rem;
    height: 2rem;
    border-radius: 1rem;
    background: $lightGrey;
    transition: 0.3s ease-out;
    position: relative;

    &.disabled {
      cursor: not-allowed;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: $basicWhite;
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;
      top: 0.32rem;
      left: 0.3rem;
      transition: 0.3s ease-out;
    }

    &-active {
      &.locale-usa {
        background: #FF7F50;
      }
      background: $primary;

      &::before {
        left: calc(100% - 1.7rem);
      }
    }
  }
</style>
