<template>
	<div class="cookies-page">
		<div v-if="locale === 'RU'">
			<h1 class="title">{{ $t('privacy_policy.title') }}</h1>
			<ul class="list">
				<li>
					<h3 class="subtitle subtitle--blue">1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
					<ul class="list">
						<li>1.1. Настоящая политика конфиденциальности и обработки персональных данных (далее – «Политика»)
							определяет порядок обработки персональной информации, указанной в п. 1.2. настоящей Политики,
							Обществом с ограниченной ответственностью «ПрофитЭдс» (далее – «Компания») при использовании
							пользователями Сайта http://profitads.ru и Сервиса ProfitAds, расположенного на Сайте (далее
							именуются, соответственно, «Сайт» и «Сервис»).</li>
						<li>1.2. В рамках настоящей Политики под персональной информацией понимаются:
							<ul class="list">
								<li>1.2.1. Любые персональные данные, которые любой пользователь (далее – «Пользователь»)
									предоставляет о себе или об иных лицах (далее – «Иные лица» или «Иное лицо»)
									самостоятельно при заполнении любых форм, в том числе при регистрации личного кабинета
									на Сайте (а именно: адрес электронной почты, номер мобильного телефона (с кодом
									российского оператора)) и при регистрации бизнес-аккаунта в Сервисе (а именно: имя,
									отчество, фамилия, электронная почта, номер телефона, ИНН физического лица), на Сайте/в
									Сервисе или в процессе использования Сайтов/Сервиса (имя, фамилия, отчество, номера
									телефонов, адреса электронной почты и иные данные, которые Пользователь предоставляет
									Компании по своему усмотрению).
									<br>
									(далее персональные данные, указанные в пп. 1.2.1. Политики, совместно именуются
									«Персональные данные»)
								</li>
								<li>1.2.2. Данные Пользователя, которые автоматически передаются сервисам Сайта/Сервиса в
									процессе их использования с помощью установленного на устройстве Пользователя
									программного обеспечения, а именно: IP-адрес, данные файлов cookie, информация о
									браузере, с помощью которого осуществляется доступ к Сайту/Сервису (или иной программе,
									с помощью которой осуществляется доступ к Сайту/Сервису), технические характеристики
									программного обеспечения, используемого для доступа к Сайту/Сервису, дата и время
									доступа к Сайту/Сервису, адреса запрашиваемых страниц и иная подобная информация.
									<br>
									(Далее персональные данные, указанные в пп. 1.2.1. Политики, и данные, указанные в п.
									1.2.2. Политики, совместно именуются «Персональная информация»)
								</li>
							</ul>
						</li>
						<li>1.3. Настоящая Политика применяется исключительно к Сайту/Сервису. Сайт не контролирует и не
							несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам,
							доступным на Сайте.</li>
						<li>1.4. Использование Сайта/Сервиса означает безоговорочное согласие Пользователя с настоящей
							Политикой и указанными в ней условиями обработки Персональной информации; в случае несогласия с
							этими условиями Пользователь не должен использовать Сайт/Сервис. Факт использования
							Пользователем Сайта/Сервиса означает согласие Пользователя с редакцией Политики, действующей на
							момент такого использования.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">2. УСЛОВИЯ И ПОРЯДОК ПРЕДОСТАВЛЕНИЯ СОГЛАСИЯ НА ОБРАБОТКУ
						ПЕРСОНАЛЬНЫХ ДАННЫХ И ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h3>
					<ul class="list">
						<li>2.1. Компания не проверяет Персональные данные, предоставляемые Пользователем. В связи с этим
							Компания исходит из того, что при предоставлении Персональных данных Пользователь подтверждает и
							гарантирует следующее:
							<ul class="list">
								<li>2.1.1. Пользователь/Иное лицо является лицом, достигшим восемнадцатилетнего возраста,
									полностью дееспособное в соответствии с законодательством Российской Федерации.</li>
								<li>2.1.2. Пользователь/Иное лицо является гражданином Российской Федерации.</li>
								<li>2.1.3. Пользователем получено согласие Иных лиц на предоставление их Персональных данных
									Компании и обработку их Персональной информации Компанией в соответствии с условиями
									настоящей Политики;</li>
								<li>2.1.4. Пользователь указывает достоверную информацию о себе и об Иных лицах в объемах,
									необходимых для использования Сайта/Сервиса. Пользователь самостоятельно поддерживает
									предоставленную им информацию о себе и об Иных лицах в актуальном состоянии. Все риски
									за последствия предоставления Пользователем недостоверной или недостаточной информации
									несет Пользователь в полном объеме.</li>
								<li>2.1.5. Пользователь принимает условия Политики и дает Компании информированное и
									осознанное согласие на обработку Персональной информации на условиях, предусмотренных
									настоящей Политикой, Федеральными законом «О персональных данных» от 27.07.2006 N 152-ФЗ
									и иным применимым законодательством:
									<ul class="list" style="list-style-type: initial;">
										<li>2.1.5.1. При регистрации Пользователя на Сайте (создании личного кабинета в
											Сервисе) по ссылке
											<a href="https://lk.profitads.ru/registration"
												target="_blank">https://lk.profitads.ru/registration</a>: в момент нажатия
											кнопки «Зарегистрироваться». Нажатие указанной кнопки возможно только при
											условии активации чекбокса «Подтверждаю свое согласие с Пользовательским
											соглашением и Политикой конфиденциальности и обработки персональных данных»;
										</li>
										<li>2.1.5.2. При регистрации Пользователем бизнес-аккаунта в Сервисе: в момент
											нажатия кнопки «Сохранить». Нажатие указанной кнопки возможно только при условии
											активации чекбоксов «Подтверждаю свое согласие с Договором-офертой» и
											«Подтверждаю свое согласие с Политикой конфиденциальности и обработки
											персональных данных».</li>
									</ul>
								</li>
								<li>2.1.6. Совершая действия, указанные в п. 2.1.5. настоящей Политики, Пользователь
									Компании/Иное лицо/Иные лица дают согласие Компании на обработку Персональных данных,
									включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
									изменение), извлечение, использование, передачу (распространение, предоставление третьим
									лицам, доступ), обезличивание, блокирование, удаление, уничтожение Персональных данных с
									использованием и без использования средств автоматизации в соответствии с целями,
									указанными в разделе 3 настоящей Политики, а также передачу Персональной информации
									третьему лицу - Обществу с ограниченной ответственностью «Управляющая компания АМДГ»,
									расположенному по адресу: 125124, г.Москва, ул. 3-я Ямского Поля, д. 2, к. 26, в
									соответствии с заключенным между третьим лицом и Компанией договором.</li>
								<li>2.1.7. Предоставленное Пользователем в соответствии с настоящей Политикой согласие на
									обработку Персональных данных действует со дня предоставления такого согласия и в
									течение срока, необходимого для достижения целей обработки Персональных данных или до
									момента отзыва Пользователем/Иным лицом указанного согласия, если иное не предусмотрено
									действующим законодательством РФ. Предоставленное Пользователем/Иным лицом в
									соответствии с настоящей Политикой согласие на обработку Персональных данных может быть
									в любой момент отозвано Пользователем/Иным лицом. Пользователь/Иное лицо может отозвать
									предоставленное Компании согласие на обработку Персональных данных путем направления
									соответствующего письменного заявления Почтой России по адресу Компании: 125124, г.
									Москва, ул. Ямского поля 3-я, д.2, корп.26.</li>
								<li>2.1.8. Пользователь, использующий Сайт/Сервис с использованием личного кабинета,
									созданного на Сайте, и/или с использованием бизнес-аккаунта в Сервисе, подтверждает, что
									ознакомился с настоящей Политикой и дает согласие на обработку данных, указанных в п.
									1.2. настоящей Политики.</li>
							</ul>
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">3. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h3>
					<ul class="list">
						<li>3.1. Сайт собирает и хранит только ту Персональную информацию, которая необходима для
							предоставления Сервиса и исполнения соглашений и договоров с юридическим лицом/индивидуальным
							предпринимателем/физическим лицом, не зарегистрированным в качестве индивидуального
							предпринимателя, создавшим бизнес-аккаунт в Сервисе, за исключением случаев, когда
							законодательством предусмотрено обязательное хранение Персональной информации в течение
							определенного законом срока.</li>
						<li>3.2. Компания обрабатывает Персональную информацию в следующих целях:
							<ul class="list">
								<li>3.2.1. Идентификация Пользователя, зарегистрированного на Сайте (создавшего Личный
									кабинет).</li>
								<li>3.2.2. Идентификация контактного лица, указанного при регистрации Бизнес-аккаунта.</li>
								<li>3.2.3. Идентификация Пользователя/Иного лица, обращающегося в техническую поддержку при
									помощи функционала Сайта/Сервиса.</li>
								<li>3.2.4. Установление с Пользователем/Иным лицом обратной связи, включая направление любых
									уведомлений, запросов, касающихся использования Сайта/Сервиса, рассылок, направление
									которых предусмотрено Пользовательским соглашением, Договором-офертой на оказание услуг
									по предоставлению возможности исполнения Сервиса и иными документами, размещенными на
									Сайте, включая:
									<ul class="list" style="list-style-type: initial;">
										<li>направление информационных писем/уведомлений/предложений на адрес электронной
											почты, указанный Пользователем в Бизнес-аккаунте;</li>
										<li>использование иных контактных данных, предоставленных Пользователем при
											регистрации в Сервисе ProfitAds и при использовании Бизнес-аккаунта;</li>
										<li>осуществление рассылки Пользователям сообщений (в том числе сообщений по
											электронной почте, sms-сообщений и т.п.), являющихся уведомлениями о введении в
											действие новых услуг, либо отмене старых услуг, утверждении и опубликовании
											новой редакции Пользовательского соглашения или иных документов, размещенных на
											Сайте, а также содержащих рекламную информацию о Сервисе ProfitAds;</li>
										<li>рассылка Пользователям сообщений, содержащих рекламу товаров или услуг третьих
											лиц (с предварительного согласия Пользователей).</li>
									</ul>
								</li>
								<li>3.2.5. Определение места нахождения Пользователя для обеспечения безопасности,
									предотвращения мошенничества.</li>
								<li>3.2.6. Подтверждение достоверности и полноты Персональных данных, предоставленных
									Пользователем.</li>
								<li>3.2.7. Создание учетной записи (Личного кабинета) для регистрации на Сайте.</li>
								<li>3.2.8. Создание бизнес-аккаунта в Сервисе.</li>
								<li>3.2.9. Предоставление Пользователю/Иному лицу технической поддержки при возникновении
									проблем, связанных с использованием Сайта/Сервиса.</li>
								<li>3.2.10. Пополнение Баланса бизнес-аккаунта (включая пополнение Баланса банковской картой
									Visa/MasterCard/МИР в соответствии с условиями Договора-оферты, размещенного в сети
									Интернет по адресу: <a href="https://lk.profitads.ru/dogovor-oferty/"
										target="_blank">https://lk.profitads.ru/dogovor-oferty/)</a>.</li>
								<li>3.2.11. Исполнение Компанией обязательств по предоставлению информации о
									распространенной в сети Интернет-рекламе в Единый реестр Интернет-рекламы в целях
									соблюдения требований ст.18.1 Федерального Закона от 13.03.2006 г. № 38-ФЗ «О рекламе».
								</li>
							</ul>
						</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">4. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ И ЕЕ
						ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ</h3>
					<ul class="list">
						<li>4.1. В отношении Персональной информации сохраняется ее конфиденциальность.</li>
						<li>4.2. Администрация Сайта вправе передать Персональную информацию третьим лицам в следующих
							случаях:
							<ul class="list">
								<li>4.2.1. Пользователь/Иное лицо выразил согласие на такие действия.</li>
								<li>4.2.2. Передача необходима для использования Пользователем/Иным лицами Сервиса либо для
									исполнения определенного соглашения с Пользователем или договора с юридическим
									лицом/индивидуальным предпринимателем, создавшим бизнес-аккаунт в Сервисе.</li>
								<li>4.2.3. Передача предусмотрена российским или иным применимым законодательством в рамках
									установленной законодательством процедуры.</li>
							</ul>
						</li>
						<li>4.3. В случае продажи Сайта/Сервиса к приобретателю Сайта/Сервиса переходят все обязательства по
							соблюдению условий настоящей Политики применительно к полученной им Персональной информации.
						</li>
						<li>4.4. При утрате или разглашении Персональных данных Компания любым доступным способом
							информирует Пользователя/Иное лицо об утрате или разглашении Персональных данных.</li>
						<li>4.5. Компания принимает необходимые организационные и технические меры для защиты Персональной
							информации от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
							копирования, распространения, а также от иных неправомерных действий третьих лиц.</li>
						<li>4.6. Компания совместно с Пользователем принимает все необходимые меры по предотвращению убытков
							или иных отрицательных последствий, вызванных утратой или разглашением Персональной информации.
						</li>
						<li>4.7. Компания производит обработку Персональных данных с использованием баз данных на территории
							РФ.</li>
						<li>4.8. Хранение Персональной информации осуществляется на электронных носителях.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">5. ОБЯЗАТЕЛЬСТВА СТОРОН</h3>
					<ul class="list">
						<li>5.1. Пользователь обязан:
							<ul class="list">
								<li>5.1.1. Предоставить полные и достоверные Персональные данные, необходимые для
									пользования Сайтом/Сервисом.</li>
								<li>5.1.2. Обновлять, дополнять предоставленную Персональные данные в случае их изменения.
								</li>
							</ul>
						</li>
						<li>5.2. Компания обязана:
							<ul class="list">
								<li>5.2.1. Использовать полученную Персональную информацию исключительно для целей,
									указанных в настоящей Политике.</li>
								<li>5.2.2. Обеспечить хранение Персональной информации в тайне, не разглашать без
									предварительного письменного разрешения Пользователя/Контактного лица/Иных лиц, а также
									не осуществлять продажу, обмен, опубликование либо разглашение иными возможными
									способами переданной Персональной информации, за исключением предусмотренных настоящей
									Политикой.</li>
								<li>5.2.3. Принимать меры предосторожности для защиты конфиденциальности Персональной
									информации согласно порядку, обычно используемому для защиты такого рода информации в
									существующем деловом обороте.</li>
								<li>5.2.4. Осуществить блокирование Персональных данных, относящихся к соответствующему
									Пользователю/Иному лицу, с момента обращения или запроса Пользователя/Иного лица или его
									законного представителя либо уполномоченного органа по защите прав субъектов
									персональных данных на период проверки в случае выявления недостоверных Персональных
									данных или неправомерных действий.</li>
							</ul>
						</li>
					</ul>
				</li>
				<li>
					<h3 class="subtitle subtitle--blue">6. ОТВЕТСТВЕННОСТЬ</h3>
					<ul class="list">
						<li>6.1. Компания, не исполнившая свои обязательства, указанные в настоящей Политике, несет
							ответственность в размере документально подтвержденного реального ущерба, понесенного
							Пользователем//Иными лицами в связи с неправомерным использованием Персональной информации, в
							соответствии с законодательством Российской Федерации.</li>
					</ul>
				</li>

				<li>
					<h3 class="subtitle subtitle--blue">7. РАЗРЕШЕНИЕ СПОРОВ</h3>
					<ul class="list">
						<li>7.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем/Иными
							лицами и Компанией, обязательным является предъявление претензии. Срок рассмотрения претензии –
							20 (Двадцать) рабочих дней с момента ее получения.</li>
						<li>7.2. При недостижении соглашения спор будет передан на рассмотрение в суд по месту нахождения
							Компании в соответствии с правилами подведомственности, установленными законодательством
							Российской Федерации.</li>
						<li>7.3. К настоящей Политике и отношениям между Пользователем/Иными лицами и Компанией применяется
							действующее законодательство Российской Федерации.</li>
					</ul>
				</li>
				<li>
					<h3 class="subtitle subtitle--blue">8. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>
					<ul class="list">
						<li>8.1. Администрация Сайта вправе вносить изменения в настоящую Политику без согласия
							Пользователя. В случае внесения Компанией изменений в условия Политики, новая редакция Политики
							подлежит опубликованию в сети Интернет по адресу <a
								href="https://lk.profitads.ru/privacy-policy/"
								target="_blank">https://lk.profitads.ru/privacy-policy/</a> и вступает в силу с момента
							такого опубликования. Компания уведомляет Пользователя о внесении изменений в условия Политики в
							день опубликования новой редакции Политики путем направления соответствующей информации на
							электронный адрес Пользователя, указанный Пользователем при регистрации на Сайте (создании
							Личного кабинета), и на электронный адрес контактного лица, указанный при регистрации
							бизнес-аккаунта в Сервисе. В случае несогласия Пользователя с новой редакцией Политики,
							Пользователь обязан прекратить использование Сайта. Факт использования Пользователем
							Сайта/Сервиса означает согласие Пользователя с редакцией Политики, действующей на момент такого
							использования.</li>
						<li>8.2. Все вопросы по настоящей Политике следует сообщать по адресу места нахождения Компании:
							125124, г. Москва, ул. Ямского поля 3-я, д.2, корп.26.</li>
						<li>8.3. Действующая редакция Политики размещена в сети Интернет по адресу: <a
								href="https://lk.profitads.ru/privacy-policy/"
								target="_blank">https://lk.profitads.ru/privacy-policy/</a>.</li>
						<li>8.4. Настоящая Политика является неотъемлемой частью Пользовательского соглашения, размещенного
							в сети Интернет по адресу: <a href="https://lk.profitads.ru/agreement/"
								target="_blank">https://lk.profitads.ru/agreement/</a>, и Договора-оферты, размещенного в
							сети Интернет по адресу: <a href="https://lk.profitads.ru/dogovor-oferty/"
								target="_blank">https://lk.profitads.ru/dogovor-oferty/</a>.</li>
					</ul>
				</li>
			</ul>

			<p class="text">
				<b>Реквизиты ООО «ПрофитЭдс»</b>
				<br>Адрес места нахождения: 125124, г. Москва, ул. Ямского поля 3-я, д.2, корп.26
				<br>ОГРН: 1217700649176
				<br>ИНН: 7714480242
				<br>КПП: 771401001
				<br>Р/с: 40702810438000024448
				<br>В банке: ПАО СБЕРБАНК г. Москва
				<br>Корр.счет: 30101810400000000225
				<br>БИК: 044525225
				<br>Адрес электронной почты: info@profitads.ru
				<br><br>
				Редакция от 01.09.2023г.
			</p>
		</div>
		<div class="cookies-page__usa" v-if="locale === 'USA'">
			<h1 class="title">PRIVACY POLICY</h1>
			<p class="text">This Privacy Policy (this “Policy”) familiarizes you with our privacy practices connected to
				your use of our online interfaces and properties (e.g., websites and mobile applications) owned and
				controlled by Digital Eagle Inc. (“Company” or “we”), and services and products made available to you
				through our websites <a href="https://digital-eagle.us/" target="_blank"> https://digital-eagle.us/ </a> and <a
					href="https://cp.digital-eagle.us/" target="_blank"> https://cp.digital-eagle.us/ </a> (each and collectively the
				"Website") and in any other way. Terms used in this Policy have the meaning prescribed to them under our
				General Terms and Conditions (“our Terms”) unless otherwise provided herein. This Privacy Policy also
				functions as a Collection Notice.</p>
			<ol class="list">
				<li><b>Purpose.</b> This Policy discloses Company’s information collection, share and dissemination
					practices in
					connection with our Website, all apps, features and other our interactive services and products
					(collectively, the “Company Platform”, “Platform” or “our Platform”). Please, remember that your use of
					the Company Platform is also subject to our Terms. This Policy also addresses Personal Information that
					you provide to us in other contexts (e.g., through a business relationship not handled through the
					Company Platform), unless otherwise is defined by applicable law or parties’ agreement.
				</li>
				<li><b>Acceptance of Policy.</b> By using the Platform, you agree with this Policy being an integral part of
					our
					Terms. Please, be aware that if you do not agree to any provision of this Policy or do not accept its
					terms, you must not access to the Platform and/or use our services. Definitions, terms and conditions
					not specified in this Policy are defined in our Terms.

				</li>
				<li><b>Our Commitment.</b> We are committed to maintaining the privacy and security of your Personal
					Information,
					and we appreciate that information may be particularly sensitive. Company respects the privacy of all
					users of our Platform.
				</li>
				<li><b>Information We Collect and Process.</b> We may collect through the Platform, from other sources like
					third –
					party services, and optional programs in which you participate, store, use, process, share and disclose
					your personal information and sensitive personal information (“Personal Information”), and information
					about your activities and devices, particularly:
					<ul class="list disc">
						<li>full name;
						</li>
						<li>date of birth;
						</li>
						<li>home address;
						</li>
						<li>e-mail address;</li>
						<li>phone number;
						</li>
						<li>social security number, driver’s license, state identification card, or passport number;
						</li>
						<li>gender;</li>
						<li>precise geolocation information (including GPS and WiFi data), IP address, the browser used,
							version of operating system, identity of carrier and manufacturer, radio type (such as 4G),
							preferences and settings (such as preferred language), application installations, device
							identifiers, advertising identifiers, and push notification tokens;
						</li>
						<li>information about your interactions with the Platform like our apps and websites, including the
							pages and content you view and the dates and times of your use;
						</li>
						<li>ayment information, financial account, debit or credit card number in combination with any
							required security or access code, password, or credentials;
						</li>
						<li>account log-in, password, or credentials allowing access to an account;
						</li>
						<li>any other personal information you may voluntarily disclose on the Platform from time to time
							(for instance, a photo, saved addresses (e.g., home or work), preferred pronouns);
						</li>
						<li>contents of the messages or attachments you send us.
						</li>
					</ul>
					<p class="text">
						<b>Cookies, Analytics, and Third-Party Technologies.</b> We collect information through the use of
						“cookies”, tracking pixels, data analytics tools like Google Analytics, SDKs, and other third-party
						technologies to understand how you navigate through the Platform and interact with advertisements,
						to
						tailor
						our social media advertising, to make your experience with us safer, to learn what content is
						popular,
						to
						improve your website experience, to serve you better ads on other sites, and to save your
						preferences.
						Those
						cookies are set by us and called first-party cookies. We also use third-party cookies – which are
						cookies
						from a domain different than the domain of the website you are visiting – for our advertising and
						marketing
						efforts. Cookies are small text files that web servers place on your device; they are designed to
						store
						basic information and to help websites and apps recognize your browser. We use cookies and other
						tracking
						technologies in the following manner: Session cookie – these are cookies which expire at the end of
						the
						browser session; Persistent cookie – these are cookies which are store on your terminal equipment
						for
						longer
						than one browser session. They allow for your preferences, derived from actions on one or multiple
						sites, to
						be remembered. You can prevent your web browser from accepting new cookies or disabling cookies
						altogether.
						The “Help” feature on most browsers contains those settings. If you block or otherwise reject our
						cookies,
						you may not be able to register and/or use your personal account on the Platform, add items to your
						shopping
						cart or proceed to checkout.
					</p>
					<p class="text">
						<b>Third Party Services.</b> Third party services provide us with information needed for core
						aspects of
						the
						Platform, as well as for additional services, programs, loyalty benefits, and promotions that can
						enhance
						your experience. These third-party services can include financial service providers, marketing
						providers,
						and other businesses. We may obtain the following additional information about you from these
						third-party
						services:
					<ul class="list disc">
						<li>Information to make the Platform safer; </li>
						<li>Information to operationalize loyalty and promotional programs, such as information about your
							use
							of such programs; and
						</li>
						<li>Information about you provided by specific services, such as demographic and market segment
							information.
						</li>
					</ul>
					</p>
				</li>
				<li>
					<b>How We Use Your Information.</b> We use your Personal Information for the following purposes:
					<ol class="list" type="A">
						<li><b>Providing with Services.</b> We use your Personal Information to provide you with our
							services, an
							intuitive, useful, efficient, and worthwhile experience on our Platform. To do this, we use your
							Personal Information to: respond to your inquiries and fulfill your requests; verify your
							identity and maintain your account, settings, and preferences; calculate prices and process
							payments; keep a record of your contact information and correspondence and use it to respond to
							you via e-mail or other means of communication, communicate with you and collect feedback about
							your experience with us; send administrative information, for example information regarding the
							Platform and changes to our Terms; facilitate additional services and programs with third
							parties.
						</li>
						<li><b>Maintaining Safety and Security, Improving the Platform.</b> We are always working to improve
							your
							experience and provide you with new and helpful features. To do this, we use your Personal
							Information to: perform research, testing, and analysis; develop new products, features,
							partnerships, and services; authenticate users; find and prevent fraud; and block and remove
							unsafe or fraudulent users from the Platform; prevent, find, and resolve software or hardware
							bugs and issues; and monitor and improve our operations and processes, including security
							practices, algorithms, and other modeling.
						</li>
						<li><b>Building and maintaining the Community.</b> We work to be a positive part of the community.
							We may
							use your Personal Information to: communicate with you about events, promotions, elections, and
							campaigns; personalize and provide content, experiences, communications, and advertising to
							promote and grow the Platform. We may also use your Personal Information to deliver
							advertisements and marketing communications to you that we believe may be of interest. Marketing
							communications that you receive from us may include (i) advertisements for our service, and (ii)
							advertisements for other goods or services.
						</li>
						<li><b>Customer Support.</b> We work hard to provide the best experience possible, including
							supporting you
							when you need it. To do this, we use your Personal Information to: investigate and assist you in
							resolving questions or issues you have regarding the Platform; and provide you support or
							respond to you.
						</li>
						<li><b>Compliance and Responding to Legal Proceedings.</b> Sometimes the law, government entities,
							or other
							regulatory bodies impose demands and obligations on us with respect to the services we seek to
							provide. In such a circumstance, we may use your Personal Information to respond to those
							demands or obligations. We also collect, use and process your Personal Information as to be
							necessary or appropriate: (a) under applicable law, including laws outside your country of
							residence; (b) to comply with legal process; (c) to respond to requests from public and
							government authorities including public and government authorities outside your country of
							residence; (d) to enforce our Terms; (e) to protect our operations or those of any of our
							affiliates; (f) to protect our rights, privacy, safety or property, and/or that of our
							affiliates, you, or others; and (g) to allow us to pursue available remedies or limit the
							damages that we may sustain.
						</li>
					</ol>
				</li>
				<li><b>Consent to disclosure.</b> We treat all your Personal Information strictly confidentially, and we do
					not
					sell, publish, or otherwise share it with any third parties, unless specified otherwise in our Terms,
					and unless we need to share some of your information with our affiliates, your contracting parties,
					payment processor, web-hosting, website-related consulting and monitoring, data analysis, information
					technology service provider or other third-party service providers for purposes of providing our
					services to you. You acknowledge and agree that we may disclose your Personal Information to enable
					third parties for the legitimate purposes specified above.
					<br>
					You acknowledge and agree that we may disclose your Personal Information if required to do so by law or
					in the good faith belief that such disclosure is reasonably necessary to: (a) comply with a current
					judicial proceeding, a court order or legal process served on us, (b) enforce our Terms; (c) protect our
					rights, property or personal safety of our company, its members, agents, employees, users, and the
					public; or (d) enable the transfer or sale to another entity of all or substantially all assets in the
					line of our business, or upon any other company reorganization, subject to the promises made in our
					Terms.

				</li>
				<li><b>Records Retention and Protection of Your Information.</b> We retain your Personal Information within
					the
					period of your using our Platform, providing you services and then within the period reasonably
					necessary for the disclosed purpose, unless applicable laws state otherwise. This means we keep your
					profile information for as long as you maintain an account. We retain transactional information, such as
					payments, for at least seven years to ensure we can perform legitimate business functions, such as
					accounting for tax obligations. We take reasonable and appropriate measures designed to protect Personal
					Information, but no security measures can be 100% effective, and we cannot guarantee the security of
					your information, including against unauthorized intrusions or acts by third parties.
				</li>
				<li><b>Your Rights and Choices Regarding Your Data.</b> As explained more below, we provide ways to exercise
					your
					rights to your Personal Information.
					<ol class="list none">
						<li><b>8.1 RIGHT TO NON-DISCRIMINATION</b>
							<p class="text">We cannot deny services, charge you a different price, or provide a different
								level or quality of services just because you exercised your rights under the relevant Data
								Protection Law. However, if you refuse to provide your Personal Information to us or ask us
								to delete your Personal Information, and that Personal Information is necessary to provide
								you with services, we may not be able to complete the relevant transaction.
							</p>
						</li>
						<li><b>8.2 IGHT TO KNOW</b>
							<p class="text">You may request that we disclose to you what Personal Information we have
								collected, used or shared about you, and why we collected, used or shared that information.
								Specifically, you may request that we disclose:
							</p>
							<ul class="list disc">
								<li>The categories of Personal Information collected.
								</li>
								<li>Specific pieces of Personal Information collected.
								</li>
								<li>The categories of sources from which we collected Personal Information.
								</li>
								<li>The purposes for which we use the Personal Information.
								</li>
								<li>The categories of third parties with whom we share the Personal Information.
								</li>
								<li>The categories of information that we disclose to third parties.
									<p class="text">We must provide you this information free of charge and for the 12-month
										period preceding your request (in California – for and within the records retention
										period).
									</p>
									<p class="text">
										There are some exceptions to the right to know. Common reasons why we may refuse to
										satisfy your request include:
									</p>
								</li>
								<li>We do not retain Personal Information once the records retention period expires.</li>
								<li>We cannot verify your request.
								</li>
								<li>The request is manifestly unfounded or excessive, or we have already provided Personal
									Information to you more than twice in a 12-month period.
								</li>
								<li>We cannot disclose certain sensitive information, such as your social security number,
									financial account number, or account passwords, but we must tell you if we’re collecting
									that type of information.</li>
								<li>Disclosure would restrict our ability to comply with legal obligations, exercise legal
									claims or rights, or defend legal claims.
								</li>
								<li>If the Personal Information is certain medical information, consumer credit reporting
									information, or other types of information exempt by law.
								</li>
							</ul>
						</li>
						<li><b>8.3 RIGHT TO DELETE</b>
							<p class="text">In most circumstances, we will securely delete or dispose of Personal
								Information when we no longer need it for our legitimate business purposes outlined in this
								Policy. Our approach to retention is outlined in our records retention schedule.</p>
							<p class="text">You may request that we delete Personal Information we collected from you and to
								tell our service providers to do the same. However, there are many exceptions that allow us
								to keep your Personal Information.</p>
							<p class="text">There are exceptions to the right to delete. Common reasons why we may keep your
								Personal Information include:</p>
							<ul class="list disc">
								<li>We cannot verify your request.
								</li>
								<li>o complete your transaction, provide a reasonably anticipated service, or for certain
									warranty and product recall purposes.
								</li>
								<li>For certain business security practices.
								</li>
								<li>For certain internal uses that are compatible with reasonable consumer expectations or
									the context in which the information was provided.
								</li>
								<li>To comply with legal obligations, exercise legal claims or rights, or defend legal
									claims.
								</li>
								<li>If the Personal Information is certain medical information, consumer credit reporting
									information, or other types of information exempt by law.
								</li>
							</ul>
						</li>
						<li><b>8.4 RIGHT TO CORRECT AND RESTRICT</b>
							<p>
								You may request to correct inaccurate information, restrict sensitive personal information
								processing, update, suppress, or otherwise modify, any of your information that you have
								previously provided to us, or object to the use or processing of such information by us. In
								your request, please make clear what information you would like to have changed; whether you
								would like to have your information suppressed from our database or otherwise modified; or
								let us know what limitations you want to place upon your information. For your protection,
								we may only implement requests with respect to your information associated with the
								particular email address that you use to send us your request, and we may need to verify
								your identity before implementing your request. We will try to comply with your request as
								soon as reasonably possible. Please note that we may need to retain certain information for
								recordkeeping purposes, and there may also be residual information that will remain within
								our databases and other records, which will not be removed as defined hereby.
							</p>
						</li>
						<li><b>8.5 REQUEST TO KNOW/ TO CORRECT / TO RESTRICT/ TO DELETE</b>
							<p class="text">Make sure you submit your request to know/ to correct / to restrict or to delete
								through one of our designated methods specified in the “Contact” section below. If our
								designated method of submitting request is not working, notify us in writing and consider
								submitting your request through another designated method if possible.</p>
							<p class="text">We must respond to your request within 45 calendar days. We can extend that
								deadline by another 45 days (90 days total) if we notify you of it. If you submitted a
								request and have not received any response within the timeline, check this Policy to make
								sure you submitted your request through the designated way. </p>
							<p class="text">We must verify that the person making a request is the user about whom we have
								Personal Information. We may need to ask you for additional information for verification
								purposes. If we ask for Personal Information to verify your identity, we can only use that
								information for this verification purpose.</p>
							<p class="text">We must verify that the person making a request is the user about whom we have
								Personal Information. We may need to ask you for additional information for verification
								purposes. If we ask for Personal Information to verify your identity, we can only use that
								information for this verification purpose.</p>
							<p class="text">If you submit a request to our service provider instead of us, our service
								provider may deny the request. You must submit your request to us.</p>
						</li>
						<li><b>8.6 OTHER WAYS OF RIGHTS EXERCISE</b>
							<ol class="list roman-numbers-bold" type="i">
								<li><b>Email Subscriptions.</b> You can always unsubscribe from our commercial or
									promotional
									emails by clicking unsubscribe in those messages.
								</li>
								<li><b>Text Messages.</b> You can opt out of receiving text messages by contacting us as
									provided
									below (“Contact Us”). Note that opting out of receiving texts may impact your use of the
									Platform.</li>
								<li><b>Push Notifications.</b> You can opt out of receiving push notifications through your
									device
									settings. Please, note that opting out of receiving push notifications may impact your
									use of the Platform.</li>
								<li><b>Profile Information.</b> You can review and edit certain account information you have
									chosen
									to add to your profile by logging into your account settings and profile.</li>
								<li><b>Location Information.</b> You can prevent your device from sharing location
									information
									through your device’s system settings. But if you do, this may impact our ability to
									provide you our full range of features and services.</li>
								<li><b>Cookie Tracking.</b> You can modify your cookie settings on your browser, but if you
									delete
									or choose not to accept our cookies, you may be missing out on certain features of the
									Platform.</li>
								<li><b>Do Not Track.</b> Your browser may offer you a “Do Not Track” option, which allows
									you to
									signal to operators of websites and web applications and services that you do not want
									them to track your online activities. The Platform may not support Do Not Track
									requests.</li>
								<li><b>Right to Delete.</b> If you would like to delete your account at the Platform,
									please, visit
									our Platform. In some cases, we will be unable to delete your account, such as if there
									is an issue with your account related to trust, safety, or fraud. When we delete your
									account, we may retain certain information for legitimate business purposes or to comply
									with legal or regulatory obligations. For example, we may retain your information to
									resolve open payment claims, or we may be obligated to retain your information as part
									of an open legal claim. When we retain such data, we do so in ways designed to prevent
									its use for other purposes. In most circumstances, we will securely delete or dispose of
									Personal Information when we no longer need it for our legitimate business purposes
									outlined in this Policy.</li>
							</ol>
						</li>
						<li><b>8.7 CALIFORNIA RESIDENTS: Your California Privacy Rights.</b> Under the California Consumer
							Privacy Act of 2018 (“CCPA”), California residents have certain rights regarding our collection,
							use, and sharing of their Personal Information. We do not sell your Personal Information and
							will not do so in the future without providing you with notice and an opportunity to opt-out of
							such sale as required by law. Similarly, we do not offer financial incentives associated with
							our collection, use, or disclosure of your Personal Information. We collect various categories
							of Personal Information when you use the Platform, including Cookies, commercial information,
							internet or other electronic network or device activity information, geolocation data, and
							professional information.
							<p class="text">You have the right to know what Personal Information has been collected about
								you, to access that information, and to rectify your Personal Information (correct, update
								or modify). You also have the right to request deletion of your Personal Information, though
								exceptions under the CCPA may allow us to retain and use certain Personal Information
								notwithstanding your deletion request. You can learn more about how to submit a data rights
								request by contacting us by methods listed below in the “Contact Us” section. We will not
								discriminate against you for exercising your rights under the CCPA. An agent may submit a
								request on your behalf, but you must verify that your agent is authorized to do so.
							</p>
							<p class="text">
								We generally do not collect sensitive personal data, which is data such as your social
								security, driver’s license, state identification card, or passport number; religious or
								philosophical beliefs, racial or ethnic origin, health or medical information (other than
								for the purpose of processing your order or request), genetic or biometric data, bank
								account , debit card, or credit card number in combination with any required security or
								access code information (other than for the purpose of processing your order) or other
								similar sensitive personal data as such term is defined under applicable law. If we
								reasonably need to collect any sensitive personal data from you, we will obtain your consent
								for the collection of such personal data as may be required under applicable law.
							</p>
							<p class="text">
								Separate from the CCPA, California’s Shine the Light law gives California residents the
								right to ask companies what Personal Information they share with third parties for those
								third parties’ direct marketing purposes. We do not disclose your Personal Information to
								third parties for the purpose of directly marketing their goods or services to you unless
								you request such disclosure. If you have any questions regarding this policy, or would like
								to change your preferences, you may contact us by methods listed below in the “Contact Us”
								section.
							</p>
						</li>
						<li><b>8.8 NEVADA RESIDENTS: Your Nevada Privacy Rights.</b> Nevada law (SB 220) requires website
							operators to provide a way for Nevada consumers to opt out of the sale of certain information
							that the website operator may collect about them. We do not sell your Personal Information to
							third parties as defined in Nevada law, and will not do so in the future without providing you
							with notice and an opportunity to opt-out of such sale as required by law. If you have any
							questions regarding our data privacy practices or our compliance with Nevada data privacy law,
							please contact us at the email address listed below in the “Contact Us” section.
						</li>
						<li><b>8.9 GDPR Compliance.</b> As the Platform is not intended to be used by individuals residing
							in the European Union; is not advertised or directed towards individuals residing in the
							European Union; and is not hosted on websites, platforms, or is using technology that is located
							or operated inside the European Union, the Platform is not to be subject to European Union’s
							law.
							<p class="text">
								However, we make good faith efforts to comply with the European Union General Data
								Protection Regulation ("GDPR"). We fully protect the subject's personal data from misuse in
								accordance with applicable law, in particular while respecting the principles and rules set
								out in the Regulation (EU) 2016/679 of the European Parliament and of the Council, on the
								protection of natural persons with regard to the processing of personal data and on the free
								movement of such data, and repealing Directive 95/46/EC (General Data Protection
								Regulation). We process the personal data on the basis of the compliance with a legal
								obligation, performance of a contract, legitimate interests of the controller, and on the
								basis of consent of the data subject.
							</p>
						</li>
					</ol>
				</li>
				<li><b>Children’s Data.</b>
					The Platform is not directed to direct use by children, and we don’t knowingly collect Personal
					Information from children under the age of 18 unless such data is provided by their parents or lawful
					guardians. If you believe that a child under the age of 18 has given us Personal Information without
					his/her parents’ or lawful guardians’ permission, please Contact Us.
				</li>
				<li><b>Third-party Websites.</b> The Platform may contain links to third-party websites, plug-ins and
					applications (collectively, hereinafter “Links”). Clicking Links may allow third parties to collect or
					share data about you. We do not control these third-party sites or applications, and are not responsible
					for their privacy statements, policies, or terms. When you leave the Platform, we encourage you to read
					the privacy notices, policies, and terms of every other site which you visit. Links may or may not have
					our authorization, and we may block any Links to or from the Platform. Your use of third-party Links is
					at your own risk. You acknowledge and agree that we shall not be responsible or liable, directly or
					indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or
					reliance on any such content, goods or services available on or through any such Links.
				</li>
				<li><b>Changes to This Privacy Policy.</b>
					We may update this Policy from time to time as the Platform changes and privacy law evolves. If we
					update it, we will do so online, and if we make material changes, we will let you know through the
					Platform or by some other method of communication like email. When you use the Platform, you are
					agreeing to the most recent terms of this Policy.
				</li>
				<li><b>Dispute Resolution.</b>
					Any dispute, claim, or controversy arising out of or relating to this Policy or previous Privacy Policy
					statements shall be resolved through negotiation or dispute resolution as provided in our Terms.
				</li>
				<li><b>Entire agreement.</b>
					This Policy hereby incorporates by reference any additional terms that we post on the Platform
					(including, without limitation, our Terms) and, except as otherwise expressly stated herein, this Policy
					is the entire agreement between you and us relating to the subject matter herein and supersedes any and
					all prior or contemporaneous written or oral agreements or understandings between you and us relating to
					such subject matter.
				</li>
				<li><b>Contact Us</b>
					If you have any questions or comments about this Policy or our privacy practices, please contact us
					(submit your request) via email at info@digital-eagle.us. To the extent you are required to send a
					written request to us to exercise any right described in this Policy, you must submit your request to:
					<div class="text right margin-top">
						<span><b>Digital Eagle Inc.</b></span>
						<span><b>Attn:</b> Compliance Officer</span>
						<span><b>Address:</b> 1992 Morris Ave #326, Union New Jersey, 07083, USA</span>
						<span><b>Email:</b> <a href="mailto:info@digital-eagle.us">info@digital-eagle.us</a></span>
					</div>
					<p class="text">
						You may also make a complaint to your local data protection authority.
					</p>
				</li>
			</ol>

		</div>
		<!-- <div v-if="locale === 'USA'">
			<h1 class="title">{{ $t('privacy_policy.title') }}</h1>
			<h3 class="title subtitle--blue">Introduction</h3>
			<p class="text">
				Digital Eagle respect your privacy and are committed to protecting it through our
				compliance with this policy.

				This policy describes the types of information we may collect from you or that you may provide when you
				visit
				the website <a href=" https://digital-eagle.us/">digital-eagle</a> and our practices for collecting, using,
				maintaining, protecting and
				disclosing that information.
			</p>

			<h3 class="subtitle">This policy applies to information we collect:</h3>
			<ul class="list">
				<li>On this Website.</li>
				<li>In e-mail, text and other electronic messages between you and this Website, including, but not limited
					to financing applications and attachments submitted through the website.
				</li>
				<li>Through mobile and desktop applications you download from this Website, which provide dedicated
					non-browser-based interaction between you and this Website as well as through other applications to
					which you grant us access or which you link to our services (e.g. iTunes Connect, Google Play accounts
					and analytic tools).</li>
				<li>When you interact with our advertising and applications on third-party websites and services, if those
					applications or advertising include links to this policy.
				</li>
			</ul>

			<h3 class="subtitle">It does not apply to information collected by:</h3>
			<ul class="list">
				<li>us offline or through any other means, including on any other website operated by Company or any third
					party; or
				</li>
				<li>any third party, including through any application or content (including advertising) that may link to
					or be accessible from or on the Website.
				</li>
			</ul>
			<p class="text">
				Please read this policy carefully to understand our policies and practices regarding your information and
				how we will treat it. If you do not agree with our policies and practices, your choice is not to use our
				Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from
				time to time. Your continued use of this Website after we make changes is deemed to be acceptance of those
				changes, so please check the policy periodically for updates.
			</p>

			<h3 class="title subtitle--blue">Children Under the Age of 18</h3>
			<p class="text">
				Our Website is not intended for children under 18 years of age. No one under age 18 may provide any
				information to or on the Website. We do not knowingly collect personal information from children under 18.
				If you are under 18, do not use or provide any information on this Website or on or through any of its
				features/register on the Website, submit any financing applications or materials through the Website, use
				any of the interactive or public comment features of this Website or provide any information about yourself
				to us, including your name, address, telephone number, e-mail address or any screen name or user name you
				may use. If we learn we have collected or received personal information from a person under 18 without
				verification of parental consent, we will delete that information. If you believe we might have any
				information from or about a person under 18, please contact us at <a
					href="mailto:info@digital-eagle.us">info@digital-eagle.us</a>
			</p>

			<h3 class="title subtitle--blue">Information We Collect About You and How We Collect It</h3>
			<h3 class="subtitle">We collect several types of information from and about users of our Website, including
				information:
			</h3>

			<ul class="list">
				<li>
					by which you may be personally identified, such as name, postal address, e-mail address or telephone
					number (“personal information“);
				</li>
				<li>
					that is about you but individually does not identify you, such as financial and business information
					contained in financing applications; and/or
				</li>
				<li>
					about your internet connection, the equipment you use to access our Website and usage details.
				</li>
			</ul>
			<h3 class="subtitle">We collect this information:</h3>
			<ul class="list">
				<li>
					Directly from you when you provide it to us.
				</li>
				<li>
					Automatically as you navigate through the site. Information collected automatically may include usage
					details, IP addresses and information collected through cookies and other tracking technologies.
				</li>
			</ul>
			<p class="text"></p>
			<h3 class="title subtitle--blue">Information You Provide to Us</h3>
			<h3 class="subtitle">We collect this information:</h3>
			<ul class="list">
				<li>Information that you provide by filling in forms on our Website, including financing application forms.
					This includes information provided at the time of registering to use our Website, submitting financing
					application information, posting attachments, posting user generated content or material or requesting
					further services. We may also ask you for information when further processing a financing application,
					when you enter a contest or promotion sponsored by us, and when you report a problem with our Website.
				</li>
				<li>Records and copies of your correspondence (including e-mail addresses), if you contact us.</li>
				<li>Your responses to surveys that we might ask you to complete for research purposes.

				</li>
				<li>Details of transactions you carry out through our Website and of the fulfillment of your orders.</li>
			</ul>
			<p class="text">
				You also may provide information to be published or displayed (hereinafter, “posted“) on public areas of the
				Website, or transmitted to other users of the Website or third parties (collectively, “User Contributions“).
				Your User Contributions are posted on and transmitted to others at your own risk. Additionally, we cannot
				control the actions of other users of the Website with whom you may choose to share your User Contributions.
				Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized
				persons.
			</p>
			<h3 class="title subtitle--blue">Usage Details, IP Addresses And Cookies</h3>
			<h3 class="subtitle">We collect this information:</h3>
			<ul class="list">
				<li>Details of your visits to our Website, including traffic data, location data, logs and other
					communication data and the resources that you access and use on the Website.</li>
				<li>Information about your computer and internet connection, including your IP address, operating system and
					browser type.</li>
			</ul>
			<h3 class="subtitle">The information we collect automatically is statistical data, and does not identify any
				individual. It helps us to improve our Website and to deliver a better and more personalized service by
				enabling us to:
			</h3>
			<ul class="list">
				<li>Estimate our audience size and usage patterns.</li>
				<li>Store information about your preferences, allowing us to customize our Website according to your
					individual interests.</li>
				<li>Speed up your searches.</li>
				<li>Recognize you when you return to our Website.</li>
			</ul>
			<h3 class="subtitle">The technologies we use for this automatic data collection may include:
			</h3>
			<ul class="list">
				<li><b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of your computer.
					You
					may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if
					you select this setting you may be unable to access certain parts of our Website. Unless you have
					adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you
					direct your browser to our Website.</li>
				<li><b>Flash Cookies.</b> Certain features of our Website may use local stored objects (or Flash cookies) to
					collect and store information about your preferences and navigation to, from and on our Website. Flash
					cookies are not managed by the same browser settings as are used for browser cookies.
				</li>
				<li><b>Web Beacons.</b> Pages of our the Website and our e-mails may contain small electronic files known as
					web
					beacons (also referred to as clear gifs. pixel tags and single-pixel gifs) that permit the Company, for
					example, to count users who have visited those pages or opened an e-mail and for other related website
					statistics (for example, recording the popularity of certain website content and verifying system and
					server integrity).</li>
			</ul>
			<p class="text">We do not collect personal Information automatically, but we may tie this information to
				personal information about you that we collect from other sources or you provide to us.</p>
			<h3 class="title subtitle--blue">Third-party Advertiser Use of Cookies and Other Tracking Technologies</h3>
			<p class="text">Some advertisements on the Website are served by third-party advertisers, ad networks and ad
				servers. These third parties may use cookies alone or in conjunction with web beacons or other tracking
				technologies to collect information about our users. This may include information about users’ behavior on
				this and other websites to serve them interested-based (behavioral) advertising. We do not control these
				third parties’ tracking technologies or how they may be used. If you have any questions about an
				advertisement, you should contact the responsible advertiser directly.</p>

			<h3 class="title subtitle--blue">How We Use Your Information</h3>
			<h3 class="subtitle">We use information that we collect about you or that you provide to us, including any
				personal
				information:</h3>
			<ul class="list">
				<li>To present our Website and its contents to you.</li>
				<li>To provide you with information, products or services that you request from us.</li>
				<li>To fulfill any other purpose for which you provide it.</li>
				<li>To provide you with notices about your account.</li>
				<li>To carry out our obligations and enforce our rights arising from any contracts entered into between you
					and
					us, including for billing and collection.
				</li>
				<li>To notify you about changes to our Website or any products or services we offer or provide though it.
				</li>
				<li>In any other way we may describe when you provide the information.
				</li>
				<li>For any other purpose with your consent.
				</li>
			</ul>
			<p class="text">We may also use your information to contact you about [our own and third-parties’ goods and
				services
				that may be of interest to you. If you do not want us to use your information in this way, please check the
				relevant box located on the form on which we collect your data or adjust your user preferences in your
				account
				profile).</p>

			<p class="text">We may use the information we have collected from you to enable us to display advertisements to
				our
				advertisers’ target audiences. Even though we do not disclose your personal information for these purposes
				without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume
				that you meet its target criteria.
			</p>
			<h3 class="title subtitle--blue">Disclosure of Your Information</h3>
			<p class="text">
				We may disclose aggregated information about our users, and information that does not identify any
				individual,
				without restriction.
			</p>
			<h3 class="subtitle">We may disclose personal information that we collect or you provide as described in this
				privacy policy:
			</h3>
			<ul class="list">
				<li>To our subsidiaries and affiliates.</li>
				<li>To contractors, service providers and other third parties we use to support our business and who are
					bound
					by contractual obligations to keep personal information confidential and use it only for the purposes
					for
					which we disclose it to them (for example, to process financing applications).</li>
				<li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
					dissolution or other sale or transfer of some or all of Digital Eagle assets, whether as a going concern
					or
					as
					part of bankruptcy, liquidation or similar proceeding, in which personal information held by Digital
					Eagle
					about
					our Website users is among the assets transferred.</li>
				<li>To third parties to market their products or services to you if you have not opted out of these
					disclosures.
					We contractually require these third parties to keep personal information confidential and use it only
					for
					the purposes for which we disclose it to them.</li>
				<li>To fulfill the purpose for which you provide it. For example, if you give us an e-mail address to use a
					“share by e-mail” feature of our Website, we will transmit the contents of that e-mail and your e-mail
					address to the recipients.</li>
				<li>For any other purpose disclosed by us when you provide the information</li>
				<li>With your consent.</li>
			</ul>
			<h3 class="subtitle">We may also disclose your personal information:</h3>
			<ul class="list">
				<li>To comply with any court order, law or legal process, including to respond to any government or
					regulatory
					request.s</li>
				<li>To enforce or apply our Terms of Use and other agreements, including for billing and collection
					purposes.
				</li>
				<li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
					Braavo,
					our customers or others. This includes exchanging information with other companies and organizations for
					the
					purposes of fraud protection and credit risk reduction.</li>
			</ul>
			<p class="text"></p>
			<h3 class="title subtitle--blue">Choices About How We Use and Disclose Your Information</h3>
			<h3>We strive to provide you with choices regarding the personal information you provide to us. We have created
				mechanisms to provide you with the following control over your information:</h3>
			<ul class="list">
				<li><b>Tracking Technologies and Advertising.</b> You can set your browser to refuse all or some browser
					cookies, or to
					alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the
					Flash player settings page on Adobe’s website. If you disable or refuse cookies, please note that some
					parts
					of this site may then be inaccessible or not function properly.</li>
				<li><b>Disclosure of Your Information for Third-Party Advertising.</b> If you do not want us to share your
					personal
					information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by
					checking the relevant box located on the form on which we collect your data. You can also always opt-out
					by
					logging into the Website and adjusting your user preferences in your account profile by checking or
					unchecking the relevant boxes or by sending us an e-mail stating your request to <a
						href="mailto:info@digital-eagle.us">info@digital-eagle.us</a></li>
				<li><b>Promotional Offers from the Company.</b> If you do not wish to have your e-mail address/contact
					information used
					by the Company to promote our own or third parties’ products or services, you can opt-out by checking
					the
					relevant box located on the form on which we collect your data or at any other time by logging into the
					Website and adjusting your user preferences in your account profile by checking or unchecking the
					relevant
					boxes or by sending us an e-mail stating your request to <a
						href="mailto:info@digital-eagle.us">info@digital-eagle.us</a> If we have sent you a
					promotional e-mail, you may send us a return e-mail asking to be omitted from future e-mail
					distributions.
					This opt out does not apply to information provided to the Company as a result of a funded financing
					application.</li>
				<li><b>Targeted Advertising.</b> If you do not want us to use information that we collect or that you
					provide to
					us to
					deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by
					checking the relevant box located on the form on which we collect your data. You can also always adjust
					your
					user advertising preferences in your account profile by checking or unchecking the relevant boxes or by
					sending us an e-mail stating your request to <a
						href="mailto:info@digital-eagle.us">info@digital-eagle.us</a> For this opt-out to function, you must
					have your browser set to accept browser cookies.</li>
			</ul>
			<p class="text">We do not control third parties’ collection or use of your information to serve interest-based
				advertising.
				However these third parties may provide you with ways to choose not to have your information collected or
				used in this way. You can opt out of receiving targeted ads from members of the Network Advertising
				Initiative (“NAI“) on the NAI’s website.
			</p>
			<h3 class="title subtitle--blue">Accessing and Correcting Your Information</h3>
			<p class="text">You can review and change your personal information by logging into the Website and visiting
				your
				account profile page.</p>
			<p class="text">You may also send us an e-mail at <a
					href="mailto:info@digital-eagle.us">info@digital-eagle.us</a>
				to request access to, correct or delete any personal information that you have provided to us. We cannot
				delete
				your personal information except by also deleting your user account. We may not accommodate a request to
				change
				information if we believe the change would violate any law or legal requirement or cause the information to
				be
				incorrect.</p>
			<p class="text">If you delete your User Contributions from the Website, copies of your User Contributions may
				remain
				viewable in cached and archived pages, or might have been copied or stored by other Website users.</p>
			<h3 class="title subtitle--blue">Data Security</h3>
			<p class="text">We have implemented measures designed to secure your personal information from accidental loss
				and
				from unauthorized access, use, alteration and disclosure. Any payment transactions and financing application
				and
				financial information will be encrypted using SSL technology.</p>
			<p class="text">The safety and security of your information also depends on you. Where we have given you (or
				where
				you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this
				password confidential. We ask you not to share your password with anyone. We urge you to be careful about
				giving
				out information in public areas of the Website like message boards. The information you share in public
				areas
				may be viewed by any user of the Website.</p>
			<p class="text">Unfortunately, the transmission of information via the internet is not completely secure.
				Although
				we do our best to protect your personal information, we cannot guarantee the security of your personal
				information transmitted to our Website. Any transmission of personal information is at your own risk. We are
				not
				responsible for circumvention of any privacy settings or security measures contained on the Website.</p>
			<h3 class="title subtitle--blue">Changes to Our Privacy Policy</h3>
			<p class="text">It is our policy to post any changes we make to our privacy policy on this page [with a notice
				that
				the privacy policy has been updated on the Website home page]. If we make material changes to how we treat
				our
				users’ personal information, we will notify you by e-mail to the primary e-mail address specified in your
				account and/or through a notice on the Website home page. The date the privacy policy was last revised is
				identified at the top of the page. You are responsible for ensuring we have an up-to-date active and
				deliverable
				e-mail address for you, and for periodically visiting our Website and this privacy policy to check for any
				changes.</p>
			<h3 class="title subtitle--blue">Contact Information</h3>
			<p class="text">To ask questions or comment about this privacy policy and our privacy practices, contact us at
				<a href="mailto:info@digital-eagle.us">info@digital-eagle.us</a>.
			</p>
		</div> -->
	</div>
</template>

<script>
export default {
	name: 'PrivacyPolicy',
	metaInfo: {
		title: `${process.env.VUE_APP_LOCALE === 'USA' ? 'Digital eagle' : 'ProfitAds'}`
	},
	data() {
		return {
			locale: process.env.VUE_APP_LOCALE,
		}
	}

}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";

.cookies-page {
	width: 100%;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.9;
	color: $basicBlack;
	background: #fff;
	box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
	padding: 12rem;

	&__usa {
		>ol {
			>li::marker {
				font-weight: bold;
			}
		}
		ol.roman-numbers-bold {
			margin-left: 15px;
			>li::marker {
				font-weight: bold;
			}
		}
	}

	.title {
		font-size: 3rem;
		line-height: 4rem;
		font-weight: 700;
		margin: 0 0 3rem;
	}

	.subtitle {
		font-weight: 700;
		margin: 0 0 1.5rem;

		&--blue {
			color: var(--primary);
		}
	}

	.text {
		margin: 0 0 3rem;

		&--gray {
			color: $gray;
		}
	}

	.list {
		padding: 0 0 0 2rem;
		margin: 0 0 3rem;

		&__item {
			margin: 0 0 1.5rem;

			a {
				color: var(--primary);
			}

			ul {
				padding-left: 2rem;
			}
		}
	}

	a {
		color: var(--primary);
		text-decoration: underline;

		&:hover {
			opacity: 0.7;
		}
	}

	.link {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 1.6rem;
		line-height: 1.9;
		color: var(--primary);
		background-color: transparent;
		border: 1px solid var(--primary);
		border-radius: 4px;
		text-decoration: none;
		transition: color .4s linear, background-color .4s linear;
		padding: 7px 50px;

		&:hover {
			color: $basicWhite;
			background-color: var(--primary);
		}
	}

	.none {
		list-style: none;
	}

	.disc {
		list-style: disc;
	}

	.bold-roman-numbers {
		list-style-type: none;
		counter-reset: item;
	}

	.bold-roman-numbers>li {
		counter-increment: item;
	}

	.bold-roman-numbers>li:before {
		content: counter(item, lower-roman) ". ";
		font-weight: bold;
	}
	.right {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}
	div.margin-top {
		margin-top: 20px;
	}
}
</style>
