<template>
    <div class="selector multi-selector">
      <div class="selector__content" @click="showOptions = !showOptions">
        <div class="selector__title">{{ title }}</div>
        <div class="selector__value"> 
          <span v-if="selectedValues.length === options.length">{{ $t('multiselector.all') }}</span>
          <span v-else-if="!selectedValues.length">{{ placeholder }}</span>
          <div v-else class="selector__value-selected">
            <span v-for="user in selectedValues" :key="user.id">{{ user.name }}, </span>
          </div>
        </div>
        <!-- <div class="selector__arrow" :class="{'selector__arrow--open' : showOptions}">
          <img src="@/assets/icons/icon-arrow.svg" alt="arrow">
        </div> -->
      </div>
      <div v-if="showOptions" class="selector__options vk-selector">
        <div class="selector__options-item choose-all" v-if="!selectedValues.length || selectedValues.length < options.length" @click="choseAll(true)">
          {{ $t('multiselector.choose_all') }}
        </div>
        <div class="selector__options-item remove-all" v-else @click="choseAll(false)">
          {{ $t('multiselector.remove_all') }}
        </div>
        <div
            v-for="option in options"
            :key="option.id"
            class="selector__options-item"
            :class="{'selector__options-item--active': checkStatus(option.id)}"
            @click="chooseOption(option.id, option.name)"
        >
          <div>{{ option.name }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "MultiSelect",
    props: {
      title: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: null
      },
      selected: {
        type: [String, Array],
        default: null
      },
      placeholder: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        selectedValues: [],
        showOptions: false,
      }
    },
    created() {
      if (this.selected && this.selected.length) {
        this.selected === 'all' ? this.choseAll(true) : this.selectedValues = JSON.parse(JSON.stringify(this.selected))
      }
    },
    methods: {
      checkStatus(id) {
        if(this.selectedValues) {
          const checked = this.selectedValues.findIndex(el => el.id === id)
          return checked !== -1
        }
        return false;
      },
      chooseOption(id, name) {
        const index = this.selectedValues.findIndex(el => el.id === id);
        if (index === -1) {
          this.selectedValues.push({ id, name });
        } else {
          this.removeOption(id);
        }
        this.$emit('handleChange', this.selectedValues);
      },
      removeOption(id) {
        const index = this.selectedValues.findIndex(el => el.id === id);
        if (index !== -1) {
          this.selectedValues.splice(index, 1);
        }
      },
      choseAll(add) {
        this.selectedValues = [];
        if (add) {
          this.options.forEach(el => {
            this.selectedValues.push(el);
          });
        }
        this.$emit('handleChange', this.selectedValues);
      },
    },
  }
  </script>
  <style scoped lang="scss">
  @import "../../assets/styles/helpers/variables";
  @import "../../assets/styles/helpers/mixin";

  .selector {
    &__content {
      padding-right: 3rem;
      background: none;
      border: 1px solid #BFC2C6;
    }

    &__options {
      z-index: 9999;
    }
    
    &__value {
      overflow: hidden;
      color: #878B93;
      left: 1.4rem;
      font-size: 1.6rem;
      line-height: 1.9rem;

      &-selected {
        display: flex;
        gap: 0.5rem;

        span {
          white-space: nowrap;
          color: #333;
        }
      }
    }
    &__options-item {
      &.choose-all {
        font-weight: 700;
      }
      &.remove-all {
        font-weight: 700;
      }
    }
  }
</style>

  