<template>
    <div class="history-page page referral-history-page">
      <div class="history-page__title" :class="{'locale-usa': locale==='USA'}">
        <Title :text="$t('history.events')" />
        <!-- <div class="history-page__sum">
          {{ $t('history.total_amount') }}:
          <span>{{ sum ? numberWithSpaces(sum).split('.')[0] + " " + $t('currency.currency_symbol') : 0}}</span>
        </div> -->
      </div>
      <div class="history-page__search">
        <!-- <Search @findValue="changeSearchText" :placeholder="$t('search_props.placeholder')" /> -->
  
        <Selector
          class="history-page__selector"
          :title="$t('selector_props.event_type')"
          :options="transactionTypesItems"
          @handleChange="getNewData"
        />
        <DatePicker
          :title="$t('data_picker.title')"
          @changeDates="changeDate"
        />
      </div>
      <div class="history-page__table">
        <ReferralHistoryTable />
  
        <div class="history-page__pagination">
          <el-pagination
            v-if="pagination && pagination.total >=10"
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size="10"
            :current-page="pagination.currentPage"
            @current-change="getNewPage"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Title from "@/components/elements/Title";
//   import Search from "@/components/sort/Search";
  import Selector from "@/components/sort/Selector";
  import DatePicker from "@/components/sort/DatePicker";
  import ReferralHistoryTable from "@/components/blocks/ReferralHistoryTable";
  import fixDateFormatTransactions from '@/mixin/fixDate'
  import numberWithSpaces from '@/mixin/numberWithSpaces'
  
  export default {
    name: "ReferralHistory",
    components: {
      Title,
    //   Search,
      Selector,
      DatePicker,
      ReferralHistoryTable,
    },
    mixins: [fixDateFormatTransactions, numberWithSpaces],
    data () {
      return {
        locale: process.env.VUE_APP_LOCALE,
        loading: false,
        params: {
          per_page: 10,
        },
      }
    },
    watch: {
      $route() {
        for (let key in this.$route.query) {
          this.params[key] = this.$route.query[key]
        }
        this.getData()
      },
      activeAccount: {
        deep: true,
        handler(val) {
          if (val) {
            this.getTransactions()
          }
        }
      },
      agent: {
        deep: true,
        handler(val) {
          if (val) {
            this.getData()
          }
        }
      }
    },
    computed: {
      activeAccount() {
        return this.$store.state.Auth.activeAccount
      },
      pagination() {
        return this.$store.state.Referrals.paginationEvents
      },
      transactionTypes() {
        return this.$store.state.Referrals.transactionTypes
      },
      transactionTypesItems() {
        const arr = [{ name: this.$t('transaction_types_items.0'), id: 0 }]
        if (this.transactionTypes && Object.keys(this.transactionTypes)) {
          for(let item in this.transactionTypes){
            arr.push({ name: this.transactionTypes[item].name, id: item })
          }
        }
        return arr
      },
      sum() {
        return this.$store.state.Referrals.sum
      },
      user() {
        return this.$store.state.Auth.user
      },
      agent() {
        return this.$store.state.Auth.user.agent
      }
    },
    methods: {
      getNewPage(page) {
        const query = { ...this.$route.query, page }
        this.$router.push({query})
      },
      getData() {
        const clientToken = localStorage.getItem('client_token') || null
        const data = {
          ...this.params,
          agent_id: this.user.agent.id
        }
        this.$store.dispatch('Referrals/getHistory', {data, token: clientToken})
      },
      changeSearchText(val) {
        if (!val) {
          const query = {...this.$route.query, page: 1, search_text: null }
          this.$router.push({ path: this.$route.path, query })
        } else {
          const query = {...this.$route.query, page: 1, search_text: val }
          this.$router.push({ path: this.$route.path, query })
        }
      },
      getNewData(val) {
        if (val === 0) {
          const query = { ...this.$route.query, page: 1, type: null }
          this.$router.push({ path: this.$route.path, query })
        } else {
          const query = { ...this.$route.query, page: 1, type: val }
          this.$router.push({ path: this.$route.path, query })
        }
      },
      changeDate(val) {
        console.log('val', val)
        if (val 
            && this.fixDateFormatTransactions(val.startDate)!==this.$route.query.start_at 
            && this.fixDateFormatTransactions(val.endDate, true)!== this.$route.query.end_at) {
          const query = {
            ...this.$route.query, page: 1,
            start_at: this.fixDateFormatTransactions(val.startDate),
            end_at: this.fixDateFormatTransactions(val.endDate, true),
          }
          this.$router.push({ path: this.$route.path, query })
         
        } else {
          if(this.agent) this.getData()
        }
      },
      getTransactions() {
       const clientToken = localStorage.getItem('client_token') || null
        this.$store.dispatch('Referrals/getTransactionTypes', clientToken)
      },
    },
    mounted() {
      if (this.user) {
        this.getTransactions()
        for (let key in this.$route.query) {
          this.params[key] = this.$route.query[key]
        }
      }
      if(this.agent) this.getData()
    },
  }
  </script>
  
  <style scoped lang="scss">
    @import '@/assets/styles/helpers/variables';
    @import '@/assets/styles/helpers/mixin';

    .history-page {
      .date-picker {
        width: 50%;
      }
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
        &.locale-usa {
          font-family: 'Gilroy';
          font-style: normal;
          .title {
            font-weight: 700;
            font-size: 3.2rem;
          }
        }
      }
  
      &__sum {
        display: flex;
        align-items: center;
        font-size: 2.3rem;
        font-weight: 700;
        line-height: 1.7rem;
        color: $basicBlack;
        padding-left: 3rem;
  
        span {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.8rem;
          color: var(--primary);
          padding-left: 1rem;
        }
      }
  
      &__search {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;
  
        .search {
          flex: 1 0 auto;
        }
  
        .search, .selector {
          margin-right: 3.5rem;
        }
        .selector {
          width: 50%;
        }
      }
  
      @include below(993px) {
  
        &__title {
          margin-bottom: 24px;
  
          .title {
            font-size: 24px;
          }
        }
  
        &__sum {
          font-size: 22px;
          line-height: 1.4;
  
          span {
            font-size: 16px;
          }
        }
  
        &__search {
          margin-bottom: 16px;
  
          .search {
            width: 40%;
          }
        }
      }
  
      @include below(769px) {
  
        &__title {
          margin-bottom: 16px;
  
          .title {
            font-size: 18px;
          }
        }
  
        &__sum {
          font-size: 14px;
          line-height: 1.4;
          padding-left: 16px;
  
          span {
            font-size: 12px;
            padding-left: 4px;
          }
        }
  
        &__search {
          flex-direction: column;
  
          .search, .selector {
            width: 100%;
            margin-right: 0;
            margin-bottom: 8px;
          }
  
          .date-picker {
            width: 100%;
          }
        }
      }
    }
  
    .selector {
  
      &__content {
        display: none;
      }
  
      &__value {
        white-space: nowrap;
        overflow: hidden;
      }
    }
  </style>
  