import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_URL



  const state = () => ({
    promoList: null,
    pagination: null,
    promoAllList: null,
    promoCode: null,
    editPromo: null,
    errors: {},
    updatedPromoId: null
  })

  const getters = {}

  const mutations = {
    setData (state, data) {
        state[data.label] = data.data
    },
  }

    const actions = {
        generatePromo ({commit}, data) {
          return new Promise((resolve, reject) => {
            axios({
              url:`${apiUrl}/admin/promocode/generate`,
              method: 'GET',
              headers: { 'Authorization': `Bearer ${data.token}` }
            })
            .then(response =>{
              commit('setData', { label: 'promoCode', data: response.data })
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
          })
        },  

        getAllPromoList ({ commit }, data) {
            return new Promise((resolve, reject) => {
            axios({
                url: `${apiUrl}/admin/promocode`,
                method: 'GET',
                params: data.params,
                headers: { 'Authorization': `Bearer ${data.token}` }
            })
                .then(resp => {
                  commit('setData', { label: 'promoAllList', data: resp.data.data.data })
                  const pagination = {
                      total: resp.data.data.total,
                      currentPage: resp.data.data.current_page
                  }
                  commit('setData', { label: 'pagination', data: pagination })
                  resolve(resp)
                })
                .catch(err => {
                  console.log(err.response)
                  reject(err)
                })
            })
        },
        applyPromo({ commit }, data) {
          return new Promise((resolve, reject) => {
            axios({
              url: `${apiUrl}/business-account/${data.id}/promocode`,
              data: data.promo,
              method: 'POST' ,
              headers: { 'Authorization': `Bearer ${data.token}` }
            })
              .then(resp => {
              
                resolve(resp)
              })
              .catch(err => {
                console.log(err)
                commit('setData', { label: 'errors', data: err.response.data.errors })
                reject(err)
              })
          })
        },
        savePromo(_, data ) {
          return new Promise((resolve, reject) => {
            axios({ url: `${apiUrl}/admin/promocode`,
            method: 'POST',
            data:data,
            headers: { 'Authorization': `Bearer ${data.token}` }
            })
            .then(resp => {
              resolve(resp)
            })
            .catch(err => {
              reject(err)
            })
          })
        },

        updatePromo({commit}, data) {
          return new Promise((resolve, reject) => {
            axios({ url: `${apiUrl}/admin/promocode/${data.id}`,
            method: 'PATCH',
            data:data,
            headers: { 'Authorization': `Bearer ${data.token}` }
            })
            .then(resp => {
              commit('setData', { label: 'updatedPromoId', data: resp.data.data.id})
              resolve(resp)
            })
            .catch(err => {
              reject(err)
            })
          })
        },

        deletePromo(_, data) {
          return new Promise((resolve, reject) => {
            axios({ url: `${apiUrl}/admin/promocode/${data.id}`,
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${data.token}` }
            })
            .then(resp => {
              // commit('setData', { label: 'updatedPromoId', data: resp.data.data.id})
              resolve(resp)
            })
            .catch(err => {
              reject(err)
            })
          })
        }
         
    }

    export default {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
      };