<template>
    <div class="promocodes">
      <div class="container">
        <div class="promocodes__header">
          <div class="promocodes__title">
            {{$t('promo.title')}}
          </div>
          <div class="promocodes__add" @click="addPromo()">
            + {{$t('promo.add')}}
          </div>
        </div>
        <div class="promocodes__filters">
          <Search @search-input="fillSearch" :placeholder="$t('search_props.placeholder')" @findValue="changeSearchText" />
          <Selector
            v-if="this.$i18n._vm.locale==='ru'"
            title="Тип клиента"
            :options="clientTypes.ru"
            @handleChange="getClientType"
          />
          <Selector
            v-if="this.$i18n._vm.locale==='en'"
            title="Client type"
            :options="clientTypes.en"
            @handleChange="getClientType"
          />
  
          <Selector
            :title="$t('promo.status')"
            :options="promoStatuses"
            @handleChange="getStatus"
          />
          <DatePicker
            :title="$t('promo.active_period')"
            :use-timezone="true"
            @changeDates="changeDate"
          />
          <div class="promocodes_filters-button">
            <ButtonElement
              :text="$t('button_props.upload')"
              :disabled="false"
              @click-button="getAllPromoList"
              :ghost="true"
            >
            <template #icon>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Frame 520">
                  <path id="Vector 6" d="M12.6792 3.02148V17.4642M12.6792 17.4642L9.40625 14.1913M12.6792 17.4642L15.9521 14.1913" stroke="#395FE4" stroke-width="1.5"/>
                  <path id="Vector 7" d="M3.71094 17.8618V21.1694H21.6456V17.8618" stroke="#395FE4" stroke-width="1.5"/>
                </g>
              </svg>
            </template>
             
            </ButtonElement>
          </div>
        </div>
        <promo-table :promoList="promoList" :loading="loading"></promo-table>
        <div v-if="promoList && !promoList.length">
          {{$t('nothing_found')}}
        </div>
        <div class="promocodes__pagination">
          <el-pagination
            v-if="pagination && pagination.total >=10"
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size="10"
            :current-page="pagination.currentPage"
            @current-change="getNewPage"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Search from "@/components/sort/Search";
  import Selector from "@/components/sort/Selector";
  import fixDateFormat from '@/mixin/fixDate';
  import DatePicker from "@/components/sort/DatePicker";
  import ButtonElement from "@/components/elements/ButtonElement";
  import PromoTable from "@/components/blocks/PromoTable";
  import axios from 'axios'

  const apiUrl = process.env.VUE_APP_API_URL
  
  export default {
    name: 'ActivePromo',
    components: {
      Search,
      Selector,
      DatePicker,
      ButtonElement,
      'promo-table': PromoTable
    },
    mixins: [fixDateFormat],
    data () {
      return {
        locale: process.env.VUE_APP_LOCALE,
        clientTypes: {
        'ru': [
          
          {
            id: 0,
            name: "Все"
          },
          {
            id: 1,
            name: "Физлицо"
          },
          {
            id: 2,
            name:  "Юрлицо"
          }
        ],
        'en': [
          {
            id: 0,
            name: "All",
          },
          {
            id: 1,
            name: "Individual",
          },
          {
            id: 2,
            name:  "Entity"
          }
        ]
      },
        promoStatuses: [
          {id:'all', name:'All'},
          {id:'activated', name: 'Activated'},
          {id:'active', name: 'Active'},
          {id:'inactive', name: 'Inactive'}
        ],
        role: {
          finance: 'Финансист',
          admin: 'Админ',
          manager: 'Менеджер',
          user: 'Клиент',
        },
        loading: false,
        params: {
          per_page: 10,
        },
        period_from: new Date(),
        period_to: new Date(0),
        status: null,
        search: null
      }
    },
    computed: {
      updatedPromo() {
        return this.$store.state.promo.updatedPromoId
      },
      promoList() {
        let promos = []
        if(this.$store.state.promo.promoAllList) {
          let promoList = this.$store.state.promo.promoAllList.reduce((acc, el) => {
          const newObj = {
            ...el, 
            role_ru: this.role[el.role] || this.role.user,
            active_period: `${this.formatDate(el.available_at)}/${this.formatDate(el.expired_at)}`,
            available_at: this.formatDate(el.available_at),
            expired_at: this.formatDate(el.expired_at),
            start_at: el.available_at,
            end_at: el.expired_at
          }
          acc.push(newObj)
          return acc
          }, [])
          return promoList
        }
        return promos
      },
      pagination() {
        return this.$store.state.promo.pagination
      },
			clientRole() {
        return this.$store.state.Auth.user.role
      },
    },
    watch: {
      $route() {
        for (let key in this.$route.query) {
          this.params[key] = this.$route.query[key]
        }
        this.getPromoList()
      },
      search(value) {
        if(!value || !value.length)
        this.search = null
      },

      updatedPromo(value) {
        if(value) this.getPromoList()
      }

    },
    created() {
      // this.$store.commit('promo/setData', { label: 'updatedPromoId', data: null })
      for (let key in this.$route.query) {
        this.params[key] = this.$route.query[key]
      }
      this.getPromoList()
    },
    methods: {
      async getData() {
        this.loading = true
        const token = localStorage.getItem('token')
        await this.$store.dispatch('Users/getAllUsersList', { params: this.params, token })
        this.loading = false
      },
      async getPromoList() {
        this.loading = true
        const token = localStorage.getItem('token')
        await this.$store.dispatch('promo/getAllPromoList', { params: this.params, token })
        this.loading = false
      },

      fillSearch(value) {
    
        this.search = value
      },

     formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },

      exportExel() {
        return false
      },
      
      changeDate(val) {
        if (val) {
          this.period_from =  this.formatDate(val.startDate),
          this.period_to = this.formatDate(val.endDate)
          const query = {
          ...this.$route.query, page: 1,
            period_from: this.formatDate(val.startDate),
            period_to: this.formatDate(val.endDate)
          }
          this.$router.push({ query })
        }
      },

      getAllPromoList() {
        const token = localStorage.getItem('token')
        const params = {
          period_from: this.period_from,
          period_to: this.period_to,
          status: this.status,
          search: this.search
        }
        axios({ url: `${apiUrl}/admin/promocode/export`, method: 'GET', headers: { 'Authorization': `Bearer ${token} ` }, params })
          .then(resp => {
            this.dataError = false
            this.fileUrl = resp.data.data.url
            this.isDisabled = true
  
            const link = document.createElement('a');
            link.href = resp.data.data.url;
            link.download = 'build.xlsx';
            document.body.appendChild(link);
            link.click();
            this.$store.dispatch('Dialog/closeDialog')
            this.$notify({
              message: this.$t('notify.document_loaded'),
              type: 'success'
            });
          })
          .catch(err => {
            console.log(err.response)
            this.dataError = true
            this.respErr = err.response.statusText
            this.isDisabled = false
          })
        
    },

      changeSearchText(val) {
        if (!val) {
          const query = { ...this.$route.query, page: 1, search: null }
          this.$router.push({query})
        } else {
          this.search = val
          const query = {...this.$route.query, page: 1, search: val }
          this.$router.push({query})
        }
      },
      getNewPage(page) {
        const query = { ...this.$route.query, page }
        this.$router.push({query})
      },

      getNewData(val) {
        if (val === 0) {
          const query = { ...this.$route.query, page: 1, role: null }
          this.$router.push({query})
        } else {
          const role = val
          const query = { ...this.$route.query, page: 1, role }
          this.$router.push({query})
        }
      },

      getClientType(val) {
        if (val === 0) {
          const query = { ...this.$route.query, page: 1, is_for_legals: null }
          this.$router.push({query})
        } else if(val === 1)   {
          const query = { ...this.$route.query, page: 1, is_for_legals: 0 }
          this.$router.push({query})
        } else if(val === 2)   {
          const query = { ...this.$route.query, page: 1, is_for_legals: 1 }
          this.$router.push({query})
        }
      },

      getStatus(val) {
        if (val === 'all') {
          this.status = null
          const query = { ...this.$route.query, page: 1, status: null }
          this.$router.push({query})
        } else {
          const status = val
          this.status = val
          const query = { ...this.$route.query, page: 1, status }
          this.$router.push({query})
        }
      },

      addPromo() {
        if(this.clientRole ==='manager' || this.clientRole === 'finance' || this.clientRole === 'admin') {
          this.$store.commit('Dialog/setData', { label: 'promoTrigger', data: 'ActivePromo' })
					this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'PromoData' })
					this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
        }
      },
      // editUser(id) {
      //   let user = this.$store.state.Users.usersAllList.filter(el => el.id === id)[0]
      //   this.$store.commit('Users/setData', { label: 'editUser', data: user })
      //   this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditUser' })
      //   this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      // },
      tableRowClassName() {
          return 'el-table__row--promo-row';
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import "@/assets/styles/helpers/variables";

  .promocodes {
    padding: 5rem 0;
  
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3rem;
    }
  
    &__title {
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.6rem;
    }
  
    &__add {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: var(--primary);
      cursor: pointer;
    }
  
    &__filters {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
      gap: 2.4rem;

      .search {
        width: 70%;
      }
  
      .selector {
        width: 28%;
  
        &__content {
          width: 100%;
        }
      }
    }
  
   
    &__pagination {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
  
      .el-pagination .btn-next,
      .el-pagination .btn-prev,
      .el-pager li {
        background: transparent;
      }
    }
  }

  .promocodes_filters-button {
    height: 5rem;
    .button {
      height: 100%;
      min-width: 16rem;
      padding: 1.3rem 2.5rem;
    }
  }
  
  @media screen and (max-width: 992px) {
    .promocodes { 
      &__filters {
      flex-direction: column;
      }
      .search , .selector, .date-picker {
        width: 100%;
      }
    }
  }
  </style>
  