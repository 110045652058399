<template>
  <div v-if="items && items.length" class="faq-table">
    <el-table 
      :data="items" 
      style="width: 100%" 
      :highlight-current-row="true"
      :highlight-selection-row="true" 
      :indent="20"
    >
      <el-table-column width="150" :label="$t('faq.page.activity')">
        <template slot-scope="scope">
          <div>{{scope.row.active ? $t('faq.page.yes') : $t('faq.page.no') }}</div>
        </template>
      </el-table-column>
      <el-table-column width="150" :label="$t('faq.page.sort_order')">
        <template slot-scope="scope">
          <div>{{scope.row.pos}}</div>
        </template>
      </el-table-column>
      <el-table-column 
        width="150" 
        :label="$t('faq.page.title')"
      >
        <template slot-scope="scope">
          <div>{{scope.row.title}}</div>
        </template>
      </el-table-column>
      <el-table-column 
        class-name="faq-table__table-holding" 
        :label="$t('faq.page.text')" 
      >
        <template slot-scope="scope">
          <div v-html="scope.row.text"></div>
        </template>
      </el-table-column>
      <el-table-column width="50">
        <template slot-scope="scope">
          <el-dropdown
            class="faq-table__table-edit"
            trigger="click"
            :key="scope.id"
            @command="handleCommand"
          >
            <span class="el-dropdown-link faq-table__table-dropdown-item">
              <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 8.5C3 9.32843 2.32843 10 1.5 10C0.671573 10 0 9.32843 0 8.5C0 7.67157 0.671573 7 1.5 7C2.32843 7 3 7.67157 3 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17Z"
                  fill="#9BA2AB"/>
              </svg>
            </span>
            <el-dropdown-menu class="faq-table__table-change-list" :class="{'locale-usa': locale === 'USA'}" slot="dropdown">
              <el-dropdown-item 
                class="faq-table__table-change-list__item"
                :command="{name: 'edit', data: scope.row}"
              >
                  {{$t('edit')}}
              </el-dropdown-item>
              <el-dropdown-item 
                class=""
                :command="{name: 'delete', id: scope.row.id}"
              >
                  {{$t('delete')}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template> 
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: 'FAQTable',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {
      locale: process.env.VUE_APP_LOCALE,
    }
  },
  computed: {
    ...mapState('faq', ['pagination']),
  },
  methods: {
    ...mapMutations('faq', ['setForm', 'setPagination']),
    ...mapActions('faq', ['deleteFaq', 'fetchFaq']),
    handleCommand(command) {
      if (command.name === 'edit') {
        this.$router.push({ query: { mode: 'edit' }}).catch(() => {})
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditFAQ' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
        this.setForm({ ...command.data })
      }
      if (command.name === 'delete') {
        this.deleteFaq(command.id).then(() => {
          this.$notify({
            message: this.$t('faq.page.delete_success'),
            type: 'success'
          });

          this.fetchFaq({ params: { per_page: this.pagination.perPage, page: 1 } })
        })
      }
    },
  }
}
</script>
<style  lang="scss" >
  .faq-table {
      .el-table th > .cell, .el-table td > .cell {
          white-space: normal;
          word-break: break-word;
          padding-left: 2rem;
          padding-right: 1rem;
          font-size: 12px;
          text-align: left;
      }
  }
  .faq-table__table-edit__btn {
      padding:  0 1rem;
      &:hover {
          cursor: pointer;
      }
  }
  .faq-table {
      &__table{
          &-dropdown-item {
              &:hover {
                  cursor: pointer;
                  svg {
                      path {
                          fill: var(--primary);
                      }
                  }
              }
          }
          &-change-list {
              &__item {
                  border-bottom: 1px solid #DADEE6;
              }
          }
      }
  }
</style>