<template>
    <a href="https://t.me/info_profitads" target="_blank" class="animation-wrapper" :class="{'is-expanded':isExpand}">
      <div class="telegram-outside-link__wrap">
        <img src="../../assets/icons/icon-telegram-white.svg" alt="telegram icon" />
        <transition name="expand"> 
          <img v-if="isExpand" @click.prevent="close" class="close-icon" src="../../assets/icons/icon-small-cross.svg" alt="close icon" />
        </transition>
        <div class="telegram-outside-link__content">
            <p class="outside-link-first">{{ $t('linkElement.text1') }}</p>
            <transition name="expand"> 
              <p v-if="isExpand" class="outside-link-second">Напиши нам в <span class='telegram'>телеграм.</span></p>
            </transition>
        </div>
      </div>
      <transition name="expand">
        <div v-show="isExpand" class="telegram-outside-link__dont-show">
          <div class="line"></div>
          <span @click.prevent="dontShow" class="telegram-outside-link__text">Больше не показывать</span>
        </div>
      </transition>
    </a>
</template>

<script>
export default {
    name: "WidgetLinkElement",
    props:{
      isExpand: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data(){
      return {

      }
    },
    methods: {
      dontShow() {
        this.$emit('dontShow')
      },
      close() {
        this.$emit('close')
      }
    }
}
</script>

<style scoped lang="scss">

@import "@/assets/styles/helpers/variables";

.animation-wrapper{
  transition: height 1s ease;
  height: 83px;
}
.animation-wrapper.is-expanded {
height: 133px;
}

.expand-enter-active, .expand-leave-active {
  transition: all 0.7s ease;
}
.expand-enter, .expand-leave-to {
  transform: scale(0);
  opacity: 0;
}

.outside-link {
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $primary;
  border-radius: 4px;

  &__content {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $primary;
    margin-left: 1.6rem;
  }
}
.line {
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background: #D1D4D8;
}
.close-icon {
  position: absolute;
  top:0;
  right: 0;
}
.telegram-outside-link {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
  max-height: 150px;
  background-color: var(--primary);
  box-shadow: 0px 14px 32px 0px rgba(5, 6, 8, 0.08);
  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }
  &__dont-show {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 2rem;
  }
  &__text{
    color: #fff;
    text-decoration: underline;
    font-size: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  padding: 16px;
  border: 1px solid var(--primary);
  border-radius: 4px 4px 0px 0px;

  &__content {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-left: 1.6rem;
    color: #fff;
    .outside-link-second {
      .telegram {
        text-decoration: underline;
      }
    }
  }
}
// .app-sidebar__link {
//     margin-top: 2rem;
//     margin-bottom: -6rem;
//   }
</style>