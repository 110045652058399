<template>
  <div class="simple-layout">
    <div class="app-pages">
      <slot />
    </div>
    <div class="notifications-container">
      <MailNotification />
      <Cookies />
    </div>
  </div>
</template>

<script>
import Cookies from "@/components/blocks/Cookies";
import MailNotification from "@/components/blocks/MailNotification";

export default {
  name: "SimpleLayout",
  components: {
    Cookies,
    MailNotification
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/mixin";

.simple-layout {
  background: #F3F6FB;
  min-height: 100vh;
  padding: 7rem;

  .app-pages {
    padding: 0;
  }

  @include below(993px) {
    padding: 60px 32px;
  }

  @include below(769px) {
    padding: 32px 24px;
  }

  @include below(577px) {
    padding: 24px 16px;
  }
}
</style>
