<template>
  <div class="profile-card">
    <div class="profile-card__wrapper" @click="isMenu = !isMenu">
      <div
          v-if="role && (role === 'manager' || role === 'admin' || role === 'finance')"
          class="profile-card__name"
      >
        {{ userData.email ? userData.email : '' }}
      </div>
      <div
        v-else-if="userData"
        class="profile-card__name"
      >
        {{ activeAccount ? accountName : userData.name }}
        <span class="profile-card__email">{{ activeAccount ?  activeAccount.email : userData.email }}</span>
      </div>
      <div class="profile-card__avatar">
        <img :src="getPic(locale)" alt="ProfitAds">
      </div>
    </div>
    <div v-show="isMenu" class="profile-card__submenu">
      <button class="profile-card__submenu-close  hide-lg  hide-md  hide-sm" type="button" @click.stop="isMenu = false"
              aria-label="Показать меню" title="Показать меню">
        <svg class="close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="8.92896" y="6.10051" width="24" height="4" rx="1" transform="rotate(45 8.92896 6.10051)" fill="#333333"/>
          <rect x="6.10059" y="23.0711" width="24" height="4" rx="1" transform="rotate(-45 6.10059 23.0711)" fill="#333333"/>
        </svg>
      </button>
      <div class="profile-card__active">
        <div v-if="activeAccount && (role === 'user' || role === 'manager' || role === 'partner' || role === '')" class="profile-card__account active">
          <div class="profile-card__account-icon">
            <img :src="getPic(locale)" alt="ProfitAds"/>
          </div>
          <div class="profile-card__account-info">
            <div class="profile-card__account-name" v-text="accountName" />
            <div class="profile-card__account-mail" v-text="activeAccount.email" />
          </div>
        </div>
        <router-link v-if="activeAccount && role !== 'partner' && !isOpenReferral" :to="{name: 'Profile'}" class="profile-card__link">
          {{$t('profileCard.edit')}}
        </router-link>
        <div v-if="isOpenReferral && role === 'user'" class="profile-card__link" @click="editAgent(userData.agent)">
           {{ $t('profileCard.edit_partner') }}
        </div>
        <div class="profile-card__link" @click="logout">
          {{$t('profileCard.exit')}}
        </div>
      </div>
      <div v-if="((role === 'user' || role === 'manager' || role ==='partner' || role === '') && activeAccount)" class="profile-card__other">
        <div v-if="otherAccounts && otherAccounts.length" class="profile-card__other-title">
          {{$t('profileCard.other_accounts')}}
        </div>
        <div v-if="otherAccounts && otherAccounts.length" class="profile-card__other-list">
          <div
            v-for="item in otherAccounts"
            :key="item.id"
            class="profile-card__other-item"
          >
            <div class="profile-card__account" @click="changeActiveAccount(item)">
              <div class="profile-card__account-icon">
                <img src="@/assets/icons/icon-unactive-user.svg" alt="user photo">
              </div>
              <div v-if="role==='partner'" class="profile-card__account-info">
                <div class="profile-card__account-name" v-text="item.name" />
              </div>
              <div v-else class="profile-card__account-info">
                <div class="profile-card__account-name" v-text="item.is_legal ? item.legal_name : (item.first_name + ' ' +  item.last_name)" />
                <div class="profile-card__account-mail" v-text="item.email" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="role !== 'partner'" class="profile-card__other-add" @click="addAccount">
          <div class="profile-card__other-add-icon">
            +
          </div>
          <div class="profile-card__other-add-text">
            {{$t('profileCard.add_account')}}
          </div>
        </div>
        <div v-if="role === 'user' && userData.agent && !isOpenReferral && locale === 'RU'" class="profile-card__other-item">
          <div class="profile-card__account" @click="openReferral">
            <div class="profile-card__account-icon">
                <img src="@/assets/icons/icon-unactive-user.svg" alt="user photo">
              </div>
              <div class="profile-card__account-info">
                {{$t('profileCard.open_referral')}}
              </div>
          </div>
        </div>
        <div v-if="shouldShowProfileCard && !userData.agent && locale === 'RU'" class="profile-card__other-add" @click="addAgent">
          <div class="profile-card__other-add-icon">
            <img :src="getPicAgent(locale)" alt="add partner icon">
          </div>
          <div class="profile-card__other-add-partner">
            {{ $t('profileCard.add_partner') }}
          </div>
        </div>
      </div>
      <div v-else-if="((role === 'user'|| role === ''))" class="profile-card__other">
        <div v-if="role !== 'partner'" class="profile-card__other-add" @click="addAccount">
          <div class="profile-card__other-add-icon">
            +
          </div>
          <div class="profile-card__other-add-text">
            {{$t('profileCard.add_account')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfileCard',
  data() {
    return {
      isMenu: false,
      locale: process.env.VUE_APP_LOCALE,
    }
  },
  computed: {
    userData() {
      return this.$store.state.Auth.user
    },
    role() {
      let role = ''
      if (this.userData) {
        role = this.userData.role
      }
      return role
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    businessAccounts() {
      return this.$store.state.Auth.businessAccounts
    },
    otherAccounts() {
      const isOpenReferral = localStorage.getItem('isOpenReferral') || false
      if (this.activeAccount && this.businessAccounts && this.businessAccounts.length && !isOpenReferral) {
        return this.businessAccounts.filter(el => el.id !== this.activeAccount.id)
      } else if (this.businessAccounts && this.businessAccounts.length) {
        return this.businessAccounts
      } else {
        return null
      }
    },
    accountName() {
      let name = ''
      if (this.activeAccount) {
        if (this.activeAccount.is_legal) {
          name = this.activeAccount.legal_name
        }else if (this.userData.role && this.userData.role === 'partner') {
          name = this.activeAccount.name
        } else {
          name = this.activeAccount.first_name + ' ' +  this.activeAccount.last_name
        }
      }
      return name
    },
    businessAccountInfo() {
      return this.$store.state.Representatives.representativeBAInfo
    },
    shouldShowProfileCard() {
      return Object.keys(this.userData).length > 0 && this.role === 'user' && this.locale === 'RU';
    },

    isOpenReferral() {
      const open = localStorage.getItem('isOpenReferral')
      return open === 'true' ? true : false
    }

  },
  mounted() {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.profile-card__name')) {
        this.isMenu = false
      }
    }, true);
    if (this.activeAccount && this.role === 'partner') {
      this.getBAInfo()
    }
  },
  watch:{
    activeAccount: {
      deep: true,
      handler(val) {
          if (val && this.role === 'partner') {
            this.getBAInfo()
          }
      }
    }
  },
  methods: {
    getPic(locale) {
      if(locale==='RU') {
        return  require("@/assets/icons/icon-user.svg")
      } else {
        return require("@/assets/icons/icon-user-usa.svg")
      }
    },
    getPicAgent(locale) {
      if(locale==='RU') {
        return  require("@/assets/icons/icon-partner.svg")
      } else {
        return require("@/assets/icons/icon-partner-usa.svg")
      }
    },
    changeActiveAccount(acc) {
      if (acc) {
        this.$store.commit('Auth/setData', { label: 'activeAccount', data: acc })
        localStorage.setItem('activeBA', acc.id);
        const referral = localStorage.getItem('isOpenReferral') || false
        if (referral) {
          localStorage.removeItem('isOpenReferral');
          this.$router.push('/statistics')
          window.location.reload();
        }
        
      }
    },
    async logout() {
      await this.$store.dispatch('Ad/logout')
      await this.$store.dispatch('Auth/logout')
        .then(() => {
          this.$router.push('/')
        })
    },
    addAccount() {
      this.$store.commit('Dialog/setData', { label: 'isEditable', data: false })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BusinessAccountData' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    getBAInfo() {
      const data = {
        user_id: this.userData.id,
        ba_id: this.activeAccount.id
      }
      this.$store.dispatch('Representatives/getRepresentativeBAInfo', data)
    },
    addAgent() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'ReferralData' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    editAgent(agent) {
      this.$store.commit('Dialog/setData', { label: 'isEditable', data: true })
      this.$store.commit('Referrals/setData', { label: 'editAgent', data: agent })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'ReferralData' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openReferral() {
      localStorage.setItem('isOpenReferral', true);
      this.$router.push({ name: 'ReferralStatistics' })
      window.location.reload();
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.profile-card {
  position: relative;

  @include below(993px) {
    max-width: none;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__submenu {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    min-width: 30rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 10;

    &-item {
      cursor: pointer;
      transition: 0.3s ease-out;

      &:hover {
        color: #2F80ED;
      }
    }
  }

  &__link {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-left: 5rem;
    margin-bottom: 1.6rem;
    cursor: pointer;
    transition: 0.3s ease-out;
    display: block;

    &:hover {
      color: var(--primary);
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:first-of-type {
      margin-bottom: 1.6rem;
    }
  }

  &__active {
    border-bottom: 1px solid #DADEE6;
    padding: 2rem;
  }

  &__other {
    padding: 2rem 0;

    &-list {
      max-height: 28rem;
      overflow-y: auto;
    }

    &-item {
      cursor: pointer;
      padding: 1rem 2rem;
      transition: 0.3s ease-out;

      &:hover {
        background: #F5F7FA;
      }
    }

    &-title {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #7F858D;
      margin-bottom: 1.8rem;
      padding: 0 2rem;
    }

    &-add {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 1rem 2rem;
      transition: 0.3s ease-out;

      &:hover {
        background: #F5F7FA;
      }

      &-icon {
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 50%;
        background: #E6EAF2;
        color: #7F858D;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.4rem;
      }

      &-text {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #7F858D;
      }

      &-partner {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: var(--primary);
      }
    }
  }

  &__account {
    display: flex;
    align-items: center;

    &.active {
      margin-bottom: 1.6rem;

      .profile-card__account-name {
        font-weight: 700;
      }
    }

    &-icon {
      width: 3.6rem;
      height: 3.6rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 1.4rem;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    &-info {
      width: calc(100% - 5rem);
    }

    &-name {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-bottom: 0.2rem;
    }

    &-mail {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #7F858D;
    }
  }

  &__call {
    margin-right: 3.3rem;
    display: flex;
    align-items: center;
  }

  &__name {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-right: 2rem;
    text-align: right;
    max-width: 250px;
    color: #7F858D;
  }

  &__email {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6;
    color: $gray;
  }

  &__avatar {
    min-width: 3.6rem;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include below(993px) {

    &__name, &__email {
      font-size: 14px;
      line-height: 1.4;
    }

    &__name {
      margin-right: 8px;
    }
  }

  @include below(577px) {

    &__name, &__email {
      font-size: 12px;
    }

    &__name {
      margin-right: 4px;
    }
  }
}

@include below(769px) {
  .profile-card__submenu-close{
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    outline: none;
  }
  .profile-card__submenu{
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    z-index: 1100;
  }
  .profile-card__other{
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .profile-card__other-list{
    flex-grow: 1;
  }
}
</style>
