<template>
  <div class="statistics page">
    <div v-if="statistic && !Array.isArray(statistic)" class="statistics__period">
      <div class="statistics__period-title">{{ $t('home.statistics') }}</div>
      <div v-if="lang === 'en'" :key="lang" class="calendar statistics__calendar">
        <date-range-picker class="statistics__period-calendar" :key="lang"
          :class="{ 'statistics__period-calendar--open': isShow }" ref="picker" v-model="date" single-date-picker="range"
          :auto-apply="true" :always-show-calendars="true" :ranges="false" :locale-data="localeEn"
          @toggle="isShow = !isShow" @update="getNewStatistic" opens="left">
          <template #input="picker">
            {{ fixDate(picker.startDate, picker.endDate) }}
          </template>
        </date-range-picker>
      </div>
      <div v-if="lang === 'ru'" :key="lang" class="calendar statistics__calendar">
        <date-range-picker class="statistics__period-calendar" :key="lang"
          :class="{ 'statistics__period-calendar--open': isShow }" ref="picker" v-model="date" single-date-picker="range"
          :auto-apply="true" :always-show-calendars="true" :ranges="false" :locale-data="localeRu"
          @toggle="isShow = !isShow" @update="getNewStatistic" opens="left">
          <template #input="picker">
            {{ fixDate(picker.startDate, picker.endDate) }}
          </template>
        </date-range-picker>
      </div>
    </div>
    <div v-if="statistic && !Array.isArray(statistic)">
      <div class="statistics__data">
        <div class="statistics__data-ad">
          <div class="statistics__data-header">
            <div class="statistics__data-title">
              {{ $t('home.advertising') }}
            </div>
            <div class="statistics__data-selector"></div>
          </div>
          <div class="statistics__data-ad-list">

            <data-add-item :title="$t('home.replenishment_amount')" :total="totalBalance"
              :img="locale === 'RU' ? 'icon-limit.svg' : 'icon-limit-usa.svg'" :statObject="adStatistics.total_balance" />
            <data-add-item :title="$t('home.expenses')" :total="totalSpent" :statObject="adStatistics.total_spent"
              :img="locale === 'RU' ? 'icon-cost.svg' : 'icon-cost-usa.svg'" />
            <data-add-item :title="$t('cashback')" :total="totalCashback" :statObject="adStatistics.total_cashback"
              :img="locale === 'RU' ? 'icon-cashback-statistic.svg' : 'icon-cashback-statistic-usa.svg'" />

          </div>
        </div>
        <div class="statistics__data-cost">
          <div class="statistics__data-header">
            <div class="statistics__data-title">
              {{ $t('home.account_spending') }}
            </div>
            <div class="statistics__data-selector">
              <button 
                :title="$t('transactionDialog.uploadReport')"
                class="statistics__download"
                @click="openTransactionsDialog"
              >
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.0037 9.5C18.8413 9.74603 20 11.2143 20 14.4286V14.5317C20 18.0794 18.5891 19.5 15.0657 19.5H9.93431C6.41093 19.5 5 18.0794 5 14.5317V14.4286C5 11.2381 6.14293 9.76984 8.93326 9.50794" stroke="#395FE4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.5 4.5V14.5" stroke="#395FE4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 12.5L12.5 15.5L10 12.5" stroke="#395FE4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <img src="@/assets/icons/icon-xlsx-document-2.svg" alt="">
              </button>
            </div>
          </div>
          <div class="statistics__data-cost-values">
            <div class="statistics__data-cost-diagram" :class="{ empty: !totalSpent }">
              <img v-if="!totalSpent" src="@/assets/images/doughnut-chart-default.svg"
                class="statistics__data-cost-diagram__img" alt="doughnutChart" title="doughnutChart">
              <DoughnutChartElement v-else ref="doughnutChart" :data="dataDoughnutChart" />
            </div>

            <div class="statistics__data-cost-legend" :class="{'locale-usa':locale==='USA'}">
              <ul v-if="!totalSpent" class="statistics__data-cost-legend__list">
                <li>{{ $t('no_data') }}</li>
                <li>{{ $t('no_data') }}</li>
                <li>{{ $t('no_data') }}</li>
              </ul>
              <!-- This temporaryAddNamesToDynamicOfSpent should be removed to addNamesToDynamicOfSpent -->
              <div v-else v-for="(item, idx) in addNamesToDynamicOfSpent" :key="idx" class="statistics__data-cost-item">
                <div class="statistics__data-cost-name">
                  <span :style="`background-color: ${getColor(idx)};`" />
                  <!-- {{idx}} -->
                  {{ item.name}}
                </div>
                <div class="statistics__data-cost-percent">
                  {{locale ==='USA'? numberWithSpacesFix2(item.total_spent_in_service) : numberWithSpacesCeil(item.total_spent_in_service) }} {{ $t('currency.currency_symbol') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!totalSpent" class="statistics__chart-default">
        <div class="statistics__chart-default__title">{{ $t('home.statistics_no_data_text') }}</div>
        <p>{{ $t('home.statistics_no_data_change_range') }}</p>
        <img src="@/assets/images/statistics-chart-default.svg" alt="statistics chart default"
          title="statistics chart default">
      </div>

      <div v-else class="statistics__chart">
        <div class="statistics__chart-header">
          <div class="statistics__chart-title">
            {{ $t('statistics_chart.dynamics_of_ad_spend') }}
          </div>
          <div class="statistics__chart-filter">
            <div class="statistics__chart-filter-item" :class="{ active: activePeriod === 'day' }"
              @click="changeActivePeriod('day')">
              {{ $t('statistics_chart.day') }}
            </div>
            <div class="statistics__chart-filter-item" :class="{ active: activePeriod === 'week' }"
              @click="changeActivePeriod('week')">
              {{ $t('statistics_chart.week') }}
            </div>
            <div class="statistics__chart-filter-item" :class="{ active: activePeriod === 'month' }"
              @click="changeActivePeriod('month')">
              {{ $t('statistics_chart.month') }}
            </div>
          </div>
        </div>
        <div class="statistics__chart-data">
          <LineChartElement ref="lineChart" :data="dataChart" :minimal-yticks="0" :show-tooltip-title="false" />
        </div>
      </div>
    </div>
    <div v-else class="statistics__default">
      <!-- <img src="@/assets/images/statistics-default.svg" alt="statistics" title="statistics"> -->
      <div class="statistics__default-title">{{ $t('statistics_default.statistics_default_title') }}</div>
      <p>{{ $t('statistics_default.statistics_default_text') }}</p>
    </div>
  </div>
</template>

<script>
import checkCashback from '@/mixin/checkCashback'
import numberWithSpaces from '@/mixin/numberWithSpaces'
import numberWithSpacesCeilFix2 from '@/mixin/numberWithSpaces'
import DateRangePicker from 'vue2-daterange-picker'
import DataAddItem from '../components/blocks/DataAddItem.vue'

export default {
  name: 'Home',
  components: {
    DoughnutChartElement: () => import('@/components/elements/DoughnutChartElement'),
    LineChartElement: () => import('@/components/elements/LineChartElement'),
    DateRangePicker,
    // DatePicker,
    'data-add-item': DataAddItem
  },
  mixins: [checkCashback, numberWithSpaces, numberWithSpacesCeilFix2],
  data() {
    return {
      isShow: false,
      locale: process.env.VUE_APP_LOCALE,
      lang: this.$i18n._vm.locale,
      date: {
        startDate: new Date(),
        endDate: new Date()
      },
      minDate: null,
      localeEn: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        // daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        daysOfWeek: ['sun', 'mon', 'tu', 'wen', 'th', 'fr', 'sat'],
        // daysOfWeek: [this.$t('daysOfWeek.0'), this.$t('daysOfWeek.1'), this.$t('daysOfWeek.2'), this.$t('daysOfWeek.3'), this.$t('daysOfWeek.4'), this.$t('daysOfWeek.5'), this.$t('daysOfWeek.6')],
        // monthNames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'],
        monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        // monthNames: [this.$t('monthNames.0'), this.$t('monthNames.1'), this.$t('monthNames.2'), this.$t('monthNames.3'), this.$t('monthNames.4'), this.$t('monthNames.5'), this.$t('monthNames.6'), this.$t('monthNames.7'), this.$t('monthNames.8'), this.$t('monthNames.9'), this.$t('monthNames.10'), this.$t('monthNames.11')],
        firstDay: 1
      },
      localeRu: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        // daysOfWeek: [this.$t('daysOfWeek.0'), this.$t('daysOfWeek.1'), this.$t('daysOfWeek.2'), this.$t('daysOfWeek.3'), this.$t('daysOfWeek.4'), this.$t('daysOfWeek.5'), this.$t('daysOfWeek.6')],
        monthNames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'],
        // monthNames: [this.$t('monthNames.0'), this.$t('monthNames.1'), this.$t('monthNames.2'), this.$t('monthNames.3'), this.$t('monthNames.4'), this.$t('monthNames.5'), this.$t('monthNames.6'), this.$t('monthNames.7'), this.$t('monthNames.8'), this.$t('monthNames.9'), this.$t('monthNames.10'), this.$t('monthNames.11')],
        firstDay: 1
      },
      monthsRu: [
        {
          id: 0,
          shortName: 'янв'
        },
        {
          id: 1,
          shortName: 'фев'
        },
        {
          id: 2,
          shortName: 'мар'
        },
        {
          id: 3,
          shortName: 'апр'
        },
        {
          id: 4,
          shortName: 'май'
        },
        {
          id: 5,
          shortName: 'июн'
        },
        {
          id: 6,
          shortName: 'июл'
        },
        {
          id: 7,
          shortName: 'авг'
        },
        {
          id: 8,
          shortName: 'сен'
        },
        {
          id: 9,
          shortName: 'окт'
        },
        {
          id: 10,
          shortName: 'нояб'
        },
        {
          id: 11,
          shortName: 'дек'
        }
      ],
      monthsEn: [
        {
          id: 0,
          shortName: 'jan'
        },
        {
          id: 1,
          shortName: 'feb'
        },
        {
          id: 2,
          shortName: 'mar'
        },
        {
          id: 3,
          shortName: 'apr'
        },
        {
          id: 4,
          shortName: 'may'
        },
        {
          id: 5,
          shortName: 'jun'
        },
        {
          id: 6,
          shortName: 'jul'
        },
        {
          id: 7,
          shortName: 'aug'
        },
        {
          id: 8,
          shortName: 'sep'
        },
        {
          id: 9,
          shortName: 'oct'
        },
        {
          id: 10,
          shortName: 'nov'
        },
        {
          id: 11,
          shortName: 'dec'
        }
      ],
      // months: [
      //   {
      //     id: 0,
      //     shortName: this.$t('monthNames.0')
      //   },
      //   {
      //     id: 1,
      //     shortName: this.$t('monthNames.1')
      //   },
      //   {
      //     id: 2,
      //     shortName: this.$t('monthNames.2')
      //   },
      //   {
      //     id: 3,
      //     shortName: this.$t('monthNames.3')
      //   },
      //   {
      //     id: 4,
      //     shortName: this.$t('monthNames.4')
      //   },
      //   {
      //     id: 5,
      //     shortName: this.$t('monthNames.5')
      //   },
      //   {
      //     id: 6,
      //     shortName: this.$t('monthNames.6')
      //   },
      //   {
      //     id: 7,
      //     shortName: this.$t('monthNames.7')
      //   },
      //   {
      //     id: 8,
      //     shortName: this.$t('monthNames.8')
      //   },
      //   {
      //     id: 9,
      //     shortName: this.$t('monthNames.9')
      //   },
      //   {
      //     id: 10,
      //     shortName: this.$t('monthNames.10')
      //   },
      //   {
      //     id: 11,
      //     shortName: this.$t('monthNames.11')
      //   }
      // ],
      activePeriod: 'day',
      dataChart: {
        datasets: [],
        labels: []
      },
      dataDoughnutChart: {
        datasets: [{
          data: [],
          backgroundColor: [],
          borderWidth: [0, 0, 0, 0]
        }],
        labels: []
      }
    }
  },
  computed: {
    platformList() {
      const platforms = this.$store.state.Ad.platformsList
      const platFormList = platforms.reduce((initial, item) => {
        const obj = { name: item.name, slug: item.slug, statistics: item.statistics }
        initial.push(obj)
        return initial
      }, [])
      return platFormList
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    services() {
      return this.$store.state.Ad.statistic.services
    },
    visibleServices() {
     return this.$store.state.Auth.activeAccount.visible_services 
    },
    adStatistics() {
      const visibleServices = this.$store.state.Auth.activeAccount.visible_services
      const transformedServices = {
        total_balance: {},
        total_spent: {},
        total_cashback: {}
      };
      if (this.statistic) {
        const services = this.services
        const platformList = this.platformList
    
        if(services) {
          for (const [key, value] of Object.entries(services)) {
            const platform = platformList.find(platform => platform.slug === key);
            transformedServices.total_balance[key] = { amount: value.total_balance, isVisible: visibleServices[key], statistics: platform ? platform.statistics : false };
            transformedServices.total_spent[key] = { amount: value.total_spent, isVisible: visibleServices[key], statistics: platform ? platform.statistics : false };
            transformedServices.total_cashback[key] = { amount: value.total_cashback, isVisible: visibleServices[key], statistics: platform ? platform.statistics : false };
            }
          }
      }
     
      return transformedServices
    },
    temporaryAddNamesToDynamicOfSpent() {
      let entries = Object.entries(this.addNamesToDynamicOfSpent);
            let filtered = entries.filter(([key]) => key !== "telegram");
            let result = Object.fromEntries(filtered);
            return result;
    },

    addNamesToDynamicOfSpent() {
      const platformList = this.platformList.filter(platform => platform.statistics )
      const dynamic_of_spent = this.statistic.dynamic_of_spent
      const result = {};
      for (const key in dynamic_of_spent) {
        if (Object.prototype.hasOwnProperty.call(dynamic_of_spent, key)) {
          const platform = platformList.find(p => p.slug === key);
          if (platform) {
            result[key] = { ...dynamic_of_spent[key], name: platform.name };
          } 
        }
      }
      const filteredObject = {};
      //remove mytarget and vkontakte fields in the USA locale
      if (this.locale === 'USA') {
        for(let key in result) {
          if(key !=='mytarget' && key !== 'vkontakte') {
            filteredObject[key] = result[key]
          }
        }
        return filteredObject
      }
      return result;
    },
    
    statistic() {
      return this.$store.state.Ad.statistic
    },
    totalBalance() {
      let balance = 0
      if (this.statistic) {
        balance += +this.statistic.total_balance
      }
      return Math.floor(balance)
    },
    totalSpent() {
      let balance = 0
      if (this.statistic) {
        balance += +this.statistic.total_spent
      }
      return Math.floor(balance)
    },
    totalCashback() {
      let balance = 0
      if (this.statistic) {
        balance += +this.statistic.total_cashback
      }
      return Math.floor(balance)
    },
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    },
    filteredData() {
     let result = this.dataChart.datasets.filter(item => item.label !=='telegram')
 
     return { ...this.dataChart, datasets :result }
    // return this.dataChart
    }
  },
  watch: {
    getLang: {
      deep: true,
      handler(val) {
        this.dataPickerforceRerender(val)
      }
    },
    statistic: {
      deep: true,
      handler(val) {
        this.dataDoughnutChart.datasets[0].data = []
        this.dataDoughnutChart.labels = []
        if (val) {
          this.changeActivePeriod('day')
          this.setDoughnutData()
        }
      }
    },
    activeAccount: {
      deep: true,
      handler(val) {
        if (val) {
          this.getNewStatistic()
        }
      }
    },
  },
  mounted() {
    if (this.statistic) {
      this.changeActivePeriod('day')
      this.setDoughnutData()
    }
    const endMonth = this.date.endDate.getMonth()
    const endYear = this.date.endDate.getFullYear()

    const start = new Date(endYear + '/' + (endMonth + 1) + '/01')
    if (start) this.date.startDate = start
    const targetDate = new Date()
    this.minDate = new Date(targetDate.setDate(targetDate.getDate() - 90))
    if (this.activeAccount) {
      this.getNewStatistic()
    }
  },
  methods: {
    addObject(arr, obj) {
      function findIndexByLabel(arr, label) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].label === label) {
            return i;
          }
        }
        return -1;
      }

      let index = findIndexByLabel(arr, obj.label);
      if (index >= 0) {
      arr[index] = obj;
      } else {
        arr.push(obj);
      }
    },
    getPic(data) {
      let result = ''
      if(this.locale==='RU') {
        result = require(`@/assets/icons/${data[0]}`)
      } else {
        result = result = require(`@/assets/icons/${data[1]}`)
      }
      return result
    },
    dataPickerforceRerender(value) {
      this.lang = value
    },
    setDoughnutData() {
      if (this.statistic) {
        for (let key in this.statistic.spent_percent_per_services) {
          if (this.statistic.spent_percent_per_services[key]) {
            this.dataDoughnutChart.datasets[0].data.push(+this.statistic.spent_percent_per_services[key])
            this.dataDoughnutChart.labels.push(key)
            this.dataDoughnutChart.labels = this.dataDoughnutChart.labels.map((element)=>{
              if(element ==="facebook") {
                return "meta"
              }else {
                return element
              }
            })
            let color = ''
            if (key === 'mytarget') {
              color = '#fc2c38'
            } else if (key === 'yandex') {
              color = '#fc0'
            } else if (key === 'vkontakte') {
              color = '#5181b8'
            } else if (key === 'google') {
              color = '#34a853'
            } else if (key === 'tiktok'){
              color = '#000000'
            } else if (key === 'promopages'){
              color = '#8B00FF'
            } else if (key === 'telegram'){
              color = '#229ED9'
            }else {
              color = '#2e89ff'
            }
            this.dataDoughnutChart.datasets[0].backgroundColor.push(color)
          }
        }
        if (this.$refs.doughnutChart) this.$refs.doughnutChart.update()
      }
    },
    getColor(place) {
      if (place === 'mytarget') {
        return '#fc2c38'
      } else if (place === '') {
        return '#fc0'
      } else if (place === 'yandex') {
        return '#fc0'
      } else if (place === 'vkontakte') {
        return '#5181b8'
      } else if (place === 'google') {
        return '#34a853'
      } else if (place === 'tiktok') {
        return '#000'
      }else if (place === 'vkads') {
        return '#00D9ED'
      }else if (place === 'promopages') {
        return '#8B00FF'
      }else if (place === 'telegram') {
        return '#229ED9'  
      }else {
        return '#2e89ff'
      }
    },
    updateDataExample(spent, date) {
      if (spent.length) {
        spent.forEach((el, index) => {
          const dataChartItem = {}
          dataChartItem.data = spent[index].spentItem
          dataChartItem.label = spent[index].platform.name
          dataChartItem.statistics = spent[index].platform.statistics
          dataChartItem.borderWidth = 2,
          dataChartItem.backgroundColor = 'transparent'
          dataChartItem.borderColor =  spent[index].platform.slug=== 'mytarget' 
          ? '#fc2c38' 
          :  spent[index].platform.slug === 'yandex' 
          ? '#fc0' 
          : spent[index].platform.slug === 'vkontakte' 
          ? '#5181b8' 
          : spent[index].platform.slug === 'google' 
          ? '#34a853' 
          : spent[index].platform.slug === 'tiktok' 
          ?'#000'
          : spent[index].platform.slug === 'vkads' 
          ?'#00D9ED'
          : spent[index].platform.slug === 'promopages' 
          ?'#8B00FF'
          : spent[index].platform.slug === 'telegram' 
          ?'#229ED9'
          // : spent[index].platform.slug === 'facebook'
          // ?'#4267B2' 
          :'#2e89ff'

          this.addObject(this.dataChart.datasets, dataChartItem)
         
        })
        this.dataChart.labels = date
        if (this.$refs.lineChart) this.$refs.lineChart.update()
        
      } else {
        this.dataChart.datasets.forEach((el) => {
          el.data = []
          el.label = []
        })
        if (this.$refs.lineChart) this.$refs.lineChart.update()
      }
    },
    changeActivePeriod(period) {
      this.activePeriod = period
      const spent = []
      const date = []
      const type = period === 'day' ? 'by_days' : period === 'week' ? 'by_weeks' : 'by_months'

      if (this.statistic) {
        for (let key in this.statistic.dynamic_of_spent) {
          if (this.statistic.dynamic_of_spent[key][type]) {
            const spentItem = []
            this.statistic.dynamic_of_spent[key][type].forEach(el => {
              spentItem.push({ x: el.date || el.for_date, y: el.spent })
              date.push(el.date || el.for_date)
            })
            const platformName = this.platformList.find(element => element.slug === key)
          
            // spent.push({ spentItem, label: key })
            spent.push({ spentItem, label: platformName.name, platform: {name: platformName.name, slug: platformName.slug, statistics: platformName.statistics } })
         
          }
        }
      
        const uniqueDates = date.filter((v, i, a) => a.indexOf(v) === i).sort()
        //remove mytarget and vkontakte
        let filteredSpent = []
        if (this.locale === 'USA') {
          // filteredSpent = spent.filter( item => item.label !== 'mytarget' && item.label !== 'vkontakte')
          filteredSpent = spent.filter( item => item.platform.slug !== 'mytarget' && item.platform.slug !== 'vkontakte')
      
          this.updateDataExample(filteredSpent, uniqueDates)
        } else {
          // filteredSpent = spent.filter( item => item.label !== 'telegram')
          // filteredSpent = spent.filter( item => item.platform.slug !== 'telegram')
          this.updateDataExample(spent, uniqueDates)
        }
      }
    },
    fixDate(start, end) {
      if (start && end) {
        let str = ''
        const startDay = start.getDate()
        const endDay = end.getDate()

        const startMonth = start.getMonth()
        const endMonth = end.getMonth()

        let nameStartMonth, nameEndMonth
        if (this.$i18n._vm.locale === 'ru') {
          nameStartMonth = this.monthsRu.find(el => el.id === startMonth).shortName
          nameEndMonth = this.monthsRu.find(el => el.id === endMonth).shortName
        } else if (this.$i18n._vm.locale === 'en') {
          nameStartMonth = this.monthsEn.find(el => el.id === startMonth).shortName
          nameEndMonth = this.monthsEn.find(el => el.id === endMonth).shortName
        }

        // const nameStartMonth = this.months.find(el => el.id === startMonth).shortName
        // const nameEndMonth = this.months.find(el => el.id === endMonth).shortName

        if (startDay === endDay && startMonth === endMonth) {
          str = startDay + ' ' + nameStartMonth
        } else if (startMonth === endMonth) {
          str = startDay + '-' + endDay + ' ' + nameStartMonth
        } else {
          str = startDay + ' ' + nameStartMonth + ' - ' + endDay + ' ' + nameEndMonth
        }

        return str
      }
    },
    fixDateFormatNumbers(fixedDate) {
      if (fixedDate) {
        let date = new Date(fixedDate)
        let str = ''
        const startDay = String(date.getDate()).padStart(2, '0')
        const startMonth = String(date.getMonth() + 1).padStart(2, '0');
        const startYear = date.getFullYear()
        str = startYear + '-' + startMonth + '-' + startDay
        return str
      }
    },
    getNewStatistic() {
      const data = {
        id: this.activeAccount.id,
        params: {
          date_from: this.fixDateFormatNumbers(this.date.startDate),
          date_to: this.fixDateFormatNumbers(this.date.endDate)
        }
      }
      this.$store.dispatch('Ad/getStatistic', data)
    },

    // testDataRange() {
    //   console.log(this.dateRange)
    // },
    openTransactionsDialog() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'TransactionsXLS' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.statistics {
  &__calendar {
    display: flex;
  }

  &__period {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(158, 158, 158, 0.1);
    border-radius: 6px;
    padding: 1.6rem 2.6rem;
    margin: 0 0 4rem;

    &-title {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 2.8rem;
      color: $basicBlack;
    }

    &-calendar {
      .reportrange-text {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.9rem;
        color: $basicBlack;
        background: transparent;
        border: none !important;
        position: relative;
        padding: 0 15px 0 0 !important;

        &::after {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          background: url("../assets/icons/icon-arrow.svg") no-repeat;
          background-size: contain;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate3d(0, -50%, 0);
          transition: 0.3s ease-out;
        }
      }

      .daterangepicker td.active,
      .daterangepicker td.active:hover {
        background-color: var(--primary);
      }

      &--open {
        .reportrange-text {

          &::after {
            transform: translate3d(0, -50%, 0) rotate(180deg);
          }
        }

        .daterangepicker.opensleft.opensleft {
          right: -10px;
        }
      }
    }
  }

  &__default {
    max-width: 69rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $basicBlack;
    padding: 6rem 0 0;

    img {
      display: block;
      width: 35.8rem;
      height: auto;
      margin: 0 0 4rem;
    }

    &-title {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.3rem;
      margin: 0 0 1rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin: 0;
    }
  }

  &__data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4.5rem;


    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.5rem;
    }

    &-title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #333333;
    }

    &-ad {
      width: 43%;
      padding-left: 3rem;

      // &-item {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   margin-bottom: 2rem;

      //   &:last-child {
      //     margin-bottom: 0;
      //   }
      // }

      &-item {
        margin-bottom: 2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-icon {
        display: block;
        width: 3.3rem;
        height: 3.3rem;
        margin-right: 1.2rem;
      }

      &-name {
        font-size: 1.6rem;
        line-height: 1.9;
        color: #333333;
      }

      &-title {
        display: flex;
        align-items: center;
      }

      &-value {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.9;
        color: #333333;

        &.empty {
          font-weight: 400;
          color: $grey1;
        }
      }
    }

    &-cost {
      // width: 39%;
      width: 45%;

      &-values {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
      }

      &-diagram {
        width: 42%;

        &.empty {
          width: 15rem;

          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.4rem;
        line-height: 1.6rem;
        margin-bottom: 1.5rem;
        position: relative;
        min-width: 17rem;
      }

      &-name {
        margin-right: 1.6rem;
        display: flex;
        align-items: center;

        &>span {
          display: block;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          margin-right: 1rem;
        }
      }

      &-percent {
        white-space: nowrap;
      }

      &-legend {
        &.locale-usa {
          align-self: flex-start;
        }
        &__list {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            position: relative;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.9rem;
            color: $grey1;
            padding: 0 0 0 3rem;
            margin: 0 0 1rem;

            &::before {
              width: 1rem;
              height: 1rem;
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate3d(0, -50%, 0);
              border-radius: 50%;
              background-color: $gray3;
            }

            &:nth-child(2) {

              &::before {
                background-color: $gray7;
              }
            }

            &:last-child {
              margin-bottom: 0;

              &::before {
                background-color: $grey1;
              }
            }
          }
        }
      }
    }
  }

  &__chart {
    background: #FFFFFF;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 4rem;

    &-default {
      max-width: 69rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 9rem 0 0;

      &__title {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.3rem;
        margin: 0 0 1.5rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 1.9rem;
        margin: 0 0 3rem;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4.5rem;
    }

    &-title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    &-filter {
      display: flex;
      font-size: 1.4rem;
      line-height: 2.2rem;

      &-item {
        border: 1px solid #D9D9D9;
        padding: 0.5rem 1.6rem;
        color: #595959;
        cursor: pointer;
        transition: 0.3s ease-out;

        &.active,
        &:hover {
          color: #fff;
          background: var(--primary);
          border: 1px solid var(--primary);
        }

        &:first-of-type {
          border-right: none;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-of-type {
          border-left: none;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  &__download {
    border: none;
    background: none;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--primary);
    border-radius: 4px;
    border: 1.5px solid var(--primary);
    padding: 8px 10px;
  }
}

@include below(769px) {
  .statistics__period-title {
    margin-right: 20px;
  }

  .statistics__data {
    flex-wrap: wrap;
  }

  .statistics__data-ad {
    width: 100%;
    margin-bottom: 30px;
    padding-left: 0;
  }

  .statistics__data-cost {
    width: 100%;
  }

  .statistics__period,
  .statistics__chart {
    padding: 15px;
  }

  .statistics__chart-header {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .statistics__chart-title {
    margin-bottom: 10px;
  }

  .statistics__period-calendar {
    flex-shrink: 0;
  }

  .statistics__period-calendar .reportrange-text {
    white-space: nowrap;
  }

  .statistics__chart-data {
    overflow-x: auto;

    >div {
      min-width: 500px;
    }
  }
}
</style>
