<template>
  <div class="not-found">
    <div class="not-found__block">
      <h1 class="not-found__title">{{$t('notfound.404')}}</h1>
      <div class="not-found__text">
        {{$t('notfound.404_text')}}
      </div>
      <a href="/" class="not-found__link">{{$t('notfound.404_button')}}</a>
      <!-- <router-link to="/" class="not-found__link">На главную</router-link> -->
    </div>
    <div class="not-found__img">
      <!-- <img src="../assets/images/404.svg" alt="404"> -->
      <img :src="imageUrl" alt="404">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  data(){
    return {
      locale: process.env.VUE_APP_LOCALE
    }
  },
  computed: {
    imageUrl() {
      if(this.locale==='RU') {
        return  require("@/assets/images/404.svg")
      } else {
        return require("@/assets/images/404_usa.svg")
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.not-found {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem;

  @include below($sm) {
    padding: 6rem 2rem;
  }

  &__block {
    max-width: 52rem;

    @include below($sm) {
      text-align: center;
    }
  }

  &__title {
    font-size: 5rem;
    font-weight: 700;
    line-height: 5.9rem;
    color: $basicBlack;
    margin: 0 0 4rem;
  }

  &__text {
    font-size: 2.3rem;
    font-weight: 400;
    line-height: 2.7rem;
    color: $basicBlack;
    margin: 0 0 4rem;
  }

  &__link {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.9;
    color: $basicWhite;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 4px;
    text-decoration: none;
    transition: color 0.4s linear, background-color 0.4s linear;
    padding: 0.7rem 7.5rem;

    &:hover {
      color: var(--primary);
      background-color: $basicWhite;
    }
  }

  &__img {
    @include below($sm) {
      display: none;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
