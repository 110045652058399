<template>
    <div class="disclaimer" :style="{'background-color': background, 'color': color}">
        <div class="disclaimer__wrapper">
            <h1 class="disclaimer__title">{{ title }} <span class="disclaimer__platform">{{`${platform} ${platform && platform.length?'ADS':''}`}}</span></h1>
            <slot name="content"></slot>
            <slot name="link-google"></slot>
            <slot name="link-telegram"></slot>
            <slot name="footer-content"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "Disclaimer",
    props:{
        title:{
            type: String,
            default: ''
        },
        platform: {
            type: String,
            default: ''
        },
        background: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        }
    },
    data() {
        return {   
        }
    }

}
</script>
<style lang="scss" scoped>
    .disclaimer {
        // background-color: #E6EAF2;
        border-radius: 0.6rem;
        margin-top: 3.6rem;
         &__wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 2.4rem;
            display: flex;
            flex-direction: column;
        }
        &__title {
            font-family: 'Roboto';
            font-size: 1.8rem;
            font-weight: 700;  
        }
        &__platform {
            text-transform: uppercase;
        }
        &__content {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 1.4rem;

            &-text {
                margin: 2.4rem 0 0 0;
                line-height: 1.17;
            }
            &-link {
                color: var(--primary);
                text-decoration: underline;
                word-wrap: break-word;
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 1.17;
            }
        }
    }

</style>