<template>
  <div class="cashback-cart">
    <div class="cashback-cart__header">
      <div>
        <h3 class="cashback-cart__title">
          {{ title }}
        </h3>
        <p v-if="description" class="cashback-cart__desc">
          {{ description }}
        </p>
      </div>
      <div v-if="!editable && balance !== null" class="cashback-cart__balance">
        <p class="total">
          {{locale === 'USA'? numberWithSpacesCeilFix2(balance): numberWithSpacesCeil(balance) }} {{$t('currency.currency_symbol')}}
        </p>
        <p> {{ type === 'commission-terms' ? $t('cashback_cart.expense') : $t('cashback_cart.income') }}</p>
      </div>
    </div>
    <div v-if="!editable && conditions && conditions.length" class="cashback-cart__table">
      <template v-for="(item, idx) in conditions">
        <div
          v-if="item.percent"
          class="cashback-cart__row"
          :key="idx"
        >
          <div class="table-price">
            <span v-if="!item.max">{{$t('cashback_cart.more')}}&nbsp;</span>
            <span class="table-price__min">
              {{locale==='USA'? numberWithSpacesCeilFix2(item.min): numberWithSpacesCeil(item.min) }} {{$t('currency.currency_symbol')}}
            </span>
            <span v-if="item.max" class="table-price__max">
              – {{locale==='USA'? numberWithSpacesCeilFix2(item.max) : numberWithSpacesCeil(item.max) }} {{$t('currency.currency_symbol')}}
            </span>
          </div>
          <span class="table-percent">
          {{ item.percent }}
          <small>%</small>
        </span>
        </div>
      </template>
    </div>
    <div v-if="editable && editableConditions && editableConditions.length" class="cashback-cart__table">
      <div
          v-for="(row, idx) in editableConditions"
          class="cashback-cart__row cashback-cart__row--edit"
          :key="idx + 'edit'"
      >
        <div class="table-price table-price--edit">
          <div class="table-price__min">
            <span>{{$t('cashback_cart.from')}}</span>
            <input type="number" v-model="editableConditions[idx].min" min="0" />
          </div>
          <div class="table-price__min">
            <span>{{$t('cashback_cart.to')}}</span>
            <input type="number" v-model="editableConditions[idx].max" min="0" />
          </div>
        </div>
        <span class="table-percent table-percent--edit">
          <input type="text" v-model="editableConditions[idx].percent"/>
        </span>
        <div class="cashback-cart__row--delete" @click="deleteRow(idx)">
          <img :src="getPic(locale)" alt="">
        </div>
      </div>
    </div>
    <div v-if="editable" class="cashback-cart__add-row" @click="pushRow">
      + {{$t('cashback_cart.spacing')}}
    </div>
  </div>
</template>

<script>
import numberWithSpaces from '@/mixin/numberWithSpaces'
import numberWithSpacesFix2 from '@/mixin/numberWithSpaces'

export default {
  name: "CashbackCart",
  mixins: [numberWithSpaces, numberWithSpacesFix2],
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    balance: {
      type: [String, Number],
      default: null
    },
    conditions: {
      type: Array,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    platform: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      editableConditions: JSON.parse(JSON.stringify(this.conditions)) || []
    }
  },
  computed: {
    role() {
      return this.$store.state.Auth.role
    },
  },
  watch: {
    editableConditions: {
      deep: true,
      handler(val) {
        this.$emit('change', val, this.type, this.platform)
      },
    },
  },
  methods: {
    getPic(locale) {
      if(locale==='RU') {
        return  require("@/assets/icons/icon-delete-2.svg")
      } else {
        return require("@/assets/icons/icon-delete-5.svg")
      }
    },
    pushRow() {
      this.editableConditions.push({ max: 0, min: 0, percent: 0 })
    },
    deleteRow(idx) {
      this.editableConditions.splice(idx, 1);
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.cashback-cart {
  border-radius: .8rem;
  padding: 3.5rem 4.5rem 3.0rem;

  input {
    background: #FFFFFF;
    border: .1rem solid #D1D4D8;
    box-sizing: border-box;
    border-radius: .2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #333333;
    width: 15rem;
    height: 5rem;
    margin-left: .8rem;
    padding-left: 1.4rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &__title {
    font-weight: bold;
    font-size: 2.0rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
  }

  &__desc {
    font-size: 1.6rem;
    line-height: 1.9rem;
    max-width: 32rem;
  }

  &__balance {
    background: #FFFFFF;
    box-shadow: .0rem .4rem 1.0rem rgba(25, 47, 79, 0.1);
    border-radius: .6rem;
    padding: 1.4rem 2rem;

    p {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    p.total {
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.1rem;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    height: 6.7rem;
    border-bottom: .1rem solid #BFC2C6;

    &--edit {
      position: relative;

      &:before {
        position: absolute;
        left: -4.5rem;
        right: -4.5rem;
        top: 0;
        bottom: 0;
        background: #DADEE6;
        content: '';
        opacity: 0;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    &--delete {
      position: relative;
      cursor: pointer;
      width: 1.6rem;
      height: 1.6rem;
      margin-left: 1.3rem;
    }

    &:last-child {
      border-bottom: none;
    }

    .table-price {
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 2.2rem;

      &--edit {
        position: relative;
        display: flex;
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: #7F858D;
        align-items: center;

        .table-price__min {
          margin-right: 2.5rem;
        }
      }
    }

    .table-percent {
      font-weight: 500;
      font-size: 4.0rem;
      line-height: 4.7rem;
      letter-spacing: -0.0539216rem;
      margin-left: auto;

      small {
        font-size: 60%;
      }

      &--edit {
        position: relative;
        display: flex;

        input {
          width: 70px;
        }

        &:after {
          z-index: 1;
          position: absolute;
          top: 50%;
          right: 1.4rem;
          content: '%';
          font-weight: normal;
          font-size: 16px;
          color: #9BA2AB;
          transform: translateY(-42%);
        }
      }
    }
  }

  &__add-row {
    color: var(--primary);
    cursor: pointer;
    margin-top: 2.5rem;
    display: inline-block;
  }
}

@include below(769px) {
  .cashback-cart{
    padding: 15px;
  }
}

@include below(500px) {
  .cashback-cart__header{
    flex-wrap: wrap;
  }
  .cashback-cart__balance{
    width: 100%;
    margin-top: 10px;
  }
}
</style>
