let withSpaces = {
    methods: {
        numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesCeil(x) {
            const y = Math.floor(+x)
            return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesFixed(x, step) {
            const y = (+x).toFixed(step)
            return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesCeilFix2(x) {
            const y = Math.floor(+x);
            const z = y.toFixed(2);
            return z.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesFix2(x) {
            if (typeof x !== "number") {
                x = Number(x)
            }
            return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesFixedFormatted(x, step) {
            x = (+x).toFixed(step);
            x = +x;
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesCeilOrigin(x) {
            const y = +x
            return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesCeilRound(num) {
            // Преобразуем число в строку для удобства работы с разрядами
            let str = num.toString();
            // Находим позицию десятичной точки
            let decimalIndex = str.indexOf('.');
            // Если десятичной точки нет, возвращаем число как есть
            if (decimalIndex === -1) return num;
            // Получаем цифру после десятичной точки
            let nextDigit = parseInt(str[decimalIndex + 1]);
            // Если следующая цифра 0, 1, 2, 3 или 4, округляем вниз
            if (nextDigit >= 0 && nextDigit <= 4) {
                return Math.floor(num);
            }
            // Если следующая цифра 5, 6, 7, 8 или 9, округляем вверх
            else {
                return Math.ceil(num);
            }
        },

    }
}

export default withSpaces;
