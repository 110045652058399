<template>
  <div class="icon" :class="'icon--' + type">
    <img :src="require('@/assets/icons/icon-' + icon + '.svg')" alt="icon">
  </div>
</template>

<script>
export default {
  name: "IconRound",
  props: {
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    type () {
      let type = ''
      switch (this.icon) {
        case 'add':
          type = 'green'
          break;
        case 'transfer':
          type = 'orange'
          break;
        case 'fine':
          type = 'red'
          break;
        case 'ad':
          type = 'yellow'
          break;
        case 'cashback':
          type = 'primary'
          break;
        case 'add-bonus':
          type = 'orange'
          break;
        case 'add-custom':
          type = 'green'
          break;
        case 'add-custom-cashback':
          type = 'green'
          break;
        case 'refund-to-bonus':
          type = 'green'
          break;
        case 'percent':
          type = 'green'
          break;
        case 'out':
          type = 'blue'
          break;
        case 'refund':
          type = 'green'
          break;
        case 'transfer-from-acc-to-balance':
          type = 'green'
          break;
        case 'transfer-from-acc-to-cashback-balance':
          type = 'green'
          break;
        case 'commission-cashback':
          type = 'primary'
          break;
        case 'commission':
          type = 'gray'
          break;
      }
      return type
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/styles/helpers/variables";
  @import "../../assets/styles/helpers/mixin";

  .icon {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    &--yellow {
      background: #EFCF24;
    }

    &--light-green {
      background: $lightGreen;
    }

    &--green {
      background: $greenClassic;
    }

    &--red {
      background: $redLight;
    }

    &--blue {
      background: $primaryPale;
    }

    &--orange {
      background: $orange;
    }

    &--primary {
      background: $primary;
    }

    &--gray {
      background: #7F858D;
    }
  }
</style>
