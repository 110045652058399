<template>
  <div class="holdings">
    <div class="container">
      <div class="holdings__header">
        <div class="holdings__title" :class="{'locale-usa': locale==='USA'}">
          {{$t('business_accounts.title')}}
        </div>
        <Search :placeholder="$t('search_props.placeholder')" @findValue="changeSearchText" />
      </div>
      <div
        v-if="accountsList && accountsList.length"
        class="holdings__table"
        :class="{'locale-usa': locale === 'USA'}"
        v-loading="loading"
      >
        <el-table
          :data="accountsList"
          style="width: 100%"
          @row-click="setActiveAccount"
        >
          <el-table-column :key="lang">
            <template slot="header">
              <div class="holdings__table-th">
                <div class="holdings__table-th-name">
                  {{$t('full_name')}}
                  <br>
                  <span>{{$t('email')}}</span>
                </div>
                <div class="holdings__table-th-sort">
                  <button
                    class="holdings__table-th-arrow up"
                    :class="{ active: params.sort_col === 'email' && params.sort_dir === 'asc' }"
                    @click="changeSortParams('asc', 'email')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                  <button
                    class="holdings__table-th-arrow"
                    :class="{ active: params.sort_col === 'email' && params.sort_dir === 'desc' }"
                    @click="changeSortParams('desc', 'email')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <span>
                {{ (scope.row.legal_contact_name || '') }}
              </span>
              <br>
              <a :href="'mailto:' + scope.row.email" class="holdings__table--blue">
                {{ scope.row.email }}
              </a>
            </template>
          </el-table-column>
          <el-table-column prop="legal_name" class-name="wrap-words">
            <template slot="header">
              <div class="holdings__table-th">
                <div class="holdings__table-th-name" v-html="$t('business_accounts.company_name')">
                </div>
                <div class="holdings__table-th-sort">
                  <button
                    class="holdings__table-th-arrow up"
                    :class="{ active: params.sort_col === 'legal_name' && params.sort_dir === 'asc' }"
                    @click="changeSortParams('asc', 'legal_name')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                  <button
                    class="holdings__table-th-arrow"
                    :class="{ active: params.sort_col === 'legal_name' && params.sort_dir === 'desc' }"
                    @click="changeSortParams('desc', 'legal_name')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="holdings__table-th">
                <div class="holdings__table-th-name" v-html="$t('business_accounts.registration_date')">
                </div>
                <div class="holdings__table-th-sort">
                  <button
                    class="holdings__table-th-arrow up"
                    :class="{ active: params.sort_col === 'created_at' && params.sort_dir === 'asc' }"
                    @click="changeSortParams('asc', 'created_at')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                  <button
                    class="holdings__table-th-arrow"
                    :class="{ active: params.sort_col === 'created_at' && params.sort_dir === 'desc' }"
                    @click="changeSortParams('desc', 'created_at')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="holdings__table-date">
                {{ fixDateFormat(scope.row.created_at) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="holdings__table-th">
                <div class="holdings__table-th-name">
                  {{$t('business_accounts.balance')}} /
                  <br>
                  <span>{{$t('cashback')}}</span>
                </div>
                <div class="holdings__table-th-sort">
                  <button
                      class="holdings__table-th-arrow up"
                      :class="{ active: params.sort_col === 'balance' && params.sort_dir === 'asc' }"
                      @click="changeSortParams('asc', 'balance')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                  <button
                      class="holdings__table-th-arrow"
                      :class="{ active: params.sort_col === 'balance' && params.sort_dir === 'desc' }"
                      @click="changeSortParams('desc', 'balance')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <span>
                {{ scope.row.balance ? numberWithSpacesCeil(scope.row.balance) : 0 }}
              </span>
              <br>
              <span class="holdings__table--blue">
                {{ scope.row.cashback_balance ? numberWithSpacesCeil(scope.row.cashback_balance) : 0 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              {{$t('business_accounts.number_of')}} <br> {{$t('business_accounts.add_acc')}}
            </template>
            <template slot-scope="scope">
              <div class="holdings__table-accounts">
                <div
                  v-for="(item, key) in scope.row.advert_accounts_count"
                  :key="key"
                  class="holdings__table-account"
                >
                  <img v-if="item.service === 'mytarget'" src="../assets/icons/icon-mytarget-logo.svg" alt="">
                  <img v-else-if="item.service === 'vkontakte'"  src="../assets/icons/icon-vkontacte.svg" alt="">
                  <img v-else-if="item.service === 'facebook'" src="../assets/icons/icon-meta.svg" alt="">
                  <img v-else-if="item.service === 'yandex'" src="../assets/icons/yandex-direct-icon.svg" alt="">
                  <img v-else-if="item.service === 'google'" src="../assets/icons/icon-google.svg" alt="">
                  <img v-else-if="item.service === 'vkads'"  src="../assets/icons/icon-vkreklama.svg" alt="">
                  
                  <span>
                  {{ item.count }}
                </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="holdings__table-th">
                <div class="holdings__table-th-name" v-html="$t('business_accounts.all_ad')">
                </div>
                <div class="holdings__table-th-sort">
                  <button
                      class="holdings__table-th-arrow up"
                      :class="{ active: params.sort_col === 'advert_accounts_count' && params.sort_dir === 'asc' }"
                      @click="changeSortParams('asc', 'advert_accounts_count')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                  <button
                      class="holdings__table-th-arrow"
                      :class="{ active: params.sort_col === 'advert_accounts_count' && params.sort_dir === 'desc' }"
                      @click="changeSortParams('desc', 'advert_accounts_count')"
                  >
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              {{ getAmountAccounts(scope.row.advert_accounts_count) }}
            </template>
          </el-table-column>
          <el-table-column prop="spent">
            <template slot="header">
              {{$t('business_accounts.last_week')}} <br> {{$t('business_accounts.ad_exp')}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="accountsList && !accountsList.length">
        {{$t('nothing_found')}}
      </div>
      <div class="holdings__pagination">
        <el-pagination
            v-if="pagination && pagination.total>=10"
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size="10"
            :current-page="pagination.currentPage"
            @current-change="getNewPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/sort/Search";
import fixDateFormat from "@/mixin/fixDate";
import numberWithSpaces from '@/mixin/numberWithSpaces'

export default {
  name: "BusinessAccounts",
  components: {Search},
  mixins: [fixDateFormat, numberWithSpaces],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      lang: this.$i18n._vm.locale,
      loading: false,
      params: {
        per_page: 10,
      },
    }
  },
  computed: {
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    },
    accountsList() {
      return this.$store.state.Auth.holdingsAccounts
    },
    pagination() {
      return this.$store.state.Auth.holdingsPagination
    },
    businessAccounts() {
      return this.$store.state.Auth.businessAccounts
    },
  },
  watch: {
    getLang: {
      deep: true,
      handler(val) {
        this.dataPickerforceRerender(val)
      }
    },
    $route() {
      for (let key in this.$route.query) {
        this.params[key] = this.$route.query[key]
      }
      this.getData()
    }
  },
  created() {
    for (let key in this.$route.query) {
      this.params[key] = this.$route.query[key]
    }
    this.getData()
  },
  methods: {
    dataPickerforceRerender(value) {
      this.lang = value
    },
    async getData() {
      this.loading = true
      await this.$store.dispatch('Auth/getHoldingsBusinessAccounts', this.params )
      this.loading = false
    },
    changeSearchText(val) {
      if (!val) {
        const query = {...this.$route.query, page: 1, search_text: null }
        this.$router.push({query})
      } else {
        const query = {...this.$route.query, page: 1, search_text: val }
        this.$router.push({query})
      }
    },
    getNewPage(page) {
      const query = { ...this.$route.query, page }
      this.$router.push({query})
    },
    changeSortParams(direction, type) {
      this.params.sort_dir = direction
      this.params.sort_col = type
      const query = { ...this.$route.query, sort_dir: direction, sort_col: type, page: 1, }
      this.$router.push({query})
    },
    getAmountAccounts(accounts) {
      let count = 0
      if (accounts && accounts.length) {
        accounts.forEach(el => count += el.count)
      }
      return count
    },
    setActiveAccount(row) {
      const active = this.businessAccounts.find(el => el.id === row.id)
      this.$store.commit('Auth/setData', { label: 'activeAccount', data: active })
      localStorage.setItem('activeBA', row.id)
      this.$router.push('/statistics')
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.holdings {
  padding: 5rem 0;
  .el-table {
    &__row {
      .cell {
        padding: 0 10px;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.6rem;
  }

  &__title {
    &.locale-usa {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 4rem;
    }
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;

    .search {
      width: 70%;
    }

    .selector {
      width: 28%;

      &__content {
        width: 100%;
      }
    }
  }

  &__table {
    padding: 1.6rem 2.4rem 0 2.4rem;
    background-color: #fff;
    &.locale-usa {
      .el-table {
        font-family: 'Gilroy';
        .holdings__table-th-name, .has-gutter .cell {
          color: var(--black);
        }
      }
    }

    &-accounts {
      display: flex;
      flex-wrap: wrap;
    }

    &-account {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 2.8rem;

      &:last-of-type {
        margin-right: 0;
      }
      img {
        display: inline-block;
        max-width: 3rem;
        width: 100%;
      }
    }

    &-date {
      color: #9BA2AB;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    &--blue {
      color: var(--primary);
    }

    &-th {
      color: #9BA2AB;
      display: flex;
      align-items: flex-end;

      span {
        color: var(--primaryLight);
      }

      &-sort {
        margin-left: 1rem;
      }

      &-arrow {
        display: block;
        background: none;
        border: none;
        opacity: 0.5;
        transition: 0.3s ease-out;

        &:hover,
        &.active {
          opacity: 1;
        }

        &.up {
          img {
            display: block;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }
}

@include below(769px) {
  .holdings{
    .container{
      padding: 0;
    }
  }
  .holdings__header{
    flex-wrap: wrap;
    .search{
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
