export default {
    install(Vue) {
        Vue.prototype.$colors = () => {
            return {
                primary: '#395FE4',
                primaryLight:"#D6E0F2",
                primaryHover: "#2D52D2",
                black: '#000',
                gray: '#FCFCFE',
                darkGray: '#7F858D',
                bgGray: '#FCFCFE'
            }       
        }
    }
}