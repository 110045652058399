<template>
  <button
    class="button"
    :class="[{localeUsa}, {ghost}]"
    :disabled="disabled"
    @click.prevent="$emit('click-button')"
  >
    <slot name="icon"></slot>
    {{ text }}

    <div v-if="download" class="button__download">
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0037 9.5C18.8413 9.74603 20 11.2143 20 14.4286V14.5317C20 18.0794 18.5891 19.5 15.0657 19.5H9.93431C6.41093 19.5 5 18.0794 5 14.5317V14.4286C5 11.2381 6.14293 9.76984 8.93326 9.50794" stroke="#395FE4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.5 4.5V14.5" stroke="#395FE4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 12.5L12.5 15.5L10 12.5" stroke="#395FE4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </button>
</template>

<script>
export default {
  name: "ButtonElement",
  props: {
    text: {
      type: String,
      default: ''
    },
    view: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    download: {
      type: Boolean,
      default: false
    },
    ghost: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
    }
  },
  computed: {
    localeUsa() {
      if(this.locale === 'USA') return `${this.view ? 'button--' + this.view : ''} locale-usa`
      return this.view ? 'button--' + this.view : '' 
    }
  }
}
</script>

<style scoped lang="scss">
 @import "@/assets/styles/helpers/variables";

 .button {
   font-weight: 700;
   font-size: 1.6rem;
   line-height: 1.9rem;
   color: $basicWhite;
   border-radius: 4px;
   background: var(--primary);
   border: 1.5px solid var(--primary);
   height: 3.5rem;
   min-width: 12.5rem;
   transition: 0.3s ease-out;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0 6px;
   &.locale-usa {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    border-width: 3px;
    border-radius: 8px;
   }

   &.ghost {
    background: transparent;
    color: var(--primary);
    display: flex;
    gap: 0.8rem;
    align-items: center;
    text-align: center;
    outline: none;
    &:hover {
      background: transparent;
      color: var(--primary);
    }
    &:focus {
      border: 1px solid var(--primary);
    }
    path {
      stroke: var(--primary);
    }
   }
   &:disabled {
     cursor: not-allowed;
     opacity: 0.5;

     &:hover {
       background: var(--primary);
     }
   }

   &:hover {
     background: var(--primaryHover);
   }

   &:focus {
     border: 2px solid #000;
   }

   &--empty {
     font-weight: 400;
     color: var(--primary);
     background: $basicWhite;

     &:hover {
       color: $basicWhite;
       background: var(--primary);
     }
   }

   &__download {
    path {
      stroke: currentColor;
    }
   }
 }
</style>
