import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_URL

const state = ()=> ({
    commission: null,
    serverErrors: null
})

const getters = {}
const mutations = {
  setData (state, data) {
    state[data.label] = data.data
  },
}

const actions = {
    sendCommission({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios({ 
            url: apiUrl + `/advertservice/${data.id}/commission-replenishment`, 
            headers: { 'Authorization': `Bearer ${data.token} ` }, 
            method: 'PUT', data: { value: data.commission }
          })
          .then(resp => {
            commit('setData', { label: 'commission', data: resp.data.data })
            commit('setData', { label: 'serverErrors', data: null})
            resolve(resp)
          })
          .catch(err => {
            console.log('err', err.response)
            commit('setData', { label: 'serverErrors', data: err.response.data.errors })
            reject(err)
          })
        })
      }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  };