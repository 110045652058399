import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  accountsList: null,
  orderHistory: null,
  accountsStatistic: null,
  pagination: null,
})
const getters = {
  fixedAccountsList: (state) => {
    if (state.accountsList) {
      const arr = [{id: 'all', name: 'все'}]
      state.accountsList.forEach(el => {
        arr.push({ id: el.id, name: el.type })
      })
      return arr
    }
  },
}
const mutations = {
  setData (state, data) {
    state[data.label] = data.data
  },
}
const actions = {
  getAccounts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/marketplace/accounts`, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'accountsList', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getAccountsStatistic ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/marketplace/statistic/accounts`, method: 'GET', params })
        .then(resp => {
          commit('setData', { label: 'accountsStatistic', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getOrderHistory ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/marketplace/order/products`, method: 'GET', params })
        .then(resp => {
          commit('setData', { label: 'orderHistory', data: resp.data.data.data })
          const pagination = {
            total: resp.data.data.total,
            currentPage: resp.data.data.current_page
          }
          commit('setData', { label: 'pagination', data: pagination })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
