<template>
  <div class="reset-password">
    <FormElement
      :title="isStepOne ? $t('reset_password.mail_code') : $t('reset_password.new_login')"
      :message="isStepOne ? `${$t('reset_password.mail_sent')} ${email}` : $t('reset_password.password_error')"
    >
      <div
          v-if="isStepOne"
          class="form-input"
          :class="
        [
          {'form-input--full' : form.password_reset_code},
          {'form-input--error' : errors.password_reset_code || (authErrors && authErrors.password_reset_code)}
        ]"
      >
        <input v-model="form.password_reset_code" type="text">
        <span class="form-input__label">
          {{$t('reset_password.message-code')}}
        </span>
        <div v-if="errors.password_reset_code" class="form-input__error" v-text="errors.password_reset_code" />
        <div v-if="authErrors && authErrors.password_reset_code" class="form-input__error">
          <p v-for="(err, index) in authErrors.password_reset_code" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <div
          v-if="!isStepOne"
          class="form-input"
          :class="
        [
          {'form-input--full' : form.password},
          {'form-input--error' : errors.password || (authErrors && authErrors.password)}
        ]"
      >
        <input v-model="form.password" :type="isHidePassword ? 'password' : 'text'">
        <span class="form-input__label">
          {{$t('reset_password.new_password')}}
        </span>
        <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
          <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
          <img v-else src="@/assets/icons/icon-eye.svg">
        </div>
        <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
        <div v-if="authErrors && authErrors.password" class="form-input__error">
          <p v-for="(err, index) in authErrors.password" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <div
          v-if="!isStepOne"
          class="form-input"
          :class="
        [
          {'form-input--full' : form.password_confirmation},
          {'form-input--error' : errors.password_confirmation || (authErrors && authErrors.password_confirmation)}
        ]"
      >
        <input v-model="form.password_confirmation" :type="isHidePassword ? 'password' : 'text'">
        <span class="form-input__label">
          {{$t('reset_password.repeat_password')}}
        </span>
        <div v-if="errors.password_confirmation" class="form-input__error" v-text="errors.password_confirmation" />
        <div v-if="authErrors && authErrors.password_confirmation" class="form-input__error">
          <p v-for="(err, index) in authErrors.password_confirmation" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <ButtonElement v-if="isStepOne" :text="$t('button_props.send')" @click-button="checkStepOne" />
      <ButtonElement v-else :text="$t('button_props.continue')" @click-button="resetPassword" />
    </FormElement>
  </div>
</template>

<script>
import FormElement from "../components/elements/FormElement";
import ButtonElement from "../components/elements/ButtonElement";
import formValidate from "../mixin/formValidate";

export default {
  name: 'ResetPassword',
  components: {
    FormElement,
    ButtonElement,
  },
  mixins: [formValidate],
  data () {
    return {
      isStepOne: true,
      isHidePassword: true,
      form: {
        password: null,
        password_confirmation: null,
        password_reset_code: null,
      },
      errors: {
        password: null,
        password_confirmation: null,
        password_reset_code: null,
      }
    }
  },
  computed: {
    email() {
      return this.$store.state.Auth.resetPasswordEmail
    },
  },
  methods: {
    checkStepOne() {
      if (!this.form.password_reset_code) {
        this.errors.password_reset_code = this.$t('reset_password.enter_code')
        return false
      } else {
        const data = {
          email: this.email,
          password_reset_code: this.form.password_reset_code
        }
        this.$store.dispatch('Auth/checkCode', data)
        .then(() => {
          this.isStepOne = false
        })
        .catch((err) => {
          console.log(err)
        })
      }
    },
    resetPassword() {
      this.validateForm()
      if (this.isError) {
        return false
      }
      const data = this.form
      if (this.email) {
        data.email = this.email
        this.$store.dispatch('Auth/resetPassword', data)
          .then(() => {
            this.$router.push('/')
          })
          .catch(err => {
            console.log(err.response)
            if (err.response.data.errors.password_reset_code) this.isStepOne = true
          })
      }
    },
    validateForm () {
      if (!this.form.password) {
        this.errors.password = this.$t('reset_password.enter_password')
      } else {
        this.validatePassword()
      }

      if (!this.form.password_confirmation) {
        this.errors.password_confirmation = this.$t('reset_password.enter_password')
      } else if (this.form.password_confirmation !== this.form.password) {
        this.errors.password_confirmation = this.$t('reset_password.mismatch')
      }
    }
  }
}
</script>
