<template>
  <div class="main-layout">
    <header class="header">
      <div class="header-container">
        <div class="inner">
          <div class="header-left">
            <router-link class="logo" :to="{ name: 'MainPage' }">
              ProfitAds
              <!-- <img class="img-responsive img-responsive--svg" src="@/assets/icons/icon-logo.svg" alt="logo" title="logo"> -->
            </router-link>
            <ul class="menu">
              <li class="menu__item">
                <a href="#playgrounds">Площадки</a>
              </li>
              <li class="menu__item">
                <a href="#profitable">Преимущества</a>
              </li>
              <li class="menu__item">
                <a href="#capability">Возможности</a>
              </li>
              <li class="menu__item">
                <a href="#start">Как начать</a>
              </li>
            </ul>
          </div>

          <ul class="menu">
            <!--            <li class="menu__item">-->
            <!--              <router-link :to="{name: 'MainPage'}">Блог</router-link>-->
            <!--            </li>-->

            <li class="menu__item">
              <router-link :to="{ name: 'Login' }">Войти</router-link>
            </li>
          </ul>

          <router-link class="btn btn--blue" :to="{ name: 'Registration' }">
            Создать аккаунт
          </router-link>

          <div
            class="mobile-btn"
            :class="{ 'mobile-btn--close': dialogVisible }"
            @click="handlerClick"
          >
            <svg
              class="open"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="4" y="14" width="24" height="4" rx="1" fill="#333333" />
              <rect x="4" y="3" width="24" height="4" rx="1" fill="#333333" />
              <rect x="4" y="25" width="24" height="4" rx="1" fill="#333333" />
            </svg>

            <svg
              class="close"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8.92896"
                y="6.10051"
                width="24"
                height="4"
                rx="1"
                transform="rotate(45 8.92896 6.10051)"
                fill="#333333"
              />
              <rect
                x="6.10059"
                y="23.0711"
                width="24"
                height="4"
                rx="1"
                transform="rotate(-45 6.10059 23.0711)"
                fill="#333333"
              />
            </svg>
          </div>
        </div>
      </div>

      <el-dialog
        class="mobile-menu"
        :class="{ 'mobile-menu--show': dialogVisible }"
        :visible.sync="dialogVisible"
        :modal="false"
        :fullscreen="true"
      >
        <div class="mobile-menu__body">
          <ul class="mobile-menu__list">
            <!--            <li class="mobile-menu__list-item">-->
            <!--              <router-link :to="{name: 'MainPage'}">Блог</router-link>-->
            <!--            </li>-->
            <li class="mobile-menu__list-item">
              <a href="#playgrounds" @click="handlerClick">Площадки</a>
            </li>
            <li class="mobile-menu__list-item">
              <a href="#profitable" @click="handlerClick">Преимущества</a>
            </li>
            <li class="mobile-menu__list-item">
              <a href="#capability" @click="handlerClick">Возможности</a>
            </li>
            <li class="mobile-menu__list-item">
              <a href="#start" @click="handlerClick">Как начать</a>
            </li>
            <li class="mobile-menu__list-item">
              <router-link :to="{ name: 'Login' }">Вход</router-link>
            </li>
          </ul>

          <router-link
            :to="{ name: 'Registration' }"
            class="mobile-menu__btn btn btn--blue"
          >
            Регистрация
          </router-link>
        </div>
      </el-dialog>
    </header>

    <slot />

    <footer class="footer">
      <div class="container-main">
        <div class="inner">
          <div class="footer__col">
            <div class="logo">ProfitAds</div>
            <div class="logo-text">
              Единый сервис пополнения <br />рекламных кабинетов
            </div>
          </div>

          <div class="footer__col">
            <div class="footer__col-title">Компания</div>
            <div class="copyright">© ProfitAds, 2022</div>
            <ul class="menu">
              <li class="menu__item">
                <router-link :to="{ name: 'Agreement' }"
                  >Пользовательское соглашение</router-link
                >
              </li>

              <li class="menu__item">
                <router-link :to="{ name: 'PrivacyPolicy' }"
                  >Обработка персональных данных</router-link
                >
              </li>
            </ul>
          </div>

          <div class="footer__col">
            <div class="footer__col-title">Контакты</div>
            <div class="adress">
              125124, г. Москва, ул. Ямского поля 3-я, <br />д.2, корп.26, этаж
              3, помещ. VIII, ком.24
            </div>
            <a href="mailto:info@profitads.ru" class="email"
              >info@profitads.ru</a
            >
            <!-- <ul class="menu">
              <li class="menu__item">
                <router-link :to="{name: 'Agreement'}">Служба поддержки</router-link>
              </li>
            </ul> -->
          </div>

          <div class="footer__col">
            <ul class="menu">
              <li class="menu__item">
                <router-link :to="{ name: 'Login' }">Войти</router-link>
              </li>
              <li class="menu__item">
                <router-link :to="{ name: 'Registration' }"
                  >Создать аккаунт</router-link
                >
              </li>
            </ul>

            <a href="https://t.me/info_profitads" class="footer__tg-link" target="_blank">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37259 0 0 5.37259 0 12C0 18.6274 5.37259 24 12 24Z" fill="white"/>
                <path d="M5.1443 11.7372C5.1443 11.7372 11.0885 9.20835 13.15 8.31787C13.9403 7.96172 16.6203 6.82191 16.6203 6.82191C16.6203 6.82191 17.8573 6.32328 17.7542 7.53427C17.7198 8.03295 17.445 9.7782 17.1701 11.666C16.7578 14.3373 16.3111 17.258 16.3111 17.258C16.3111 17.258 16.2424 18.0772 15.6583 18.2197C15.0742 18.3622 14.1121 17.7211 13.9403 17.5786C13.8028 17.4717 11.3633 15.8689 10.47 15.0853C10.2295 14.8716 9.95462 14.4442 10.5043 13.9455C11.7413 12.7701 13.2187 11.3098 14.1121 10.3837C14.5244 9.95631 14.9367 8.95901 13.2187 10.17C10.7792 11.9153 8.37408 13.5537 8.37408 13.5537C8.37408 13.5537 7.82432 13.9099 6.79355 13.5893C5.76273 13.2688 4.56015 12.8414 4.56015 12.8414C4.56015 12.8414 3.73557 12.3071 5.1443 11.7372Z" fill="#395FE4"/>
              </svg>

              Мы в Telegram
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "MainLayout",
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handlerClick() {
      this.dialogVisible = !this.dialogVisible;
      if (this.dialogVisible == true) {
        this.open();
      } else {
        this.close();
      }
    },
    open() {
      document.body.classList.add("el-popup-parent--hidden");
    },
    close() {
      document.body.classList.remove("el-popup-parent--hidden");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/public/style";

.header {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  height: 8rem;

  .header-container {
    padding: 0 6.4rem;
  }

  .header-left {
    display: flex;
    align-items: center;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 0;
  }

  .logo {
    display: inline-block;
    font-weight: 400;
    font-size: 3.4rem;
    line-height: 4.4rem;
    color: $primary;
    margin-right: 18.2rem;

    img {
      width: 19rem;
      height: 4rem;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0 6.5rem 0 auto;

    &__item {
      margin: 0 6.5rem 0 0;

      a {
        display: inline-block;
        position: relative;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
        text-decoration: none;
        color: $basicBlack;
        transition: color 0.4s;

        &::before {
          width: 0;
          height: 2px;
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: $primary;
          opacity: 0;
          transition: all 0.4s;
        }

        &:hover {
          color: $primary;

          &::before {
            width: 100%;
            left: 0;
            opacity: 1;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .mobile-btn {
    width: 3.2rem;
    height: 3.2rem;
    position: relative;
    cursor: pointer;
    display: none;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      transition: opacity 0.4s, transform 0.4s;

      &.close {
        transform: translate3d(-50%, -50%, 0) scale(0);
        opacity: 0;
      }
    }

    &--close {
      svg.open {
        transform: translate3d(-50%, -50%, 0) scale(0);
        opacity: 0;
      }

      svg.close {
        transform: translate3d(-50%, -50%, 0) scale(1);
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .logo {
      margin-right: 10rem;
    }
  }

  @media only screen and (max-width: 1200px) {
    .menu {
      display: none;
    }

    .btn {
      display: none;
    }

    .mobile-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 991px) {
    .logo {
      img {
        width: 15.2rem;
        height: 3.2rem;
      }
    }

    .menu {
      margin-right: 4rem;

      &__item {
        margin-right: 3rem;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .inner {
      padding: 2rem 0;
    }
  }
}

.footer {
  background-color: $primary;

  &__col-title {
    font-size: 1.8rem;
    line-height: 2.8rem;
    color: $basicWhite;
    margin-bottom: 3.6rem;
  }

  .inner {
    display: flex;
    justify-content: space-between;
    padding: 4.8rem 0;
  }

  .logo {
    color: $basicWhite;
    font-weight: 400;
    font-size: 3.4rem;
    line-height: 4.4rem;
    margin-bottom: 3.2rem;
  }

  .logo-text {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #b9d6f1;
  }

  .copyright {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #b9d6f1;
    margin: 0 0 4rem;
  }

  .adress {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #b9d6f1;
    margin: 0 0 1.6rem;
  }

  .email {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #fff;
    margin: 0 0 1.6rem;
  }

  .menu {
    list-style: none;
    padding: 0;

    &__item {
      margin: 0 3.2rem 0 0;

      a {
        display: inline-block;
        position: relative;
        font-size: 1.4rem;
        line-height: 2.4rem;
        text-decoration: none;
        color: $basicWhite;
        margin-bottom: 0.4rem;

        &::before {
          width: 0;
          height: 1px;
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: $basicWhite;
          opacity: 0;
          transition: all 0.4s;
        }

        &:hover {
          &::before {
            width: 100%;
            left: 0;
            opacity: 1;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .social {
    display: flex;
    align-items: center;
    list-style: none;

    &__item {
      margin: 0 3.6rem 0 0;

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          transform: translate3d(0, 0, 0);
          transition: transform 0.4s;
        }

        &:hover {
          svg {
            transform: scale(1.2);
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__tg-link {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1.4rem;
    color: #ffffff;
    margin-top: 9.2rem;

    svg {
      margin-right: .8rem;
    }
  }

  @media only screen and (max-width: 768px) {
    &__col {
      margin-bottom: 3.2rem;
    }

    &__col-title {
      margin-bottom: 0.6rem;
    }

    .logo {
      margin-bottom: 0.6rem;
    }

    .inner {
      flex-direction: column;
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
    }

    .copyright {
      margin-bottom: 3.2rem;
    }

    .adress {
      margin-bottom: 0.6rem;
    }

    .menu {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;

      &__item {
        margin-right: 0;
        margin-bottom: 0;

        a {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__tg-link {
      margin-top: 2rem;
    }
  }
}

.mobile-menu {
  top: 7.2rem;
  background: rgba(255, 255, 255, 0.96);

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.6rem;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 3.2rem;

    &-item {
      margin: 0 0 2.4rem;

      a {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
        color: $basicBlack;
        transition: color 0.4s;
        text-align: center;
        display: block;

        &:hover {
          color: $primary;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__btn.btn {
    display: inline-flex;
  }
}
</style>
