<template>
  <div class="history-table">
    <div v-for="item in historyData" :key="item.id" class="history-table__row">
      <div class="history-table__type">
        <div class="history-table__icon">
          <IconRound :icon="item.type" />
        </div>
        <div class="history-table__name">
          <div class="history-table__name-title">
            {{ item.name }}
          </div>
          <div class="history-table__name-desc">
            {{ item.desc }}
          </div>
        </div>
      </div>
      <div class="history-table__info">
        <div class="history-table__amounts">
          <div class="history-table__amounts-total" :class="'history-table__amounts-total--' + item.type">
            {{locale ==='RU' ? numberWithSpaces(item.amountTotal).split('.')[0] + " "+ $t('currency.currency_symbol'):numberWithSpacesFix2(item.amountTotal) + $t('currency.currency_symbol')}}
          </div>
        </div>
        <div class="history-table__date">
          <!-- {{ fixDateFormatTime(item.date) }} -->
          {{ fixDateFormatLocale(item.date) }}
        </div>
      </div>
    </div>

    <div v-if="historyData && !historyData.length">
      {{$t('nothing_found')}}
    </div>
  </div>
</template>

<script>
import IconRound from "@/components/elements/IconRound";
import fixDateFormatTime from '@/mixin/fixDate'
import fixDateFormatLocale from '@/mixin/fixDate'
import numberWithSpaces from '@/mixin/numberWithSpaces'
import numberWithSpacesFix2 from '@/mixin/numberWithSpaces'

export default {
  name: "HistoryTable",
  components: {
    IconRound,
  },
  mixins: [fixDateFormatTime, numberWithSpaces, numberWithSpacesFix2, fixDateFormatLocale],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
    }
  },
  computed: {
    historyData() {
      return this.$store.state.Ad.history
    },
  },
  mounted() {
   
  },
  methods: {
    getNewPage(page) {
      const query = { ...this.$route.query, page }
      this.$router.push({query})
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/styles/helpers/variables";
  @import "../../assets/styles/helpers/mixin";

  .history-table {

    &__row {
      display: flex;
      justify-content: space-between;
      background-color: $basicWhite;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      padding: 1.5rem 1rem 1.5rem 2rem;
    }

    &__type {
      display: flex;
      align-items: center;
    }

    &__icon {
      margin-right: 1.2rem;
    }

    &__name {
      padding-right: 2rem;

      &-title {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $basicBlack;
        margin-bottom: .5rem;
      }

      &-desc {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $gray2;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    &__info {
      display: flex;
    }

    &__amounts {
      margin-right: 3rem;

      &-total {
        white-space: nowrap;
        font-size: 1.6rem;
        line-height: 1.9rem;
        text-align: right;
        color: $greenClassic;
        margin-bottom: .5rem;

        &--out, &--fine, &--ad, &--add-custom {
          color: $basicBlack;
        }

        &--cashback {
          color: $primary;
        }
      }

      &-usd {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $gray2;
        text-align: right;
      }
    }

    &__date {
      width: 10rem;
      white-space: nowrap;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $gray2;
    }

    @include below(769px) {

      &__row {
        flex-direction: column;
        padding: 8px 16px;
      }

      &__type {
        width: 100%;
        align-items: flex-start;
        margin-bottom: 8px;
      }

      &__icon {
        margin-right: 8px;

        .icon {
          width: 32px;
          height: 32px;
        }
      }

      &__name {
        padding-right: 0;

        &-title {
          font-size: 14px;
          line-height: 1.4;
          margin-bottom: 4px;
        }

        &-desc {
          font-size: 12px;
          line-height: 1.4;
        }
      }

      &__info {
        width: 100%;
        padding-left: 40px;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
      }

      &__amounts {
        margin-right: 16px;

        &-total {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 1.4;
        }
      }

      &__date {
        width: auto;
        font-size: 14px;
        line-height: 1.4;
      }
    }
  }
</style>
