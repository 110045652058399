<template>
  <div class="cookies" v-if="isVisible === true">
    <div class="cookies__inner">
      <p class="cookies__text">
        {{ $t('cookies.title') }}
        <br>
       {{ $t('cookies.in_detail') }}
        <router-link
            class="cookies__link"
            :to="{name: 'CookiesPage'}"
        >
         {{ $t('cookies.here') }}
        </router-link>
      </p>

      <ButtonElement
          :text="$t('button_props.accept')"
          class="cookies__btn"
          @click-button="isVisible = false"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";

export default {
  name: "Cookies",
  data () {
    return {
      isVisible: true,
    }
  },
  components: {
    ButtonElement,
  },
  mounted() {
    if (localStorage.isVisible) {
      this.isVisible = localStorage.isVisible;
    }
  },
  watch: {
    isVisible(value) {
      localStorage.isVisible = value;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.cookies {
  width: 100%;
  background-color: $basicWhite;
  box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
  padding: 3rem;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $basicBlack;
    padding: 0;
    margin: 0 3rem 0 0;
  }

  &__link {
    display: inline-block;
    position: relative;
    color: $primary;

    &::before {
      width: 100%;
      height: 1px;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $primary;
      transition: opacity .4s linear;
    }

    &:hover {

      &::before {
        opacity: 0;
      }
    }
  }

  &__btn {
    height: auto;
    padding: 1rem 5rem;
  }

  @include below(993px) {
    width: 85%;
    padding: 24px;

    &__btn {
      flex-shrink: 0;
      min-width: unset;
    }

    &__text {
      font-size: 14px;
      margin-right: 24px;
    }
  }

  @include below(577px) {
    width: 90%;
    padding: 16px;

    &__inner {
      flex-direction: column;
      text-align: center;
    }

    &__btn {
      font-size: 14px;
      padding: 8px 16px;
    }

    &__text {
      margin-right: 0;
      margin-bottom: 8px;

      br {
        display: none;
      }
    }
  }
}

.v-model {
  font-size: 20px;
}
</style>
