<template>
    <span class="notification" v-if="showElement">
        {{ unreadNotifications }}
    </span>
</template>
<script>
export default {
    name: "NotifiCationElement",
    props: {
        notification: {
            type: Number,
            default:0
        }
    },
    computed: {
        unreadNotifications() {
        return this.notification >9?'9+':this.notification
        },
        showElement() {
            return this.notification>0
        }
    }
}
</script>
<style scoped lang="scss">
.notification {
    display: inline-block;
    padding: 2px 6px;
    color: #fff;
    background: var(--primary);
    border-radius: 50%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    min-width: 18px;
    min-height: 18px;
}
    
</style>