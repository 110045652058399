<template>
  <div class="title">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
  .title {
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 3.3rem;
  }
</style>
