<template>
  <div class="selector-trigger" :class="{ 'selector-trigger--disabled': disabled }">
    <div class="selector-trigger__content" @click="showOptions = !showOptions">
      <slot name="content" />
      <div class="selector-trigger__icons-group">
        <svg 
          alt="arrow"
          class="selector-trigger__arrow-icon" 
          :class="{ 'selector-trigger__arrow-icon--open': showOptions }"  
          width="10" 
          height="6" 
          viewBox="0 0 10 6" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.125 1L5.0625 5.0625L1 1" stroke="#9BA2AB" stroke-width="1.47727" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <slot name="icons"/>
      </div>
    </div>
    <slot v-if="showOptions" name="options" />
  </div>
</template>

<script>
export default {
  name: "TableHeaderFilterSelector",
  props: {
    disabled: Boolean,
    isOpen: Boolean,
  },
  watch: {
    isOpen: {
      handler(value) {
        this.showOptions = value
      },
      immediate: true,
    }
  },
  data() {
    return {
      showOptions: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixin";

.selector-trigger {
  position: relative;

  &--disabled {
    pointer-events: none;
    opacity: .5;
  }

  &__content {
    position: relative;
    background: $gray8;
    color: $gray2;
    padding: .9rem 1.2rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    border-radius: .4rem;
    min-height: inherit;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $textGrey;
    margin-bottom: 0.2rem;
    transition: .2s;

    &--disabled {
      position: relative;
      top: 11px;
    }
  }

  &__value {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  &__icons-group {
    display: flex;
    gap: 1rem;
    top: 50%;
    right: .5rem;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  &__arrow-icon {
    cursor: pointer;
    transition: 0.3s ease-out;
    width: 1rem;
    height: 1rem;

    &--open {
      transition: 0.3s ease-out;
      transform: rotate(180deg);
    }

    // &__options {
    //   position: absolute;
    //   top: calc(100% + 1rem);
    //   left: 0;
    //   width: 100%;
    //   background: $backgroundInput;
    //   border: 1px solid $border;
    //   border-radius: 2px;
    //   z-index: 1;

    //   &-item {
    //     cursor: pointer;
    //     transition: 0.3s ease-out;
    //     padding: 0.7rem 1.5rem;

    //     &:hover {
    //       background: var(--primary);
    //       color: $basicWhite;
    //     }
    //   }
    // }

    @include below(769px) {

      &__content {
        width: 100%;
        transform: rotate(180deg);
      }
    }
  }

  &__options {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    width: 100%;
    background: $backgroundInput;
    border: 1px solid $border;
    border-radius: 2px;
    z-index: 999;

    &--limit-height {
      max-height: 20rem;
      overflow: auto;
    }

    &-item {
      cursor: pointer;
      transition: 0.3s ease-out;
      padding: 0.5rem 1.5rem;

      &--disabled {
        pointer-events: none;
        opacity: .5;
      }

      &:hover {
        background: var(--primary);
        color: $basicWhite;
      }
    }

    &-search {
      &.form-input {
        width: calc(100% - 20px);
        margin: 10px;
      }
    }
  }

  @include below(769px) {

    &__content {
      width: 100%;
    }
    &__options {
      width: 100%;
    }
  }
}
</style>
