<template>
  <div class="fast-registration-page">
    <div class="fast-registration-page__promotion">
      <div class="promotion-wrapper">
        <div class="promotion-content">
          <div class="promotion-content__item">
            <img src="../assets/icons/logo-getprofirt-white.svg" alt="logo" title="logo" class="white-logo">
            <div class="line"></div>
          </div>
          <div class="promotion-content__item-wrapper">
            <div class="promotion-content__item item-title">
              <p>Единый сервис пополнения рекламных кабинетов</p>
              <span>Оптимизируйте расходы, получайте кэшбэк со всех платежей и управляйте рекламой выгодно</span>
            </div>
            <div class="promotion-content__item item-link">
              <a class="service-detail" href="https://profitads.ru/" target="_blank">Подробнее о сервисе</a>
            </div>
          </div>
          <div class="promotion-content__item">
              <div class="social-icons-list">
                <div class="social-item"><img src="../assets/icons/small-google.svg" alt=""></div>
                <div class="social-item"><img src="../assets/icons/small-telegram.svg" alt=""></div>
                <div class="social-item"><img src="../assets/icons/small-vk-reklama.svg" alt=""></div>
                <div class="social-item"><img src="../assets/icons/small-yandex.svg" alt=""></div>
                <div class="social-item"><img src="../assets/icons/small-vkontakte.svg" alt=""></div>
                <div class="social-item"><img src="../assets/icons/small-mytarget.svg" alt=""></div>
                <div class="social-item"><img src="../assets/icons/small-tiktok.svg" alt=""></div>
                <div class="social-item"><img src="../assets/icons/small-avito.svg" alt=""></div>
                <div class="social-item"><img src="../assets/icons/small-metrika-pro.svg" alt=""></div>
                <div class="social-item"><img src="../assets/icons/small-yandex-promo.svg" alt=""></div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="fast-registration-page__register-form">
      <div v-if="locale==='RU'">
        <FormElement v-if="!phoneVerified || !showSendVerify" :title="$t('registration.title')" v-loading="loading">
          <!-- name -->
          <div class="form-input" :class="[
            { 'form-input--full': form.name },
            { 'form-input--error': errors.name || (authErrors && authErrors.name) }
          ]">
            <input v-model="form.name" type="text" required>
            <span class="form-input__label">
              {{ $t('name') }}
            </span>
            <div v-if="errors.name" class="form-input__error" v-text="errors.name" />
            <div v-if="authErrors && authErrors.name" class="form-input__error">
              <p v-for="(err, index) in authErrors.name" :key="index">
                {{ err }}
              </p>
            </div>
          </div>
          <!--end name -->
          <!-- phone -->
          <div class="phone-wrap">
            <div 
              class="form-input" 
              v-if="phoneSend"
              :class="[
                { 'form-input--full': form.phone },
                { 'form-input--error': errors.phone || (authErrors && authErrors.phone) }
          ]">
              <input v-if="locale === 'RU'" v-model="form.phone" type="tel"  required>
              <input v-if="locale === 'USA'" v-model="form.phone" type="tel" v-mask="'+##########'" required>
              <span class="form-input__label">{{ $t('phone') }} </span>
              <div v-if="phoneVerified" class="registration-page__phone-verified-icon">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5402 2.03515C8.87897 2.28222 6.41222 3.53417 4.64172 5.53715C2.87219 7.54007 1.93272 10.1426 2.01472 12.8134C2.09772 15.4854 3.19539 18.0244 5.08497 19.9149C6.97559 21.8045 9.51472 22.9021 12.1865 22.9851C14.8575 23.0672 17.46 22.1277 19.4627 20.3581C21.4656 18.5876 22.7177 16.1209 22.9647 13.4596C23.2421 10.3531 22.1278 7.2829 19.9227 5.0769C17.7167 2.87182 14.6465 1.75765 11.54 2.0349L11.5402 2.03515ZM18.1477 10.1329L11.925 16.2901C11.5373 16.6681 10.9181 16.6681 10.5304 16.2901L6.86244 12.7871C6.46399 12.4043 6.45034 11.7705 6.83412 11.3711C7.21692 10.9727 7.85072 10.959 8.25015 11.3428L11.2101 14.1826L16.7501 8.7098C17.1456 8.3387 17.7648 8.34945 18.1466 8.73617C18.5275 9.12192 18.5314 9.74202 18.1554 10.1326L18.1477 10.1329Z" fill="#219653"/>
                </svg>
              </div>
              <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
              <div v-if="authErrors && authErrors.phone" class="form-input__error">
                <p v-for="(err, index) in authErrors.phone" :key="index">
                  {{ err }}
              </p>
              </div>
            </div>
            <div v-if="!phoneVerified && !phoneSend" class="fast-registration-page__phone-row">
            <div>
              <div class="form-input-width-country-select">
                <div class="country-select-wrapper">
                  <el-select 
                    v-model="countryValue" 
                    placeholder="Страна"
                    popperClass="country-select"
                    @change="selectCountry"
                  >
                    <template v-slot:prefix>
                      <img class="country-select__flag" :src="flagImagePath" alt="flag-icon">
                    </template>
                      <el-option
                        v-for="item in countryOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        >
                      </el-option>  
                  </el-select>
                </div>
                <div
                  :key="'phone'"
                  class="form-input" 
                  :class="
                  [
                    {'form-input--full' : getPhone},
                    {'form-input--error' : errors.phone || (authErrors && authErrors.phone) || authErrors}
                  ]"
                >
                  <input 
                    ref="phone"
                    :value="getPhone"
                    @input="getPhone = $event.target.value"
                    type="tel" 
                    v-mask="getPhoneMask" 
                    required 
                    :disabled="phoneSend"
                  >
                  <span class="form-input__label">{{$t('phone')}} </span>
                </div>
              </div>
             
              <!-- <div>
                <ButtonElement
                  :text="$t('button_props.confirm')"
                  @click-button="sendPhone"
                />
              </div> -->
            </div>
            <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
            <div v-if="authErrors && authErrors.phone" class="form-input__error">
              <p v-for="(err, index) in authErrors.phone" :key="index">
                {{ err }}
              </p>
            </div>
          <div v-if="phoneSend && smsTimer" class="registration-page__phone-row-message">{{ $t('registration.resendCodeTimeout') }} <span>{{ smsTimerCount }}</span> {{ $t('registration.resendCodeTimeout2') }}</div>
          <div v-if="phoneSend && !smsTimer" class="registration-page__phone-row-message is-button" @click="sendPhone('resend')">{{ $t('registration.resendCode') }}</div>
        </div>
            <!-- <div class="phone-submit">
              <ButtonElement :text="$t('login.submit')" />
            </div> -->
          </div>

          <!-- end phone section -->
          <!-- email -->
          <div class="form-input" :class="[
            { 'form-input--full': form.email },
            { 'form-input--error': errors.email || (authErrors && authErrors.email) }
          ]">
            <input v-model="form.email" type="text" required>
            <span class="form-input__label">
              {{ $t('email') }}
            </span>
            <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
            <div v-if="authErrors && authErrors.email" class="form-input__error">
              <p v-for="(err, index) in authErrors.email" :key="index">
                {{ err }}
              </p>
            </div>
          </div>
          <!-- end email -->
          <div class="registration-page__agree">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  value="'all'"
                  required
                  v-model="form.isAgreeAdds"
              >
              <span class="input__item-checkbox__check">
                <img src="@/assets/icons/icon-done.svg">
              </span>
              <span class="input__item-checkbox__info">
                <span class="input__item-checkbox__title">
                  {{$t('registration.agree_ads')}}
                  <a href="https://profitads.ru/soglasie-na-poluchenie-reklamy/" target="_blank">{{$t('registration.get_adds')}}</a>
                </span>
              </span>
            </label>
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  value="'all'"
                  required
                  v-model="form.isAgreePolicy"
              >
              <span class="input__item-checkbox__check">
                <img src="@/assets/icons/icon-done.svg">
              </span>
              <span class="input__item-checkbox__info">
                <span class="input__item-checkbox__title">
                  {{$t('registration.agree_with')}}
                  <a href="https://profitads.ru/privacy-policy" target="_blank">{{$t('registration.privacy_policy')}}</a>
                  {{$t('registration.and')}}
                  <a href="https://profitads.ru/agreement" target="_blank">{{$t('registration.user_agreement')}}</a>
                </span>
              </span>
            </label>
            <div v-if="errors.isAgree" class="form-input__error" v-text="errors.isAgree" />
          </div>
          <div v-if="phoneSend && !phoneVerified" class="registration-page__phone-row">
            <div class="registration-page__phone-row-inner">
              <div>
                <div 
                  v-if="phoneSend"
                  :key="'smsCode'"
                  class="form-input" 
                  :class="
                  [
                    {'form-input--full' : phoneSmsCode}
                  ]"
                >
                  <input v-model="phoneSmsCode" type="text">
                  <span class="form-input__label">{{$t('smsCode')}} </span>
                  <div v-if="errors.phoneSmsCode" class="form-input__error" v-text="errors.phoneSmsCode" />
                </div>
              </div>
              <div>
                <ButtonElement
                  v-if="phoneSend"
                  :text="$t('button_props.confirm')"
                  @click-button="checkSmsCode"
                />
              </div>
            </div>
            <div v-if="phoneSend && smsTimer" class="registration-page__phone-row-message">{{ $t('registration.resendCodeTimeout') }} <span>{{ smsTimerCount }}</span> {{ $t('registration.resendCodeTimeout2') }}</div>
            <div v-if="phoneSend && !smsTimer" class="registration-page__phone-row-message is-button" @click="sendPhone('resend')">{{ $t('registration.resendCode') }}</div>
          </div>
          <ButtonElement
            v-if="!phoneSend && !isRegister"
            :text="$t('login.next')"
            @click-button="register"
          />
          <ButtonElement
            v-if="isRegister"
            :text="$t('login.register')"
            :disabled="locale === 'RU' && !phoneVerified"
            @click-button="showVerify"
          />
          <div class="registration-page__link-auth">
            {{ $t('registration.have_account') }}
            <router-link :to="{ name: 'Login' }">{{ $t('registration.log_in') }}</router-link>
          </div>
        </FormElement>
      </div>
      <div v-if="locale==='USA'">
        <FormElement :title="$t('registration.title')">
          <!-- name -->
          <div class="form-input" :class="[
            { 'form-input--full': form.name },
            { 'form-input--error': errors.name || (authErrors && authErrors.name) }
          ]">
            <input v-model="form.name" type="text" required>
            <span class="form-input__label">
              {{ $t('name') }}
            </span>
            <div v-if="errors.name" class="form-input__error" v-text="errors.name" />
            <div v-if="authErrors && authErrors.name" class="form-input__error">
              <p v-for="(err, index) in authErrors.name" :key="index">
                {{ err }}
              </p>
            </div>
          </div>
          <!--end name -->
          <!-- phone -->
          <div class="phone-wrap">
            <div class="form-input" :class="[
            { 'form-input--full': form.phone },
            { 'form-input--error': errors.phone || (authErrors && authErrors.phone) }
          ]">
              <input v-if="locale === 'RU'" v-model="form.phone" type="tel" v-mask="'+7(###)###-##-##'" required>
              <input v-if="locale === 'USA'" v-model="form.phone" type="tel" v-mask="'+##########'" required>
              <span class="form-input__label">{{ $t('phone') }} </span>
              <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
              <div v-if="authErrors && authErrors.phone" class="form-input__error">
                <p v-for="(err, index) in authErrors.phone" :key="index">
                  {{ err }}
              </p>
              </div>
            </div>
            <!-- <div class="phone-submit">
              <ButtonElement :text="$t('login.submit')" />
            </div> -->
          </div>

          <!-- end phone section -->
          <!-- email -->
          <div class="form-input" :class="[
            { 'form-input--full': form.email },
            { 'form-input--error': errors.email || (authErrors && authErrors.email) }
          ]">
            <input v-model="form.email" type="text" required>
            <span class="form-input__label">
              {{ $t('email') }}
            </span>
            <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
            <div v-if="authErrors && authErrors.email" class="form-input__error">
              <p v-for="(err, index) in authErrors.email" :key="index">
                {{ err }}
              </p>
            </div>
          </div>
          <!-- end email -->
          <div class="registration-page__agree">
            <label class="input__item-checkbox">
              <input type="checkbox" value="'all'" required v-model="form.isAgree">
              <span class="input__item-checkbox__check">
                <img src="@/assets/icons/icon-done.svg">
              </span>
              <span class="input__item-checkbox__info">
                <span class="input__item-checkbox__title">
                  {{ $t('registration.agree_with') }}
                  <router-link to="/privacy-policy">{{ $t('registration.privacy_policy') }}</router-link>
                  {{ $t('registration.and') }}
                  <router-link to="/agreement">{{ $t('registration.user_agreement') }}</router-link>
                </span>
              </span>
            </label>
            <div v-if="errors.isAgree" class="form-input__error" v-text="errors.isAgree" />
          </div>
          <ButtonElement :text="$t('login.register')" @click-button="registerUSA" />
          <div class="registration-page__link-auth">
            {{ $t('registration.have_account') }}
            <router-link :to="{ name: 'Login' }">{{ $t('registration.log_in') }}</router-link>
          </div>
        </FormElement> 
      </div>
      
    </div>
    <!-- Notification -->
    <div v-if="phoneVerified && showSendVerify && isRegister" class="fast-registration-success" @click.self="closePopap">
      <div class="success-popap">
        <img class="success-popap__rocket" src="../assets/images/rocket-group.png" alt="rocket">
        <div class="success-popap__content">
          <h1>
            Полный успех! Поехали!
          </h1>
          <div class="success-popap__email">
            <img src="../assets/icons/icon-message.svg" alt="" />
            <p>Проверьте почту и поменяйте пароль по ссылке.</p>
          </div>
          <div class="success-popap__submit">
            <router-link class="button" :to="{ name: 'Login' }">{{ $t('registration.log_in') }}</router-link>
          </div>
        </div>
        <img @click.self="closePopap" src="../assets/icons/icon-close-gray.svg" class="close" alt="close">
        <img class="success-popap__money" src="../assets/images/money-group.png" alt="money">
      </div>
    </div>
    <!-- <div v-if="isRegister" class="fast-registration-success" @click.self="closePopap">
      <div class="success-popap">
        <img class="success-popap__rocket" src="../assets/images/rocket-group.png" alt="rocket">
        <div class="success-popap__content">
          <h1>
            Полный успех! Поехали!
          </h1>
          <div class="success-popap__email">
            <img src="../assets/icons/icon-message.svg" alt="" />
            <p>Проверьте почту и поменяйте пароль по ссылке.</p>
          </div>
          <div class="success-popap__submit">
            <router-link class="button" :to="{ name: 'Login' }">{{ $t('registration.log_in') }}</router-link>
          </div>
        </div>
        <img @click.self="closePopap" src="../assets/icons/icon-close-gray.svg" class="close" alt="close">
        <img class="success-popap__money" src="../assets/images/money-group.png" alt="money">
      </div>
    </div> -->
  </div>
</template>
  
<script>
import FormElement from "../components/elements/FormElement";
import ButtonElement from "../components/elements/ButtonElement";
import formValidate from "../mixin/formValidate";

export default {
  name: "RegistrationPromotion",
  metaInfo: {
		title: `${process.env.VUE_APP_LOCALE === 'USA' ? 'Replenishment of advertising offices - Digital eagle' : 'Пополнение рекламных кабинетов - сервис ProfitAds'}`
	},
  components: {
    FormElement,
    ButtonElement
  },
  mixins: [formValidate],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      form: {
        email: null,
        phone: null,
        type: 'individual',
        isAgree: false,
        name: null,
        isAgreePolicy: false,
        isAgreeAgreement: false,
        isAgreeAdds: false
      },
      errors: {
        email: null,
        phone: null,
        type: null,
        isAgree: null,
        name: null
      },
      isRegister: false,
      checkingPassword: false,
      isContainNumber: false,
      isContainLetter: false,
      isContainBigLetter: false,
      isNotice: false,
      phoneVerified: false,
      showSendVerify: false,
      loading: false,
      phoneSend: false,
      phoneSmsCode: '',
      smsTimer: null,
      smsTimerCount: 0,
      
      phoneMasksList: [
        {code:'RU', mask: '+7 (###) ###-##-##'},
        {code:'BY', mask: '+375 (##) ###-##-##'},
        {code:'KZ', mask: '+7 (###) ###-##-##'}
      ],
      maskFormPhone: "+7",
      countryValue: 'RU',
      flagImagePath: require('@/assets/images/flag-russia.png'),
      countryOptions: [
        {
          value: 'BY',
          label: 'BY',
          flag: require('@/assets/images/flag-belarus.png')
        }, 
        {
          value: 'RU',
          label: 'RU',
          flag: require('@/assets/images/flag-russia.png')
        }, 
        {
          value: 'KZ',
          label: 'KZ',
          flag: require('@/assets/images/flag-kazakhstan.png')
        }
      ],
    }
  },
  computed: {
    getPhone: {
      get() {
        return this.maskFormPhone
      },
      set(value) {
        this.form.phone = value.replace(/[()\s-]/g, '');
        this.maskFormPhone = value
      }
    },
    unmaskedPhone() {
      const re = new RegExp('-', 'g');
      if (this.form.phone) {
        const phone = this.form.phone.replace(re, '').replace('(', '').replace(')', '')
        return phone
      }
      return ''
    },
    getPhoneMask() {
      let maskValueString
      const codeObj =  this.phoneMasksList.find(value =>  value.code === this.countryValue )
      maskValueString = codeObj ? codeObj.mask : '';
      return maskValueString
    }
  },
  watch: {
    isRegister: {
      deep: true,
      handler(val) {
        if (val) {
          let body = document.querySelector('body')
          body.style.overflow = 'hidden'
        }
        if (!val) {
          let body = document.querySelector('body')
          body.style.overflow = 'auto'
        }
      }
    },
    phoneSmsCode() {
      this.errors.phoneSmsCode = null
    }
  },
  methods: {
    register() {
      this.validateForm()
      if (this.isError) {
        return false
      }
      const data = this.form
      data.phone = this.phoneFormat(this.form.phone)
      data.privacy_policy_accepted = true
      data.user_agreement_accepted = true
      if(this.countryValue ==='KZ' && !this.isKazakhstanPhoneNumber(data.phone)) {
          this.errors.phone = this.$t('registration.incorrect_phone')
          return false
      }
      this.loading = true
      this.$store.dispatch('Auth/registerFast', data)
        .then(() => {
          this.isRegister = true
          this.$metrika.reachGoal('form_submit')
          if(this.countryValue ==='KZ' && this.isKazakhstanPhoneNumber(data.phone)) {
            this.showSendVerify = true
            this.phoneVerified = true
            this.countryValue = 'RU'
            this.form =  {
              email: null,
              phone: null,
              type: 'individual',
              isAgree: false,
              name: null,
              isAgreePolicy: false,
              isAgreeAgreement: false,
            }
            throw new Error('Skipping to catch and finally');
          }
        })
        .then(()=> {
          this.sendPhone()
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    registerUSA() {
      this.validateForm()
      if (this.isError) {
        return false
      }
      const data = this.form
      data.phone = this.phoneFormat(this.form.phone)
      data.privacy_policy_accepted = true
      data.user_agreement_accepted = true

      this.$store.dispatch('Auth/registerFast', data)
        .then(() => {
          this.isRegister = true
          this.$metrika.reachGoal('form_submit')
          this.form.email = null
          this.form.phone = null
          this.form.isAgree = false
          this.form.name = null
        })
        .catch(err => console.log(err))
    },
    validateForm() {
      if (!this.form.isAgreePolicy || !this.form.isAgreeAdds ) {
        this.errors.isAgree = this.$t('registration.should_agree')
      }
      if (!this.form.name) {
        this.errors.name = this.$t('registration.input_name')
      }
      if (!this.form.email) {
        this.errors.email = this.$t('registration.input_email')
      } else {
        this.validateEmail()
      }
      if (!this.form.phone) {
        this.errors.phone = this.$t('registration.input_phone')
      } else {
        const phoneIsValid = this.locale === 'RU' 
          ? this.countryValue === 'BY'
            ? (/^\+375 \(\d{2}\) \d{3}-\d{2}-\d{2}$/).test(this.maskFormPhone)
            : (/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/).test(this.maskFormPhone)
          : (/^(\+)?([0-9]+)$/).test(String(this.form.phone))

        if (!phoneIsValid) {
          this.errors.phone = this.$t('registration.correct_number')
        }
      }
    },
    repeatVerify() {
      this.$store.dispatch('Auth/repeatVerifyFromRegistration', { email: this.form.email })
        .then(() => this.isNotice = true)
        .catch(err => {
          console.log(err)
        })
    },
    phoneFormat(val) {
      const regex = /[()\-\s]/g;
      if (val) {
        const result = val.replace(regex, '');
        return result;
      }
      return ''
    },
    closePopap() {
      this.isRegister = false
      this.phoneSend = false
      this.phoneVerified = false
      this.showSendVerify = false
      this.phoneSmsCode = ''
      this.maskFormPhone = ''
      this.countryValue = 'RU'
      this.form =  {
        email: null,
        phone: null,
        type: 'individual',
        isAgree: false,
        name: null,
        isAgreePolicy: false,
        isAgreeAgreement: false,
      }
    },
    showVerify() {
      this.showSendVerify = !this.showSendVerify
    },
    isKazakhstanPhoneNumber(phoneNumber) {
      if (typeof phoneNumber !== 'string') {
        phoneNumber = String(phoneNumber);
      }
      phoneNumber = phoneNumber.replace(/\s|\(|\)|-/g, '');
      if (phoneNumber.startsWith('+7')) {
        let networkCode = phoneNumber.slice(2, 5);
        if (networkCode.startsWith('6') || networkCode.startsWith('7')) {
          return true;
        }
      }
      return false;
    },
    sendPhone(type) {
      if (!this.form.phone) {
        this.errors.phone = this.$t('registration.input_phone')
        return false
      } else {
        const regPhone = this.locale==='RU' ? /^\+\d+$/ : (/^(\+)?([0-9]+)$/)
        if (!regPhone.test(String(this.form.phone))) {
          this.errors.phone = this.$t('registration.correct_number')
          return false
        }
      }
      this.loading = true
      this.$store.dispatch(type === 'resend' ? 'Auth/resendSmsCode' : 'Auth/sendSmsCode', { phone: this.unmaskedPhone })
        .then((data) => {
          if (data.data && data.data.success) {
            this.phoneSend = true
            this.smsTimerCount = 60
            this.smsTimer = setInterval(() => {
              this.smsTimerCount--
              if (!this.smsTimerCount) {
                clearInterval(this.smsTimer)
                this.smsTimer = null
              }
            }, 1000);
          } else {
            console.log(data.data.errors)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectCountry() {
      const selectedCountry = this.countryOptions.find(item => item.value === this.countryValue);
      
      this.getPhone = selectedCountry.label === 'RU' || selectedCountry.label === 'KZ'
        ? '+7 '
        : '375 '

      if (selectedCountry) {
        this.flagImagePath = selectedCountry.flag;
      }
      this.$nextTick(() => {
        this.$refs.phone.focus()
      })
    },
    checkSmsCode() {
      if (!this.phoneSmsCode) {
        this.errors.phoneSmsCode = this.$t('registration.no_sms_code')
        return false
      }
      this.loading = true
      this.$store.dispatch('Auth/checkSmsCode', { phone: this.unmaskedPhone, code: this.phoneSmsCode })
        .then((data) => {
          if (data.data && data.data.success) {
            this.phoneVerified = true
            this.showSendVerify = true
          } else {
            this.$notify({
              message: this.$t('registration.falseCode'),
              type: 'error'
            });
          }       
        })
        .catch(() => {
          this.$notify({
            message: this.$t('registration.falseCode'),
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  mounted() {
    // this.countdown("2023-12-31 23:59:59")
    // if (this.$route.query && this.$route.query.utm_partner) this.form.partner = this.$route.query.utm_partner
    // if (this.$route.query && this.$route.query.email) {
    //   this.form.email = this.$route.query.email
    // }
    //  const footer = document.querySelector('.footer')
    //  footer.style.display= 'none'
    if (this.isRegister) {
      let body = document.querySelector('body')
      body.style.overflow = 'hidden'
      // body.classList.add('scroll-overflow')
    }
  },
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.fast-registration-page {
  &__phone-row {
    width: 100%;
  }
}

.phone-wrap {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  .button {
    margin: 0;
    background: #fff;
    color: var(--primary);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 1.3rem 2.4rem;
  }
}

.social-icons-list {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  // justify-content: space-between;
  .social-item {
    img {
      display: inline-block;
      width: 53px;
    }
  }
}
.service-detail {
  color: var(--primary);
  background: #fff;
  padding: 15px 32px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.scroll-overflow {
  overflow: hidden;
}

.fast-registration-success {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.40);
  z-index: 99;
}

.success-popap {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 680px;
  padding: 4.3rem 15rem 4.3rem 30rem;
  padding: 43px 150px 43px 300px;
  background-color: #FFF;
  border-radius: 13px;
  // min-width: 68rem;

  &__content {
    max-width: 270px;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    h1 {
      font-family: 'Roboto';
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #333;
    }
  }

  &__email {
    display: flex;
    align-items: center;
    gap: 0.8rem;
      p {
        font-family: 'Roboto';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color:#333;
        white-space: normal;
      }
  
  }

  &__rocket {
    display: inline-block;
    max-width: 100%;
    position: absolute;
    left: 0;
    bottom: -1px;
    z-index: -1;
  }

  &__money {
    display: inline-block;
    max-width: 100%;
    position: absolute;
    right: 0;
    bottom: 10%;
    z-index: -1;
  }

  &__submit {
    .button {
      background: none;
      background-color: #000;
      color: #fff;
      outline: none;
      border: none;
      border-radius: 4px;
      padding: 13px 84px;
      font-family: 'Roboto';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.fast-registration-page {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100vh;

  &__register-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    flex-direction: column;
    padding-bottom: 4rem;
    width: 50%;

    // .social-icons-list {
    //   padding: 0 4rem;
    //   display: flex;
    //   gap: 12px;
    //   margin-top: auto;
    // }

    .form-element {
      box-shadow: none;
      // margin-top: auto;

      &__title {
        text-align: center;
      }
    }

    .form-element__title {
      text-align: center;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #9CAFF1;
    opacity: 50%;
  }

  &__promotion {
    color: #FFFFFF;
    width: 50%;

    .promotion-wrapper {
      height: 100%;
      width: 100%;
      background-color: var(--primary);
      padding:  4rem;
    }

    .white-logo {
      max-width: 20rem;
      margin-bottom: 2rem;
    }

    .promotion-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      &__item {
        padding-bottom: 1.4rem;
        padding-top: 1.4rem;

        .platform-discount {
          display: flex;
          justify-content: space-between;

          .discount-value {
            position: relative;
            width: 110px;
            height: 110px;

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #FFF;
              font-family: 'Roboto';
              font-size: 2.8rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
        }

        .discount-content {
          max-width: 48rem;
          display: flex;
          flex-direction: column;
          gap: 1.2rem;

          p,
          a {
            color: #D6E0F2;
            font-family: 'Roboto';
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          a {
            text-decoration: underline;
          }
        }

        .platform-name {
          display: flex;
          gap: 1.6rem;
          padding-right: 3.6rem;
          align-items: center;

          span {
            font-family: 'Roboto';
            font-size: 2.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        &.item-title {
          display: flex;
          flex-direction: column;
          gap: 3.2rem;
          p {
            font-family: 'Roboto';
            font-size: 4rem;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            // max-width: 390px;
          }
          span {
              max-width: 260px;
              color:#D6E0F2;
              font-family: 'Roboto';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
          }
        }
        &.item-link {
          margin-top: 6.4rem;
        }
      }
    }
  }

  &__agree {
    margin-top: 2rem;

    .input__item-checkbox {
      align-items: flex-start;

      &__title {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $text;
      
        a {
          color: var(--primary);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__link-auth {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $grey1;
    text-align: center;

    a {
      color: var(--primary);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__notice {
    background: #FFFFFF;
    box-shadow: 0 5px 10px rgba(158, 158, 158, 0.1);
    border-radius: 5px;
    width: 43rem;
    padding: 4rem;

    &-title {
      font-weight: bold;
      font-size: 2rem;
      line-height: 2.3rem;
      color: $basicBlack;
      margin-bottom: 0.8rem;
    }

    &-text {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $basicBlack;
      margin-bottom: 4rem;
    }

    &-link {
      border-radius: 4px;
      background: var(--primary);
      color: $basicWhite;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.9rem;
      transition: 0.3s ease-out;

      &.locale-usa:hover {
        background: var(--primary)
      }

      &:hover {
        background: $primaryHover;
      }
    }

    &-repeat {
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-align: center;
      text-decoration: underline;
      color: var(--primary);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__types {
    padding-top: 2rem;

    label {

      display: flex;
      margin-bottom: .5rem;
      cursor: pointer;

      input {
        margin-right: 1rem;
      }
    }
  }

  @include below(577px) {

    &__agree {
      margin-top: 0;
      margin-bottom: 16px;

      .input__item-checkbox__title {
        font-size: 14px;
        line-height: 1.4;
      }
    }

    &__link-auth {
      font-size: 14px;
      line-height: 1.4;
    }
    .phone-wrap {
      .button {
        margin-bottom: 16px;
      }
    }
    .social-icons-list {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 992px) {
  .fast-registration-page {
    flex-direction: column;

    &__promotion {
      order: 2;
      width: 100%;
    }

    &__register-form {
      order: 1;
      width: 100%;
    }
    .promotion-content__item:nth-last-child(1) {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 576px) {
  .success-popap__rocket , .success-popap__money {
    display: none;
  }

  .success-popap {
    padding: 4rem;
  }
}

@media (max-width: 540px) {
  .social-icons-list {
    .social-item {
    img {
      width: 32px;
    }
  }
  }
}

@media (max-width: 440px) {
  .fast-registration-page {
    .promotion-content {
      &__item {
        .platform-name {
          padding-right: 0;
          span {
            font-size: 1.4rem;
          }
        } 
      }
    }
  }
}
</style>
  
<style lang="scss">
.form-input-width-country-select {
  .country-select-wrapper {
    font-family: 'Roboto, sans-serif';
    max-width: 11rem;
    .el-select {
      input[type='text'] {
        height: 5rem;
        border: 1px solid #BFC2C6;
        border-radius: 2px;
        padding-left: 10px;
        font-size: 14px;
        color: rgb(135, 139, 147) 
      }
      .el-input {
        &__prefix:nth-child(2) {
          display: flex;
          align-items: center;
          left: 50%;
          transform: translateX(-40%);
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .country-select {
      &__flag {
        width: 20px;
        height: 20px;
      }
    }
  }
  .el-select-dropdown.country-select {
    height: 6rem;
  }
  .form-input {
    margin-top: 0;
    margin-bottom: 0;
  }
    display: flex;
    gap: 1rem;
    align-items: center;
}
</style>