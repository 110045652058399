<template>
  <div class="facebook-form">
    <div class="ad-page__default-img">
      <img :src="getMultiPic(['accounts-default.svg','accounts-default-usa.svg'])" alt="img" title="img">
    </div>
    <div class="facebook-form__title" v-html="$t('facebook_form.title')">
    </div>
    <div v-if="showInput" class="facebook-form__content">
      <div
          class="form-input"
          :class="
        [
          {'form-input--full' : form.page_link}
        ]"
      >
        <input v-model="form.page_link" type="text" required>
        <span class="form-input__label">
        {{$t('facebook_form.landing_link')}}
      </span>
      </div>
      <div class="facebook-form__files">
        <div v-if="files && files.length" class="facebook-form__files-list">
          <div
            v-for="(file, idx) in files"
            :key="idx"
            class="facebook-form__files-item"
          >
            <div class="facebook-form__files-name">
              {{ file.name }}
            </div>
            <div class="facebook-form__files-error">
              {{ file.errorText }}
            </div>
            <div
              v-if="file.errorText"
              class="facebook-form__files-remove"
              @click="removeFile(idx)"
            >
              <img src="../../assets/icons/icon-close.svg" alt="">
            </div>
          </div>
        </div>
        <label class="facebook-form__file">
          <input type="file" multiple @change="changedFile">
          <span>
            {{$t('facebook_form.add_media')}}
          </span>
        </label>
      </div>
      <div class="facebook-form__radio">
        <div class="facebook-form__radio-text">
          {{$t('facebook_form.facebook_ban')}}
        </div>
        <div class="facebook-form__radio-items">
          <label>
            <input type="radio" :value="false" v-model="form.is_baned">
            <span>{{$t('facebook_form.facebook_form_radio_items.0')}}</span>
          </label>
          <label>
            <input type="radio" :value="true" v-model="form.is_baned">
            <span>{{$t('facebook_form.facebook_form_radio_items.1')}}</span>
          </label>
        </div>
      </div>
      <div
          v-if="form.is_baned"
          class="form-input"
          :class="
        [
          {'form-input--full' : form.ban_reason}
        ]"
      >
        <textarea v-model="form.ban_reason" rows="5" />
        <span class="form-input__label">
          {{$t('facebook_form.ban_reason')}}
        </span>
      </div>
      <div
          class="form-input"
          :class="
        [
          {'form-input--full' : form.more_page_links}
        ]"
      >
        <input v-model="form.more_page_links" type="text" required>
        <span class="form-input__label">
          {{$t('facebook_form.link_to_the_additional_page')}}
        </span>
      </div>
      <div
          class="form-input"
          :class="
        [
          {'form-input--full' : form.preferred_name}
        ]"
      >
        <input v-model="form.preferred_name" type="text" required>
        <span class="form-input__label">
          {{$t('facebook_form.preferred_name')}}
        </span>
      </div>
      <div
          class="form-input"
          :class="
        [
          {'form-input--full' : form.email}
        ]"
      >
        <input v-model="form.email" type="text" required>
        <Tooltip :data="toolTipEmail"/>
        <span class="form-input__label">
          {{$t('facebook_form.email')}}
        </span>
      </div>
      <div
          class="form-input"
          :class="
        [
          {'form-input--full' : form.timezone}
        ]"
      >
        <input v-model="form.timezone" type="text" required>
        <Tooltip :data="toolTimezone"/>
        <span class="form-input__label">
          {{$t('facebook_form.timezone')}}
        </span>
      </div>
      <div
          class="form-input"
          :class="
        [
          {'form-input--full' : form.pixelId}
        ]"
      >
        <input v-model="form.pixelId" type="text" required>
        <span class="form-input__label">
          {{$t('facebook_form.pixelId')}}
        </span>
      </div>
      <div class="facebook-form__remark">
        {{$t('facebook_form.remark')}}
      </div>
      <ButtonElement
        :text="$t('button_props.send')"
        view="empty"
        :disabled="!form.page_link || !!files.find(el => el.errorText) || !form.preferred_name || !form.email || !form.timezone "
        @click-button="send"
      />
    </div>
    <ButtonElement 
      v-if="!showInput" 
      class="ad-page__no-data-add" 
      :text="$t('button_props.add_account')"
      @click-button="showInput = true" 
    />
    <div
      v-if="showInput" 
      class="ad-page__no-data-back"
      :class="{'locale-usa': locale === 'USA'}" 
      @click="showInput = false"
    >
      {{$t('back')}}
    </div>
  </div>
</template>

<script>
import ButtonElement from '../elements/ButtonElement'
import Tooltip from '../elements/Tooltip.vue';
export default {
  name: 'FacebookForm',
  components: {
    ButtonElement,
    Tooltip
  },
  data() {
    return {
      form: {
        page_link: '',
        more_page_links: '',
        ban_reason: '',
        is_baned: false,
        preferred_name:'',
        email:'',
        timezone: '',
        pixelId: ''
      },
      maxSize: 5242880,
      acceptFiles: 'image/*, .mp4, .avi', 
      filesExpansion: ['image/png', 'image/jpg', 'image/jpeg', 'mp4', 'avi', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'text/plain', 'video/mp4', 'video/avi'],
      files: [],
      locale: process.env.VUE_APP_LOCALE,
      showInput: false
    }
  },
  computed:{
    toolTipEmail() {
      return this.$t('facebook_form.tooltip_email')
    },
    toolTimezone() {
      return this.$t('facebook_form.tooltip_timezone')
    },
    
  },
  methods: {
    send() {
      const data = new FormData()
      for (let key in this.form) {
        if (this.form[key]) data.append(key, this.form[key])
      }
      data.append('business_account_id', this.$store.state.Auth.activeAccount.id)
      data.append('is_baned', this.form.is_baned)
      if (this.files && this.files.length) {
        this.files.forEach(el => {
          data.append('files[]', el)
        })
      }
      this.$store.dispatch('Ad/addFacebookAccount', data)
        .then((data) => {
          if (data.data.success) {
            this.$notify({
              message: this.$t('forms_notifies.success'),
              type: 'success'
            })
            this.files = []
            for (let key in this.form) {
              this.form[key] = ''
            }
            this.form.is_baned = false
          } else {
            this.$notify({
              message: this.$t('forms_notifies.error'),
              type: 'error'
            })
          }
        })
    },
    changedFile(e) {
      const files = e.target.files || e.dataTransfer.files
      if (files) {
        for (let key in files) {
          if (typeof files[key] === 'object') this.files.push(files[key])
        }
        this.files.forEach((el) => {
          if(!this.filesExpansion.find(el2 => el2 === el.type)) {
            el.errorText = this.$t('forms_notifies.error_format')
          }
          if(el.size > this.maxSize) {
            el.errorText = this.$t('forms_notifies.error_size')
          }
        })
      }
    },
    removeFile(idx) {
      this.files.splice(idx, 1)
    },
    getMultiPic(data) {
      let result = ''
      if(this.locale==='RU') {
        result = require(`@/assets/images/${data[0]}`)
      } else {
        result = result = require(`@/assets/images/${data[1]}`)
      }
      return result
    },
  }
}
</script>
<style lang="scss" scoped>
  .facebook-form {
    &__files {
      padding: 1rem;
      border: 1px solid var(--primary);
    }
    &__file {
      border: none;
      border-radius: 2px;
    }
    textarea {
        font-family: Arial, sans-serif;
      }
  }
</style>
