<template>
    <div>
        <div class="modal" v-if="isModalOpen">
            <div class="modal-content" ref="modalRef">
                <button class="close-button" @click="closeModal"></button>
                <div class="poll-info">
                    <h1 class="poll-info__title">Оцените нашу работу</h1>
                    <div class="poll-info__stars">
                        <img src="../../assets/icons/icon-stars-sprite.svg" alt="Icon stars">
                    </div>
                    <div class="poll-info__text">
                        <p>Расскажите как сделать наш сервис еще более удобным и полезным для Вас!</p>
                        <p></p>
                    </div>
                    <div class="poll-info__button">
                        <a @click="followTheLink" href="https://n.enjoysurvey.com/ru/survey/11961/index/m_8df30756500f13997ca98ecacc37b563" target="_blank">Пройти опрос</a>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>

export default {
    name: 'PollModal',
    props: {
        isModalOpen: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
            initialBodyOverflow: null
        }
    },
    mounted() {
        document.addEventListener('mouseup', this.closeModalOnClickOutside);  
        this.initialBodyOverflow = document.querySelector('body').style.overflow
        this.setBodyOverflow('hidden')
    },
    methods: {
        closeModal() {
            this.$emit('close-pool-modal')
            this.setBodyOverflow(this.initialBodyOverflow)
            document.removeEventListener('mouseup', this.closeModalOnClickOutside)
        },
        followTheLink() {
          this.$emit('follow-poll')
          document.removeEventListener('mouseup', this.closeModalOnClickOutside)  
        },
        closeModalOnClickOutside(event) {
          const modal = this.$refs.modalRef
          if (!modal.contains(event.target)) {
            this.closeModal();
          }
        },
        setBodyOverflow (value) {
            let body = document.querySelector('body')
            body.style.overflow = value
        }
    }
}
</script>
<style lang="scss" scoped>
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      overflow: auto;
      z-index: 3000;
    }
    .modal-content {
      max-height: 700px;
      height: 100%;
      max-width: 544px;
      width: 100%;
      padding: 40px;
      border-radius: 16px;
      margin: 10vh auto auto;
      position: relative;
      background-color: #EBF3FA;
      background-image: url("../../assets/images/poll-info-background.png");
      background-repeat: no-repeat;
      background-size: 120%;
      background-position: center center;
    }

    .close-button {
        background: none;
        background-image: url("../../assets/icons/icon-close-black.svg");
        position: absolute;
        top: 24px;
        right: 24px;
        border: none;
        cursor: pointer;
        width: 32px;
        height: 32px;
    }
    .poll-info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        &__title {
            color: #395FE4;
            text-align: center;
            font-family: "Roboto";
            font-size: 34px;
            font-style: normal;
            font-weight: 500;
            line-height: 44px;
            padding-top: 20px;
            text-align: center;
        }
        &__stars {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
        }
        &__text {
            color: #333;
            font-family: "Roboto";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            margin-top: 150px;
            p {
                max-width: 190px;
            }
        }
        &__button {
            margin-top: auto;
            transform: translateY(-30px);
            display: flex;
            a {
                padding: 16px 8px;
                background: #F96714;
                display: inline-flex;
                align-items: flex-start;
                justify-content: center;
                border-radius: 4px;
                box-shadow: 0px 4px 10px 0px #B9C6E5;
                max-width: 236px;
                width: 100%;
                color:  #FFF;
                text-align: center;
                font-family: "Roboto";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                margin: auto;
            }
           
        }

    }
    @media screen and (max-width: 500px) {
        .modal-content {
            background-image: none;
            height: auto;
        }
        .poll-info {
            gap: 20px;
            &__text {
                margin: 0;
                p {
                max-width: 100%;
                }
            }
            &__button {
                transform: translateY(0);
                margin: 0;
            }
        }

    }

</style>