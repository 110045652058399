import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
    companyData: null,
})
const getters = {}
const mutations = {
    setData(state, data) {
        state[data.label] = data.data
    },
}
const actions = {
    changeCampaignBudget(ctx, data) {
        return new Promise((resolve, reject) => {
            axios({
                url: `${apiUrl}/advertservice/change-campaign-budget`,
                data,
                method: 'POST'
            })
            .then(resp => {
                resolve(resp)
            })
            .catch(err => {
                console.log(err.response)
                reject(err)
            })
        })
    },
    getFunds(ctx, { data, token }) {
        return new Promise((resolve, reject) => {
          axios({
            url: `${apiUrl}/enrollment-queue`,
            method: 'POST',
            data,
            headers: { 'Authorization': `Bearer ${ token }` }
          })
            .then(resp => {
              resolve(resp)
            })
            .catch(err => {
              console.log(err.response)
              reject(err.response)
            })
        })
      },
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
